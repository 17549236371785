import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.css']
})
export class LeadDetailComponent implements OnInit {
  leadId:any;
  buyerData:any=[];
  allData: any = [];
  productName:any;
  subProductName:any;
  urlfile:any;
  constructor(  private router: Router, private _location: Location,
    private route: ActivatedRoute,private service: JsonPlaceHolderService, ) { }

  ngOnInit(): void {
    this.urlfile= localStorage.getItem('s3BaseUrl');
    this.leadId = this.route.snapshot.paramMap.get('leadID');
    this.getLeadDetail();
  }
  go_back(){
    this._location.back();
  }

  getLeadDetail() {  
    let params = new HttpParams();
    params = params.append('requirementId',this.leadId);
    this.service.getData(apiUrl._getLeadDetail, params)
      .subscribe(async (res) => {
        this.allData = res.data[0]; 
        this.buyerData=this.allData.buyerData[0];     
        console.log(this.allData, this.buyerData, "lead")              
          for(let a =0; a < this.allData?.categoryData.length;a++){
            const pCategory=  this.allData?.categoryData[a]
            if(this.allData?.categoryId==pCategory._id){  
              this.productName = pCategory.categoryName;           
              for(let b =0; b < pCategory?.subCategory.length;b++){
                 if(this.allData?.subCategoryId==pCategory?.subCategory[b]._id){
                   this.subProductName = pCategory?.subCategory[b].name                 
                }
              }             
            }
          }

         
      })
  }

}
