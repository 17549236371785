<body class="order_flow_page">
    <!-- <app-headerwithoutslick></app-headerwithoutslick> -->
    <app-header></app-header>
    <main class="main_cont">

        <button class="btnFulls bxIcon" type="button" data-toggle="" data-target="" aria-controls="" aria-expanded="false" aria-label="Toggle navigation" (click)=openSidebar(1)>
                <span class="fa fa-list"></span>
            </button>

        <!-- Pending Orders -->
        <section class="pending_orders fullBxfilter">
            <div class="sidebar_order" id="sidenav" [ngClass]="{
                'activeSlidebar':slideCheck == 1}">
                <button _ngcontent-ulf-c135="" type="button" data-dismiss="modal" aria-label="Close" class="close"><span _ngcontent-ulf-c135="" aria-hidden="true" (click)=openSidebar(0)>×</span></button>
                <div class="nav-tp">
                    <a (click)="changeState('Seller')" class="{{value=='Seller'?'nav-link active':'nav-link'}}">{{'homePage.buyer.Selling' | translate}}</a>
                    <a (click)="changeState('Buyer')" class="{{value=='Buyer'?'nav-link active':'nav-link'}}">{{'homePage.buyer.Buying' | translate}}</a>
                </div>
                <ul class="side_nav">
                    <li *ngIf="value=='Seller'" (click)="changeState1('PENDING')"><a class="{{value1 == 'PENDING' ? 'active':''}}">Pending Deals </a></li>
                    <li *ngIf="value=='Seller'" (click)="changeState1('ACCEPTED')"><a class="{{value1 == 'ACCEPTED' ? 'active':''}}">Approved Deals </a></li>


                    <li *ngIf="value=='Seller'" (click)="changeState1('CANCEL')"><a class="{{value1 == 'CANCEL' ? 'active':''}}">Cancelled Deals </a></li>



                    <li *ngIf="value=='Buyer'" (click)="changeState1('PENDING')"><a class="{{value1 == 'PENDING' ? 'active':''}}">Pending Deals </a></li>
                    <li *ngIf="value=='Buyer'" (click)="changeState1('ACCEPTED')"><a class="{{value1 == 'ACCEPTED' ? 'active':''}}">Approved Deals </a></li>

                    <li *ngIf="value=='Buyer'" (click)="changeState1('CANCEL')"><a class="{{value1 == 'CANCEL' ? 'active':''}}">Cancelled Deals </a></li>
                </ul>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="value=='Seller' && value1=='PENDING'" class="content_sidebr">
                            <!-- <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                                <div class="col-lg-6 col-md-4">
                                    <a class="m_btn_red btn_red prem_lead">
                                     Premium Leads</a>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status" *ngFor="let allData of allDatas">

                                        <p class="mn_head clr_ylw"><strong>{{'homePage.buyer.dealOpen' | translate}}</strong></p>
                                        <table class="table">

                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        Deal<br><strong>#{{allData?.orderId ? (allData?.orderId) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.businessName' | translate}}
                                                        <br><strong>{{allData?.sellerData[0]?.businessName ? (allData?.sellerData[0]?.businessName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.subCategory' | translate}}
                                                        <br><strong>{{allData?.products?.subCategoryName ? (allData?.products?.subCategoryName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Quantity' | translate}}<br><strong>{{allData?.quantity ? (allData?.quantity) :'--'}}</strong>
                                                    </td>


                                                </tr>
                                                <tr>
                                                    <td>{{'homePage.buyer.unitPrice' | translate}}<br><strong><i
                                                        class="fa fa-rupee"></i>{{allData?.dealAmount ? (allData?.dealAmount) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Date' | translate}}<br><strong>{{allData?.deliveryDate ? (allData?.deliveryDate | date : "MMMM
                                                        d,
                                                        y") :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.deliveryAvailability' | translate}}<br><strong>{{allData?.products?.isDeliveryAvailable  == false ? 'NO' : 'Yes' ? (allData?.products?.isDeliveryAvailable == false ? 'NO' : 'Yes') :'--'}}</strong>
                                                    </td>
                                                    <!-- <td>{{'homePage.buyer.estLoadingdays' | translate}}<br><strong>{{allData?.products?.estimatedLoadingDays ? (allData?.products?.estimatedLoadingDays) :'--'}}</strong>
                                                    </td> -->
                                                </tr>


                                                <tr>

                                                    <td scope="row">Buyer Details
                                                        <br><strong>{{allData?.buyerData[0]?.name ? (allData?.buyerData[0]?.name) :'--'}}</strong>
                                                    </td>
                                                    <td>Buyer Address
                                                        <br><strong>{{allData?.buyerData[0]?.businessAddress?.addressLine1 ? (allData?.buyerData[0]?.businessAddress?.addressLine1) :'--'}}</strong>
                                                    </td>
                                                    <td>Buyer Rating<br>
                                                        <strong>
                                                            <ul class="star_rate">
                                                                <li><i class="fa fa-star"></i></li>
                                                                {{allData?.buyerRating == null? '0' :  allData?.buyerRating | number:'1.1-1'}}

                                                            </ul>
                                                        </strong>
                                                    </td>
                                                    <td><a class="m_btn_red btn_red" (click)="createChatDialog(allData?.productId)">{{'header.menu.iteam7' | translate}}</a>
                                                        <a href="javascript:;" class="m_btn_red btn_red" [routerLink]="'/details/'+allData?._id">Details</a>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="No_found" *ngIf="allDatas?.length == 0">
                                        <span class="norecordIcon"></span>{{'homePage.buyer.noData' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Seller' && value1=='ACCEPTED'" class="content_sidebr">
                            <div class="row">
                                <div class="col-lg-6 col-md-8">
                                    <!-- <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span> -->
                                </div>
                                <div class="col-lg-6 col-md-4">
                                    <!-- <a [routerLink]="['/NewOrdersSeller']" class="m_btn_red btn_red prem_lead">
                                        Premium Leads</a> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr" *ngFor="let allData of allDatas">
                                        <p class="mn_head"><strong>{{'homePage.buyer.dealAccepted' | translate}}</strong>
                                        </p>
                                        <table class="table">

                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        {{'header.menu.iteam6' | translate}}<br><strong>#{{allData?.orderId ? (allData?.orderId) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.businessName' | translate}}
                                                        <br><strong>{{allData?.sellerData[0]?.businessName ? (allData?.sellerData[0]?.businessName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.subCategory' | translate}}
                                                        <br><strong>{{allData?.products?.subCategoryName ? (allData?.products?.subCategoryName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Quantity' | translate}}<br><strong>{{allData?.quantity ? (allData?.quantity) :'--'}}</strong>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{{'homePage.buyer.unitPrice' | translate}}<br><strong><i
                                                        class="fa fa-rupee"></i>{{allData?.dealAmount ? (allData?.dealAmount) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Date' | translate}}<br><strong>{{allData?.deliveryDate ? (allData?.deliveryDate | date : "MMMM
                                                        d,
                                                        y") :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.deliveryAvailability' | translate}}<br><strong>{{allData?.products?.isDeliveryAvailable  == false ? 'NO' : 'Yes' ? (allData?.products?.isDeliveryAvailable == false ? 'NO' : 'Yes') :'--'}}</strong>
                                                    </td>
                                                    <!-- <td>{{'homePage.buyer.estLoadingdays' | translate}}<br><strong>{{allData?.products?.estimatedLoadingDays ? (allData?.products?.estimatedLoadingDays) :'--'}}</strong>
                                                    </td> -->
                                                </tr>
                                                <tr>

                                                    <td scope="row">Buyer Details
                                                        <br><strong>{{allData?.buyerData[0]?.name ? (allData?.buyerData[0]?.name) :'--'}}</strong>
                                                    </td>
                                                    <td>Buyer Address
                                                        <br><strong>{{allData?.buyerData[0]?.businessAddress?.addressLine1 ? (allData?.buyerData[0]?.businessAddress?.addressLine1) :'--'}}</strong>
                                                    </td>
                                                    <td>Buyer Rating<br>
                                                        <strong>
                                                            <ul class="star_rate">
                                                                <li><i class="fa fa-star"></i></li>
                                                                {{allData?.buyerRating == null? '0' :  allData?.buyerRating | number:'1.1-1'}}

                                                            </ul>
                                                        </strong></td>
                                                    <td>
                                                        <a class="m_btn_red btn_red btn_brdr" (click)="openRating(loginModal30, allDatas, 2)" *ngIf="allData?.sellerRating  == null || allData?.duration >= 48">Rating</a><a class="m_btn_red btn_red" (click)="createChatDialog(allData?.productId)">{{'header.menu.iteam7' | translate}}</a>

                                                        <a href="javascript:;" class="m_btn_red btn_red" [routerLink]="'/details/'+allData?._id">Details</a>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="No_found" *ngIf="allDatas?.length == 0">
                                        <span class="norecordIcon"></span> {{'homePage.buyer.noData' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Seller' && value1=='CANCEL'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <!-- <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span> -->
                                </div>
                                <div class="col-lg-6 col-md-4">
                                    <!-- <a class="m_btn_red btn_red prem_lead">
                                       Premium Leads</a> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr cancel_or" *ngFor="let allData of allDatas">
                                        <p class="mn_head"><strong> {{'homePage.buyer.dealCancelled' | translate}}</strong><span class="text_rd">For this order seller rating will imapct on cancelling
                                                orders after confirmation</span></p>
                                        <table class="table">

                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        {{'header.menu.iteam6' | translate}}<br><strong>#{{allData?.orderId ? (allData?.orderId) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.businessName' | translate}}
                                                        <br><strong>{{allData?.sellerData[0]?.businessName ? (allData?.sellerData[0]?.businessName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.subCategory' | translate}}
                                                        <br><strong>{{allData?.products?.subCategoryName ? (allData?.products?.subCategoryName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Quantity' | translate}}<br><strong>{{allData?.quantity ? (allData?.quantity) :'--'}}</strong>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{{'homePage.buyer.unitPrice' | translate}}<br><strong><i
                                                        class="fa fa-rupee"></i>{{allData?.dealAmount ? (allData?.dealAmount) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Date' | translate}}<br><strong>{{allData?.deliveryDate ? (allData?.deliveryDate | date : "MMMM
                                                        d,
                                                        y") :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.deliveryAvailability' | translate}}<br><strong>{{allData?.products?.isDeliveryAvailable  == false ? 'NO' : 'Yes' ? (allData?.products?.isDeliveryAvailable == false ? 'NO' : 'Yes') :'--'}}</strong>
                                                    </td>
                                                    <!-- <td>{{'homePage.buyer.estLoadingdays' | translate}}<br><strong>{{allData?.products?.estimatedLoadingDays ? (allData?.products?.estimatedLoadingDays) :'--'}}</strong>
                                                    </td> -->
                                                </tr>
                                                <tr>

                                                    <td scope="row">Buyer Details
                                                        <br><strong>{{allData?.buyerData[0]?.name ? (allData?.buyerData[0]?.name) :'--'}}</strong>
                                                    </td>
                                                    <td>Buyer Address
                                                        <br><strong>{{allData?.buyerData[0]?.businessAddress?.addressLine1 ? (allData?.buyerData[0]?.businessAddress?.addressLine1) :'--'}}</strong>
                                                    </td>
                                                    <td>Buyer Rating<br>
                                                        <strong>
                                                            <ul class="star_rate">
                                                                <li><i class="fa fa-star"></i></li>
                                                                {{allData?.buyerRating == null? '0' :  allData?.buyerRating | number:'1.1-1'}}

                                                            </ul>
                                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red" (click)="createChatDialog(allData?.productId)">{{'header.menu.iteam7' | translate}}</a>

                                                        <a href="javascript:;" class="m_btn_red btn_red" [routerLink]="'/details/'+allData?._id">Details</a>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="No_found" *ngIf="allDatas?.length == 0">
                                        <span class="norecordIcon"></span> {{'homePage.buyer.noData' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Buyer' && value1=='CANCEL'" class="content_sidebr">
                            <!-- <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr cancel_or" *ngFor="let allData of allDatas">
                                        <p class="mn_head"><strong>{{'homePage.buyer.dealCancelled' | translate}}</strong> <span class="text_rd">For this order seller rating will imapct on cancelling
                                                orders after confirmation</span></p>
                                        <table class="table">

                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        {{'header.menu.iteam6' | translate}}<br><strong>#{{allData?.orderId ? (allData?.orderId) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.businessName' | translate}}
                                                        <br><strong>{{allData?.sellerData[0]?.businessName ? (allData?.sellerData[0]?.businessName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.subCategory' | translate}}
                                                        <br><strong>{{allData?.products?.subCategoryName ? (allData?.products?.subCategoryName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Quantity' | translate}}<br><strong>{{allData?.quantity ? (allData?.quantity) :'--'}}</strong>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{{'homePage.buyer.unitPrice' | translate}}<br><strong><i
                                                        class="fa fa-rupee"></i>{{allData?.dealAmount ? (allData?.dealAmount) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Date' | translate}}<br><strong>{{allData?.deliveryDate ? (allData?.deliveryDate | date : "MMMM
                                                        d,
                                                        y") :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.deliveryAvailability' | translate}}<br><strong>{{allData?.products?.isDeliveryAvailable  == false ? 'NO' : 'Yes' ? (allData?.products?.isDeliveryAvailable == false ? 'NO' : 'Yes') :'--'}}</strong>
                                                    </td>
                                                    <!-- <td>{{'homePage.buyer.estLoadingdays' | translate}}<br><strong>{{allData?.products?.estimatedLoadingDays ? (allData?.products?.estimatedLoadingDays) :'--'}}</strong>
                                                    </td> -->
                                                </tr>
                                                <tr>

                                                    <td scope="row">Seller Details
                                                        <br><strong>{{allData?.sellerData[0]?.name ? (allData?.sellerData[0]?.name) :'--'}}</strong>
                                                    </td>
                                                    <td>Seller Address
                                                        <br><strong>{{allData?.sellerData[0]?.businessAddress?.addressLine1 ? (allData?.sellerData[0]?.businessAddress?.addressLine1) :'--'}}</strong>
                                                    </td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                                            <ul class="star_rate">
                                                                <li><i class="fa fa-star"></i></li>
                                                             
                                                                {{allData?.sellerRating == null? '0' :  allData?.sellerRating | number:'1.1-1'}}

                                                            </ul>
                                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red" (click)="createChatDialog(allData?.productId)">{{'header.menu.iteam7' | translate}}</a>
                                                        <a href="javascript:;" class="m_btn_red btn_red" [routerLink]="'/details/'+allData?._id">Details</a>

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>


                                    </div>
                                    <div class="No_found" *ngIf="allDatas?.length == 0">
                                        <span class="norecordIcon"></span> {{'homePage.buyer.noData' | translate}}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="value=='Buyer' && value1=='PENDING'" class="content_sidebr">
                            <!-- <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status" *ngFor="let allData of allDatas">

                                        <p class="mn_head clr_ylw"><strong>{{'homePage.buyer.dealOpen' | translate}}</strong></p>
                                        <table class="table">

                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        {{'header.menu.iteam6' | translate}}<br><strong>#{{allData?.orderId ? (allData?.orderId) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.businessName' | translate}}
                                                        <br><strong>{{allData?.sellerData[0]?.businessName ? (allData?.sellerData[0]?.businessName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.subCategory' | translate}}
                                                        <br><strong>{{allData?.products?.subCategoryName ? (allData?.products?.subCategoryName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Quantity' | translate}}<br><strong>{{allData?.quantity ? (allData?.quantity) :'--'}}</strong>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{{'homePage.buyer.unitPrice' | translate}}<br><strong><i
                                                        class="fa fa-rupee"></i>{{allData?.dealAmount ? (allData?.dealAmount) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Date' | translate}}<br><strong>{{allData?.deliveryDate ? (allData?.deliveryDate | date : "MMMM
                                                        d,
                                                        y") :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.deliveryAvailability' | translate}}<br><strong>{{allData?.products?.isDeliveryAvailable  == false ? 'NO' : 'Yes' ? (allData?.products?.isDeliveryAvailable == false ? 'NO' : 'Yes') :'--'}}</strong>
                                                    </td>
                                                    <!-- <td>{{'homePage.buyer.estLoadingdays' | translate}}<br><strong>{{allData?.products?.estimatedLoadingDays ? (allData?.products?.estimatedLoadingDays) :'--'}}</strong>
                                                    </td> -->
                                                </tr>
                                                <tr>

                                                    <td scope="row">Seller Details
                                                        <br><strong>{{allData?.sellerData[0]?.name ? (allData?.sellerData[0]?.name) :'--'}}</strong>
                                                    </td>
                                                    <td>Seller Address
                                                        <br><strong>{{allData?.sellerData[0]?.businessAddress?.addressLine1 ? (allData?.sellerData[0]?.businessAddress?.addressLine1) :'--'}}</strong>
                                                    </td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                                            <ul class="star_rate">
                                                                <li><i class="fa fa-star"></i></li>
                                                                {{allData?.sellerRating == null? '0' :  allData?.sellerRating | number:'1.1-1'}}

                                                            </ul>
                                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red" (click)="createChatDialog(allData?.productId)">{{'header.menu.iteam7' | translate}}</a>
                                                        <a href="javascript:;" class="m_btn_red btn_red" [routerLink]="'/details/'+allData?._id">Details</a>

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="No_found" *ngIf="allDatas?.length == 0">
                                        <span class="norecordIcon"></span>{{'homePage.buyer.noData' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Buyer' && value1=='ACCEPTED'" class="content_sidebr">
                            <!-- <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr" *ngFor="let allData of allDatas">
                                        <p class="mn_head"><strong>{{'homePage.buyer.dealAccepted' | translate}} </strong>
                                        </p>
                                        <table class="table">

                                            <tbody>
                                                <tr>
                                                    <td scope="row">
                                                        {{'header.menu.iteam6' | translate}}<br><strong>#{{allData?.orderId ? (allData?.orderId) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.businessName' | translate}}
                                                        <br><strong>{{allData?.sellerData[0]?.businessName ? (allData?.sellerData[0]?.businessName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.subCategory' | translate}}
                                                        <br><strong>{{allData?.products?.subCategoryName ? (allData?.products?.subCategoryName) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Quantity' | translate}}<br><strong>{{allData?.quantity ? (allData?.quantity) :'--'}}</strong>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{{'homePage.buyer.unitPrice' | translate}} <br><strong><i
                                                        class="fa fa-rupee"></i>{{allData?.dealAmount ? (allData?.dealAmount) :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.Date' | translate}}<br><strong>{{allData?.deliveryDate ? (allData?.deliveryDate | date : "MMMM
                                                        d,
                                                        y") :'--'}}</strong>
                                                    </td>
                                                    <td>{{'homePage.buyer.deliveryAvailability' | translate}}<br><strong>{{allData?.products?.isDeliveryAvailable  == false ? 'NO' : 'Yes' ? (allData?.products?.isDeliveryAvailable == false ? 'NO' : 'Yes') :'--'}}</strong>
                                                    </td>
                                                    <!-- <td>{{'homePage.buyer.estLoadingdays' | translate}}<br><strong>{{allData?.products?.estimatedLoadingDays ? (allData?.products?.estimatedLoadingDays) :'--'}}</strong>
                                                    </td> -->
                                                </tr>
                                                <tr>

                                                    <td scope="row">Seller Details
                                                        <br><strong>{{allData?.sellerData[0]?.name ? (allData?.sellerData[0]?.name) :'--'}}</strong>
                                                    </td>
                                                    <td>Seller Address
                                                        <br><strong>{{allData?.sellerData[0]?.businessAddress?.addressLine1 ? (allData?.sellerData[0]?.businessAddress?.addressLine1) :'--'}}</strong>
                                                    </td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                                            <ul class="star_rate">
                                                                <li><i class="fa fa-star"></i></li>
                                                                {{allData?.sellerRating == null? '0' :  allData?.sellerRating | number:'1.1-1'}}

                                                            </ul>
                                                        </strong></td>
                                                    <td>
                                                        <a class="m_btn_red btn_red btn_brdr" (click)="openRating(loginModal30, allDatas, 1)" *ngIf="allData?.sellerRating  == null && allData?.duration >= 48">Rating</a>
                                                        <a class="m_btn_red btn_red" (click)="createChatDialog(allData?.productId)">{{'header.menu.iteam7' | translate}}</a>
                                                        <a href="javascript:;" class="m_btn_red btn_red" [routerLink]="'/details/'+allData?._id">Details</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="No_found" *ngIf="allDatas?.length == 0">
                                        <span class="norecordIcon"></span>{{'homePage.buyer.noData' | translate}}
                                    </div>
                                    <!-- <div class="No_found" *ngIf="allDatas.length == 0">
                                        <span class="norecordIcon"></span> No Data Found !
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
</body>

<ng-template #loginModal30>
    <div class="log_pop otp_scrn submit_acc add_pop reci_ordr rate_order_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" (click)="close()" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <!-- <h2>Please give your feedback about your order</h2> -->
                        <h3>Please give your feedback about your order</h3>

                    </div>
                    <div class="d-flex flex-column text-center">

                        <div class="form-group position_rel row">
                            <div class="col-12">
                                <div class="have_reci">
                                    <!-- <span><img src="./assets/images/egg.png"></span> -->
                                    <ul>
                                        <!-- <h4>Hatched Eggs</h4> -->
                                        <!-- <h5>{{ratingBusinessname}} </h5> -->
                                        <li><strong>Service</strong> <br>
                                            <ul class="rt_str_ul">

                                                <li>
                                                    <rating name="fieldRate" [(ngModel)]="serviceRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><strong>Commitment </strong> <br>
                                            <ul class="rt_str_ul">

                                                <li>
                                                    <rating [(ngModel)]="commitmentRate" name="fieldcmmitmentRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>

                                            </ul>
                                        </li>

                                        <li *ngIf="ratingBuyer == 1"><strong>Quality</strong> <br>
                                            <ul class="rt_str_ul">
                                                <li>
                                                    <rating [(ngModel)]="qualityRate" name="fieldqualityRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>

                                        <li *ngIf="ratingBuyer == 1"><strong>Packaging</strong> <br>
                                            <ul class="rt_str_ul">
                                                <li>
                                                    <rating [(ngModel)]="packagingRate" name="fieldpackagingRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>
                                        <li *ngIf="ratingSeller == 1"><strong>Payment on time</strong> <br>
                                            <ul class="rt_str_ul">
                                                <li>
                                                    <rating [(ngModel)]="paymentRate" name="fieldpaymentRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>
                                        <div class="text-danger" *ngIf="showErrorRating">
                                            *Please Select All Rating.
                                        </div>

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class="form-group position_rel row">
                            <div class="col-12">
                                <textarea class="form-control" [(ngModel)]="description" name="fieldescription" id="exampleFormControlTextarea1" rows="3" placeholder="Additional Comment"></textarea>
                            </div>

                        </div>
                        <div class="form-group position_rel row point_rvw">
                            <div class="col-12">
                                <p><img src="./assets/images/img_coins.svg"> 2 points for rating and 3 points for review
                                </p>
                            </div>

                        </div>
                        <div class="col-12">
                            <div class="">
                                <button type="button" class="btn btn-info btn-block btn-round btn_red" (click)="submitRating()" data-dismiss="modal" aria-label="Close">Submit</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>


</ng-template>