<div class="container" [ngClass]="appPage ? 'TBPadding':''" *ngIf="userRatesPlan != null && isLoading == false">
    <!-- <div class="title_text">
        <h2 *ngIf="!appPage">Rates</h2>
        <a class="handcursul" (click)="pricePgee(productName[0])">View all</a>
    </div> -->
    <div class="newRatesWrap">
        <div class="row align-items-center ">
            <div class="col-6 col-md-2">
                <div class="title_text">
                    <h2 style="width: 100%;" *ngIf="!appPage">Rates</h2>
                </div>
            </div>
            <div class="col-6 col-md-2  order-md-last">
                <div class="title_text">
                    <a class="handcursul" (click)="pricePgee(productName[0])">View all</a>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <ul class="category_pro ">
                    <li><a href="javascript:;" (click)="gotoPricePage('Bird')"><span><img
                                    src="../../../assets/images/Bird-2.png"></span><br>Bird</a>
                    </li>
                    <li><a href="javascript:;" (click)="gotoPricePage('Egg')"><span> <img
                                    src="../../../assets/images/Eggs-1.png"> </span><br>Egg</a>
                    </li>
                    <li><a href="javascript:;" (click)="gotoPricePage('Chicks & Hatching Egg')"><span><img
                                    src="../../../assets/images/Chicks-1.png"></span><br>Chicks & Hatching Egg</a></li>


                    <li><a href="javascript:;" (click)="gotoPricePage('Grains')"><span><img
                                    src="../../../assets/images/Grains-1.png"></span><br>Grains</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- tabls -->
        <div class="main_box">
            <ul class="priceTabs">
                <li class="nav-item">
                    <div class="yellow_box" (click)="updateRate(1)" [ngClass]="rateType == 1 ? 'active':''">
                        <h2>Today's Rate</h2>
                        <p class="date">{{currentDate | date: 'dd/MM/yyyy' }}</p>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="yellow_box" (click)="updateRate(2)" [ngClass]="rateType == 2 ? 'active':''">
                        <h2>Tomorrow's Rate</h2>
                        <p class="date">{{tomorrowDate | date: 'dd/MM/yyyy' }}</p>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="yellow_box blue" (click)="updateRate(3)" [ngClass]="{'blinkBG': bgBlink , 'active': rateType == 3}">
                        <h2>Current Selling Price</h2>
                        <p class="date">{{currentDate | date: 'dd/MM/yyyy' }}</p>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="yellow_box blue " (click)="updateRate(4)" [ngClass]="{'blinkBG1': bgBlinkM , 'active': rateType == 4}">
                        <h2>Market Forecast</h2>
                        <p class="date">{{tomorrowDate | date: 'dd/MM/yyyy' }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="main_slider_div" *ngIf="hideBird">
            <div class="title_text">
                <h2>Broiler: Big/Mota</h2>
                <a href="javascript:;" *ngIf="birdAllRate?.length > 0" (click)="viewDetailsPgae(birdAllRate[0]?.rateData.product)">View all</a>

                <a href="javascript:;" *ngIf="isLoggedIn" style="margin-right: 5px;
                animation: blinkingText 1s infinite;
                padding: 0px 5px 0px 5px;
                border-radius: 3px;">
                  <input type="checkbox" 
                    (change)="viewMyRates($event)"
                    style="font-size: 16px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                    View My Rates 
                </a>
            </div>
            <div class="priceSlider">
                <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="priceConfig">
                    <div ngxSlickItem class="slide" *ngFor="let mandiRate of birdAllRate; let i = index">
                        <div class="rateinner" *ngIf="mandiRate.visible === undefined || mandiRate.visible == true">
                            <h3>
                                {{mandiRate.rateData.mandi ? (mandiRate.rateData.mandi):''}}
                            </h3>
                            <p [class]="mandiRate.rateData.color ? (mandiRate.rateData.color):''">
                                <!-- <i class="fa fa-rupee"></i> -->
                                {{mandiRate.rateData.modifiedRate ? (mandiRate.rateData.modifiedRate.replace('₹', '')):'Coming soon'}}
                            </p>
                            <span *ngIf="isLoggedIn" style="font-size: 10px;bottom: -5px;">
                                <input type="checkbox" 
                                    (change)="addToMyRates($event, mandiRate)"
                                    [checked]="mandiRate.isInMyRatesList"
                                    style="font-size: 10px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                Add to my rates list
                            </span>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class="noRatefound" *ngIf="birdAllRate?.length<1">
                    No Rate Found
                </div>
            </div>
        </div>

        <!-- Egg: White Egg -->
        <div class="main_slider_div" *ngIf="hideEgg">
            <div class="title_text">
                <h2>Egg: White Egg</h2>
                <a href="javascript:;" *ngIf="eggAllRate?.length>1" (click)="viewDetailsPgae(eggAllRate[0]?.rateData.product)">View all</a>
            </div>
            <div class="priceSlider">
                <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="priceConfig">
                    <div ngxSlickItem class="slide" *ngFor="let mandiRate of eggAllRate; let i = index">
                        <div class="rateinner">
                            <h3>{{mandiRate.rateData.mandi ? (mandiRate.rateData.mandi):''}} </h3>
                            <p [class]="mandiRate.rateData.color ? (mandiRate.rateData.color):''">
                                <!-- <i class="fa fa-rupee"></i> -->
                                {{mandiRate.rateData.modifiedRate ? (mandiRate.rateData.modifiedRate.replace('₹', '')):'Coming soon'}}
                            </p>
                            <span *ngIf="isLoggedIn" style="font-size: 10px;bottom: -5px;">
                                <input type="checkbox" 
                                    (change)="addToMyRates($event, mandiRate)"
                                    [checked]="mandiRate.isInMyRatesList"
                                    style="font-size: 10px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                Add to my rates list
                            </span>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class="noRatefound" *ngIf="eggAllRate?.length<1">
                    No Rate Found
                </div>
            </div>
        </div>

        <!-- Chick: Broiler Chick (Rs/Chick) -->
        <div class="main_slider_div" *ngIf="hideChick">
            <div class="title_text">
                <h2>Chick: Broiler Chick (Rs/Chick)</h2>
                <a href="javascript:;" *ngIf="chickAllRate?.length>1" (click)="viewDetailsPgae(chickAllRate[0]?.rateData.product)">View all</a>
            </div>
            <div class="priceSlider">
                <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="priceConfig">
                    <div ngxSlickItem class="slide" *ngFor="let mandiRate of chickAllRate; let i = index">
                        <div class="rateinner">
                            <h3>{{mandiRate.rateData.mandi ? (mandiRate.rateData.mandi):''}} </h3>
                            <p [class]="mandiRate.rateData.color ? (mandiRate.rateData.color):''">
                                <!-- <i class="fa fa-rupee"></i> -->
                                {{mandiRate.rateData.modifiedRate ? (mandiRate.rateData.modifiedRate.replace('₹', '')):'Coming soon'}}
                            </p>
                            <span *ngIf="isLoggedIn" style="font-size: 10px;bottom: -5px;">
                                <input type="checkbox" 
                                    (change)="addToMyRates($event, mandiRate)"
                                    [checked]="mandiRate.isInMyRatesList"
                                    style="font-size: 10px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                Add to my rates list
                            </span>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class="noRatefound" *ngIf="chickAllRate?.length<1">
                    No Rate Found
                </div>
            </div>
        </div>


        <!-- Grain: Makki/Corn (Rs/Quintal) -->
        <div class="main_slider_div" *ngIf="hideGains">
            <div class="title_text">
                <h2>Grain: Maize/Makki (Rs/Quintal)</h2>
                <a href="javascript:;" *ngIf="grainkAllRate?.length>1" (click)="viewDetailsPgae(grainkAllRate[0]?.rateData.product)">View all</a>
            </div>
            <div class="priceSlider">
                <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="priceConfig">
                    <div ngxSlickItem class="slide" *ngFor="let mandiRate of grainkAllRate; let i = index">
                        <div class="rateinner">
                            <h3>{{mandiRate.rateData.mandi ? (mandiRate.rateData.mandi):''}} </h3>
                            <p [class]="mandiRate.rateData.color ? (mandiRate.rateData.color):''">
                                <!-- <i class="fa fa-rupee"></i> -->
                                {{mandiRate.rateData.modifiedRate ? (mandiRate.rateData.modifiedRate.replace('₹', '')):'Coming soon'}}
                            </p>
                            <span *ngIf="isLoggedIn" style="font-size: 10px;bottom: -5px;">
                                <input type="checkbox" 
                                    (change)="addToMyRates($event, mandiRate)"
                                    [checked]="mandiRate.isInMyRatesList"
                                    style="font-size: 10px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                Add to my rates list
                            </span>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class="noRatefound" *ngIf="grainkAllRate?.length<1">
                    No Rate Found
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-rates" *ngIf="userRatesPlan == null && isLoading == false">
    <div class="message">
        <h4>you do not have any active subscription plan to view the rates!</h4>
        <h5>Please select a plan below to subscribe.</h5>
    </div>
    <div class="plans">
        <div class="plan" *ngFor="let data of ratesPlan">
            <div class="form-group">
                <label class="check_listi">
                    <div style="width: 40%;">
                        {{data.displayName}}
                    </div>                
                    <div style="width: 30%;">
                        <i class="fa fa-rupee"></i>
                        {{data.price}}
                    </div>
                    <div>
                        <input type="checkbox" name="plan" [(ngModel)]="data.checkbox" id="{{data.name}}"
                            (change)="onPlanSelection($event)">
                        <span class="checkmark"></span>
                    </div>
                </label>
                
            </div>
        </div>
    </div>

    <div style="width: 200px;">
        <button id="btnid" (click)="doSubscription()" type="button" class="btn btn-info btn-block btn-round btn_red" data-toggle="modal">Start Subscription</button>
    </div>

    <div id="payment-form"></div>
</div>