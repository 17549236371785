import { Component, OnInit, ElementRef, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { HomeService } from './home-page.service';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { MapsAPILoader } from '@agm/core';

import { FcmserviceService } from './../../services/fcmservice.service';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import { faTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faMix } from '@fortawesome/free-brands-svg-icons/faMix';
import { faXing } from '@fortawesome/free-brands-svg-icons/faXing';
import { faLine } from '@fortawesome/free-brands-svg-icons/faLine';
import axios from 'axios';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faBook, faCoffee, faInfo, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { BusinessModalComponent } from './../../Common/business-modal/business-modal.component';
import { MouseEvent } from '@agm/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataSharingService } from '../../services/data.share.service'
import * as CONSTANT from '../../services/constants';
import { NoWhitespaceValidator } from '../../services/NoWhiteSpace.validator';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {
 
  slides = [
    { img: "./assets/images/quote.png", img2: "./assets/images/quote2.png", title: 'Rahul Diwakar ', state:'Muradabad(UP)', dis: 'आप बहुत अच्छा काम कर रहे हो आपके इस प्रयास (रेट बताना ) से बहुत से व्यापारीयो को नुकसान से बचने के लिये थोडा समय मिल जाता है और वो भारी नुकसान से बच जाता है और फायदा भी अच्छा हो जाता है' },
    { img: "./assets/images/quote.png",  img2: "./assets/images/quote2.png", title: 'Himanshu Mahajan ', state:'Gurgaon(Haryana)', dis: 'Very good, we are grateful for your services that are correct and I have shared with my friends and we follow it. Thanku' },
    { img: "./assets/images/quote.png",  img2: "./assets/images/quote2.png", title: 'Gaurav Sharma', state:'Firozpur (Punjab)', dis: 'You are really fantastic guys , because of you i am always updated with the egg rates. Thanks for helping new farmers like me .thank you keep up the good work' },
    { img: "./assets/images/quote.png",  img2: "./assets/images/quote2.png",  title: 'Sanket Mahaske ', state:'Nashik (Maharashtra)', dis: 'Sir, hamare liye apka pyar bhut khuch hai bus isi tareh se sabhi ki help kiya kare, thanks sir ' },
        { img: "./assets/images/quote.png",  img2: "./assets/images/quote2.png", title: 'Anil Kumar ',  state:'East Delhi', dis: 'Best application for Egg and boilers rate I am fully satisfied ' }
  ];
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "dots": true, "autoplay": true, 'speed': 3000, "autoplaySpeed": 2000, "vertical": true,    'centerMode': false, 'cssEase': 'linear',  "infinite": true, "adaptiveHeight": true, };
  
  fbIcon = faFacebookSquare;
  pinIcon = faPinterest;
  tweetIcon = faTwitterSquare; 
  ratingBusinessname = '';
  // smsIcon = faSms;
  icons = [
    faFacebookF, faTwitter, faLinkedinIn, faGooglePlusG, faPinterestP, faRedditAlien, faTumblr,
    faWhatsapp, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faCommentAlt, faBook, faLine,
    faEnvelope, faCheck, faPrint, faExclamation, faLink, faEllipsisH, faMinus, faLightbulb, faCoffee, faInfo
  ];
  busineesForm: FormGroup;
  modalRef: BsModalRef;
  s3url:any;
  mySlideImages1: any = [{ image: './assets/images/sld1.png' }];
  mySlideImagess: any;
  mySlideOptions = {
    items: 1, loop: true, dots: false, nav: true,
  };
  myCarouselOptions = {
    items: 1, loop: true, dots: false, nav: true,
  };
  uploadImage = '';
  urlfile: any = '';
  image: any = [];
  uploadImage1 = '';
  urlfile1: any = '';
  showError: boolean = false;
  getbusineeslist: any;
  getuserlist: any;
  Countrylist: any;
  stateslist: any;
  NearData: any = [];
  viewall: boolean = true;
  showError1: boolean = false;
  ratingBusinesslist: any = [];
  ratingBusinesslist1: any = [];
  Phonenum = '';
  input: any;
  selectedID = '';
  ServiceRate = [{ point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }];
  CommitmentRate = [{ point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }];
  Selectproduct: any;
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  allData1: any = [];
  shareshow: boolean = false;
  Data: any;
  searchvalue: any = 'India';
  BussinessVerified: any = 'false';
  zoom: number;
  search = '';
  productSubcategory: any;
  address: string;
  allData: any = [];
  productCompany: any;
  referralCode: any = '';
  selectedSub: any;
  selectedCompany: any;
  private geoCoder;
  showSub = 0;
  showCompany = 0;
  pageCount: any = [];
  currentPage: number = 1;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 1, totalItems: null }
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  max = 5;
  rate = 0;
  isReadonly = false;
  ratingBuyer =0;
  ratingSeller =0;
  serviceRate =0;
  qualityRate =0;
  commitmentRate =0;
  paymentRate =0;
  packagingRate =0;
  description:any;
  showErrorRating: boolean = false;
  public unSubscribe:any={}
  config1 = {
  ignoreBackdropClick: true

  };
  @ViewChild('search')
  public searchElementRef: ElementRef;
  // @ViewChild('myModal') myModal;
  @ViewChild('loginModal30') private Rating;
  constructor(private mapsAPILoader: MapsAPILoader,
   private messagingService: FcmserviceService,
    private ngZone: NgZone, private dataSharingService: DataSharingService ,private router: Router, private modalService: BsModalService, private hs: HomeService, public admin: JsonPlaceHolderService, private formBuilder: FormBuilder,) {
    this.busineesForm = this.formBuilder.group({
      userType: ['', Validators.compose([Validators.required])],
      BusinessType: ['', Validators.compose([Validators.required])],
      BusinessName: ['', Validators.compose([Validators.required,
      Validators.minLength(2), Validators.maxLength(45)])],
      AddressLine1: ['', Validators.compose([Validators.required])],
      AddressLine2: ['', Validators.compose([])],
      Country: ['', Validators.compose([])],
      state: ['', Validators.compose([])],
      city: ['', Validators.compose([])],
      pincode: ['', Validators.compose([])],
    });
  }


  ngOnInit(): void {  
    this.latitude = localStorage.getItem('latitude');
    this.longitude = localStorage.getItem('longitude');
    var search1 = localStorage.getItem('search');
    if (search1 != null && search1 != '') {
      this.search = search1;
    }
    this.BussinessVerified = localStorage.getItem('BussinessVerified');
    this.getuser();
    this.getTredcate();
    this.getbusiness();
    this.getappInitials();
    // this.setCurrentLocation(); 
   
    this.ViewAllCategories();
    this.getuserdetails();
    let id = localStorage.getItem('id');
    this.getacceptedDealDuration(id);
    //this.getRecordRateTrends();

    this.unSubscribe.name = this.dataSharingService.addressUpdate.subscribe(value => {
      this.latitude = localStorage.getItem('latitude');
      this.longitude = localStorage.getItem('longitude');
      let token = localStorage.getItem('adminToken');
      if(token){       
        this.getindex();
      }     
    });

  }
  ngOnDestroy() {
    this.unSubscribe.name.unsubscribe();   
  }
  confirmSelection(event: KeyboardEvent) {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.isReadonly = true;
    }
  } 
  resetStars() {
    this.rate = 0;
    this.isReadonly = false;
  }
  changeredeem1(event) {
    this.Phonenum = event.target.value;
  } 
  Backopen(template) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template);
  }
  service(i, num) {
    this.ServiceRate[i].point = num;
  }
  Commitment(i, num) {
    this.CommitmentRate[i].point = num;
  }


  sendRefer() {
    if (this.Phonenum != '' && this.Phonenum.length == 10) {
      const data = {
        'phoneNumber': '+91-' + this.Phonenum,
        // 'country':'+91-'
        'refferalCode': this.allData.referralCode
      }
      this.modalRef.hide();
      this.Phonenum ='';
      this.hs.submitForm(data, apiUrl._postshareRefferalCode)
        .subscribe(async (res) => {   
          this.modalRef.hide();
          this.admin.showAlert('Refer Code Send Successfully', '');
        })
    } else {
      this.showError1 = true;
      setTimeout(() => {
        this.showError1 = false;
      }, 5000);
    }
  }

  shareOpen(template) {
    this.modalRef = this.modalService.show(template);
  }

  Reating(template, num) {
    if (num == 1) {
      this.modalRef = this.modalService.show(template);
    } else {

      this.modalRef.hide();
      this.modalRef = this.modalService.show(template);
    }
  }
  onChnageSelectproduct(event) {
    this.selectedID = event.target.value;
    this.selectedSub = '';
    this.selectedCompany = '';
    this.getTredsubcate(this.selectedID);

  }
  onChnageSubproduct(event) {
    this.selectedSub = event.target.value;

  }
  companyProduct(event) {
    this.selectedCompany = event.target.value;

  }
  /*get rate and trand*/
  getTredcate() {
    let params = new HttpParams();
    this.hs.getList(params, apiUrl._getProductlist)
      .subscribe(async (res) => {
        this.Selectproduct = res.data;
        this.selectedID = res.data[0];
        this.getTredsubcate(this.selectedID);
        this.getRecordRateTrends()

      })
  }
  /*get rate and trand*/
  getTredsubcate(subCategory) {
    let params = new HttpParams();
    params = params.append('search', subCategory);
    this.hs.getList(params, apiUrl._getProductsublist)
      .subscribe(async (res) => {
        this.productSubcategory = res.data.subProduct;
        this.productCompany = res.data.company;
        if (this.productSubcategory.length > 0) {
          this.showSub = 1;
        } else {
          this.showSub = 0;
        }
        if (this.productCompany.length > 0) {
          this.showCompany = 1;
        } else {
          this.showCompany = 0;
        }
      })
  }



  getRecordRateTrends() {
    let params = new HttpParams();
    params = params.append('product', this.selectedID);
    if (this.selectedSub) {
      params = params.append('subproduct', this.selectedSub);
    }
    if (this.selectedCompany) {
      params = params.append('company', this.selectedCompany);
    }

    params = params.append('limit', '6');
    params = params.append('skip', this.pagination.skip.toString());
    this.hs.getList(params, apiUrl._getRecord)
      .subscribe(async (res) => {
        this.allData = res.data;
        this.pageCount = res.data.count;
      })
  }


  getacceptedDealDuration(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    this.hs.getList(params, apiUrl._getacceptedDealDuration)
      .subscribe(async (res) => {
        if (res.data.buyerDeals.length) {
          this.ratingBusinesslist = [];
          for (let i = 0; i < res.data.buyerDeals.length; i++) {
            this.ratingBusinesslist.push(res.data.buyerDeals[i])
          }
          this.ratingBusinessname = res.data.buyerDeals[0].sellerData[0].name;
          setTimeout(() => {
            this.modalRef = this.modalService.show(this.Rating, this.config1);
          }, 1000);
          
          this.ratingBuyer = 1;
        } else if (res.data.sellerDeals.length) {

          for (let i = 0; i < res.data.sellerDeals.length; i++) {
            this.ratingBusinesslist.push(res.data.sellerDeals[i])
            // this.ratingBusinesslist1.push(res.data.sellerDeals[i])

          }
          this.ratingSeller = 1;
           this.ratingBusinessname = res.data.sellerDeals[0].businessName;    
          setTimeout(() => {
            this.modalRef = this.modalService.show(this.Rating, this.config1);
          }, 1000);
        }
 
      })
  }


  submitRating() {
    let params = new HttpParams();
    params = params.append('userId', localStorage.getItem('id'));
    params = params.append('productId',  this.ratingBusinesslist[0].productId);
    params = params.append('service', 'service');
    params = params.append('serviceRating', this.serviceRate+'');
    params = params.append('commitment', 'commitment');
    params = params.append('commitmentRating', this.commitmentRate+'');
    params = params.append('description', this.description);
    params = params.append('orderId', this.ratingBusinesslist[0].orderId);  
    params = params.append('buyerId', this.ratingBusinesslist[0].buyerId);
    params = params.append('sellerId', this.ratingBusinesslist[0].sellerId);  

    
    if(this.ratingBuyer == 1){
      if(this.serviceRate == 0 || this.commitmentRate == 0 || this.qualityRate == 0 || this.packagingRate == 0){
        this.showErrorRating = true;
        setTimeout(() => {
          this.showErrorRating = false;
        }, 3000);
        return;
      }
      params = params.append('quality', 'quality');
      params = params.append('qualityRating', this.qualityRate+'');
      params = params.append('package', 'package');
      params = params.append('packageRating', this.packagingRate+'');
      params = params.append('otherId', this.ratingBusinesslist[0].sellerId);
      params = params.append('userType', 'buyer');
      params = params.append('paymentOnTime', 'paymentOnTime');
      params = params.append('paymentOnTimeRating', '0');
    }else{
      if(this.serviceRate == 0 || this.commitmentRate == 0 || this.paymentRate == 0){
        this.showErrorRating = true;
        setTimeout(() => {
          this.showErrorRating = false;
        }, 3000);
        return;
      }
      params = params.append('quality', 'quality');
      params = params.append('qualityRating', '0');
      params = params.append('package', 'package');
      params = params.append('packageRating', '0');
      params = params.append('userType', 'seller');
      params = params.append('paymentOnTime', 'paymentOnTime');
      params = params.append('paymentOnTimeRating', this.paymentRate+'');
      params = params.append('otherId', this.ratingBusinesslist[0].buyerId);
    }
    this.hs.submitForm(params, apiUrl._postFeedback)
      .subscribe(async (res) => {
        this.modalRef.hide();
        this.ratingBusinesslist=[];
        this.serviceRate = 0;
        this.qualityRate = 0;
        this.commitmentRate = 0;
        this.paymentRate = 0;
        this.packagingRate = 0;
        this.description = '';
        this.getacceptedDealDuration(localStorage.getItem('id'));
      })

    // this.ratingBusinesslist1 = [];
    // for (let i = 0; i < this.ratingBusinesslist.length; i++) {
   

    //   this.ratingBusinesslist1.push(this.ratingBusinesslist[i])
   
    // }
    // this.ratingBusinesslist.splice(0, 1);
    // this.modalRef.hide();
    // if (this.ratingBusinesslist1.length != 0) {

    //   this.ratingBusinessname = this.ratingBusinesslist1[0].businessName;
    //   this.modalRef = this.modalService.show(this.Rating);
    // }

  }
  closeRating(){  
    this.modalRef.hide();
    let params = new HttpParams();
    params = params.append('orderId', this.ratingBusinesslist[0].orderId); 
    params = params.append('productId',  this.ratingBusinesslist[0].productId);
    params = params.append('userType',  this.ratingBuyer == 1? 'buyer' :'seller');    
    this.hs.updateForm(params, apiUrl._postFeedbackCancel)
      .subscribe(async (res) => {
        this.ratingBusinesslist =[];
        this.modalRef.hide();
        this.getacceptedDealDuration(localStorage.getItem('id'));
      })
    
  }
  getuserdetails() {
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));    
    this.hs.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => {
        if(localStorage.getItem("adminToken") != res.data.accessToken){
          localStorage.setItem('adminToken', res.data.accessToken)
          }

        localStorage.setItem('feeStatus', res.data.onetimeFee[0].status);
        localStorage.setItem('adminToken', res.data.accessToken);
        localStorage.setItem('phone', res.data.phoneNumber);
        this.Data = res.data;
        this.referralCode = "Hi there," + this.Data.name + "USER Invite You to EGIYOK. Your referral code is" + this.Data.referralCode + "Link:http://65.1.37.77:8080";
        // this.getFaqId(res.data._id)
      })
  }
  copy(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.admin.showAlert('Copy to Clipboard', '');
  }
  getindex() {    
    let params = new HttpParams();
    params = params.append('latitude', this.latitude);
    params = params.append('longitude', this.longitude);
    params = params.append('limit', '20');
    params = params.append('page', '1');
    params = params.append('nearBy', '20000000');
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('priceType', '1');
    //params= params.append('search', this.search);
    this.hs.submitForm(params, apiUrl._posthomePage)
      .subscribe(async (res) => { 
        this.NearData = res.data;        
        this.mySlideImages1 = [];       
      })
  }


  ViewAllCategories() {
    let params = new HttpParams();
    this.hs.getList(params, apiUrl._getCategories)
      .subscribe(async (res) => {
        this.allData1 = res.data.categoryData
        this.viewall = false;
      })
  }
 
  preventDefault(event, num) {
    if (num == 1) {
      this.mapsAPILoader.load().then(() => {
        // this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
        this.searchElementRef.nativeElement = event.target.value;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;
          });
        })
      });
    }
  }
  
  getuser() {
    this.s3url= localStorage.getItem('s3BaseUrl');
    let params = new HttpParams();
    this.hs.getList(params, apiUrl._getusertype)
      .subscribe(async (res) => {
        this.getuserlist = res.data;
      })
  }
  getbusiness() {

    let params = new HttpParams();
    this.hs.getList(params, apiUrl._getBusineestype)
      .subscribe(async (res) => {
        this.getbusineeslist = res.data;
      })
  }
  getappInitials() {
    let params = new HttpParams();
    this.hs.getList(params, apiUrl._getappInitials)
      .subscribe(async (res) => {
        this.Countrylist = res.data.countries;
        this.stateslist = res.data.states;
      })
  }

  routherPath(id, business: BusinessModalComponent, type) {
    // business.open(nav)
    if (type == 1) {
      this.router.navigate(['Buyerlisting/' + id]);
      // if (this.BussinessVerified == true || this.BussinessVerified == 'true') { 
      //   this.router.navigate(['Buyerlisting/' + id]);
      // } else {
      //   business.openBusinessModel();
      // }
    } else if (type == 2) {
      this.router.navigate(['Buyerlisting/ProductDetail/' + id]);
      // if (this.BussinessVerified == true || this.BussinessVerified == 'true') {
      //   this.router.navigate(['Buyerlisting/ProductDetail/' + id]);
      // } else {
      //   business.openBusinessModel();
      // }

    } else {
      this.router.navigate(['Buyerlisting']);
      // if (this.BussinessVerified == true || this.BussinessVerified == 'true') {
      //   this.router.navigate(['Buyerlisting']);
      // } else {
      //   business.openBusinessModel();
      // }
    }
  }
  
  businessTypeSelect(event) {

    this.busineesForm.value.BusinessType = event.target.value;
  }
  userTypeSelect(event) {
    this.busineesForm.value.userType = event.target.value;
  }

  close() {
    this.modalRef.hide();
  }
  cancel() {
    this.modalRef.hide();
  }
  buyingquotesdetailspage1(template) {
    if (this.BussinessVerified == 'false' || this.BussinessVerified == false) {
      this.modalRef = this.modalService.show(template);
    } else {
      this.modalRef.hide();
      this.router.navigate(['buyingquotesdetailspage1']);
    }
  }
  open(template, type) {
    if (type == 1) {
      if (this.busineesForm.value.userType == '' || this.busineesForm.value.userType == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.BusinessType == '' || this.busineesForm.value.BusinessType == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.BusinessName == '' || this.busineesForm.value.BusinessName == null || this.busineesForm.value.BusinessName.length < 2) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else {
        this.modalRef.hide();
        this.modalRef = this.modalService.show(template);
      }
    } else if (type == 2) {
      if (this.busineesForm.value.AddressLine1 == '' || this.busineesForm.value.AddressLine1 == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else {
        this.modalRef.hide();
        this.modalRef = this.modalService.show(template, { class: "my-class-modals" });
      }
    } else if (type == 3) {
      // _putBusinessInfo
      if (type == 3) {
        // const fd = new FormData();

        let params = new HttpParams();
        if (this.uploadImage && !this.uploadImage1) {
          this.image = [];
          this.image.push(this.uploadImage)
     
          // fd.append('businessDocuments', this.image);
        } else if (this.uploadImage1 && !this.uploadImage) {
          this.image = [];
          this.image.push(this.uploadImage1)
     
          // fd.append('businessDocuments', this.image);
        } else if (this.uploadImage1 && this.uploadImage) {
          this.image = [];
          this.image.push(this.uploadImage)
          this.image.push(this.uploadImage1)


          // fd.append('businessDocuments', this.image);
        }
        const data = {
          'userType': this.busineesForm.value.userType,
          'businessType': this.busineesForm.value.BusinessType,
          'businessName': this.busineesForm.value.BusinessName,
          'addressLine1': this.busineesForm.value.AddressLine1,
          'addressLine2': this.busineesForm.value.AddressLine2,
          'city': this.busineesForm.value.city,
          'state': this.busineesForm.value.state,
          'country': this.busineesForm.value.Country,
          'pinCode': this.busineesForm.value.pincode,
          'latitude': this.latitude,
          'longitude': this.longitude,
          'businessDocuments': this.image,
          'stateSortCode': 'PB',
        };        

        this.hs.updateForm(data, apiUrl._putBusinessInfo)
          .subscribe(async (res) => {
            this.modalRef.hide();
            this.admin.showAlert('Bussiness Profile Added Successfully', '');
            localStorage.setItem('BussinessVerified', 'true');
            this.BussinessVerified = true;
            this.reloadCurrentRoute()
            // this.modalRef = this.modalService.show(template);
          })
      }
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    // this.loading = false;
  }
  upload(event, num) {
    if (event.target.files && event.target.files.length && num == 1) {
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.hs.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          this.uploadImage = res.data;
          const reader = new FileReader();

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            this.urlfile = reader.result;
          }
        })
    } else if (event.target.files && event.target.files.length && num == 2) {
      const file = event.target.files[0];

      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.hs.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          this.uploadImage1 = res.data;
          const reader = new FileReader();

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            this.urlfile1 = reader.result;
          }
        })
    }
  }
  openModule(template) {
   if (this.BussinessVerified == 'false' || this.BussinessVerified == false) {
      this.modalRef = this.modalService.show(template);
    } else {
      this.modalRef.hide();
      this.router.navigate(['Buyerlisting']);
    }
  }
  openModule1(template) {
    this.modalRef = this.modalService.show(template);
  }

  /*pagination */
  limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 1;
    this.pagination.totalItems = null;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.getRecordRateTrends();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) + 1;
    this.getRecordRateTrends();
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }  
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if(deviceToken)
      return deviceToken.toString() 
      else 
      return  "NOTOKEN";
    }
  }

  getDistanceFromLatLonInKm(e) {
    const lat1  = Number(this.latitude);
    const lon1 =  Number(this.longitude);
    const lat2 = e[1]
    const lon2 = e[0]
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    // var c = d*1.60934
    return d  
  }
  
   deg2rad(deg) {
    return deg * (Math.PI/180)
  }

}
