import { TemplateRef, Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IndexService } from './index.service';
import { Router } from '@angular/router';
import * as CONSTANT from '../../services/constants';
import { NoWhitespaceValidator } from '../../services/NoWhiteSpace.validator';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { apiUrl } from '../../global/global';
import { HttpParams } from '@angular/common/http';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { LoginModalComponent } from './../../Common/login-modal/login-modal.component';
import { LocationStrategy } from '@angular/common';
import { DataSharingService } from 'src/app/services/data.share.service';
import { TranslateService } from '@ngx-translate/core';
import { FcmserviceService } from './../../services/fcmservice.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.css']
})


export class IndexPageComponent implements OnInit {
  slides = [
    { img: "./assets/images/quote.png", img2: "./assets/images/quote2.png", title: 'Rahul Diwakar ', state:'Muradabad(UP)', dis: 'आप बहुत अच्छा काम कर रहे हो आपके इस प्रयास (रेट बताना ) से बहुत से व्यापारीयो को नुकसान से बचने के लिये थोडा समय मिल जाता है और वो भारी नुकसान से बच जाता है और फायदा भी अच्छा हो जाता है' },
    { img: "./assets/images/quote.png", img2: "./assets/images/quote2.png",  title: 'Himanshu Mahajan ', state:'Gurgaon(Haryana)', dis: 'Very good, we are grateful for your services that are correct and I have shared with my friends and we follow it. Thanku' },
    { img: "./assets/images/quote.png", img2: "./assets/images/quote2.png", title: 'Gaurav Sharma', state:'Firozpur (Punjab)', dis: 'You are really fantastic guys , because of you i am always updated with the egg rates. Thanks for helping new farmers like me .thank you keep up the good work' },
    { img: "./assets/images/quote.png", img2: "./assets/images/quote2.png", title: 'Sanket Mahaske ', state:'Nashik (Maharashtra)', dis: 'Sir, hamare liye apka pyar bhut khuch hai bus isi tareh se sabhi ki help kiya kare, thanks sir ' },
        { img: "./assets/images/quote.png", title: 'Anil Kumar ', img2: "./assets/images/quote2.png",  state:'East Delhi', dis: 'Best application for Egg and boilers rate I am fully satisfied ' }
  ];
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "dots": true, "autoplay": true, 'speed': 3000, "autoplaySpeed": 2000, "vertical": true,    'centerMode': false, 'cssEase': 'linear',  "infinite": true, "adaptiveHeight": true, };
  allData: any = [];
  allData2: any = [];
  allData1: any = [];  
  password = '';
  loginsForm: FormGroup;
  token = '';
  showErrormatch21: boolean = false;
  showErrormatch11: boolean = false;
  showErrormatch1: boolean = false;
  showErrormatch2: boolean = false;
  updateProfileForm: FormGroup;
  password2 = '';
  password1 = '';
  otperror: boolean = false;
  resenderror: boolean = false;
  uploadImage = '';
  urlfile: any = '';
  varification = '';
  allDataProduct = '';
  showError1: boolean = false;
  showError: boolean = false;
  form: FormGroup;
  modalRef: BsModalRef;
  browserLang;
  s3url:any;
  videoPlay='';

  @ViewChild('model') private loginModal: LoginModalComponent;
  constructor(private modalService2: BsModalService, public translate: TranslateService,private dataSharingService: DataSharingService,private modalService: LoginModalComponent, private router: Router, private IS: IndexService, private formBuilder: FormBuilder, private admin: JsonPlaceHolderService, private locationStrategy: LocationStrategy,private messagingService: FcmserviceService,private parent: AppComponent) {
  
    this.dataSharingService.selectedLang.subscribe(res => {
     this.browserLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN' ;
      this.translate.setDefaultLang(this.browserLang);
      this.translate.use(this.browserLang);
      this.languageChange();
    });
  }
  
  ngOnInit(): void {
    this.getindex();
    let adminToken = localStorage.getItem("adminToken") ? (localStorage.getItem("adminToken")) : '';
    if (!adminToken) {
      history.pushState(null, null, location.href);
      this.locationStrategy.onPopState(() => {
        history.pushState(null, null, location.href);
      })
    }
  }
  languageChange(){
    this.translate.use(this.browserLang.match(/HI|EN|TE|MR|TA|BN/) ? this.browserLang : 'EN');
    
  }

  openLoginModal() {
    this.loginModal.openModal();
  }

  getindex() {
    let params = new HttpParams();
    this.IS.getList(params, apiUrl._getIndexPage)
      .subscribe(async (res) => { 
        this.allData = res.data;    
        this.allData1 = res.data.categories;     
        console.log(this.allData);
        this.s3url=this.allData.s3BaseUrl;
        localStorage.setItem('s3BaseUrl', this.allData.s3BaseUrl);        
      })
  }
  playPause(video) {
    this.modalRef = this.modalService2.show(video, {class: 'modal-lgs'});
    var myVideo: any = document.getElementById("egg_video");
    myVideo.play();
  }
}
