<!-- <app-headerwithoutslick></app-headerwithoutslick> -->
<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view profile_view2 buyer_pro_dtl">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="left_profile_detail">
                        <a class="go_back" href="javascript:void(0)" (click)="backClicked()">
                            <img src="./assets/images/back.svg"> Back
                        </a>
                        <div class="single_product">
                            <div class="container-fluid" style=" background-color: #fff; padding: 11px;">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="sell_near left_cont_dtl">
                                            <div class="detail_pro ">
                                                <h5>
                                                    {{NearData?.subCategoryName}}
                                                    <!-- {{NearData?.attributesData[0]?.attrName}} -->
                                                    <!-- <span class="rds">(Radius 20 km)</span> -->
                                                </h5>
                                                <div class="dtl_tp">
                                                    <ul>
                                                        <li *ngFor="let opt of NearData?.attributesData;let i = index">
                                                            <i *ngIf="i != 0" class="fa fa-circle"></i>{{opt.traitName}}
                                                        </li>
                                                    </ul>
                                                    <div class="approved_s">
                                                        <span><i class=""><img src="./assets/images/star.svg"></i>
                                                            {{NearData?.productRatingData == null ? '0' : NearData?.productRatingData | number:'1.1-1'}}</span>
                                                        <span><i class=""><img src="./assets/images/chat-bx.svg"></i>
                                                            {{NearData?.feedbackDetail?.length}}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-9 col-md-12 col-sm-9">
                                                <div class="image_selected">
                                                    <img *ngIf="!selectedVideoImage?.fileType" class="height-100" src="{{selectedVideoImage?.file ?s3url+'products/' + selectedVideoImage?.file:'./assets/images/egg.png'}} " alt="">

                                                    <video *ngIf="selectedVideoImage?.fileType" class="imageheight w-100" controls="true" #videoPlayer>
                                                            <source src="{{selectedVideoImage?.file ?s3url+'products/' + selectedVideoImage?.file :'./assets/images/egg.png'}}" type="video/mp4" />
                                                        </video>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12 col-sm-3">
                                                <ul class="image_list">

                                                    <li data-image="" *ngFor="let opt of showImageArray;let i = index" class="active" [class.active]="step== i" (click)="step= i">
                                                        <span (click)="selectImageVideo(i, opt)"> 
                                                        <img 
                                                        src="{{s3url+'products/' + opt?.file}}"
                                                        alt=" " *ngIf="!opt.fileType"></span>
                                                        <span (click)="selectImageVideo(i, opt)">
                                                        <video  *ngIf="opt.fileType" class="imageheight w-100"   #videoPlayer>
                                                            <source src="{{s3url+'products/' + opt?.file}}" type="video/mp4" />
                                                        </video>
                                                       </span>

                                                    </li>

                                                </ul>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-lg-7 col-md-7 order-3">
                                        <div class="product_description">
                                            <div class="sell_near">
                                                <div class="detail_pro">
                                                    <img class="widthset profile_pc mr-2" src="{{NearData?.sellerDetail?.profilePic ?s3url+'users/' + NearData?.sellerDetail?.profilePic : './assets/images/default.jpg'}}">
                                                    <h5>{{NearData?.sellerDetail?.businessName}} <img *ngIf="NearData?.sellerDetail?.businessName" class="veri_img" src="./assets/images/checked.svg"></h5>
                                                    <div class="approved_s" *ngIf="NearData?.sellerDetail?.businessName">
                                                        <!-- <span class="code_red">#PB11ABC</span> -->
                                                        <span class="verify" *ngIf="NearData?.sellerDetail?.businessVerified == true"><i  class="fa fa-check"></i>{{'homePage.buyer.verified' | translate}}</span>
                                                        <span class="verify warning" *ngIf="NearData?.sellerDetail?.businessVerified == false">{{'homePage.buyer.unverified' | translate}}</span>
                                                        <!-- <span class="code_red">#PB11ABC</span> -->
                                                        <!-- <span class="verify"><i class="fa fa-check"></i> {{NearData.userDetail.businessVerified==true?'Verified':'Unverified'}}</span> -->
                                                        <span><i class="fa fa-star"></i>
                                                            {{NearData?.sellerDetail?.userRating == null ? '0' : NearData?.sellerDetail?.userRating | number:'1.1-1'}}</span>
                                                        <div class="loc_mark"><img src="./assets/images/loc-gray.svg"> {{NearData?.address?.city}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="detail_cont" *ngIf="showChat==1">
                                                    <a class="phn" (click)="checkCall(business)"><img tooltip="{{ (BussinessVerified && BussinessVerified =='true') ? (NearData?.sellerDetail?.phoneNumber) : '' }}" placement="bottom" [attr.href]="callUs" src="./assets/images/phone-res.svg"></a>

                                                    <a class="m_btn_red btn_red" (click)="createChatDialog(NearData?._id, business)">{{'header.menu.iteam7' | translate}}</a>
                                                </div>
                                                <p>{{NearData?.description}}</p>
                                            </div>

                                            <div class="selectd_type">
                                                <ul>
                                                    <li>Type Of {{NearData?.categoryData?.name}}
                                                        <strong>{{NearData?.subCategoryName}}</strong>
                                                    </li>
                                                    <li *ngFor="let opt of NearData?.attributesData;let i = index">
                                                        {{opt?.attrName}}<strong>{{opt?.traitName ? opt?.traitName
                                                            :'-'}}</strong></li>

                                                    <li class="edit_opt" *ngIf="NearData?.quantity">Selling Quantity
                                                        <div class="input-group">
                                                            <strong>{{NearData?.quantity ? (NearData?.quantity):''}}<label>{{NearData?.quantityUnit ? (NearData?.quantityUnit):''}}</label></strong>


                                                        </div>
                                                        <!-- <a data-toggle="modal" data-target="#loginModal27"><i class="fa fa-edit"></i></a> -->
                                                    </li>
                                                    <li class="edit_opt" *ngIf="NearData?.moq">Minimum Order Quantity
                                                        <div class="input-group">
                                                            <strong>{{NearData?.moq ? (NearData?.moq):''}}<label>{{NearData?.moqUnit ? (NearData?.moqUnit):''}}</label></strong>
                                                        </div>
                                                    </li>
                                                    <li class="edit_opt" *ngIf="NearData?.price"> {{'homePage.buyer.pricePerunit' | translate}}<strong class="bld"><i
                                                                class="fa fa-rupee"></i> {{NearData?.price}}<label>{{NearData?.priceUnit ?(NearData?.priceUnit):''}}</label>
                                                            
                                                            </strong>
                                                        <!-- <a data-toggle="modal" data-target="#loginModal27"><i class="fa fa-edit"></i></a> -->
                                                    </li>

                                                    <li>Is Price Negotiable<strong>{{NearData?.negotiatePrice}}</strong>
                                                    </li>
                                                    <li> {{'homePage.buyer.isDelivery' | translate}}<strong>{{NearData?.isDeliveryAvailable ==
                                                            true ? 'Yes' :'No'}}</strong></li>
                                                    <li *ngIf="NearData?.loadingDate">Estimated Loading Date<strong>{{NearData?.loadingDate}}</strong>
                                                    </li>
                                                    <li class="">{{'homePage.buyer.loadingAddress' | translate}}<strong class="bld">{{NearData?.address?.googleAddress}}</strong>
                                                    </li>
                                                    <li class="">{{'City' | translate}}<strong class="bld">{{NearData?.address?.city}}</strong>
                                                    </li>
                                                    <li class="">{{'State' | translate}}<strong class="bld">{{NearData?.address?.state}}</strong>
                                                    </li>
                                                    <!-- <li class="loc_fld">Delivery<strong>20 km area</strong></li> -->
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="review_cst">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row revw_ttl">
                                <div class="col-6">
                                    <h2>Customer Reviews</h2>
                                </div>
                                <div class="col-6">
                                    <div class="form_cont" *ngIf="NearData?.feedbackDetail?.length > 0">
                                        <span class="slt_wrap shortBy">
                                            <select (change)="sortby($event)">
                                                <option value="" selected disabled>Sort by</option>
                                                <option value="0">Ascending order</option>
                                                <option value="1">Descending order</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="No_found" *ngIf="NearData?.feedbackDetail?.length == 0">
                                    <span class="norecordIcon"></span> No customer reviews found
                                </div>
                                <div class="main_rvw_div" *ngFor="let review of NearData?.feedbackDetail">
                                    <div class="rv_profl" *ngFor="let reviewDetails of review?.reviewUser">
                                        <span>
                                            <img src="{{reviewDetails?.profilePic ? s3url+'users/' + reviewDetails?.profilePic:'https://image.ibb.co/jw55Ex/def_face.jpg'}}"
                                                alt="">

                                        </span>
                                        <h6>{{reviewDetails?.name}}</h6>
                                    </div>
                                    <div class="rate_commnt_descrip">
                                        <div class="rating_str">
                                            <div class="full-stars" [ngStyle]="riderRation(review.sellerRating | number:'1.0-0')"></div>
                                            <!-- <span class="float-right">
                                            <i class="text-warning fa fa-star"></i>
                                        </span> -->

                                        </div>

                                        <p>{{review?.description}}</p>
                                    </div>
                                </div>

                                <!-- Pagination -->
                                <!-- <nav class="user_review_pagination" aria-label="...">
                                    <ul class="pagination ">
                                      <li class="page-item disabled">
                                        <span class="page-link">Previous</span>
                                      </li>
                                      <li class="page-item"><a class="page-link" href="#_">1</a></li>
                                      <li class="page-item active">
                                        <span class="page-link">
                                          2
                                          <span class="sr-only">(current)</span>
                                        </span>
                                      </li>
                                      <li class="page-item"><a class="page-link" href="#_">3</a></li>
                                      <li class="page-item">
                                        <a class="page-link" href="#_">Next</a>
                                      </li>
                                    </ul>
                                  </nav> -->

                                <div class="clear"></div>
                                <!-- <a (click)="viewMore()" class="m_btn_red btn_red rvw_btn"
                                    *ngIf="NearData?.feedbackDetail.length > 5">View More</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="other_prdct pdb_0">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="text-center">Other similar products</h2>
                </div>
            </div>
            <div class="row slide_category">
                <div class="col-md-12">
                    <owl-carousel [options]="mySlideOptions" [items]="mySlideImages" [carouselClasses]="['owl-theme', 'sliding']">
                        <div class="item" *ngFor="let xdata of mySlideImages;let i = index">
                            <div class="near_sell_outer_box">
                                <a class="sell_near handcursul" href="/Buyerlisting/ProductDetail/{{xdata._id}}">
                                    <h5>{{xdata?.subCategoryName}}

                                    </h5>
                                    <div class="approved_s">
                                        <span class="verify" [ngClass]="{'warning': xdata?.userDetail?.businessVerified == false}">
                                        <i class="fa fa-check" *ngIf="xdata?.userDetail?.businessVerified != false"></i> {{xdata?.userDetail?.businessVerified ==
                                        true? ('homePage.buyer.verified' | translate) : ('homePage.buyer.unverified' | translate)}}</span>
                                    </div>

                                    <ul>
                                        <!-- <h6>{{xdata?.description}}</h6> -->
                                        <h6>{{xdata?.sellerDetail?.name}}</h6>
                                        <li *ngFor="let opt of xdata.attributesData;let i = index"><i *ngIf="i != 0" class="fa fa-circle"></i>{{opt?.traitName}}</li>
                                    </ul>
                                    <div class="commnt_s">
                                        <span *ngIf="xdata?.sellerRating == null"><i class=""><img src="./assets/images/star.svg"></i>
                                      0</span>

                                        <span *ngIf="xdata?.sellerRating != null"><i class=""><img src="./assets/images/star.svg"></i>
                                            {{xdata?.sellerRating == 0 ? '0': xdata?.sellerRating | number:'1.1-1'}}</span>

                                        <span><i class=""><img src="./assets/images/chat-bx.svg"></i>
                                        {{xdata?.messageCount}}</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                    </owl-carousel>
                    <div class="No_found" *ngIf="mySlideImages.length == 0">
                        <span class="norecordIcon"></span> {{'homePage.buyer.noData' | translate}}</div>


                </div>
            </div>
        </div>
    </section>
</main>
<ng-template #loginModal7>
    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Place Order</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group position_rel row">
                                <label for="category">Select Category</label>
                                <div class="col-12">
                                    <span class="slt_wrap">
                                        <select>
                                            <option>Layer Framing</option>
                                            <option>Layer Framing</option>
                                            <option>Layer Framing</option>
                                            <option>Layer Framing</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="category">Select Sub Category</label>
                                <div class="col-12">
                                    <span class="slt_wrap">
                                        <select>
                                            <option>Eggs</option>
                                            <option>Eggs</option>
                                            <option>Eggs</option>
                                            <option>Eggs</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel selectd_type row">
                                <label for="category">Select Quantity</label>
                                <div class="col-12">
                                    <div class="input-group bordershow">

                                        <input type="text" name="quant[1]" class="form-control bordernone input-number" value="{{value}}" min="1" max="500">

                                        <span class="input-group-btn btnclass btn_pls" (click)="addvalue()">
                                            <button type="button" class="btn btn-default btn-number" data-type="plus"
                                                data-field="quant[1]">
                                                <span><i class="fa fa-plus"></i></span>
                                        </button>
                                        </span>
                                        <span class="input-group-btn btnclass1 btn_minus" (click)="subvalue()">
                                            <button type="button" class="btn btn-default btn-number" disabled="disabled"
                                                data-type="minus" data-field="quant[1]">
                                                <span><i class="fa fa-minus"></i></span>
                                        </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="address">Select Sub Category</label>
                                <div class="col-12">
                                    <input type="text" value="" name="" placeholder="SCO 10, Sector 22 A, Chandigarh">
                                </div>
                            </div>
                            <div class="form-group position_rel row dt_pick">
                                <label for="address">Select Delivery Date</label>
                                <div class="col-12">
                                    <span><input type="text" value="" name="" placeholder="Sep 23, 2020"><i
                                            class=""><img src="./assets/images/cal.svg"></i></span>

                                </div>
                            </div>

                            <button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step1" data-toggle="modal" (click)="open(loginModal12)" data-target="#loginModal12">Request
                                Now</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<ng-template #loginModal12>

    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Enter Basic Information</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group position_rel row">
                                <label for="category">User Type</label>
                                <div class="col-12">
                                    <span class="slt_wrap">
                                        <select>
                                            <option>User Type</option>
                                            <option>User Type</option>
                                            <option>User Type</option>
                                            <option>User Type</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="category">Business Type</label>
                                <div class="col-12">
                                    <span class="slt_wrap">
                                        <select>
                                            <option>Eggs</option>
                                            <option>Eggs</option>
                                            <option>Eggs</option>
                                            <option>Eggs</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="address">Business Name</label>
                                <div class="col-12">
                                    <input type="text" value="" name="" placeholder="John Williams">
                                </div>
                            </div>

                            <button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(loginModal9)" data-target="#loginModal9">Next</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<ng-template #loginModal9>

    <div class="log_pop otp_scrn submit_acc add_pop map_loc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <h2>Set Business Location</h2>
                        <span class="srch_l"><input type="Search" name="" value="" placeholder="Search Location"> <a> <i
                                    class="fa fa-search"></i></a></span>
                    </div>
                    <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 300px">
                        <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" style="border:0" allowfullscreen></iframe>
                    </div>

                    <div class="d-flex flex-column text-center">
                        <form>

                            <div class="form-group position_rel row">
                                <div class="col-xl-6 col-md-12">
                                    <div class="srch_opt_in">
                                        <label for="address">Address Line 1</label>
                                        <input type="text" value="" name="" placeholder="SCO 10, Sector 22 A">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-12">
                                    <div class="srch_opt_in">
                                        <label for="address">Address Line 2</label>
                                        <input type="text" value="" name="" placeholder="SCO 10, Sector 22 A">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <div class="col-xl-3 col-md-6 col-sm-6">
                                    <div class="srch_opt_in">
                                        <label for="address">Country</label>
                                        <span class="slt_wrap">
                                            <select>
                                                <option>India</option>
                                                <option>India</option>
                                                <option>India</option>
                                                <option>India</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-6">
                                    <div class="srch_opt_in">
                                        <label for="address">State</label>
                                        <span class="slt_wrap">
                                            <select>
                                                <option>Chandigarh</option>
                                                <option>Chandigarh</option>
                                                <option>Chandigarh</option>
                                                <option>Chandigarh</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-6">
                                    <div class="srch_opt_in">
                                        <label for="address">City</label>
                                        <input type="text" value="" name="" placeholder="Chandigarh">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-6">
                                    <div class="srch_opt_in">
                                        <label for="address">Pincode</label>
                                        <input type="text" value="" name="" placeholder="160022">
                                    </div>
                                </div>
                            </div>

                            <div class="use_add"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3" data-toggle="modal" (click)="open(loginModal20)" data-target="#loginModal20">Next</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loginModal23>
    <div class="log_pop otp_scrn submit_acc add_pop subscription_pay customize_plan_reg">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-column text-center">
                        <div class="cutomize_tbl mr_50">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col"></th>
                                        <th scope="col">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Yearly Registration Fee</th>
                                        <td></td>
                                        <td>1000</td>
                                    </tr>

                                </tbody>
                                <tfoot>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td><span>Total</span><br><strong><i class="fa fa-rupee"></i> 1999</strong></td>
                                </tfoot>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Payment Methods</h5>
                            </div>
                            <div class="col-lg-4 col-md-6 cc_pd">
                                <a (click)="buyingquotesdetailspage1()" class="credit_cc"><img class="cc_img" src="./assets/images/cre_c.svg"> Credit Card</a>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <a (click)="buyingquotesdetailspage1()" class="credit_cc"><img src="./assets/images/gpay.svg"></a>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <a (click)="buyingquotesdetailspage1()" class="credit_cc"><img src="./assets/images/ptm.svg"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loginModal20>

    <div class="log_pop otp_scrn submit_acc add_pop doc_upload_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">

                        <h2>Enter Basic Information</h2>
                        <p>Please upload documents (optional)</p>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>

                            <div class="form-group position_rel row">
                                <div class="col-xl-6 col-md-6">
                                    <div class="bx-upl_fl">
                                        <img src="./assets/images/up-doc.svg"><br>
                                        <span>Upload Document 1</span>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6">
                                    <div class="bx-upl_fl">
                                        <img src="./assets/images/up-doc.svg"><br>
                                        <span>Upload Document 1</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <p class="padd_40">Benefits of uploading business documents.</p>
                                </div>
                                <div class="col-xl-12 col-md-12">
                                    <ul class="doc_list_req">
                                        <li><img src="./assets/images/check-doc.svg"> Verified Badge</li>
                                        <li><img src="./assets/images/check-doc.svg"> Better deals</li>
                                        <li><img src="./assets/images/check-doc.svg"> Quality leads</li>
                                        <li><img src="./assets/images/check-doc.svg"> Top on the search list</li>
                                        <li><img src="./assets/images/check-doc.svg"> High conversion rate</li>
                                        <li><img src="./assets/images/check-doc.svg"> Preferred support</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="use_add"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step4" data-toggle="modal" (click)="open(loginModal23)" data-target="#loginModal23">Resigter</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-business-modal #business>
</app-business-modal>
<app-footer></app-footer>