export const apiUrl = {
    _postLogin: 'api/user/login',
    _postvalidationphno:'api/user/validatePhone',
    _PUTVarifyOTP: 'api/user/verifyOTP',
    _putGenrateOTP:'api/user/generateOTP',
    _putUpdateprofile:'api/user/updateProfile',
    _postResetPassword:'api/user/resetPassword',
    _putlogout:'api/user/logout',
    _postupload:'api/common/upload',
    _putBusinessInfo:'api/user/updateBusinessInfo',
    _getusertype:'api/common/userTypes',
    _getBusineestype:'api/common/businessTypes',
    _getappInitials:'api/common/appInitials',
    _putChangePassword:'api/user/changePassword',
    _getuserDetail:'api/user',
    _getFaq:'api/faq/getAllFaq',
    _getFaqId:'api/faq',
    _getProductDetails:'api/product/productDetail',
    _getCategories:'api/category/categoryList',
    _getSubCategories:'api/category/subCategoryList',
    _getIndexPage:'api/user/homePageData',
    _getProductlist:'api/rate/product/list',
    _getProductsublist:'api/rate/subproduct/list',
    _posthomePage:'api/user/homePage',
    _addProduct : 'api/product/addProduct',
    _editProduct : 'api/product/editProduct',
    _redeemPoint : 'api/user/redeemPoint',
    _supportUpload : 'api/support/upload',
    _postSupport:'api/support',
    _getsupport:'api/redeemPointList',
    _postshareRefferalCode:'api/user/shareRefferalCode',
    _postproductList:'api/product/productList',
    _puteditProductDetail:'api/product/editProductDetail',
    _getRecordRateTrends:'api/user/getRecordRateTrends',
    _getRecord:'api/rate/list',
    _getCurrentplan:'api/user/currentPlanDetail',
    _getPlanlisting:'api/plan',
    _getplanHistory:'api/user/planHistory',
    _getdealBuyer:'api/product/buyerSellerDealByStatus',

    _getacceptedDealDuration:'api/user/acceptedDealDuration',
    _putenableOrDeleteProduct:'api/product/enableOrDeleteProduct',
    _putfeedbacks:'api/product/feedbacks',
    _categoryList:'api/category/categoryList',

    _getReviews:'api/product/reviewlist',
    _postPayment:'api/generate/orderId',
    _ontimePayment:'api/user/updateOneTimeFee',
    _purchasePlan:'api/user/purchasePlan',
    _placeOrder: 'api/product/offerDeal',
    _updateOfferDeal: 'api/product/updateOfferDealById',
    _getChatHistory: 'api/chat',
    _getPoints:'api/user/referredPointEarn',
    // _postPayment:'api/generate/orderId',
    // _ontimePayment:'api/user/updateOneTimeFee',

    _getNotificationList:'api/notification/notificationList',
    _readNotification:'api/notification/readNotification',
    _postFeedback:'api/product/feedbacks',
    _postFeedbackCancel:'api/product/cancelFeedback',

    _getRate:'api/rates',
    _getProductName:'api/rate/product/list',
    _getCategoriesRate:'api/rates/rateCategories',
    _getproductRateDetail:'api/rates/productRateDetail',

    _getRateHistory:'api/rates/rateHistory',
    _postRequirement:'api/product/postRequirement',
    _getPremiumDeal:'api/product/requirementByUserOrSeller',
    _getLeadDetail:'api/product/premimumDetailById',
    _getsimilarProduct:'api/product/similarProduct',
    _addBrowserId:'api/user/addedBrowserId',

    _getRateStatus:'api/user/checkBrowserStatus',
    _getdeal:'api/product/orderDealDetailById',


    _getStations:'api/search/getMandisWithProducts',
    _getMyRates:'api/myrates',
    _objectStore:'api/objectstore',
    _getRatesPlan:'api/get_rates_plan',
    _get_payment_token:'api/get_payment_token'
}
