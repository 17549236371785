<app-header *ngIf="hideHeaderFooter"></app-header>
<main class="main_cont" [ngClass]="!hideHeaderFooter ? 'removeTopSpace':''">
    <section class="trend_pricing pdb_0 newPrice">
        <div class="container">
            <div class="g_bk_trends">
                <div class="row">
                    <a (click)="back()" class="go_back"><img src="../../../assets/images/back.svg"> <span class="hideMobile">Back</span> <span class="showMobile">{{pageHeading}}</span> </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="product_slt">
                        <h5>Select Product & Service</h5>
                        <form [formGroup]="productForm" (ngSubmit)="searchProduct(productForm)">
                            <div class="fromWrap">
                                <div class="form_cont">
                                    <label>Products</label>
                                    <span class="slt_wrap">
                                        <select formControlName="product" (change)="selectProduct($event)">
                                            <option *ngFor="let name of productName" [value]="name">
                                                {{name}} </option>
                                        </select>

                                    </span>
                                    <div class="text-danger" *ngIf="showError&&productForm.controls.product.errors?.required">
                                        *Please select product
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>Sub Category</label>
                                    <span class="slt_wrap">
                                        <select formControlName="category" (change)="selectSubProduct($event)">   
                                            <option value="select" disabled>Select sub category</option>                                 
                                            <option *ngFor="let name of productData.categories" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="showError&&productForm.controls.category.errors?.required">
                                        *Please select product
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>State</label>
                                    <span class="slt_wrap">
                                        <select formControlName="state"  (change)="selectState($event)"  [attr.disabled]="stateEnabled">
                                            <option value="select" disabled>Select State</option> 
                                            <option *ngFor="let name of productData.state" [value]="name">
                                                {{name}} </option>
                                           
                                        </select>
                                    </span>
                                </div>
                                <div class="form_cont">
                                    <label>Station/Mandi</label>
                                    <span class="slt_wrap">
                                        <select formControlName="mandi" (change)="selectMandi($event)" [attr.disabled]="mandiEnabled">
                                            <option value="select" disabled>Station/Mandi</option> 
                                            <option *ngFor="let name of productData.mandi" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                </div>
                                <button class="m_btn_red btn_red btn_gry handcursul" type="submit">Show Results</button>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="pricing_tbl">
                        <div class="newRatesWrap">
                            <div class="title_text hideMobile">
                                <h2>{{pageHeading}}</h2>
                            </div>
                            <ul class="category_pro bottomMargin">
                                <li><a [ngClass]="this.proName=='Bird' ? 'active':''" href="javascript:;" (click)="gotoPricePage('Bird')"><span><img
                                                src="../../../assets/images/Bird-2.png"></span><br>Bird</a>
                                </li>
                                <li><a [ngClass]="this.proName=='Egg' ? 'active':''" href="javascript:;" (click)="gotoPricePage('Egg')"><span> <img
                                    src="../../../assets/images/Eggs-1.png"> </span><br>Egg</a>
                                </li>
                                <li><a [ngClass]="this.proName=='Chicks & Hatching Egg' ? 'active':''" href="javascript:;" (click)="gotoPricePage('Chicks & Hatching Egg')"><span><img
                                                src="../../../assets/images/Chicks-1.png"></span><br>Chicks & Hatching Egg</a>
                                </li>


                                <li><a [ngClass]="this.proName=='Grains' ? 'active':''" href="javascript:;" (click)="gotoPricePage('Grains')"><span><img
                                                src="../../../assets/images/Grains-1.png"></span><br>Grains</a>
                            </ul>


                            <div class="main_slider_div" *ngFor="let data of allData;let i =index">
                                <div class="title_text">
                                    <h6>{{data._id ? (data._id):'' }}</h6>
                                    <a (click)="viewRate(data._id)">View all</a>

                                    <a href="javascript:;" *ngIf="isLoggedIn && i==0" style="margin-right: 5px;margin-top: 0;
                                    animation: blinkingText 1s infinite;
                                    padding: 0px 5px 0px 5px;
                                    border-radius: 3px;">
                                        <input type="checkbox" 
                                          (change)="viewMyRates($event)"
                                          [checked]="filterMyRates"
                                          style="font-size: 16px;height: 15px;display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                          View My Rates
                                      </a>
                                </div>
                                <div class="priceSlider">
                                    <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="priceConfig">
                                        <div ngxSlickItem class="slide" *ngFor="let rateData of data.subcategories;let i =index">
                                            <div class="rateinner">
                                                <h3>{{rateData.rateData.mandi ? (rateData.rateData.mandi):''}}</h3>
                                                <p [class]="rateData.rateData.color ? (rateData.rateData.color):''">
                                                    <!-- <i class="fa fa-rupee"></i> -->
                                                    {{rateData.rateData.modifiedRate ? (rateData.rateData.modifiedRate.replace('₹', '')):'Coming soon'}}
                                                </p>
                                                <span *ngIf="isLoggedIn" style="font-size: 10px;bottom: -5px;">
                                                    <input type="checkbox" 
                                                        (change)="addToMyRates($event, rateData)"
                                                        [checked]="rateData.isInMyRatesList"
                                                        style="font-size: 10px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                                    Add to my rates list
                                                </span>
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                    <div *ngIf="data.subcategories && data.subcategories.length==0" class="noRatefound">
                                        No Rate Found In the Wishlist
                                    </div>
                                </div>
                            </div>
                            <div class="noRatefound" *ngIf="allData.length==0">
                                No Rate Found
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer *ngIf="hideHeaderFooter"></app-footer>