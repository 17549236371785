import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InternalService } from '../internal.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { FcmserviceService } from './../../services/fcmservice.service';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-sellerprofilenewregistration5',
  templateUrl: './sellerprofilenewregistration5.component.html',
  styleUrls: ['./sellerprofilenewregistration5.component.css']
})
export class Sellerprofilenewregistration5Component implements OnInit {
  id: any;
  editId: any; 
  indexvalue: any = 0;
  allData: any = [];
  addressLabel=false;
  showImageArray: any = [];
  Data: any;
  checkVideo: any;
  step = 0;
  s3url:any;
  hideField=true;
  hideIsDelivery=true;
  hidePriceNegotiate=true;
  hidePrice=true;
  selectedVideoImage: any;
  @ViewChild('video')
  public video: ElementRef;
  constructor(
    private IS: InternalService, private router: Router, private admin: JsonPlaceHolderService,  private messagingService: FcmserviceService,) {  }

  ngOnInit(): void {
    this.s3url= localStorage.getItem('s3BaseUrl');
    let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 1];
    this.id = urlnumber;
    this.getProductDetail();
    this.getuserdetails();

  }
  routh() {
    this.router.navigateByUrl('seller/category/edit-product/' + this.id);
  }

  getProductDetail() {
    let params = new HttpParams();
    params = params.append('id', this.id);
    this.IS.getList(params, apiUrl._getProductDetails)
      .subscribe(async (res) => {
        console.log('dat', res.data[0])
        this.Data = res.data[0]
        console.log('show data', this.Data);

        for (var i = 0; i < this.Data.images.length; i++) {
          let fileType = this.Data.images[i].image.split(".")[1];
          let isVideo = true;
          if (this.fileTypeChecker(fileType)) {
            isVideo = false
          }
          let file = {
            fileType: isVideo,
            file: this.Data.images[i].image
          }
          this.showImageArray=[];
          this.showImageArray.push(file);
          console.log('images', this.showImageArray);
        }

        this.selectedVideoImage = this.showImageArray[0];

        const catName=  this.Data.categoryData.name.split(' ').filter(s => s).join(' ').toLowerCase();
        if(catName=="doctor consultancy"){
          this.hideField=false;
          this.hidePrice=false;
          this.hidePriceNegotiate=false;
          this.hideIsDelivery=false;
          this.addressLabel=true;
        }
        if(catName=="lease/sale/rent services"){ 
          this.hidePrice=false;
          this.hideIsDelivery=false;       
        }
        if(catName=="lease/sale/rent services"){ 
          this.hidePriceNegotiate=false;
        }
        if(catName=="cold store"){ 
          const catSubName=  this.Data.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
          if(catSubName=="chicken"){ 
            this.hidePriceNegotiate=false;
          }else{
            this.hidePriceNegotiate=true;
          }         
        }
        if(catName=="manpower"){ 
          const catSubName=  this.Data.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
          if(catSubName=="poultry manpower"){ 
            this.hidePriceNegotiate=false;
            this.hideIsDelivery=false;
          }else if(catSubName=="poulitrey manpower"){
            this.hidePriceNegotiate=false;
            this.hideIsDelivery=false;
          }else{
            this.hidePriceNegotiate=true;
            this.hideIsDelivery=true;
          }         
        }

      })
  }

  fileTypeChecker(fileType) {
    let valid = [
      "pdf", "tif", "tiff", "jpg", "jpeg", "gif", "png"
    ];

    if (valid.includes(fileType.toLowerCase())) {
      return true
    }
    return false;
  }
 
  selectImageVideo(i, fileTypes) {
    this.selectedVideoImage = fileTypes;
    this.video.nativeElement.load();
  }
  getuserdetails() {
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('accessToken', localStorage.getItem("adminToken"));
    params = params.append('deviceToken', this.getDeviceToken());
    this.IS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => { 
        this.allData = res.data;
        if(localStorage.getItem("adminToken") != res.data.accessToken){
          localStorage.setItem('adminToken', res.data.accessToken)
          }
        console.log('this.allData',this.allData)

      })
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if(deviceToken)
      return deviceToken.toString() 
      else 
      return  "NOTOKEN";
    }
  }
  enableOrDeleteProduct(event, product, deleted) {
    console.log(event.target.checked)
    let data = {
      'productId': product,
      'userId': localStorage.getItem('id'),
      'isActive': event.target.checked,
      'isDeleted': deleted,
    }
    this.IS.updateForm(data, apiUrl._putenableOrDeleteProduct)
      .subscribe(async (res) => {
        // console.log(res)
        let status1 = '';
        if (event.target.checked == true) {
          status1 = 'Active';
        } else {
          status1 = 'Deactive';
        }
       // this.admin.showAlert('Product ' + status1 + ' Successfully', '');
        this.getProductDetail();
        this.getuserdetails();
      })

  }

  riderRation(rating) {

    let riderRating = rating ? (rating) : '0';
    //console.log(this.allData?.feedback, "let riderRating")
    let styles = {
      'width': ((riderRating * 2) * 10) + '%'
    };
    return styles;

  }

}
