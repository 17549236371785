<footer class="site_foot">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <a href="https://egiyoknews.com/" target="_blank"><img src="./assets/images/footerlogo2.svg"></a>

            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="foot_links">
                    <ul class="social_i">
                        <h4>Navigation</h4>
                        <li><a class="handcursul" (click)="Redirect()">Home</a></li>
                        <li *ngIf="!loginshow"><a routerLink="/index" pageScroll href="#about">About Us</a></li>
                        <li *ngIf="loginshow"><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/partner">Partners</a></li>
                        <!-- <li><a class="handcursul" (click)="routherPath1()">Plans</a></li> -->

                        <!-- <li><a class="handcursul">Plans</a></li> -->
                        <!-- <li><a>Offers</a></li> -->
                        <!-- <li><a>Login</a></li> -->
                        <!-- <li><a>Contact</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="foot_links">
                    <ul class="social_i">
                        <h4>Others</h4>
                        <li class="nullcss" *ngIf="urlnumber1 != 'index'">
                            <a class="handcursul" (click)="shareOpen(share)">{{'homePage.buyer.referaFriend' |
                                translate}}</a>

                            <!-- <share-button button="sms" text="REFER A FRIEND" [showIcon]="false" [showText]="true"></share-button> -->
                        </li>
                        <li><a routerLink="/terms">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy"> {{'homePage.buyer.privacyPolicy' | translate}}</a></li>
                        <li><a routerLink="/disclaimer"> disclaimer </a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="foot_links">
                    <ul class="social_i">
                        <h4>Download App</h4>
                        <!-- <p>It is a long established fact that a reader will be distracted by the readable content. </p> -->
                        <a href="https://play.google.com/store/apps/details?id=com.app.egiyok"><img src="./assets/images/g-play.svg" alt=""></a>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row align-items-center bottomFooter">
            <div class="col-md-2 col-sm-3">
                <div class="follow_us">
                    <ul class="social_i media">
                        <li>
                            <a href="https://www.facebook.com/Egiyokofficial/" target="_blank"><img src="./assets/images/fb.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/egiyokofficial" target="_blank"><img src="./assets/images/linkedin.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/egiyokofficial" target="_blank"><img src="./assets/images/twitter.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/egiyok/" target="_blank"><img src="./assets/images/insta.svg" alt=""></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-10 col-sm-9">
                <span class="copyRight">© Egiyok 2021. All rights reserved.</span>
            </div>
        </div>
    </div>
</footer>

<ng-template #share>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modulecss">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-title text-center">
                    <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                    <h2>Refer A Friend</h2>

                </div>
                <div class="d-flex flex-column text-center">
                    <form>
                        <div class="form-group position_rel row">
                            <div class="col-12 change_no">
                                <input class="form-control" type="" value="{{Phonenum}}" (keyup)="changeredeem1($event)" id="" placeholder="Enter Mobile Number">

                                <div class="text-danger" *ngIf="showError1">
                                    *Please enter valid mobile no.
                                </div>
                            </div>
                        </div>
                        <button type="button" (click)="sendRefer()" class="btn btn-info btn-block btn-round btn_red mr-2" data-toggle="modal" data-target="">Share</button>
                        <!-- <button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr " (click)="close()" data-toggle="modal" data-target="">Cancel</button> -->
                    </form>
                </div>
            </div>
        </div>

    </div>
</ng-template>