import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-trendslisting',
  templateUrl: './trendslisting.component.html',
  styleUrls: ['./trendslisting.component.css']
})
export class TrendslistingComponent implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  GoBackHomePage(){
    
    console.log("home")
    // this.reloadCurrentRoute();
    this.router.navigate(['Buyer']);
  }
  trendslisting2(){
    console.log("home")
    // this.reloadCurrentRoute();
    this.router.navigate(['trendslisting2']);
   
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    // this.loading = false;
  }
}
