import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { apiUrl } from '../../../global/global';
import { HttpParams } from '@angular/common/http';
import { IndexService } from '../../index-page/index.service';
import { FormGroup, FormControl, FormBuilder, Validators, } from '@angular/forms';
import { AppComponent } from '../../../app.component';
import * as CONSTANT from '../../../services/constants';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.css']
})
export class ProductPriceComponent implements OnInit {
  productName: any = [];
  productData: any = []; 
  hideHeader=false;
  allData:any=[];
  mandiEnabled:boolean=true;
  stateEnabled:boolean=true;
  hidehistory=true;
  categoryError=false;
  pageHeading="Today's Rate"
  updateProductName=false;
  proName: any;
  ratesType:any=1;
  hideHeaderFooter = true;
  subProduct:any;
  showError = false;
  stateName: any;
  mandiName:any;
  productForm: FormGroup;

  tempNewlyAddedRates_ids: any = {};

  isLoggedIn = false;
  myStationsRaw: object[];
  filterMyRates = false;

  currentPage: number = 1;
  pageCount: any;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 1, totalItems: null }
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;

  constructor(private route: ActivatedRoute, private IS: IndexService, private router: Router, private formBuilder: FormBuilder, private _location: Location,private parent: AppComponent) {
    this.productForm = formBuilder.group({
      product: ['', Validators.required],
      category: [''],
      state:[''],
      mandi:[''],
    });
   }

  ngOnInit(): void {
    let appUrl = localStorage.getItem('mobileApp') ? (localStorage.getItem('mobileApp')):'';
    if(appUrl){
      this.hideHeaderFooter = false;
    }
    
    let token = localStorage.getItem("adminToken");
    if (token) {
      this.isLoggedIn = true;
    }

    this.getMyStations();
    this.getProductName();

    const parentData = this.parent.getNavtionData() ? (this.parent.getNavtionData()):'';
    
    if(parentData){
      this.proName= parentData[0].productName ? (parentData[0].productName):"";
      this.subProduct= parentData[0].subProductName ? (parentData[0].subProductName):"";

      this.ratesType = parentData[0].rateType ? (parentData[0].rateType) : 1;
      if(this.ratesType==2){
        this.pageHeading="Tomorrow's Rate";
      }
      if(this.ratesType==3){
        this.hidehistory=false;
        this.pageHeading="Current Selling Price";
      }
      if(this.ratesType==4){
        this.hidehistory=false;
        this.pageHeading="Market Forecast";        
      }
      this.getCategories(this.proName);
      this.productDetailRates(); 
    }else{
      this.updateProductName= true;
    }    
  }

  viewMyRates(event) {
    event.stopPropagation();

    if (!this.isLoggedIn) {
      return;
    }

    this.filterMyRates = event.target.checked;

    this.productDetailRates();
  }

  checkValue(value) {
    let rate = parseInt(value);
    if (rate == value) {
      let type = '2'
      return type
    } else {
      let type = '1'
      return type
    }
  }

  back() {
    let type ;
    type= Number(this.ratesType);
    localStorage.setItem('rateType',type);
    this.router.navigate(['/price']);

  }
  getProductName() {
    let data = {}
    this.IS.getList(data, apiUrl._getProductName)
      .subscribe(async (res) => {
        this.productName = res.data;
        if(this.updateProductName){
          this.proName=this.productName[0];
          this.getCategories(this.proName); 
          this.productDetailRates();          
        }
        this.productForm.patchValue({'product': this.proName})
        if(this.subProduct){
          this.productForm.patchValue({'category': this.subProduct})
        }
      }, err => {
       
      })
  }

  getCategories(productName) {
    let params = new HttpParams();
    params = params.append('product', productName); 
    params = params.append('view', '1');
    params = params.append('rateType', this.ratesType);
    if(this.subProduct){
      params = params.append('category', this.subProduct); 
    }
    if(this.stateName){
      params = params.append('state', this.stateName);
    } 
    this.IS.getList(params, apiUrl._getCategoriesRate)
      .subscribe(async (res) => {
        this.productData = res.data;
        if(this.productData.mandi.length>1){
          let boolean:boolean;
          this.mandiEnabled=boolean;
        }
        if(this.productData.state.length>1){
          let boolean:boolean;
          this.stateEnabled=boolean;  
        }       
      })
  }

  getMyStations() {
    this.IS.getList({}, apiUrl._getMyRates).toPromise().then((res) => {
      this.myStationsRaw = res.data;
    });
  }

  addToMyRates(event, mandiRate) {
    if (event.target.checked) {
      let url = apiUrl._getMyRates 
      + '?mandi='+ encodeURIComponent(mandiRate._id.mandi)
      + '&product=' + encodeURIComponent(this.proName)
      + '&state=' + encodeURIComponent(mandiRate._id.state) 
      + '&subproduct=' + encodeURIComponent(mandiRate._id.subproduct);

      this.IS.submitForm({}, url)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            this.tempNewlyAddedRates_ids[`${res.data.success.mandi}_${res.data.success.subproduct}_${res.data.success.state}_${res.data.success.product}`] = res.data.success._id;
          }
          this.getMyStations();
      })
    } else {
      let wishListId = mandiRate.wishListId;
      if (!wishListId) {
        wishListId = this.tempNewlyAddedRates_ids[`${mandiRate.rateData.mandi}_${mandiRate.rateData.subproduct}_${mandiRate.rateData.state}_${this.proName}`];
      }

      if (wishListId) {
        this.IS.deleteList({}, apiUrl._getMyRates + '?wishlistId='+ wishListId)
        .subscribe((res) => {
            this.getMyStations();
        })
      }
    }
  }

  productDetailRates(){
    let params = new HttpParams();
    params = params.append('product',this.proName);
    params = params.append('view', '1');
    params = params.append('rateType', this.ratesType ); 
    params = params.append('limit', this.filterMyRates ? '1000' : this.pagination.limit.toString());
    params = params.append('page',  this.filterMyRates ? '1' : this.pagination.skip.toString()); 
    if (this.subProduct){
      params = params.append('category', this.subProduct);
    } 
    if(this.stateName){
      params = params.append('state', this.stateName);
    } 
    if(this.mandiName){
      params = params.append('mandi', this.mandiName);
    }   
    this.IS.getList(params, apiUrl._getproductRateDetail)
    .subscribe(async (res) => { 
     
      this.allData= res.data.list.length>0 ? (res.data.list[0]?.subcategories):[];
      this.pageCount = res.data.total; 
      if(res.total==1){
        this.hideHeader=  true;        
      }else{
        this.hideHeader=  false;
      }
      if (this.filterMyRates) {
        // get subcat 
        let tempArr = [];

        this.myStationsRaw.map((mst) => {
          for (let index = 0; index < this.allData.length; index++) {
            const st = this.allData[index];
            
            if(st['_id'].mandi.toLowerCase() == mst['mandi'].toLowerCase() && st['_id'].subproduct.toLowerCase() == mst['subproduct'].toLowerCase()) {
              st.isInMyRatesList = true;
              st.wishListId = mst['_id'];
              tempArr.push(st);
              break;
            }
          }
        })
        this.allData = tempArr;
      } else {
         this.myStationsRaw.map((mst) => {
           for (let index = 0; index < this.allData.length; index++) {
             const st = this.allData[index];
             
             if(st['_id'].mandi.toLowerCase() == mst['mandi'].toLowerCase() && st['_id'].subproduct.toLowerCase() == mst['subproduct'].toLowerCase()) {
               st.isInMyRatesList = true;
               st.wishListId = mst['_id'];
               break;
             }
           }
         })
      }
     
    }, err => {
     
    })
  }

  selectProduct(event) {
    this.subProduct="";
    this.stateName="";
    this.mandiName="";
    this.proName = event.target.value;
    this.productForm.patchValue({'category':"select"})
    this.getCategories(this.proName); 
  }

  selectSubProduct(event){
    this.stateName=""; 
    this.subProduct= event.target.value;    
    this.getCategories(this.proName);
    this.productForm.patchValue({'state':"select"})
  }
  selectState(event){
    this.stateName= event.target.value;
    this.getCategories(this.proName);
    this.productForm.patchValue({'mandi':"select"})
  }
  selectMandi(event){
    this.mandiName= event.target.value;
  }
   /*pagination */
   limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 1;
    this.pagination.totalItems = null;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.productDetailRates();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) + 1;
    this.productDetailRates();
  }
  searchProduct(form) { 
    if(!this.subProduct){
      this.categoryError=true;
      setTimeout(() => {
        this.categoryError=false;
      }, 3000);
      return false
    } 
    this.productDetailRates();
  }
  viewHistory(productData){
    const data=[];
    data.push({'productName':this.proName, 'subProduct':productData.rateData.subproduct, 'mandi':productData.rateData.mandi, 'state':productData.rateData.state,'rateType':this.ratesType})
    this.parent.setNavtionData(data);
    this.router.navigateByUrl(`/price/mandi-price`);
  }
  checkText(text) {
    let type;
    if (text) {
      let checkTxt = text.split(' ').filter(s => s).join(' ').toLowerCase();
      if (checkTxt == 'coming soon') {
        type = '1'
        return type
      } else if (checkTxt == 'same normal') {
        type = '2'
        return type
      } else if (checkTxt == 'slow down') {
        type = '3'
        return type
      }
      else {
        type = '4'
        return type
      }
    } else {
      type = '5'
      return type
    }
  }



}
