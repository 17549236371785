import { Component, OnInit } from '@angular/core';

import {HttpParams} from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { NavigationService } from '../../Common/header/header.service';
import {Router, ActivatedRoute} from '@angular/router';
import * as CONSTANT from '../../services/constants';

@Component({
  selector: 'app-buyer-view-seller-profile-rating',
  templateUrl: './buyer-view-seller-profile-rating.component.html',
  styleUrls: ['./buyer-view-seller-profile-rating.component.css']
})
export class BuyerViewSellerProfileRatingComponent implements OnInit {
  id:any;
  allData:any;
  currentPage: number = 1;
  pageCount:any;
  s3url:any;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 1, totalItems: null }
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  constructor(private router: Router,private NS:NavigationService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.s3url= localStorage.getItem('s3BaseUrl');
    this.id = this.route.snapshot.paramMap.get('id');
    this.getproductDetail();
  
  }
  chat(){
  
    this.router.navigate(['Chat']);
  }
/* get rating listing */

  getproductDetail(){
    
    let params = new HttpParams();
    params= params.append('productId', this.id);
    params = params.append('limit',  this.pagination.limit.toString());
    params = params.append('page',  this.pagination.skip.toString());
    this.NS.getList(params , apiUrl._getReviews)
    .subscribe( async (res) => {
     this.allData= res.data;
     this.pageCount = res.total.count;
     console.log('review data',this.allData);
    })
  }

  /*rating star function */
  riderRation(rating){
    let riderRating = rating ? (rating):'0';
    let styles = {
      'width':  ((riderRating*2)*10) +'%'      
    };
    return styles;
  }
    /*pagination */
    limitRecordChange(event) {
      let limit = 0;
      this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
      this.currentPage = 1;
      this.pagination.limit = limit;
      this.pagination.skip = 1;
      this.pagination.totalItems = null;
      this.pagination.maxSize = CONSTANT.MAX_SIZE;
      this.getproductDetail();
    }
    
    pageChanged(event: any): void {
      this.currentPage = event.page;
      this.pagination.skip = (event.page - 1) + 1;
      this.getproductDetail();
    }


  /**back to product detail page */
  back(){
    this.router.navigate(['Buyerlisting/ProductDetail/'+ this.id ]);
  }

}
