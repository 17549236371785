import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import firebase from "firebase/app";
import "firebase/messaging";




@Injectable({
  providedIn: 'root' 
})
export class FcmserviceService {

  firbasNotification = new BehaviorSubject(null);
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging) {
    console.log('Constructor messaging service');
    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging) => {
    //     this.firbasNotification.next(_messaging);
    //     console.log(_messaging, "_messaging------")
    //   }
    // );
    this.angularFireMessaging.messages.subscribe(
      (_messaging) => {          
          this.firbasNotification.next(_messaging);
          console.log(_messaging, "_messaging------") 

      }, error => {
          console.log(error, "error---------")
      }
  );
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("token", token);
        let fcmToken = token ? (token) : "WEB-IP";
        localStorage.setItem("fireBaseToken", fcmToken);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    ); 
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      }, error => {
        console.log(error, "error---------")
    })
  }
  
  
}