<div class="container pt-2" *ngIf="isLoggedIn == true && userRatesPlan != null && isLoading === false">
    <div class="row pt-3">
      <div class="col-md-12 my-rates-container">
        <ng-template #customItemTemplate let-model="item" let-index="index">
          <div class="search-item">
              <div>
                  <div class="mandi-name"> {{model.mandi}} </div>
                  <div class="subproduct"> {{model.subproduct}} </div>
              </div>
              <div>
               <div (click)="addToWishlist($event, model)" *ngIf="checkIfStationAdded(model) == undefined">
                   <img src="https://egiyok.com/assets/images/plus.svg" />
                </div>
            </div>
          </div>
        </ng-template>
         
        <div class="txt-container">
            <input
                [(ngModel)]="selected"
                placeholder="Search station to add"
                class="form-control"
                id="txtInput"
                (input)="filterStation($event.target.value)"
            />
            <img 
                *ngIf="selected"
                (click)="resetFilter()"
                width="20"
                height="20"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABNVBMVEUAAADdRFXbQ1TZRVPaRFPaRFPaRFPbQ1LaRFLbSVvaRFHaQ1PaRFPaRFLYQVXmTU3aQ1PaRFPZRFPjOVXYRU7aRFPdRFXbQlLaRFPaRFTbQFLXQ1HaRVLaRVPaRFPaRFPaRVPaRFPYO07ZQ1LbRFPaRFPaRFLaRFPaRFPaRFPaRFPbRFPVQFXaRFPaRFPWR1LaRVLfQGDaRFPZQlXcR1XbRFPaRFTaRVLaRFPpkJjsnaXbSFfcTFrto6vnh5Dup67////0yc7bSljbS1r2z9PsoKf0x8vcTVv20tXrmKHaRVT+/Pz0x8z87/Dvr7XsoKjwsbfuqrHvsbfuqbDcTlz65ef1ys71zNDtpq3ws7ryvcL++Pj76+3nh5H//f3++/vle4bnhI7lfojibHfkeIPlfYjhZnJq+cVTAAAAOHRSTlMAD1ucyur6m1oOKZ/2nicKkPuOCRrPHjLm5RwmydSE9aCZDVfLyOvp+fjHVgyX9BmCCNMbJNKDmEnptKwAAAABYktHRED+2VzYAAAAB3RJTUUH4QsVCy4K5lz76AAAAXBJREFUOMt1k9dawkAQhUdIKEoHRUSJgBSlKaKsYlmxB7H3hv39H8Eks0kWkpybnMx/vt3MZhbA0JjLLYgejyh4fX6wanwiQAwFgqERHI5EyZCisTjPEwKxaHLK5MlpYqNUUucztpwQMY08PkscNBfWAhHiqIzWn/79G5sdNFvbO6wkJZTAPHvZpXSvq5r9A0oPWTEL4A8YnNIjZY3jE9WdsiVy4EIn99QyPesgp322RB7czJ1TTCCnF8YeC8zJl5TTld5HAYq6la9NfmM0WoIysSZMTha5ALntI7/r8AFjC61/1H2X20Kwcj6xBF4bziWy4GPuAcnjEz6fWbkCfglbeEFOZEy8sqOuAgTRvr1rnGBi8IHVmvI3Q+x3f/ZY/8p5DL6wVm+oAxFj233/6Cf2+8fcsjZRK44j18SRg9WUw9C2jLEv2vKkeTHSTStfaw1dvYw0jOvr8ZHbmWhzEanWsLnfuXy2IJbLpUK7UjWr/wEht7H6UvsdAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTExLTIxVDExOjQ2OjEwKzAwOjAw5oXgbwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0xMS0yMVQxMTo0NjoxMCswMDowMJfYWNMAAAAASUVORK5CYII="/>
        </div>
        <div *ngIf="selected" class="search-list-container">
            <div *ngFor="let station of filteredStations" class="search-item">
                <div>
                    <div class="mandi-name">
                        {{station.mandi}}
                        <span *ngIf="station.companies">({{station.companies}})</span>
                    </div>                    
                    <div class="subproduct"> {{station.subproduct}} </div>
                </div>
                <div>
                    <div (click)="addToWishlist($event, station)" *ngIf="checkIfStationAdded(station) == undefined">
                        <img src="https://egiyok.com/assets/images/plus.svg" />
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngIf="myStationsRaw && myStationsRaw.length <= 2" style="text-align: center;margin-top: 15px;">
            <iframe
                width="97%"
                height="300"
                src="https://www.youtube.com/embed/LETLgbC6fW0"
                title="My Rate"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
        </div>

        <div class="table my-rates-table-conatiner">
            <table>
                <thead class="thead-red">
                    <tr>
                        <th style="width: 25%;">Station</th>
                        <th>Todays Rate</th>
                        <th>Current Selling Price</th>
                        <th>Tomorrows Rate</th>
                        <th>Forecast</th>
                    </tr>
                </thead>
            </table>
            <table>
                <tbody  cdkDropList
                #personList = "cdkDropList"
                [cdkDropListData] = "productCategories"
                class = "example-list tbl-data"
                (cdkDropListDropped) = "onDrop($event)"
                >
                    <tr *ngIf="productCategories && productCategories.length == 0" >
                        <td colspan="6" style="text-align: center">
                            No station added yet to wishlist.
                        </td>
                    </tr>

                    <tr *ngFor="let product of productCategories" >
                        <td colspan="6" style="text-align: center">
                            <table class="tbl_{{product}}" cdkDrag>
                                <tr class="myrate-row product-heading">
                                    <td class="col-md" colspan="5">
                                        <span class="product-name">
                                            {{product}}
                                        </span>
                                        <span style="font-weight: 500;">
                                            (Rs./{{product == 'Egg' ? '100 Eggs': product == 'Chicks & Hatching Egg'? 'Chick': product == 'Grains' ? 'Quintal' :'Kg'  }})
                                        </span>
                                    </td>
                                    <td class="col-md">
                                        <img 
                                            cdkDragHandle
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAk0lEQVRIieWTMQ6AMAhFv3poR+vmtRw8k6MuYhgoBWxjjC/p+l/Lp8DfSACWluHHdapLeHh1iRTe7CUUbKavfQOrIAWyzOOimUtoIzJ1wguNCFSJti3ec0t4B7vyMi/ZrBHPRzSV7CSJCIrhRG5NNUFktV0Ckdd+Mkcq3lyoFS6pHi5JzOGDQ7AB6ACsAGbX1T7NCQRnU9Dc3JNNAAAAAElFTkSuQmCC"/>
                                    </td>
                                </tr>
                                 <tr *ngFor="let station of myStations[product]"  class="myrate-row">
                                    <td class="col-md">
                                        <div class="station-name">
                                            {{station.mandi}}
                                        </div>
                                        <div *ngIf="station.todaysRateData.companies" class="company">({{station.todaysRateData.companies}})</div>
                                        <div class="subproduct-name">
                                            {{station.subproduct}}
                                        </div>
                                    </td>
                                    <td class="col-md">
                                        <p [class]="station.todaysRateData.color ? (station.todaysRateData.color):'no-data'">
                                            {{station.todaysRateData.currentRate ? (station.todaysRateData.currentRate):'Coming soon'}}
                                        </p>
                                    </td>
                                    <td class="col-md">
                                        <p [class]="station.currentRateData.color ? (station.currentRateData.color):'no-data'">
                                            {{station.currentRateData.currentRate ? (station.currentRateData.currentRate):'Coming soon'}}
                                        </p>
                                    </td>
                                    <td class="col-md">
                                        <p [class]="station.tomorrowsRateData.color ? (station.tomorrowsRateData.color):'no-data'">
                                            {{station.tomorrowsRateData.currentRate ? (station.tomorrowsRateData.currentRate):'Coming soon'}}
                                        </p>
                                    </td>
                                    <td class="col-md">
                                        <p [class]="station.forcastRateData.color ? (station.forcastRateData.color):'no-data'">
                                            {{station.forcastRateData.currentRate ? (station.forcastRateData.currentRate):'Coming soon'}}
                                        </p>
                                    </td>
                                    <td class="col-md">
                                        <img 
                                            (click)="deleteMyRate($event, station)"
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAlElEQVRYhe2XTQ5AMBBGHwtXEifEOZytvQYLtdAM9dNBZF4yCxnp96arDhjHqYAe8MAYlQe68I8avRAcV6spsExeC70m9JymwDLl1b5IsXGQBlIWpVLYYUSrQK6b2Mt4/wbOCBSsp0l9ZxdQwQRMwARMwARM4NPvAZ8hPPlI3RMYMgjcOqNiXjYc6X0gLscDi4rxDybyyi07l4o3PgAAAABJRU5ErkJggg=="/>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="height: 100px;">&nbsp;</div>
      </div>
    </div>
</div>

<div class="subscribe-rates" *ngIf="(userRatesPlan === null && isLoading === false)">
    <div class="message">
        <h4>you do not have any active subscription plan to view the rates!</h4>
    </div>
</div>