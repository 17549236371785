import { Component, OnInit } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import { apiUrl } from '../../global/global';

import { NavigationService } from '../../Common/header/header.service';
@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./buyer.component.css']
})
export class BuyerComponent implements OnInit {
  mySlideOptions={items: 1, loop: true, dots: false,   nav: true,
  };
myCarouselOptions={items: 1, loop: true, dots: false, nav: true,
};
mySlideImages1:any=[{image:'./assets/images/sld1.png'}];

mySlideImages:any;
  constructor(private NS: NavigationService) { }

  ngOnInit(): void {
    
  }


}
