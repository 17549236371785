<!-- <app-headerwithoutslick></app-headerwithoutslick> -->
<app-header></app-header>
<main class="main_cont">
    <!-- Pending Orders -->
    <section class="pending_orders confirm_ordr chat_box_page my_profl buyer_listi fullBxfilter">
        <div class="sidebar_order" id="sidenav" [ngClass]="{
                'activeSlidebar':slideCheck == 1}">
            <button _ngcontent-ulf-c135="" type="button" data-dismiss="modal" aria-label="Close" class="close"><span _ngcontent-ulf-c135="" aria-hidden="true" (click)=openSidebar(0)>×</span></button>
            <h6>Filter By</h6>
            <ul class="side_nav">
                <li class="main_m">
                    <a class=" open_drp " data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">By type of user</a>
                    <div id="collapseExample" class="main_drp collapse show">
                        <ul class="nav nav-tabs drop_menu" id="myTab" role="tablist">
                            <li class="nav-item" *ngFor="let data of userType1">
                                <div class="form-group form-check">
                                    <label class="check_listi">{{data.name}} 
                                        <input type="checkbox" name="user" [(ngModel)]="data.checkbox" id="{{data.id}}"
                                            (change)="onChange($event)">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="main_m">
                    <a class=" open_drp collapsed" data-toggle="collapse" href="#collapseExample7" role="button" aria-expanded="false" aria-controls="collapseExample">By distance</a>
                    <div id="collapseExample7" class="main_drp collapse">
                        <ul class="nav nav-tabs drop_menu" id="myTab" role="tablist">
                            <li class="nav-item" *ngFor="let data of nearbylist">
                                <div class="form-group form-check">
                                    <label class="check_listi">{{data.name}}
                                        <input type="checkbox" name="user2" [(ngModel)]="data.checkbox" id="{{data.id}}"
                                            (change)="onChange2($event)">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="main_m">
                    <a class=" open_drp collapsed" data-toggle="collapse" href="#collapseExample10" role="button" aria-expanded="false" aria-controls="collapseExample">By Verified seller or not</a>
                    <div id="collapseExample10" class="main_drp collapse">
                        <ul class="nav nav-tabs drop_menu" id="myTab" role="tablist">
                            <li class="nav-item" *ngFor="let data of verifylist">
                                <div class="form-group form-check">
                                    <label class="check_listi">{{data.name}}
                                        <input type="checkbox" name="user1" [(ngModel)]="data.checkbox" id="{{data.id}}"
                                            (change)="onChange1($event)">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>
                            </li>

                        </ul>
                    </div>
                </li>
                <li class="main_m" *ngIf="mainCategoryName[0]?.name">
                    <div class="formData ">
                        <div class="form_cont">
                            <label for="cc-number" class="control-label">Type of {{mainCategoryName[0]?.name}}</label>
                            <span class="slt_wrap rating">
                                <select (change)="subCategoryChange($event)">
                                    <option value="select" selected hidden></option>
                                    <option *ngFor="let opt of  subCategoryList" [value]="opt.subCategory._id">
                                        {{opt.subCategory.name}}</option>
                                </select>
                            </span>
                        </div>
                    </div>
                </li>
                <li class="main_m" *ngIf="subcategoryID">
                    <div class="formData">
                        <div class="priceRange" [ngClass]="priceType=='-1'?'active':''" (click)="selectPriceType('-1')"><span class="arrowiocn"></span> <i class="fa fa-rupee"></i> - Low to High</div>
                        <div class="priceRange" [ngClass]="priceType=='1'?'active':''" (click)="selectPriceType('1')"><span class="arrowiocn"></span><i class="fa fa-rupee"></i> - High to Low</div>
                    </div>

                </li>
                <li class="main_m" *ngIf="this.attributesList1.length!=0">
                    <div *ngFor="let opt of attributesList1;let i = index">
                        <div class="formData">
                            <!-- <div class="form_cont motherBreedNamewrap{{i}}">
                                <label for="cc-number" class="control-label">{{opt.name}}</label>
                                <span class="{{opt.fieldType != 1 ? 'slt_wrap':''}}">
                                    <select (change)="traitChange($event,i)" *ngIf="opt.fieldType != 1">

                                        <option *ngFor="let traits of opt.traitsData">{{traits.traitName}}</option>
                                    </select>
                                </span>
                            </div> -->

                            <div class="form_cont motherBreedNamewrap{{i}}">
                                <label for="cc-number" class="control-label">{{opt.name}}</label>
                                <span class="{{opt.fieldType != 1 ? 'slt_wrap':''}}">
                                    <select (change)="traitChange($event,i)" *ngIf="opt.fieldType != 1">
                                        <option *ngFor="let traits of opt.traitsData" [value]="traits._id">
                                            {{traits.traitName}}</option>
                                    </select>

                                    <input *ngIf="opt.fieldType == 1" value="{{attributesData1[i].traitName}}"
                                        (keyup)="valueset(i,$event)" type="text">
                                </span>


                            </div>
                        </div>
                    </div>

                    <li class="main_m">
                        <div class="formData ">
                            <div class="form_cont">
                                <label for="cc-number" class="control-label">Search Location</label>

                                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #mapSearch [readonly]="isReadOnly">
                            </div>
                        </div>
                    </li>
                    <li class="main_m">
                        <div class="formData ">
                            <div class="form_cont">
                                <label for="cc-number" class="control-label">Rating</label>
                                <span class="slt_wrap rating">
                                    <select (change)="filterByRating($event)">
                                        <option disabled selected>Select</option>
                                        <option value="0">1 &#9733; & above</option>
                                        <option value="2">2 &#9733; & above</option>
                                        <option value="3">3 &#9733; & above</option>
                                        <option value="4">4 &#9733; & above</option>
                                        <!-- <option value="4">5 &#9733; & above</option> -->
                                    </select>
                                </span>

                                <!-- <input type="number" class="form-control" [(ngModel)]="rating" (change)="filterByRating($event)"> -->
                            </div>
                        </div>
                    </li>
                    <li class="main_m" *ngIf="hideMoq">
                        <div class="formData ">
                            <div class="form_cont">
                                <label for="cc-number" class="control-label">Moq</label>
                                <div class="d-flex">
                                    <div class="w50">
                                        <input type="number" class="form-control" [(ngModel)]="maq" (change)="filterByMaq($event)">
                                    </div>
                                    <span class="slt_wrap rating w50">
                                    <select (change)="selectmoqUnit($event)">
                                        <option value="select" selected hidden>Select </option>
                                        <option *ngFor="let opt of  moqUnitData" [value]="opt.name">
                                            {{opt.name}}</option>
                                    </select>
                                </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="filterBtns">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6"><button type="button" class="btn btn-info btn-block btn-round btn_red mr-2" (click)=openSidebar(0)>Apply</button></div>
                                <div class="col-6"><button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr disable" style="width: 100%;" (click)=openSidebar(0)>Cancel</button></div>
                            </div>
                        </div>
                    </li>
            </ul>


        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="content_sidebr">
                        <div class="row msg_rw">
                            <div class="col-md-12">
                                <div class="row sort_seller_list mr_50">
                                    <div class="col-md-6 col-sm-6">
                                        <button class="btnFulls" type="button" data-toggle="" data-target="" aria-controls="" aria-expanded="false" aria-label="Toggle navigation" (click)=openSidebar(1)>
                                            <span class="fa fa-filter"></span>
                                        </button>
                                        <form (ngSubmit)="searchFun()" name="searchForm" *ngIf="hideSeaction == 0">
                                            <div class="srch_order"><input type="text" name="" value="" placeholder="{{'homePage.buyer.search' | translate}}" [(ngModel)]="search" autocomplete="off" name="search" (keydown)="eventHandler($event)">
                                                <span (click)="searchFun()" class="search input-icon input-icon-right fa fa-search" type="submit" *ngIf="closeSearchicon"><i
                                                        class="ti-search"></i></span>
                                                <a class="closeBtn_search " (click)="refreshPage()" *ngIf="closeSearch">&times;</a>
                                            </div>
                                        </form>

                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="form_cont sort_lst">
                                            <span class="slt_wrap">
                                                <select (change)="categoryChange($event)">
                                                    <option value="select" selected disabled>Select</option>
                                                    <option *ngFor="let opt of categoryList" [value]="opt._id"
                                                        [selected]="opt._id == category ? true : null">
                                                        {{opt.name}}</option>
                                                </select>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div class="chat_left_usr near_seller">
                                    <div class="row">
                                        <div class="col-lg-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let xdata of NearData;let i = index">
                                            <div class="near_sell_outer_box">
                                                <a class="sell_near handcursul" (click)="BuyerProductDetail(xdata._id)">
                                                    <h5>{{xdata.subCategoryName}}
                                                        <!--<img class="img_vr"
                                                            src="./assets/images/checked.svg"> -->
                                                    </h5>
                                                    <div class="approved_s">
                                                        <span class="verify" [ngClass]="{'warning': xdata.userDetail.businessVerified == false}"><i
                                                                class="fa fa-check"
                                                                *ngIf="xdata.userDetail.businessVerified != false"></i>
                                                            {{xdata.userDetail.businessVerified ==
                                                            true? ('homePage.buyer.verified' | translate) :
                                                            ('homePage.buyer.unverified' | translate)}}</span>
                                                        <span><i class="fa fa-star"></i> {{xdata.sellerRating == null?
                                                            '0' :xdata.sellerRating |
                                                            number:'1.1-1'}}</span>
                                                    </div>

                                                    <ul>
                                                        <h6>{{xdata.userDetail.businessName}}</h6>
                                                        <li *ngFor="let opt of xdata.attributesData;let i = index"><i *ngIf="i != 0" class="fa fa-circle"></i>{{opt.traitName}}</li>
                                                        <!-- <li>Desi <i class="fa fa-circle"></i></li>
                                                        <li>Golden</li> -->
                                                    </ul>
                                                    <div class="commnt_s">
                                                        <span><i class=""><img src="./assets/images/star.svg"></i>
                                                            {{xdata.productRating == null?
                                                            '0' : xdata.productRating | number:'1.1-1'}}</span>
                                                        <span><i class=""><img src="./assets/images/chat-bx.svg"></i>
                                                            {{xdata.messageCount}}</span>
                                                    </div>
                                                    <!-- <div class="commnt_s">
                                                        <span><i class=""><img src="./assets/images/map-marker.svg"></i>{{xdata.address.city}}</span>
                                                    </div> -->
                                                    <div class="locate_box"><i class="fa fa-map-marker"></i> {{xdata?.address?.city }} ({{this.getDistanceFromLatLonInKm(xdata?.dist?.location.coordinates) | number:'1.1-1'}}) Km</div>
                                                </a>
                                            </div>
                                        </div>{{pageCount.length }}
                                        <div class="pagination-wrapper col-12" *ngIf="pageCount > 19">

                                            <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage" [boundaryLinks]="true" [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prv" nextText="Next" firstText="First" lastText="Last" [rotate]="true" [totalItems]="pageCount"
                                                class="pagelist"></pagination>

                                        </div>
                                        <div class="No_found" *ngIf="NearData.length == 0">
                                            <span class="norecordIcon"></span> {{'homePage.buyer.noData' | translate}}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xl-3 col-lg-1 col-md-1">
                </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </section>

</main>