import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { IndexService } from '../index-page/index.service';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { AppComponent } from '../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FcmserviceService } from '../../services/fcmservice.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';

@Component({
  selector: 'my-rates',
  templateUrl: './my-rates.component.html',
  styleUrls: ['./my-rates.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyRatesComponent implements OnInit {
  selected: '';
  stations: object[];
  filteredStations: object[];
  myStations: object[];
  myStationsRaw: object[];
  productCategories: string[];
  productCategoriesOrdered: string[];
  allRates: object[];
  hideLoader = false;
  isLoggedIn = false;
  userRatesPlan = null;
  isLoading = true;

  constructor(
    private route: ActivatedRoute, private router: Router,
    private IS: IndexService, private admin: JsonPlaceHolderService, private parent: AppComponent, 
    private messagingService: FcmserviceService,
    private location: Location) {
   }


  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const adminToken = urlParams.get('token');

    if (adminToken) {
      localStorage.setItem("adminToken", adminToken);
    }
    
    let token = localStorage.getItem("adminToken");
    if (token) {
      this.isLoggedIn = true;
    }
    
    if(this.isLoggedIn) {
      this.getuserdetails()
      this.getStations();
      this.getStationsOrder();
      this.getProductRates();
    } else {
      this.isLoading = false
    }
  }

  getuserdetails() {
    if (!this.isLoggedIn) {
      this.isLoading = false;
      return;
    } 
    
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));

    this.IS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => { 
        if (res.data && res.data.ratesSubscriptionDetails && res.data.ratesSubscriptionDetails.length > 0) {
          this.userRatesPlan = res.data.ratesSubscriptionDetails[0].plan || null;
          this.isLoading = false;
        }
    })
  }

  filterStation(value) {
    let filteredRecords = this.stations && this.stations.filter((st) => {
      return st['mandi'] && st['mandi'].toLowerCase().indexOf(value.toLowerCase()) != -1
    })
    
    this.filteredStations = filteredRecords;
  }

  getStationsOrder() {
    let url = apiUrl._objectStore + '?key=FAVORITE_STATION';
    this.IS.getList({}, url)
      .subscribe(async (res) => {
       
        if (res && res.data && res.data.data && res.data.data.length > 0 && res.data.data[0].value) {
          this.productCategoriesOrdered = JSON.parse(res.data.data[0].value);
        } else {
          this.productCategoriesOrdered = [];
        }
    })
  }

  getProductRates() {
    let arrPromise = [];

    arrPromise.push(this.getRates('1')); // todays
    arrPromise.push(this.getRates('2')); // tomorrow
    arrPromise.push(this.getRates('3')); // current selling
    arrPromise.push(this.getRates('4')); // forcast

    Promise.all(arrPromise).then((allRates) => {
      this.allRates = allRates;
      this.getMyStations();
    })
  }

  getRates(rateType: string) {
    let arrPromise = [];

    let params = new HttpParams();
    params = params.append('product', 'Bird');
    params = params.append('rateType', rateType);
    params = params.append('view', '0');

    arrPromise.push(this.IS.getList(params, apiUrl._getproductRateDetail).toPromise())

    params = params.set('product', 'Egg');
    arrPromise.push(this.IS.getList(params, apiUrl._getproductRateDetail).toPromise())

    params = params.set('product', 'Chicks & Hatching Egg');
    arrPromise.push(this.IS.getList(params, apiUrl._getproductRateDetail).toPromise())

    params = params.set('product', 'Grains');
    arrPromise.push(this.IS.getList(params, apiUrl._getproductRateDetail).toPromise())

    return arrPromise;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
       moveItemInArray(event.container.data, 
          event.previousIndex, event.currentIndex);
    } else {
       transferArrayItem(event.previousContainer.data,
       event.container.data,
       event.previousIndex,
       event.currentIndex);
    }

    this.saveOrder(event.previousContainer.data);
  }

  saveOrder(data=[]) {
    let url = apiUrl._objectStore + '?key=FAVORITE_STATION'+ '&value=' + encodeURIComponent(JSON.stringify(data));

    this.IS.submitForm({}, url)
      .subscribe(async (res) => {
      this.hideLoader = false;
    })
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  resetFilter() {
    this.selected = "";
    this.location.go('/myRates',"?date="+ Math.random())
  }

  addToWishlist(event: { stopPropagation: () => void; }, data: { mandi: string | number | boolean; product: string | number | boolean; state: string | number | boolean; subproduct: string | number | boolean; companies: string | number | boolean; }) {
    event.stopPropagation();

    if (this.checkIfStationAdded(data)) {
      return;
    }
    
    if (this.hideLoader) {
      this.admin.loader(false);
      this.admin.showLoader = false;
    }

    let url = apiUrl._getMyRates + '?mandi='+ encodeURIComponent(data.mandi)
     + '&product=' + encodeURIComponent(data.product)
     + '&state=' + encodeURIComponent(data.state) 
     + '&subproduct=' + encodeURIComponent(data.subproduct)
     + '&companies=' + encodeURIComponent(data.companies);

    this.IS.submitForm({}, url)
      .subscribe(async (res) => {
        this.hideLoader = false;
        this.getMyStations();
    })
  }

  checkIfStationAdded(stationData) {
    let temp = this.myStationsRaw && this.myStationsRaw.find((s: any) => {
      if (s.mandi == stationData.mandi && s.subproduct == stationData.subproduct && s.companies == stationData.companies) {
        return s;
      }
    })

    return temp
  }

  deleteMyRate(event: any, data: { _id: string; }) {
    if (this.hideLoader) {
      this.admin.loader(false);
      this.admin.showLoader = false;
    }

    this.IS.deleteList({}, apiUrl._getMyRates + '?wishlistId='+ data._id)
      .subscribe(async (res) => {
        this.hideLoader = false;
        this.getMyStations();
    })
  }

  getStations() {
    if (this.hideLoader) {
      this.admin.loader(false);
      this.admin.showLoader = false;
    }

    this.IS.getList({}, apiUrl._getStations)
      .subscribe(async (res) => {
        this.hideLoader = false;
        this.stations = res.data;
      })
  }

  groupBy = (arr: any[], key: string) => {
    const initialValue = {};
    return arr.reduce((acc: { [x: string]: any; }, cval: { [x: string]: any; }) => {
      const myAttribute = cval[key];
      acc[myAttribute] = [...(acc[myAttribute] || []), cval]
      return acc;
    }, initialValue);
  };

  //rateForIndex  => 0, Bird, 1 => Egg
  addRateData(allRates, rateForDay, rateForCategory, wItem) {
    let tempArr = allRates[rateForDay][rateForCategory]['__zone_symbol__value'].data;

    tempArr && tempArr.map((rate) => {
      // check subcategory is matching
      if (rate['_id'] == wItem.subproduct) {

        for (let index = 0; index < rate.subcategories.length; index++) {
          const acRate = rate.subcategories[index];
          
           // rate found for favorite mandi
           if (acRate['_id'].mandi == wItem.mandi && acRate['_id'].companies == wItem.companies) {
              if (rateForDay == 0) {
                wItem.todaysRateData = acRate.rateData || {};
              } else if (rateForDay == 1) {
                wItem.tomorrowsRateData = acRate.rateData || {};
              } else if (rateForDay == 2) {
                wItem.currentRateData = acRate.rateData || {};
              } else if (rateForDay == 3) {
                wItem.forcastRateData = acRate.rateData || {};
              }
          }
        }
      }
    })
  }

  getMyStations() {
    if (this.hideLoader) {
      this.admin.loader(false);
      this.admin.showLoader = false;
    }

    this.productCategories = [];

    this.IS.getList({}, apiUrl._getMyRates).toPromise().then((res) => {
      this.myStationsRaw = res.data;

      this.myStations = this.groupBy(this.myStationsRaw, 'product');

      this.productCategories = Object.keys(this.myStations);

      this.fillRatesIntoStations();

      setTimeout(() => {
        this.fillRatesIntoStations.bind(this).call();
      }, 2000);

      setTimeout(() => {
        this.fillRatesIntoStations.bind(this).call();
      }, 4000);

      setTimeout(() => {
        this.fillRatesIntoStations.bind(this).call();
      }, 8000);
    });
  }

  fillRatesIntoStations() {
    if (this.myStationsRaw && this.myStationsRaw.length > 0 && this.allRates && this.allRates.length > 0) {

      this.myStationsRaw.map((wItem: any) => {
        wItem.todaysRateData = {};
        wItem.tomorrowsRateData = {};
        wItem.currentRateData = {};
        wItem.forcastRateData = {};
        
        if (wItem.product == 'Bird') {
          // rateForDay = 0 => todays data stored at zero index
          // rateForCategory = 0 => Bird
          this.addRateData(this.allRates, 0, 0, wItem) // today
          this.addRateData(this.allRates, 1, 0, wItem) // tomorrow
          this.addRateData(this.allRates, 2, 0, wItem) // current
          this.addRateData(this.allRates, 3, 0, wItem) // forcast
        } else if (wItem.product == 'Egg') {
          this.addRateData(this.allRates, 0, 1, wItem)
          this.addRateData(this.allRates, 1, 1, wItem)
          this.addRateData(this.allRates, 2, 1, wItem)
          this.addRateData(this.allRates, 3, 1, wItem)
        } else if (this.allRates, wItem.product == 'Chicks & Hatching Egg') {
          this.addRateData(this.allRates, 0, 2, wItem)
          this.addRateData(this.allRates, 1, 2, wItem)
          this.addRateData(this.allRates, 2, 2, wItem)
          this.addRateData(this.allRates, 3, 2, wItem)
        } else if (wItem.product == 'Grains') {
          this.addRateData(this.allRates, 0, 3, wItem)
          this.addRateData(this.allRates, 1, 3, wItem)
          this.addRateData(this.allRates, 2, 3, wItem)
          this.addRateData(this.allRates, 3, 3, wItem)
        }
      })
      
      this.myStations = this.groupBy(this.myStationsRaw, 'product');

      let obj = {};
      this.productCategoriesOrdered.map((oc) => {
        if (this.myStations[oc]) {
          obj[oc] = true;
        }
      });

      obj = {...obj, ...this.myStations};
      
      this.productCategories = Object.keys(obj);
      
      this.hideLoader = false;
    }
  }
}
