import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { apiUrl } from '../../../global/global';
import { HttpParams } from '@angular/common/http';
import { IndexService } from '../../index-page/index.service';
import { FormGroup, FormControl, FormBuilder, Validators, } from '@angular/forms';
import { AppComponent } from '../../../app.component';
import * as CONSTANT from '../../../services/constants';
import * as moment from 'moment'
@Component({
  selector: 'app-mandi',
  templateUrl: './mandi.component.html',
  styleUrls: ['./mandi.component.css']
})
export class MandiComponent implements OnInit {
  pageCount: any = [];
  productName: any = [];
  productData: any = [];
  allData: any = [];
  showTh=false;
  mandiEnabled:boolean=true;
  stateEnabled:boolean=true;
  updateProductName = false;
  proName: any;
  filterWrap = false;
  subProduct: any;
  selectfromError = false;
  showError = false;
  stateName: any;
  mandiName: any;
  filterTxt = "Daily Rates";
  fromDateValue: any;
  categoryError = false;
  stateError = false;
  mandiError = false;
  productForm: FormGroup;
  fromDate: any;
  hideHeaderFooter = true;
  toDate: any;
  filterError = false;
  currentPage: number = 1;
  filterType: any;
  todayDate = new Date();
  toDateValue: any;
  ratesType:any;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 1, totalItems: null }
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  constructor(private route: ActivatedRoute, private IS: IndexService, private router: Router, private formBuilder: FormBuilder, private _location: Location, private parent: AppComponent) {
    this.productForm = formBuilder.group({
      product: ['', Validators.required],
      category: [''],
      state: [''],
      mandi: [''],
    });
  }
  ngOnInit(): void {
    let appUrl = localStorage.getItem('mobileApp') ? (localStorage.getItem('mobileApp')) : '';
    if (appUrl) {
      this.hideHeaderFooter = false;
    }
    this.getProductName();
    const parentData = this.parent.getNavtionData();
    console.log(parentData, "parentData===")
    if (parentData) {
      this.proName = parentData[0].productName ? (parentData[0].productName) : "";
      this.subProduct = parentData[0].subProduct ? (parentData[0].subProduct) : "";
      this.stateName = parentData[0].state ? (parentData[0].state) : "";
      this.mandiName = parentData[0].mandi ? (parentData[0].mandi) : "";
      this.ratesType = parentData[0].rateType ? (parentData[0].rateType) : 1;
    
      this.getCategories(this.proName);
      this.getRateHistory();  
    } else {    
      this.router.navigate(['/price/product-price']);
    }
  }

  back() {
    const data=[];
    data.push({'productName':this.proName, 'subProductName':this.subProduct, 'rateType':this.ratesType})
    this.parent.setNavtionData(data);
    this.router.navigate(['/price/product-price']);
    this.subProduct
  }
  getProductName() {
    let data = {}
    this.IS.getList(data, apiUrl._getProductName)
      .subscribe(async (res) => {
        this.productName = res.data;
        if (this.updateProductName) {
          this.proName = this.productName[0];
          this.getCategories(this.proName);
        }
        this.productForm.patchValue({ 'product': this.proName })
        this.productForm.patchValue({ 'category': this.subProduct })
        this.productForm.patchValue({ 'state': this.stateName })
        this.productForm.patchValue({ 'mandi': this.mandiName })
        console.log(this.productName, "this.productName===")
      }, err => {

      })
  }

  getCategories(productName) {
    let params = new HttpParams();
    params = params.append('view', '0');
    params = params.append('product', productName); 
    if (this.subProduct) {
      params = params.append('category', this.subProduct);
    }
    if (this.stateName) {
      params = params.append('state', this.stateName);
    }
    this.IS.getList(params, apiUrl._getCategoriesRate)
      .subscribe(async (res) => {
        this.productData = res.data;

        if(this.productData.mandi.length>0){
          let boolean:boolean;
          this.mandiEnabled=boolean;
        }
        if(this.productData.state.length>0){
          let boolean:boolean;
          this.stateEnabled=boolean;  
        }        
        console.log(this.productData, "productData==")
      })
  }

  getRateHistory() {
    let params = new HttpParams();
    params = params.append('product', this.proName);
    params = params.append('limit',  this.pagination.limit.toString());
    params = params.append('page',  this.pagination.skip.toString());
    params = params.append('rateType', this.ratesType);
    if (this.subProduct) {
      params = params.append('category', this.subProduct);
    }
    if (this.stateName) {
      params = params.append('state', this.stateName);
    }
    if (this.mandiName) {
      params = params.append('mandi', this.mandiName);
    }
    if (this.fromDate) {
      params = params.append('fromDate', moment(this.fromDate).format('YYYY-MM-DD').toString());
    }
    if (this.toDate) {
      params = params.append('toDate', moment(this.toDate).format('YYYY-MM-DD').toString());
    }
    this.IS.getList(params, apiUrl._getRateHistory,
    )
      .subscribe(async (res) => {
        this.allData = res.data.list
        this.pageCount = res.total;
        this.filterWrap = false;
        if(res.data.count==1){  
          this.showTh=  true;        
        }else{
          this.showTh=  false;
        } 
        if (this.filterType == 2) {
          this.filterTxt = "Monthly rates";
        } else if (this.filterType == 3) {
          this.filterTxt = "Custom date rates";
        } else {
          this.filterTxt = "Daily Rates";
        }
        console.log(this.allData, "mandi price")
        console.log(res, "mandi price")
      }, err => {

      })
  }

  selectProduct(event) {
    this.subProduct = "";
    this.stateName = "";
    this.mandiName = "";
    this.proName = event.target.value;
    this.getCategories(this.proName);
    this.productForm.patchValue({'category':"select"})
  }

  selectSubProduct(event) {
    this.stateName = "";
    this.subProduct = event.target.value;
    this.getCategories(this.proName);
    this.productForm.patchValue({'state':"select"})
  }
  selectState(event) {
    this.stateName = event.target.value;
    this.getCategories(this.proName);
    this.productForm.patchValue({'mandi':"select"})
  }
  selectMandi(event) {
    this.mandiName = event.target.value;
  }
  searchProduct(form) {
    if (!this.subProduct) {
      this.categoryError = true;
      setTimeout(() => {
        this.categoryError = false;
      }, 3000);
      return false
    }
    if (!this.stateName) {
      this.stateError = true;
      setTimeout(() => {
        this.stateError = false;
      }, 3000);
      return false
    }
    if (!this.mandiName) {
      this.mandiError = true;
      setTimeout(() => {
        this.mandiError = false;
      }, 3000);
      return false
    }

    this.getRateHistory();

  }
  viewRate(subProductName) {
    this.router.navigateByUrl(`/price/product-price/` + this.proName + `/${subProductName}`);
  }

  /*pagination */
  limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 1;
    this.pagination.totalItems = null;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.getRateHistory();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) + 1;
    this.getRateHistory();
  }
  openFilter() {
    this.filterWrap = true;
  }
  closeFilter() {
    this.filterWrap = false;
    this.fromDate = "";
    this.toDate = "";
    this.filterType = "";
    this.fromDateValue = "";
    this.toDateValue = "";
  }
  changeEventFilter(index) {
    if (index == 1) {
      this.filterType = index;
      this.fromDate = new Date();
      this.toDate = new Date();
      this.fromDateValue = "";
      this.toDateValue = "";
    } else if (index == 2) {
      this.filterType = index;
      this.fromDateValue = "";
      this.toDateValue = "";
      this.toDate = new Date();
      let date = new Date();
      let pastDate = date.setDate(date.getDate() - 30);   
      this.fromDate = new Date(pastDate);
    }
    else if (index == 3) {
      this.fromDate = "";
      this.toDate = "";
      this.filterType = index;
    }
  }
  applyFilter() {
    if (!this.fromDate || !this.toDate) {
      this.filterError = true;
      setTimeout(() => {
        this.filterError = false;
      }, 3000);
      return false
    }
    if (this.filterType == 3) {
      let toDATE = moment(this.toDate).format('YYYY-MM-DD').toString()
      let fromDATE = moment(this.fromDate).format('YYYY-MM-DD').toString()
      if (fromDATE > toDATE) {
        this.selectfromError = true;
        this.toDateValue = "";
        setTimeout(() => {
          this.selectfromError = false;
        }, 3000);
        return false

      } else {
        this.selectfromError = false;
        this.getRateHistory();
      }
    }else{
      this.getRateHistory();
    }
    
  }
  fromDateChange(e) {
    this.fromDate = e;
    this.fromDateValue = e;
  }
  toDateChange(e) {

    this.toDate = e;
    this.toDateValue = e;
  }
  clearFilter() {
    this.fromDateValue = "";
    this.toDateValue = "";
    this.filterType = "";
  }
  checkValue(value) {
    let rate = parseInt(value);
    if (rate == value) {
      let type = '2'
      return type
    } else {
      let type = '1'
      return type
    }
  }
  checkText(text) {
    let type;
    if (text) {
      let checkTxt = text.split(' ').filter(s => s).join(' ').toLowerCase();
      if (checkTxt == 'coming soon') {
        type = '1'
        return type
      } else if (checkTxt == 'same normal') {
        type = '2'
        return type
      } else if (checkTxt == 'slow down') {
        type = '3'
        return type
      }
      else {
        type = '4'
        return type
      }
    } else {
      type = '5'
      return type
    }
  }
}
