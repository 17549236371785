import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sellerprofilenewregistration3',
  templateUrl: './sellerprofilenewregistration3.component.html',
  styleUrls: ['./sellerprofilenewregistration3.component.css']
})
export class Sellerprofilenewregistration3Component implements OnInit {
  id:any;
  constructor() { }

  ngOnInit(): void {
  }

}
