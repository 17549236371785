<header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div class="logo_location">
                <a class="navbar-brand handcursul" (click)="routherPath1('index')"><img src="./assets/images/logo.svg"></a>
                <div class="loc_acc">
                    <a data-toggle="modal handcursul" data-target="#loginModal9" (click)="addressModal()"><img src="./assets/images/map-marker.svg"><strong>{{'header.home.home' | translate}}</strong><br>
                        <p>{{addressCurrent}}</p>
                    </a>
                </div>
            </div>

            <div class="dropdown menuBar ">
                <div class="layerBg"></div>
                <button class="navbar-toggler btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="menu_nav">
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <ul class="navbar-nav ml-auto">
                            <li *ngIf="!loginshow" class="nav-item">
                                <a class="nav-link handcursul" (click)="gotoAbout()" href="javascript:;">{{'header.menu.iteam1' | translate}}
                                </a>
                            </li>
                            <!-- <li *ngIf="!loginshow" class="nav-item">
                            <a class="nav-link handcursul">{{'header.menu.iteam3' | translate}}</a>
                        </li> -->
                            <li class="nav-item">
                                <!-- <a routerLink="/index" pageScroll href="#rates" [pageScrollDuration]="5000" class="nav-link handcursul">Rates
                            </a> -->
                                <a (click)="gotoRate()" class="nav-link handcursul" href="javascript:;">Rates</a>

                            </li>
                            <li class="nav-item">
                                <a routerLink="/partner" class="nav-link handcursul">Partners
                                </a>
                            </li>

                            <li *ngIf="loginshow" class="{{ headershow || urlnumber1 =='Buyer' || urlnumber1 == 'Buyerlisting' || urlnumber1 == 'ProductDetail' ? 'nav-item active':'nav-item'}}">
                                <a class="nav-link handcursul" routerLink="/Buyer">{{'header.menu.iteam4' |
                                    translate}}</a>
                            </li>
                            <li *ngIf="loginshow" class="{{ urlnumber1 =='seller' || urlnumber1 =='category' || urlnumber1 == 'add-product' || urlnumber1 == 'edit-product' ? 'nav-item active':'nav-item'}}">
                                <a class="nav-link handcursul" routerLink="/seller">{{'header.menu.iteam5' |
                                    translate}}</a>
                            </li>

                            <li *ngIf="loginshow" class="{{ urlnumber1 =='2' ? 'nav-item active':'nav-item'}}">
                                <a class="nav-link handcursul" (click)="routherPath('approvedOrders/2',business)">{{'header.menu.iteam6' |
                                    translate}}</a>
                            </li>

                            <li *ngIf="loginshow" class="{{ urlnumber1 =='chat' || urlnumber1 =='1' || urlnumber1 =='0'? 'nav-item active':'nav-item'}}">
                                <a class="nav-link handcursul" (click)="routherPath('chat',business)">{{'header.menu.iteam7' | translate}}
                                </a>
                            </li>

                        </ul>
                        <div class="right_head mob_mode">
                            <ul class="noty_log w-100">
                                <!-- <li *ngIf="loginshow && (urlnumber1=='Buyer' || urlnumber1=='seller') && urlnumber1 !='Chat' && urlnumber1 !='myprofile'"
                                class="search_br"><input type="text"  name="" value="{{Commonsearch}}" (keyup)="search($event)"
                                    (keyup.enter)="commonsearch()"><a (click)="commonsearch()"><i class="fa fa-search"></i></a>
                            </li> -->
                                <li *ngIf="loginshow" class=" nav-item">
                                    <a routerLink="/notification">
                                        <!-- <span [ngClass]="checkNotification()"></span> -->
                                        Notification
                                    </a>
                                </li>

                                <li class="lang_o" *ngIf="!loginshow" (click)="stopClick($event)">
                                    <span class="slt_wrap">

                                        <select (change)="selectLanguage($event)" [(ngModel)]="selectedValue">
                                            <option value="EN">English</option>
                                            <option value="HI">Hindi</option>
                                            <option value="BN">Bengali</option>
                                            <option value="MR">Marathi</option>
                                            <option value="TA">Tamil</option>
                                            <option value="TE">Telugu</option>
                                        </select>
                                    </span>

                                </li>

                                <li *ngIf="!loginshow"><a class="handcursul" (click)="openLoginModal()">{{'header.menu.iteam8' | translate}} </a></li>
                                <li *ngIf="loginshow"><a (click)="myprofile()" class="text-capitalize handcursul">{{name}} <i class="fa fa-angle-down"></i>
                                    </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right_head dsktop_mode">
                <ul class="noty_log w-100">
                    <li *ngIf="loginshow" class="search_br"><input type="text" name="" (keyup.enter)="commonsearch()" [(ngModel)]="commonSearch"><a (click)="commonsearch()"><i class="fa fa-search"
                                *ngIf="closeSearch"></i></a>
                        <a class="closeBtn_search " (click)="removeSeach()" *ngIf="!closeSearch">&times;</a>
                    </li>
                    <li *ngIf="loginshow" class="notify">
                        <a routerLink="/notification">
                            <span [ngClass]="checkNotification()"></span>
                            <i class="fa fa-bell-o"></i></a>
                    </li>

                    <li class="lang_o" *ngIf="!loginshow">
                        <span class="slt_wrap">
                            <select (change)="selectLanguage($event)" [(ngModel)]="selectedValue">
                                <option value="EN">English</option>
                                <option value="HI">Hindi</option>
                                <option value="BN">Bengali</option>
                                <option value="MR">Marathi</option>
                                <option value="TA">Tamil</option>
                                <option value="TE">Telugu</option>
                            </select>
                        </span>
                    </li>
                    <li *ngIf="!loginshow"><a class="handcursul" (click)="openLoginModal()">{{'header.menu.iteam8' | translate}} </a></li>
                    <li *ngIf="loginshow">
                        <a (click)="myprofile()" class="text-capitalize handcursul">
                            <div class="imgIcon" *ngIf="profilePic != 'null'">
                                <img [src]="urlfile+profilePic">
                            </div>
                            <div class="imgIcon" *ngIf="profilePic == 'null'">
                                <i class="fa fa-user"></i>
                            </div>
                            <div class="profile-text">{{name}} </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div *ngIf="showhite && (urlnumber1 =='Buyer' || urlnumber1 =='index')" class="mn_slide">
        <div class="bxslider" *ngIf="mySlideImages?.length > 0">
            <div id="preloader" style="z-index: 1 !important;">
                <div class="loader"></div>
            </div>
            <owl-carousel [options]="mySlideOptions" [items]="mySlideImages" class="owlcarousel" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                <div class="item owlcarouselheight" *ngFor="let slide of mySlideImages; let i = index">
                    <div class="{{slide.format == 'mp4' ?'causalmargintop':'causalmargintop'}}">

                        <video class="imageheight video w-100" controls *ngIf="slide.format == 'mp4'" (click)="toggleVideo()" #videoPlayer>
                            <source src="{{slide.image}}" type="video/mp4" />
                        </video>
                        <!-- <video class="imageheight w-100" src="http://techslides.com/demos/sample-videos/small.mp4"></video> -->

                        <!-- <video class="imageheight w-100" src="{{slide.image}}" *ngIf="slide.format == '.mp4'"></video> -->
                        <!-- <a href="{{slide.image}}" target="_blank"> -->
                        <img class="h-100" src="{{slide.image}}" *ngIf="slide.format != 'mp4'" (click)="bannerRedirect(slide.link, slide.searchText, slide.categoryId)">
                        <!-- </a> -->
                    </div>
                </div>
            </owl-carousel>

        </div>

    </div>


</header>
<div class="modal fade" bsModal #currentAddress="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="log_pop otp_scrn submit_acc add_pop map_loc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close handcursul" data-dismiss="modal" aria-label="Close" (click)="currentAddress.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="busineesForm">
                        <div class="form-group mar_0">

                            <label>Enter address</label>
                            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" formControlName="googleAddress" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>

                        </div>
                        <div class="form-group mar_0">
                            <agm-map [latitude]="latitude" [longitude]="longitude">
                                <agm-marker [latitude]=" latitude " [longitude]="longitude " [markerDraggable]="true " (dragEnd)="markerDragEnd($event) "></agm-marker>
                            </agm-map>
                        </div>
                        <div class="d-flex flex-column text-center ">
                            <div class="use_add d-flex ">
                                <button type="button " class="btn btn-info btn-block btn-round btn_red sign2_step3 handcursul col-12 " data-toggle="modal " data-target="#loginModal20 " (click)="hideAddress(); ">Update</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-login-modal #model>

</app-login-modal>
<app-business-modal #business>

</app-business-modal>

<ng-template #loginModal23></ng-template>