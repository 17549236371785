import { TemplateRef, Component, OnInit, Injectable, ViewChild, NgZone, ElementRef, ViewChildren, ContentChild, QueryList } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { apiUrl } from '../../global/global';
import { myProfileService } from './myprofile.service';
import { HttpParams } from '@angular/common/http';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';

import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as CONSTANT from '../../services/constants';
import { NoWhitespaceValidator } from '../../services/NoWhiteSpace.validator';

/////////////////////////////////
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";
import { DataSharingService } from '../../services/data.share.service'; 
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Lightbox } from 'ngx-lightbox';
import { TranslateService } from '@ngx-translate/core';
import { FcmserviceService } from './../../services/fcmservice.service';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
///////////////////////////////////////////
declare var Razorpay: any;
@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {
  modalRef: BsModalRef;
  busineesForm: FormGroup;
  pinError = false;
  onNotification = 0;
  planExpirenotification = 0;
  myProfile: any = 'Basic info';
  googleMapForm = true;
  showError: boolean = false;
  showError1: boolean = false;
  showError2: boolean = false;
  showError3: boolean = false;
  showError4: boolean = false;
  busineesCode: any;
  showErrormatch11: boolean = false;
  showErrormatch21: boolean = false;
  showErrorsupport1: boolean = false;
  showErrorsupport: boolean = false;
  supportType: any = '';
  description: any = '';
  images: any = [];
  oldpwd: any = '';
  Phonenum = '';
  newpwd1: any = '';
  newpwd2: any = '';
  busineesadd = '';
  Selectproduct: any;
  selectedID = '';
  PointRedeem: any = '';
  uploadImage: any;
  urlfile: any;
  allData: any;
  faqlist = [];
  indexvalue: any = 0;
  allData1: any = [];
  sportType: any = [];
  referralCode: any = '';
  uploadImage1 = '';
  imageUpload: any = [];
  imageUpload3: any = [];
  imageUpload4: any = [];
  redeemdata: any = [];
  urlfile1: any = '';
  image: any = [];
  uploadImage2 = '';
  urlfile2: any = '';
  urlfile22: any = '';
  getbusineeslist: any;
  getuserlist: any;
  cityError = false;
  Countrylist: any;
  stateslist: any;
  paymentListing: any;
  currentPage: number = 1;
  pageCount: any;
  pageCount1: any;
  showButton: boolean = false;
  updateProfileForm: FormGroup;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 0, totalItems: null };
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  allCategory: any = [];
  urlFaq: any;
  planId: any;
  plansList: any;
  planSubscription: any;
  planVisibility: any;
  planName: any;
  planTrade: any;
  planPaymet = '';
  showPayment = 0;
  phoneNumber: any;
  userId: any;
  busineesId: any;
  busineesTyps: any = [];
  private geoCoder;
  address: string;
  gMap: any;
  mapPlot: any
  mapSector: any;
  mapLocality: any;
  mapCity: any;
  mapState: any;
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapStateshort: any;
  latitude = 30.67995;
  longitude = 76.72211;
  zoom: number;
  categoryName: any;
  showPdf = 0;
  showPdf1 = 0;
  album: any = [];
  plandetails: any;
  feeCheck: any;
  BussinessVerified: any;
  showLoaders = 0;
  showSub = 0;
  showCompany = 0;
  slideCheck = 0;
  selectedSub: any;
  selectedCompany: any;
  allDatarates: any = [];
  productSubcategory: any;
  productCompany: any;
  browserLang;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  selectedFile: any = null;
  addressError = false;
  countryError = false;
  stateError = false;
  addressLine1: any;
  switchLang;
  selectedValue: any;
  editbusinessprofile = false;

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.urlfile22 = event.base64;
    console.log(event);
    var block = this.croppedImage.split(";");
    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    var blob = this.b64toBlob(realData, contentType);
    this.selectedFile = new File([blob], new Date().getTime() + "." + blob.type.split("/")[1]);
    console.log(this.selectedFile);
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild('loginModal12') private loginModal12: TemplateRef<any>
  @ViewChild('paymentPopup') private paymentPopup: TemplateRef<any>

  profileImages = ["", ""];

  constructor(public translate: TranslateService, private _lightbox: Lightbox, private dataSharingService: DataSharingService, private formBuilder: FormBuilder, private modalService: BsModalService, public admin: JsonPlaceHolderService, private NS: myProfileService, private router: Router, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private storage: AngularFireStorage, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private messagingService: FcmserviceService,) {

    this.updateProfileForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      name: ['', Validators.compose([Validators.required,
      Validators.minLength(2), Validators.maxLength(45),
      Validators.pattern(CONSTANT.FIRSTNAME_REGEX), NoWhitespaceValidator()])],
      referredBy: ['',],
      // password : ['',  Validators.compose([Validators.required, Validators.minLength(8)])]
      phoneNumber: ['', Validators.compose([])],

    });
    this.busineesForm = this.formBuilder.group({
      googleAddress: [''],
      userType: ['', Validators.compose([Validators.required])],
      BusinessType: ['', Validators.compose([Validators.required])],
      BusinessName: ['', Validators.compose([Validators.required,
      Validators.minLength(3), Validators.maxLength(45)])],
      AddressLine1: ['', Validators.compose([Validators.required])],
      AddressLine2: ['', Validators.compose([])],
      Country: ['', Validators.compose([])],
      state: ['', Validators.compose([])],
      city: ['', Validators.compose([Validators.required])],
      pincode: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('language')) {
      this.selectedValue = localStorage.getItem('language');
    } else {
      this.selectedValue = 'EN';
    }
    this.urlFaq = localStorage.getItem('s3BaseUrl');
    this.getuserdetails();
    this.getFaq();
    this.getappInitials();
    this.getsupport();
    this.payment();
    this.currentPlan();
    this.getTredcate();
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: "IN" }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.gMap = place;
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          console.log(this.latitude, this.longitude, "adasdasd")
          this.getAddress(this.latitude, this.longitude)

        });
      });
      autocomplete.setComponentRestrictions

    });

    if (localStorage.getItem('profilePage') == null || localStorage.getItem('profilePage') == 'null') {
      this.ChangeState('Basic info')
    } else {
      this.ChangeState(localStorage.getItem('profilePage'))
    }
  }
  ngOnDestroy() {
    localStorage.removeItem('profilePage');
  }

  /*language*/
  selectLanguage(event) {
    let lang = event.target.value;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.browserLang = lang;
    console.log(this.browserLang);
    localStorage.setItem('language', lang);
    this.languageChange();
    this.dataSharingService.selectedLang.next(this.browserLang);
  }
  languageChange() {
    this.translate.use(this.browserLang.match(/HI|EN|TE|MR|TA|BN/) ? this.browserLang : 'EN');
  }


  sendRefer() {
    if (this.Phonenum != '' && this.Phonenum.length == 10) {
      const data = {
        'phoneNumber': '+91-' + this.Phonenum,
        // 'country':'+91-'
        'refferalCode': this.allData.referralCode
      }
      this.modalRef.hide();
      // this.admin.showAlert('Refer Code Send Successfully', '');
      this.Phonenum = '';
      this.NS.submitForm(data, apiUrl._postshareRefferalCode)
        .subscribe(async (res) => {
          this.modalRef.hide();
          this.admin.showAlert('Refer Code Send Successfully', '');
        })
    } else {
      this.showError1 = true;
      setTimeout(() => {
        this.showError1 = false;
      }, 5000);
    }
  }
  updateProfile(form) {
    
    if (form.valid || (!form.valid && form.value.name != '' && form.value.email == '')) {

      // let accestoken = localStorage.getItem('adminToken');
      // var authorization  = accestoken;
      const data = {
        // 'authorization':'Bearer ' + authorization,
        'name': form.value.name,
        'email': form.value.email,
        'phoneNumber': form.value.phoneNumber,
        'image': this.uploadImage,
        'referredBy': form.value.referredBy,
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken(),
      };
      this.NS.updateForm(data, apiUrl._putUpdateprofile)
        .subscribe(async (res) => {
          
          localStorage.setItem('name', <any>(res.data.name ? res.data.name : 'User Name'));
          localStorage.setItem('profilePic', res.data.profilePic);
          this.dataSharingService.isUser.next(true);
          // this.reloadCurrentRoute()
          //////////////////////////////////
          var userId = res.data._id.toString().trim();
          var userName = res.data.name;
          var profilePic = res.data.profilePic;
          var aa = this.db.object(`Users/${userId}/`).update({
            "user_name": userName.toString().trim(),
            "user_pic": profilePic,
          });
          ////////////////////////////////////
          this.getuserdetails();
          this.modalRef.hide();
        })
      // }else if(this.uploadImage == null || this.uploadImage == undefined || this.uploadImage == ''){
      //   this.showError1=true;
      //   setTimeout(()=>{
      //     this.showError1 = false;
      //    },5000);
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  businessTypeSelect(event) {
    this.busineesForm.value.BusinessType = event.target.value;
    for (let i = 0; i < this.busineesTyps.length; i++) {
      if (this.busineesTyps[i].name == event.target.value) {
        this.busineesCode = this.busineesTyps[i].code;
      }
    }
  }

  open(template, type, previousModal?) {
    if (type == 1) {
      if (this.busineesForm.value.userType == '' || this.busineesForm.value.userType == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.BusinessType == '' || this.busineesForm.value.BusinessType == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.BusinessName == '' || this.busineesForm.value.BusinessName == null || this.busineesForm.value.BusinessName.length < 3) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else {
        // this.modalRef.hide();
        // this.modalRef = template.show();
        this.modalRef.hide();
        setTimeout(() => {
          this.modalRef = this.modalService.show(template, {
            class: "my-class-modals", animated: true,
            backdrop: 'static',
          });
        }, 10);
      }
    } else if (type == 2) {

      if (this.busineesForm.value.AddressLine1 == '' || this.busineesForm.value.AddressLine1 == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.state == "" || this.busineesForm.value.state == null) {

        this.stateError = true;
        setTimeout(() => {
          this.stateError = false;
        }, 4000)
      } else if (this.busineesForm.value.city == "" || this.busineesForm.value.city == null) {

        this.cityError = true;
        setTimeout(() => {
          this.cityError = false;
        }, 4000)
      } else if (this.busineesForm.value.pincode == "" || this.busineesForm.value.pincode == null) {

        this.pinError = true;
        setTimeout(() => {
          this.pinError = false;
        }, 4000)
      } else if (this.busineesForm.value.pincode.toString().length != 6) {

        this.pinError = true;
        setTimeout(() => {
          this.pinError = false;
        }, 4000)
      }
      // else if (this.addressLine1) {
      //   
      //   if (this.busineesForm.value.AddressLine1 != this.addressLine1) {
      //     this.addressError = true;
      //     setTimeout(() => {
      //       this.addressError = false;
      //     }, 4000)
      //   }
      // } 
      else if (this.busineesForm.value.Country != this.mapcountry) {
        this.countryError = true;
        setTimeout(() => {
          this.countryError = false;
        }, 4000)

      } else if (this.busineesForm.value.state != this.mapState) {

        this.stateError = true;
        setTimeout(() => {
          this.stateError = false;
        }, 4000)
      } else if (this.busineesForm.value.city != this.mapCity) {
        this.cityError = true;
        setTimeout(() => {
          this.cityError = false;
        }, 4000)

      } else if (this.mapPostal) {
        if (this.busineesForm.value.pincode != this.mapPostal) {
          this.pinError = true;
          setTimeout(() => {
            this.pinError = false;
          }, 4000)
        }
        else {
          if (previousModal) {
            previousModal.hide();
          } else {
            this.modalRef.hide();
          }
          console.log(previousModal, "previousModal")
          this.modalRef = this.modalService.show(template, {
            class: "my-class-modals", animated: true,
            backdrop: 'static'
          });
        }
      } else {
        previousModal.hide();
        this.modalRef = this.modalService.show(template, {
          class: "my-class-modals", animated: true,
          backdrop: 'static'
        });
      }
    } else if (type == 4) {
      this.googleMapForm = true;
      this.modalRef.hide();
      this.modalRef = template.show();
    } else if (type == 5) {
      this.googleMapForm = false;
      this.setCurrentLocation();
      this.modalRef.hide();
      this.modalRef = template.show();
    } else if (type == 3) {
      // _putBusinessInfo
      if (type == 3) {
        // const fd = new FormData();

        let params = new HttpParams();
        if (this.uploadImage2 && !this.uploadImage1) {
          this.image = [];
          this.image.push(this.uploadImage2)
          // fd.append('businessDocuments', this.image);
        } else if (this.uploadImage1 && !this.uploadImage2) {
          this.image = [];
          this.image.push(this.uploadImage1)
          // fd.append('businessDocuments', this.image);
        } else if (this.uploadImage1 && this.uploadImage2) {
          this.image = [];
          this.image.push(this.uploadImage2)
          this.image.push(this.uploadImage1)
          // fd.append('businessDocuments', this.image);
        }
        const data = {
          'userType': this.busineesForm.value.userType,
          'businessType': this.busineesForm.value.BusinessType,
          'businessName': this.busineesForm.value.BusinessName,
          'addressLine1': this.busineesForm.value.AddressLine1,
          'addressLine2': this.busineesForm.value.AddressLine2,
          'city': this.busineesForm.value.city,
          'state': this.busineesForm.value.state,
          'country': this.busineesForm.value.Country,
          'pinCode': this.busineesForm.value.pincode.toString(),
          'latitude': this.latitude.toString(),
          'longitude': this.longitude.toString(),
          'stateSortCode': this.mapStateshort,
          'businessDocuments': this.image,
          'code': this.busineesCode,
        };


        this.NS.updateForm(data, apiUrl._putBusinessInfo)
          .subscribe(async (res) => {
            this.modalRef.hide();
            if (this.busineesadd == 'add') {
              localStorage.setItem('longitude', this.longitude.toString());
              localStorage.setItem('latitude', this.latitude.toString());
              localStorage.setItem('city', this.busineesForm.value.city);
              localStorage.setItem('state', this.busineesForm.value.state);
              this.dataSharingService.isUser.next(true);

              this.admin.showAlert('Bussiness Profile Register Successfully', '');
              this.paymentCheck();
            } else {
              this.admin.showAlert('Bussiness Profile Update Successfully', '');
              localStorage.setItem('longitude', this.longitude.toString());
              localStorage.setItem('latitude', this.latitude.toString());
              localStorage.setItem('city', this.busineesForm.value.city);
              localStorage.setItem('state', this.busineesForm.value.state);
              this.dataSharingService.isUser.next(true);
              this.paymentCheck();
            }
          })
      }
    }
  }


  /*payment end*/
  paymentCheck() {
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));
    this.NS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => {
        localStorage.setItem('adminToken', res.data.accessToken);
        this.allData = res.data;
        this.feeCheck = res.data.onetimeFee[0].status;
        if (localStorage.getItem("adminToken") != res.data.accessToken) {
          localStorage.setItem('adminToken', res.data.accessToken)
        }
        if (this.feeCheck == 0) {
          if (this.allData.registrationFeeVerified == false) {
            this.modalRef.hide();
            setTimeout(() => {
              this.modalRef = this.modalService.show(this.paymentPopup, {
                class: "modalLg", animated: true,
                backdrop: 'static',
              })
            }, 900);
          } else {
            this.modalRef.hide();
            localStorage.setItem('BussinessVerified', 'true');
            this.getuserdetails();
          }


        } else {
          this.modalRef.hide();
          localStorage.setItem('BussinessVerified', 'true');
          this.BussinessVerified = true;
          this.getuserdetails();
          // this.reloadCurrentRoute();
        }
      })
  }



  /*payemnt check end*/
  /*account payment check*/
  onetimePayment() {
    const data = {
      'planId': this.allData.onetimeFee[0]._id,
      'planType': 'ONETIME',
    };
    this.NS.submitForm(data, apiUrl._postPayment)
      .subscribe(async (res) => {
        this.modalRef.hide();
        this.buyRazorPay1(res.data);
      })
  }
  buyRazorPay1(razorPaData) {

    const data = {
      "key": "rzp_test_i3r1BLKbRv61mu",
      "id": razorPaData.id,
      "entity": razorPaData.entity,
      "amount": razorPaData.amount,
      "amount_paid": razorPaData.amount_paid,
      "amount_due": razorPaData.amount_due,
      "currency": razorPaData.currency,
      "receipt": razorPaData.receipt,
      "status": razorPaData.status,
      "attempts": razorPaData.attempts,
      "notes": [],
      "created_at": razorPaData.reated_at,
      "prefill": {
        "email": "egiiyok@gmail.com",
        "contact": this.allData.phoneNumber,
      },
      "theme": {
        "color": "#ae0019"
      },
      "handler": (response) => {
        const data = {
          "userId": this.allData._id,
          "registrationFeeVerified": true,
          "razorpayPaymentId": response.razorpay_payment_id,
          "razorpayOrderId": response.razorpay_order_id,
          "razorpaySignature": response.razorpay_signature,
          "amount": razorPaData.amount,
          "paymentType": "ONETIMEREGISTRATIONFEE",

        }

        this.NS.updateForm(data, apiUrl._ontimePayment)
          .subscribe(async (res) => {
            localStorage.setItem('BussinessVerified', 'true');
            this.BussinessVerified = true;
            this.admin.showAlert('Payment Successfully Done', '');
            this.pointRefer();
            this.getuserdetails();
            this.dataSharingService.busniess.next(true);


          })


      },
    }
    var rzp1 = new Razorpay(data);
    rzp1.on('payment.failed', function (data) {

      alert(data.error.metadata.order_id);
      alert(data.error.metadata.payment_id);
    });


    var rezp1 = new Razorpay(data)
    rezp1.open()
    console.log('opened');


  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    // this.loading = false;
  }
  upload1(event, num) {
    if (event.target.files && event.target.files.length && num == 1) {
      this.showLoaders = 1;
      const file = event.target.files[0];

      const fd = new FormData();

      fd.append('directory', 'users');

      fd.append('image', event.target.files[0]);

      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          this.uploadImage2 = res.data;
          const reader = new FileReader();
          if (res.message == 'Success') {
            this.showLoaders = 0;
          } else {
            setTimeout(() => {
              this.showLoaders = 0;
            }, 50000);
          }

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          if (files[0].type == "image/tif" || files[0].type == "image/tiff" || files[0].type == "image/jpg" || files[0].type == "image/jpeg" || files[0].type == "image/gif" || files[0].type == "image/png") {
            this.showPdf = 0;
          } else {
            this.showPdf = 1;
          }
          reader.onload = (_event) => {
            this.urlfile2 = reader.result;
          }
        })
    } else if (event.target.files && event.target.files.length && num == 2) {
      this.showLoaders = 1;
      const file = event.target.files[0];

      const fd = new FormData();

      fd.append('directory', 'users');

      fd.append('image', event.target.files[0]);

      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          if (res.message == 'Success') {
            this.showLoaders = 0;
          } else {
            setTimeout(() => {
              this.showLoaders = 0;
            }, 50000);
          }
          this.uploadImage1 = res.data;
          const reader = new FileReader();

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          if (files[0].type == "image/tif" || files[0].type == "image/tiff" || files[0].type == "image/jpg" || files[0].type == "image/jpeg" || files[0].type == "image/gif" || files[0].type == "image/png") {
            this.showPdf1 = 0;
          } else {
            this.showPdf1 = 1;
          }
          reader.onload = (_event) => {
            this.urlfile1 = reader.result;
          }
        })
    }
  }

  // onChnageSelectproduct(event) {
  //   this.selectedID = event.target.value;
  //   // this.allData = this.Selectproduct.filter((item) => {
  //   //   if (item._id.toString() == event.target.value.toString()) {
  //   //     return item;
  //   // }
  //   // });
  // }

  // upload(event) {
  //   if (event.target.files && event.target.files.length) {
  //     const file = event.target.files[0];
  //     const fd = new FormData();

  //     fd.append('directory', 'users');

  //     fd.append('image', event.target.files[0]);
  //     // const data = {
  //     //   'directory':'users',
  //     //   'image':event.target.files[0],
  //     // }
  //     this.NS.submitForm(fd, apiUrl._postupload)
  //       .subscribe(async (res) => {
  //         this.uploadImage = res.data;
  //         const reader = new FileReader();

  //         const files = event.target.files;
  //         reader.readAsDataURL(files[0]);
  //         reader.onload = (_event) => {
  //           this.urlfile22 = reader.result;
  //         }
  //       })
  //   }
  // }

  upload(event, template) {
    if (event.target.files && event.target.files.length) {
      this.selectedFile = <File>event.target.files[0];
      this.modalRef.hide();
      this.modalRef = this.modalService.show(template, {
        animated: true,
        backdrop: 'static',
      });
      this.imageChangedEvent = event;
    }
  }
  cropImages(Editprofile) {
    const fd = new FormData();
    fd.append('directory', 'users');
    fd.append('image', this.selectedFile);
    // const data = {
    //   'directory':'users',
    //   'image':event.target.files[0],
    // }
    this.NS.submitForm(fd, apiUrl._postupload)
      .subscribe(async (res) => {
        this.uploadImage = res.data;
        this.modalRef.hide();
        this.modalRef = this.modalService.show(Editprofile, {
          animated: true,
          backdrop: 'static',
        });
      })
  }



  getuserdetails() {
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));
    this.NS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => {
        this.onNotification = res.data.notification;
        this.planExpirenotification = res.data.planExpireReminder;
        localStorage.setItem('adminToken', res.data.accessToken);
        this.allData = res.data;
        if (localStorage.getItem("adminToken") != res.data.accessToken) {
          localStorage.setItem('adminToken', res.data.accessToken)
        }
        this.updateProfileForm.patchValue({
          phoneNumber: res.data.phoneNumber,
          name: res.data.name,
          email: res.data.email,
        });
        if (res.data.profilePic) {
          this.uploadImage = res.data.profilePic;
          this.urlfile = this.urlFaq + 'users/' + res.data.profilePic;
          var album = {
            src: this.urlfile,
            caption: "",
            thumb: this.urlfile
          };
          this.album = [];
          this.album.push(album);
          this.urlfile22 = this.urlFaq + 'users/' + res.data.profilePic;
        }
        if (res.data.referredBy != null) {
          this.updateProfileForm.patchValue({
            phoneNumber: res.data.phoneNumber,
            referredBy: res.data.referredBy,
          });
        }

      })
  }
  copy(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.admin.showAlert('Copy to Clipboard', '');
  }
  Backopen(template) {
    this.modalRef.hide();
    this.modalRef = template.show();
  }
  getCurrentPosition() {
    this.latitude = this.allData.location.coordinates[1];
    this.longitude = this.allData.location.coordinates[0];

  }

  editbusiness(template, num) {
    this.editbusinessprofile = false;
    this.uploadImage1 = '';
    this.uploadImage2 = '';
    this.busineesadd = num;
    if (this.allData.userType != '' && this.allData.userType) {

      this.editbusinessprofile = true;

      this.mapCity = this.allData.businessAddress.city;
      this.mapState = this.allData.businessAddress.state
      this.mapcountry = this.allData.businessAddress.country
      this.mapPostal = parseInt(this.allData.businessAddress.pinCode),
        this.addressLine1 = this.allData.businessAddress.addressLine1;

      this.mapStateshort = this.allData.businessAddress.stateSortCode
      this.busineesForm.patchValue({
        userType: this.allData.userType,
        BusinessType: this.allData.businessType,
        BusinessName: this.allData.businessName,
        googleAddress: this.allData.businessAddress.addressLine1,
        AddressLine1: this.allData.businessAddress.addressLine1,
        AddressLine2: this.allData.businessAddress.addressLine2,
        state: this.allData.businessAddress.state,
        Country: this.allData.businessAddress.country,
        city: this.allData.businessAddress.city,
        pincode: parseInt(this.allData.businessAddress.pinCode)
      })

      // this.getCurrentPosition();
      this.getCurrentPosition();
      // let latitude1 = this.allData.location.coordinates[1];
      // let longitude1 = this.allData.location.coordinates[0];
      // this.zoom = 12;

      // this.ngZone.run(() => {
      //   this.geoCoder = new google.maps.Geocoder;
      //   this.getAddress(latitude1, longitude1);
      // });
      this.editData(this.allData.userType);
    }
    if (this.allData.businessDocuments.length != 0) {
      this.uploadImage2 = this.allData.businessDocuments[0];
      let fileType = this.allData.businessDocuments[0].split(".")[1];
      if (this.fileTypeChecker(fileType)) {
        this.showPdf = 0;
      } else {
        this.showPdf = 1;
      }
    }
    if (this.allData.businessDocuments.length == 2) {
      this.uploadImage1 = this.allData.businessDocuments[1];
      let fileType = this.allData.businessDocuments[1].split(".")[1];
      if (this.fileTypeChecker(fileType)) {
        this.showPdf1 = 0;
      } else {
        this.showPdf1 = 1;
      }
    }
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
    });
  }

  fileTypeChecker(fileType) {
    let valid = [
      "tif", "tiff", "jpg", "jpeg", "gif", "png"
    ];
    if (valid.includes(fileType.toLowerCase())) {
      return true
    }
    return false;
  }

  faqcss(i) {
    this.indexvalue = i
  }
  getFaq() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getFaq)
      .subscribe(async (res) => {
        this.faqlist = res.data;
      })
  }
  getFaqId(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    this.NS.getList(params, apiUrl._getFaqId)
      .subscribe(async (res) => {
      })
  }
  getsupport() {
    let params = new HttpParams();

    this.NS.getList(params, apiUrl._getsupport)
      .subscribe(async (res) => {
        this.redeemdata = res.data;
      })
  }
  ChangeState(num) {
    this.myProfile = num;
    localStorage.setItem('profilePage', this.myProfile);

  }
  openModule(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  edit(template: TemplateRef<any>) {
    this.getuserdetails()
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  croperHide(template: TemplateRef<any>) {
    this.urlfile22 = this.urlfile;
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  redeempoint() {

    if (this.PointRedeem == '') {
      this.showError4 = true;
      setTimeout(() => {
        this.showError4 = false;
      }, 5000);
      return;
    }
    if ((this.allData.referralPoint < 1000)) {
      this.showError1 = true;
      setTimeout(() => {
        this.showError1 = false;
      }, 5000);
    } else {
      const data = {
        'userName': this.allData.name,
        'point': this.PointRedeem
      };
      this.NS.submitForm(data, apiUrl._redeemPoint)
        .subscribe(async (res) => {
          this.PointRedeem = '';
          this.getuserdetails();
          this.getsupport();
          this.modalRef.hide();

        })

    }
  }
  shareOpen(template) {

    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  uploadSupport(event, num1) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append('directory', 'supports');
      fd.append('image', event.target.files[0]);
      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          // this.uploadImage2 = res.data;
          const reader = new FileReader();

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            if (num1 == 1) {
              this.imageUpload3 = [];
              this.imageUpload3.push({ image: reader.result, string: res.data })
            } else if (num1 == 2) {
              this.imageUpload4 = [];
              this.imageUpload4.push({ image: reader.result, string: res.data })
            }
          }
        })
    }

  }
  delete1(num1) {
    if (num1 == 1) {
      this.imageUpload3 = [];
    } else if (num1 == 2) {
      this.imageUpload4 = [];
    }
  }
  suportkey(event, num) {
    if (num == 1) {
      this.supportType = event.target.value;
    } else {
      this.description = event.target.value;
    }
  }
  support() {
    if (this.imageUpload3.length != 0) {
      this.imageUpload.push(this.imageUpload3[0])
    }
    if (this.imageUpload4.length != 0) {
      this.imageUpload.push(this.imageUpload4[0])
    }
    if (this.supportType != '' && this.description != '') {
      this.images = [];
      if (this.imageUpload.length != 0) {
        for (let i = 0; i < this.imageUpload.length; i++) {
          this.images.push(this.imageUpload[i].string)
        }
      }
      const data = {
        'supportType': this.supportType,
        'description': this.description,
        'phoneNumber': this.allData.phoneNumber,
        'image': this.images
      };
      this.NS.submitForm(data, apiUrl._postSupport)
        .subscribe(async (res) => {
          this.admin.showAlert('Technical team will connect with you shortly.', '');
          this.description = '';
          this.supportType = '';
          this.imageUpload = [];
          this.imageUpload3 = [];
          this.imageUpload4 = [];
        })
    } else if (this.supportType == '') {
      this.showErrorsupport = true;
      setTimeout(() => {
        this.showErrorsupport = false;
      }, 5000);
    } else if (this.description == '') {
      this.showErrorsupport1 = true;
      setTimeout(() => {
        this.showErrorsupport1 = false;
      }, 5000);
    }
  }
  supportcancel(num) {
    this.description = '';
    this.supportType = '';
    this.imageUpload = [];
    this.myProfile = num;
    localStorage.setItem('profilePage', this.myProfile);
  }
  changeredeem(event) {
    this.PointRedeem = event.target.value;
  }
  changeredeem1(event) {
    this.Phonenum = event.target.value;
  }
  changepassword() {
    if (this.newpwd1 == this.newpwd2 && this.oldpwd != '' && this.newpwd1 != '' && this.newpwd2 != '' && (this.newpwd1.length == 4 || this.newpwd1.length == 6) && (this.oldpwd.length == 4 || this.oldpwd.length == 6)) {
      const data = {
        'oldPassword': this.oldpwd,
        'newPassword': this.newpwd1
      };
      this.NS.updateForm(data, apiUrl._putChangePassword)
        .subscribe(async (res) => {
          this.newpwd1 = '';
          this.newpwd2 = '';
          this.oldpwd = '';
          this.admin.showAlert('Pin Change Successfully', '');
        })
    } else if (this.oldpwd == '') {
      this.showError1 = true;
      setTimeout(() => {
        this.showError1 = false;
      }, 5000);
    } else if (this.newpwd1 == '') {
      this.showError2 = true;
      setTimeout(() => {
        this.showError2 = false;
      }, 5000);
    } else if (this.newpwd2 == '') {
      this.showError3 = true;
      setTimeout(() => {
        this.showError3 = false;
      }, 5000);
    } else if (this.oldpwd.length != 6 && this.oldpwd.length != 4 && (this.oldpwd.length == 5 || this.oldpwd.length == 3 || this.oldpwd.length == 2 || this.oldpwd.length == 1)) {
      this.showErrormatch11 = true;
      setTimeout(() => {
        this.showErrormatch11 = false;
      }, 5000);

    } else if (this.newpwd1.length != 6 && this.newpwd1.length != 4 && (this.newpwd1.length == 5 || this.newpwd1.length == 3 || this.newpwd1.length == 2 || this.newpwd1.length == 1)) {
      this.showErrormatch21 = true;
      setTimeout(() => {
        this.showErrormatch21 = false;
      }, 5000);

    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }

  }
  close() {

    this.modalRef.hide();
  }
  change(event, num) {
    if (num == 1) {
      this.oldpwd = event.target.value;
    } else if (num == 2) {
      this.newpwd1 = event.target.value;
    } else {
      this.newpwd2 = event.target.value;
    }
  }
  logout() {
    this.modalRef.hide();
    let accestoken = localStorage.getItem('adminToken');
    var authorization = accestoken;
    const data = {
      'deviceToken': this.getDeviceToken()
    }
    this.NS.updateForm(data, apiUrl._putlogout)
      .subscribe(async (res) => {

        localStorage.clear();
        // this.reloadCurrentRoute();
        this.router.navigate(['/']);
        this.browserLang = 'EN';
        this.dataSharingService.selectedLang.next(this.browserLang);
      })
  }


  /*payment history */
  payment() {
    let params = new HttpParams();
    params = params.append('page', this.pagination.skip.toString());
    params = params.append('limit', this.pagination.limit.toString());
    this.NS.getList(params, apiUrl._getplanHistory)
      .subscribe(async (res) => {
        this.paymentListing = res.data;
        this.pageCount1 = res.total;

      })
  }
  /*pagination */
  limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 1;
    this.pagination.totalItems = null;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.payment();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) + 1;
    this.payment();
  }
  /*choice category */
  categoryPopup(template) {
    // this.modalRef.hide();
    this.modalRef = this.modalService.show(template, { class: "my-class-modals lg", backdrop: 'static', });
    this.getCategories();
  }
  /* category  list*/
  getCategories() {
    let params = new HttpParams();
    // params = params.append('id', this.userId);
    this.NS.getList(params, apiUrl._getCategories)
      .subscribe(res => {
        this.allCategory = res.data.categoryData;
      },
        err => {
          console.log("error", err)
        });
  }
  /* category  select*/
  categorySelect($event, category) {
    this.showButton = $event.target.value;

    this.getPlanlist(category._id);
    this.categoryName = category.name
  }
  /*sub category*/
  subCategory(subCategory) {
    this.planPaymet = '';
    this.modalRef.hide();
    this.modalRef = this.modalService.show(subCategory, {
      class: "my-class-modals lg", animated: true,
      backdrop: 'static'
    });
  }


  /*get plan list*/
  getPlanlist(category) {
    let params = new HttpParams();
    params = params.append('categoryId', category);
    this.NS.getList(params, apiUrl._getPlanlisting)
      .subscribe(res => {
        this.plansList = res.data[0];
      },
        err => { });
  }
  /*plan api hit */
  planChoise(template) {
    this.close();
    this.modalRef = this.modalService.show(template, {
      class: "my-class-modals", animated: true,
      backdrop: 'static'
    });
  }

  /*plan select */
  onlyOneValue(event, plan) {
    this.showPayment = 1;
    this.planPaymet = plan.planPrice;
    this.planId = plan._id;
    this.planName = plan.planType;
    this.planVisibility = plan.visibility;
    this.planTrade = plan.quantity;

    this.planSubscription = plan.subscription;

    this.userId = localStorage.getItem('id');
    this.phoneNumber = localStorage.getItem('phone').substring(localStorage.getItem('phone').length - 10, localStorage.getItem('phone').length)

  }
  /*plan buy api */
  /*amount pay and razerpay */
  payments() {

    const data = {
      'planId': this.planId,
      'planType': 'PLAN',

    };
    this.NS.submitForm(data, apiUrl._postPayment)
      .subscribe(async (res) => {
        this.modalRef.hide();
        this.buyRazorPay(res.data);
      })
  }
  buyRazorPay(razorPaData) {
    const data = {
      "key": "rzp_test_i3r1BLKbRv61mu",
      "id": razorPaData.id,
      "entity": razorPaData.entity,
      "amount": razorPaData.amount,
      "amount_paid": razorPaData.amount_paid,
      "amount_due": razorPaData.amount_due,
      "currency": razorPaData.currency,
      "receipt": razorPaData.receipt,
      "status": razorPaData.status,
      "attempts": razorPaData.attempts,
      "notes": [],
      "created_at": razorPaData.reated_at,
      "prefill": {
        "email": "egiiyok@gmail.com",
        "contact": this.phoneNumber,
      },
      "theme": {
        "color": "#ae0019"
      },
      "handler": (response) => {
        const data = {
          "planData": [{
            "planId": this.planId,
            "planName": this.planName,
            "visibility": this.planVisibility.toString(),
            "trade": this.planTrade.toString(),
            "price": razorPaData.amount.toString(),
            "subscriptionType": this.planSubscription,

          }],
          "planId": this.planId,
          "userId": this.userId,
          "razorpayPaymentId": response.razorpay_payment_id,
          "razorpayOrderId": response.razorpay_order_id,
          "razorpaySignature": response.razorpay_signature,
          "amount": this.planPaymet,
          "paymentType": "SUBSCRIPTION",
        }
        this.NS.submitForm(data, apiUrl._purchasePlan)
          .subscribe(async (res) => {
            this.pointRefer();
            this.ngZone.run(() => {
              this.payment();
            });
            this.BussinessVerified = true;
            this.admin.showAlert('Payment Successfully Done', '');
          })
      },
    }

    var rzp1 = new Razorpay(data);
    rzp1.on('payment.failed', function (data) {

      alert(data.error.metadata.order_id);
      alert(data.error.metadata.payment_id);
    });


    var rezp1 = new Razorpay(data)
    rezp1.open()
  }
  /*Refer a Friend */
  pointRefer() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getPoints)
      .subscribe(async (res) => { })
  }
  /*amount pay and razerpay end*/

  /*update busniess address */
  getappInitials() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getappInitials)
      .subscribe(async (res) => {
        this.Countrylist = res.data.countries;
        this.stateslist = res.data.states;
        this.getuserlist = res.data.userTypes;
        this.getbusineeslist = res.data.businessTypes;
        this.sportType = res.data.supportTypes
      })
  }

  userTypeSelect(event) {
    this.busineesTyps = [];
    this.busineesForm.value.userType = event.target.value;
    for (var i = 0; i < this.getuserlist.length; i++) {
      if (event.target.value == this.getuserlist[i].name) {
        this.busineesId = this.getuserlist[i].id;
      }
    }
    for (var j = 0; j < this.getbusineeslist.length; j++) {
      if (this.busineesId == this.getbusineeslist[j].userTypeId) {
        this.busineesTyps.push(this.getbusineeslist[j]);
      }
    }
  }
  /*edit business*/
  editData(data) {
    this.busineesTyps = [];
    this.busineesForm.value.userType = data;
    for (var i = 0; i < this.getuserlist.length; i++) {
      if (data == this.getuserlist[i].name) {
        this.busineesId = this.getuserlist[i].id;
      }
    }
    for (var j = 0; j < this.getbusineeslist.length; j++) {
      if (this.busineesId == this.getbusineeslist[j].userTypeId) {
        this.busineesTyps.push(this.getbusineeslist[j]);

      }
    }
  }
  /*google map */

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.geoCoder = new google.maps.Geocoder;
    this.zoom = 16;
    this.getAddress(this.latitude, this.longitude)
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      let CheckPin: any;
      console.log(results);
      if (status === 'OK') {
        for (let i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types
          //plot no
          for (var j = 0; j < types.length; j++) {
            if (types.indexOf('subpremise') != -1) {
              this.mapPlot = results[0].address_components[i].long_name
            }
            //Sector
            if (types.indexOf('neighborhood') != -1) {
              this.mapSector = results[0].address_components[i].long_name
            }

            if (types.indexOf('sublocality_level_1') != -1) {
              this.mapLandmark = results[0].address_components[i].long_name
            }
            if (types.indexOf('locality') != -1) {
              this.mapLocality = results[0].address_components[i].long_name
            }
            //city
            if (types.indexOf('administrative_area_level_2') != -1) {
              this.mapCity = results[0].address_components[i].long_name,
                console.log(this.mapCity);
            }
            //state
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.mapState = results[0].address_components[i].long_name,
                this.mapStateshort = results[0].address_components[i].short_name
              console.log(this.mapState);
            }
            if (types.indexOf('country') != -1) {
              this.mapcountry = results[0].address_components[i].long_name,
                console.log(this.mapcountry);
            }
            if (types.indexOf('postal_code') != -1) {
              CheckPin = results[0].address_components[i].long_name,
                console.log('23', CheckPin);
            }
            if (types[0] == 'postal_code') {
              CheckPin = results[0].address_components[i].long_name,
                console.log('00000000000', CheckPin);
            }
          }
        }
        this.addressLine1 = results[0].formatted_address;
        this.mapPostal = CheckPin ? (CheckPin) : '';
        this.busineesForm.patchValue({
          'AddressLine1': results[0].formatted_address,
          // 'addressLine2': this.busineesForm.value.AddressLine2,
          'city': this.mapCity,
          'state': this.mapState,
          'Country': this.mapcountry,
          'pincode': this.mapPostal ? (this.mapPostal) : '',

        })

        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          let newAddress;
          if (this.mapCity) {
            newAddress = this.mapCity + ', ' + this.mapState;
            this.busineesForm.patchValue({ 'googleAddress': newAddress });
          } else {
            newAddress = this.mapState + ', ' + this.mapcountry;
            this.busineesForm.patchValue({ 'googleAddress': newAddress });
          }
          console.log(this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  openBusinessModel() {
    this.modalRef = this.modalService.show(this.loginModal12, {
      animated: true,
      backdrop: 'static'
    })
  }
  Backopen1(template) {
    // previousModal.hide();
    // this.openBusinessModel();
    this.modalRef = this.modalService.show(template, {
      class: "my-class-modals", animated: true,
      backdrop: 'static',
    });
  }

  /*remove image */
  removeImage(type) {

    if (type == 2) {
      if (this.image.length > 0) {
        this.image.splice(this.image.indexOf(this.uploadImage2), 1);
      }
      this.urlfile2 = '';
      this.uploadImage2 = '';
      this.showPdf = 0;

    } else {
      if (this.image.length > 0) {
        this.image.splice(this.image.indexOf(this.uploadImage1), 1);
      }
      this.urlfile1 = '';
      this.uploadImage1 = '';
      this.showPdf1 = 0;
    }
  }

  BackPopup(template) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template, {
      class: "my-class-modals", animated: true,
      backdrop: 'static'
    });
  }
  /*profile image open*/
  openImage(index: number): void {
    this._lightbox.open(this.album, index);
  }


  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }


  // }

  /*current plan */
  currentPlan() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getCurrentplan)
      .subscribe(async (res) => {
        this.plandetails = res.data;
      })
  }

  /**rate and trand */
  onChnageSelectproduct(event) {
    this.selectedID = event.target.value;
    this.selectedSub = '';
    this.selectedCompany = '';
    this.getTredsubcate(this.selectedID);

  }
  /*get rate and trand*/
  getTredcate() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getProductlist)
      .subscribe(async (res) => {
        this.Selectproduct = res.data;
        this.selectedID = res.data[0];
        this.getTredsubcate(this.selectedID);
        this.getRecordRateTrends()

      })
  }
  /*get rate and trand*/
  getTredsubcate(subCategory) {
    let params = new HttpParams();
    params = params.append('search', subCategory);
    this.NS.getList(params, apiUrl._getProductsublist)
      .subscribe(async (res) => {
        console.log('get sub listing', res)
        this.productSubcategory = res.data.subProduct;
        this.productCompany = res.data.company;
        if (this.productSubcategory.length > 0) {
          this.showSub = 1;
        } else {
          this.showSub = 0;
        }
        if (this.productCompany.length > 0) {
          this.showCompany = 1;
        } else {
          this.showCompany = 0;
        }
      })
  }
  getRecordRateTrends() {

    let params = new HttpParams();
    params = params.append('product', this.selectedID);
    if (this.selectedSub) {
      params = params.append('subproduct', this.selectedSub);
    }
    if (this.selectedCompany) {
      params = params.append('company', this.selectedCompany);
    }
    params = params.append('limit', '10');
    params = params.append('skip', this.pagination.skip.toString());
    this.NS.getList(params, apiUrl._getRecord)
      .subscribe(async (res) => {
        this.allDatarates = res.data;
        this.pageCount = res.data.count;
        console.log('check data', this.allData)

      })
  }
  onChnageSubproduct(event) {
    this.selectedSub = event.target.value;

  }
  companyProduct(event) {
    this.selectedCompany = event.target.value;

  }
  openSidebar(value) {
    if (value == 1) {
      this.slideCheck = 1;
    } else {
      this.slideCheck = 0;
    }
  }

  /// notification
  applyNofification(e) {
    if (e.target.checked) {
      this.onNotification = 0;
    } else {
      this.onNotification = 1;
    }

  }
  applyplanNofification(e) {
    if (e.target.checked) {
      this.planExpirenotification = 0;
    } else {
      this.planExpirenotification = 1;
    }

  }

  updateNotification() {
    const data = {
      'platform': 'WEB',
      'planExpireReminder': this.planExpirenotification,
      'notification': this.onNotification,
      'deviceToken': this.getDeviceToken(),
    };
    this.NS.updateForm(data, apiUrl._putUpdateprofile)
      .subscribe(async (res) => {
        this.getuserdetails();
      })
  }

  googlePinCode() {
     let pinCode = this.busineesForm.value.pincode;
    if (pinCode.toString().length != 6) {
      this.pinError = true;
      setTimeout(() => {
        this.pinError = false;
      }, 4000)
      return false
    }
    localStorage.setItem('mapToken', 'mapTokenKey');
    let googleKey = localStorage.getItem("g_id")

    let params = new HttpParams();
    params = params.append('components', 'postal_code:' + pinCode);
    params = params.append('sensor', "false");
    params = params.append('key', "AIzaSyCtOnbQCsWkqBiVL_6Rk8d25TfhPPt-pBk");
    setTimeout(() => {
      this.admin.searchPinCode(params)
        .subscribe(res => {
          this.latitude= res.results[0].geometry.location.lat;
          this.longitude=res.results[0].geometry.location.lng;
          this.getAddress(this.latitude, this.longitude)
          console.log('00000000000', res);
          localStorage.removeItem('mapToken')
        },
          err => {
            localStorage.removeItem('mapToken');
          });
    }, 10);

  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}



