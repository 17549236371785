<app-header></app-header>
<!-- Become a partner-->
<main class="main_cont">
    <div class="mn_slide mt-0">
        <div class="bxslider">
            <div><img src="../../../assets/images/sld1.png">

            </div>


        </div>
    </div>

    <section class="partnerBox">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">

                    <h2>Become an EGIYOK Partner</h2>
                    <p>We would love to have you associated with us as our partners, currently we are looking for "Rate Associations", to partner with us for providing rates & Product/Service Partners - Who would want to be our partners to sell/purchase
                        products & services on our platform.</p>
                    <p>If you are interested to join us, feel free to fill the form and our Support Representatives will get in touch with you asap! Thanks!!!</p>



                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="img_plan">

                        <form>
                            <div class="form-group position_rel row">
                                <label for="fullname" class="col-md-4 col-form-label text-md-right">Full Name<span class="asteriskIcon">*</span></label>
                                <div class="col-12 change_no">
                                    <input type="text" name="fullname" placeholder="John Williams" id="fullname" class="form-control">
                                </div>
                            </div>



                            <div class="form-group position_rel row">
                                <label for="email_address" class="col-md-4 col-form-label text-md-right">Email/ID</label>
                                <div class="col-12">
                                    <input type="text" placeholder="johnwilliams@gmail.com" id="email_address" class="form-control" name="email-address">
                                </div>
                            </div>


                            <div class="form-group position_rel row">
                                <label for="example-tel-input" class="col-12 col-form-label">Phone no<span class="asteriskIcon">*</span></label>
                                <div class="col-12 change_no">
                                    <input class="form-control" type="tel" placeholder="1-(555)-555-5555" id="example-tel-input">

                                </div>
                            </div>
                            <div class="product_s">
                                <div class=" form-group form_cont  position_rel">
                                    <label>State</label>
                                    <span class="slt_wrap">
              <select>
                <option value="">--Select--</option>
                <option >Haryana</option>
                <option >Punjab</option>
                <option >Chattisgarh</option>
                <option > Chandigarh</option>
                
              </select>
            </span>
                                </div>

                                <div class=" form-group form_cont  position_rel">
                                    <label>Business partner type</label>
                                    <span class="slt_wrap">
              <select>
                <option value="">--Select--</option>
                <option>Rate partner</option>
                <option>Product/Service Partner</option>
    
                
              </select>
            </span>
                                </div>

                                <div class=" form-group form_cont  position_rel">
                                    <label>Description Box</label>
                                    <textarea></textarea>
                                </div>
                            </div>





                            <button id="btnid" type="button" class="btn btn-info btn-block btn-round btn_red" data-toggle="modal" data-target="#Modal3">Become A Partner</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>