<app-header></app-header>
<main class="main_cont">
    <!-- Category Section -->
    <section class="look_category">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="title_text">
                        <h2>{{'homePage.buyer.whatLook' | translate}}</h2>

                        <a class="handcursul" (click)="ViewAllCategories()" *ngIf="viewall && allData1.length != 0">{{'homePage.buyer.viewAllcategory' | translate}}</a>

                    </div>
                    <ul class="category_pro category_list_look">
                        <li *ngFor="let item of allData1; let i = index"><a (click)="routherPath(item._id, business, 1)"><span><img class="image_80"
                                            src="{{s3url+'categories/' + item.image}}"></span><br>{{item.name}}</a>
                        </li>
                    </ul>
                    <div class="errorcss" *ngIf="allData1.length == 0">No Record Found</div>
                </div>
            </div>
        </div>
    </section>
    <!-- Trend & Pricing -->
    <div id="rates"></div>
    <section class="trend_pricing">
        <app-rate></app-rate>
    </section>

    <!-- near by egg seller-->
    <section class="near_seller">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="title_text">
                        <h2>{{'homePage.buyer.nearbySellers' | translate}}</h2>
                        <!-- <a (click)="buyerlisting(loginModal12)" data-toggle="tooltip" data-placement="bottom" title="Work in Progress">View All</a> -->
                        <a *ngIf="NearData.length != 0" (click)="routherPath(null, business, 0)" class="handcursul">{{'homePage.buyer.viewAll' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6" *ngFor="let xdata of NearData;let i = index">
                    <div class="near_sell_outer_box">
                        <a (click)="routherPath(xdata._id, business, 2)" class="sell_near handcursul">
                            <h5>{{xdata.subCategoryName}}
                                <!-- <img class="img_vr" src="./assets/images/checked.svg"> -->
                            </h5>
                            <div class="approved_s">
                                <span class="verify" [ngClass]="{'warning': xdata.userDetail.businessVerified == false}">
                                <i class="fa fa-check" *ngIf="xdata.userDetail.businessVerified != false"></i> {{xdata.userDetail.businessVerified ==
                                true?'Verified':'Unverified'}}</span>
                                <span><i class="fa fa-star"></i> {{xdata.sellerRating == null? '0' : xdata.sellerRating |
                                number:'1.1-1'}}</span>
                            </div>

                            <ul>
                                <h6>{{xdata.userDetail.businessName}}</h6>
                                <li *ngFor="let opt of xdata.attributesData;let i = index"><i *ngIf="i != 0" class="fa fa-circle"></i>{{opt.traitName}}</li>
                                <!-- <li>Desi <i class="fa fa-circle"></i></li>
                            <li>Golden</li> -->
                            </ul>
                            <div class="commnt_s">
                                <span><i class=""><img src="./assets/images/star.svg"></i> {{xdata.productRating == null? '0'
                                : xdata.productRating | number:'1.1-1'}}</span>
                                <span><i class=""><img src="./assets/images/chat-bx.svg"></i> {{xdata.messageCount}}</span>
                            </div>
                            <div class="locate_box"><i class="fa fa-map-marker"></i> {{xdata?.address?.city}} ({{this.getDistanceFromLatLonInKm(xdata?.dist?.location.coordinates) | number:'1.1-1'}})Km</div>
                        </a>
                    </div>
                </div>

                <div class="errorcss" *ngIf="NearData.length == 0">No Record Found</div>


            </div>
        </div>
    </section>
    <!-- Testimonial-->
    <section class="slide-wrapper testi">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 posi_r">
                    <div class="testi_txt">
                        <h2>Testimonials</h2>

                        <p>This is what our customers are saying about us!!! We thrive for perfection and your valuable feedback goes a long way to help us do that!!</p>

                        <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
>>
                        <a class="m_btn_red btn_red" data-toggle="modal" data-target="#long">{{'homePage.IndexPage.btn'
                            | translate}}</a> -->
                    </div>
                    <img src="./assets/images/icn.svg">
                </div>
                <div class="col-lg-6 col-md-12">
                    <!-- Indicators -->

                    <!-- Wrapper for slides -->
                    <div class="hero__title">
                        <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                <!-- <img src="{{ slide.img }}" alt="" width="100%"> -->
                                <div class="">
                                    <span class="hero__title-misc  |  animate">
                                        <div class="fill">
                                            <img class="quote_sign" src="{{ slide.img }}">
                                            <p>{{slide.dis}} <img class="quote_sign2" src="{{ slide.img2 }}"></p>
                                            
                                            <strong>{{slide.title}}</strong>
                                            <p class="spaceSet">{{slide.state}}</p>
                                        </div>
                                    </span>
                                </div>

                            </div>
                        </ngx-slick-carousel>

                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- Refer Code -->
    <!-- <section class="refer_friend">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="code_text">
                        <h2>Refer your Friend</h2>
                        <span><input type="text" name="" value="{{Data?.referralCode}}" placeholder="XXXXXX2573"
                                readonly><img (click)="copy(Data?.referralCode)" class="code_copy handcursul"
                                src="./assets/images/copy.svg">
                            <button (click)="shareOpen(share)" class="m_btn_red btn_red">Share</button>
                           
                        </span>

                        <p>You earn 50 coins & your friend earns 50 coins on successful first subscription.</p>

                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="hen_img">
                        <img src="./assets/images/hen.png">
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Newsletter -->
    <!-- <section class="news_l">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="news_bg">
                        <div class="form_letter">
                            <h2>Newsletter</h2>
                            <p>Let's begin Together with consetetur sadip.</p>
                            <form>
                                <div class="form-group">
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                                    <button class="m_btn_red btn_red">Subscribe Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</main>
<!-- <button (click)="openModule1(Calculator)" data-toggle="tooltip" data-placement="bottom" title="Work in Progress" class="m_btn_red btn_red calc_button">
    Calculator
 </button> -->
<button type="button" class="m_btn_red btn_red calc_button add_pp" [routerLink]="['/submit-requirement']">
    Submit Requirement
</button>
<app-footer></app-footer>
<ng-template #Calculator>

    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modulecss " id="#calculate">
            <div class="form-title text-center modulecss">
                <h2>Calculate</h2>
                <button type="button" class="close crosscss" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="d-flex flex-column text-center modulecss">

                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">Business Type</label>
                                <div class="col-12 change_no">
                                    <span class="slt_wrap">
                                        <select>
                                            <option>Layer Farmer</option>
                                            <option>Layer Farmer</option>
                                            <option>Layer Farmer</option>
                                            <option>Layer Farmer</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">Monthly Production</label>
                                <div class="col-12 change_no">
                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="1000">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">Option 1</label>
                                <div class="col-12 change_no">
                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Eggs">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">Option 2</label>
                                <div class="col-12 change_no">
                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Dummy">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-info btn-block btn-round btn_red">Calculate</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<app-business-modal #business>

</app-business-modal>


<ng-template #loginModal23>
    <div class="log_pop otp_scrn submit_acc add_pop subscription_pay customize_plan_reg">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-column text-center">
                        <div class="cutomize_tbl mr_50">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col"></th>
                                        <th scope="col">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Yearly Registration Fee</th>
                                        <td></td>
                                        <td>1000</td>
                                    </tr>

                                </tbody>
                                <tfoot>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td><span>Total</span><br><strong><i class="fa fa-rupee"></i> 1999</strong></td>
                                </tfoot>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Payment Methods</h5>
                            </div>
                            <div class="col-lg-4 col-md-6 cc_pd">
                                <a (click)="buyingquotesdetailspage1(business)" class="credit_cc"><img class="cc_img" src="./assets/images/cre_c.svg"> Credit Card</a>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <a (click)="buyingquotesdetailspage1(business)" class="credit_cc"><img src="./assets/images/gpay.svg"></a>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <a (click)="buyingquotesdetailspage1(business)" class="credit_cc"><img src="./assets/images/ptm.svg"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #share>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modulecss">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-title text-center">
                    <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                    <h2>Refer A Friend</h2>

                </div>
                <div class="d-flex flex-column text-center">
                    <form>
                        <div class="form-group position_rel row">
                            <div class="col-12 change_no">
                                <input class="form-control" type="" value="{{Phonenum}}" (keyup)="changeredeem1($event)" id="" placeholder="Enter Mobile Number">

                                <div class="text-danger" *ngIf="showError1">
                                    *Please enter valid mobile no.
                                </div>
                            </div>
                        </div>
                        <button type="button" (click)="sendRefer()" class="btn btn-info btn-block btn-round btn_red mr-2" data-toggle="modal" data-target="">Share</button>
                        <button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr " (click)="close()" data-toggle="modal" data-target="">Cancel</button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</ng-template>
<ng-template #Rating>
    <div class="log_pop otp_scrn submit_acc add_pop pop_off_deal reci_ordr">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group position_rel row">
                                <div class="col-6" style="border-right: solid 1px #ddd;">
                                    <div class="have_reci">
                                        <span><img src="./assets/images/egg.png"></span>
                                        <ul>
                                            <li>Seller <br><strong>Hirakani Agro</strong></li>
                                            <li>Selected Sub Category<br><strong> Eggs</strong></li>
                                            <li>Order <br><strong style="color: #AE0019">#61257182</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="txt-have_rc">
                                                <h2>Have you received your order?</h2>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="txt-have_rc">
                                                <label for="category">Please Select Option</label>

                                                <span class="slt_wrap">
                                                    <select>
                                                        <option>Yes</option>
                                                        <option>No</option>

                                                    </select>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="txt-have_rc">
                                                <button type="button" class="btn btn-info btn-block btn-round btn_red btn_rate_nw fs_15px" (click)="Reating(loginModal30,0)" data-toggle="modal" data-target="#loginModal30">Rate Your Order Now</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<ng-template #loginModal30>
    <div class="log_pop otp_scrn submit_acc add_pop reci_ordr rate_order_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" (click)="closeRating()" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <!-- <h2>Please give your feedback about your order</h2> -->
                        <h3>Please give your feedback about your order</h3>

                    </div>
                    <div class="d-flex flex-column text-center">

                        <div class="form-group position_rel row">
                            <div class="col-12">
                                <div class="have_reci">
                                    <!-- <span><img src="./assets/images/egg.png"></span> -->
                                    <ul>
                                        <!-- <h4>Hatched Eggs</h4> -->
                                        <h5>{{ratingBusinessname}} </h5>
                                        <li><strong>Service</strong> <br>
                                            <ul class="rt_str_ul">

                                                <li>
                                                    <rating name="fieldRate" [(ngModel)]="serviceRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><strong>Commitment </strong> <br>
                                            <ul class="rt_str_ul">

                                                <li>
                                                    <rating [(ngModel)]="commitmentRate" name="fieldcmmitmentRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>

                                            </ul>
                                        </li>

                                        <li *ngIf="ratingBuyer == 1"><strong>Quality</strong> <br>
                                            <ul class="rt_str_ul">
                                                <li>
                                                    <rating [(ngModel)]="qualityRate" name="fieldqualityRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>

                                        <li *ngIf="ratingBuyer == 1"><strong>Packaging</strong> <br>
                                            <ul class="rt_str_ul">
                                                <li>
                                                    <rating [(ngModel)]="packagingRate" name="fieldpackagingRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>
                                        <li *ngIf="ratingSeller == 1"><strong>Payment on time</strong> <br>
                                            <ul class="rt_str_ul">
                                                <li>
                                                    <rating [(ngModel)]="paymentRate" name="fieldpaymentRate" [max]="max" [readonly]="isReadonly" (keyup)="confirmSelection($event)"></rating>
                                                </li>
                                            </ul>
                                        </li>
                                        <div class="text-danger" *ngIf="showErrorRating">
                                            *Please Select All Rating.
                                        </div>

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class="form-group position_rel row">
                            <div class="col-12">
                                <textarea class="form-control" [(ngModel)]="description" name="fieldescription" id="exampleFormControlTextarea1" rows="3" placeholder="Additional Comment"></textarea>
                            </div>

                        </div>
                        <div class="form-group position_rel row point_rvw">
                            <div class="col-12">
                                <p><img src="./assets/images/img_coins.svg"> 2 points for rating and 3 points for review
                                </p>
                            </div>

                        </div>
                        <div class="col-12">
                            <div class="">
                                <button type="button" class="btn btn-info btn-block btn-round btn_red" (click)="submitRating()" data-dismiss="modal" aria-label="Close">Submit</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>


</ng-template>