import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { apiUrl } from '../../global/global';
import { NavigationService } from '../header/header.service';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  Phonenum='';
  urlnumber1='';
  allData:any;
  modalRef: BsModalRef;
  showError1:boolean=false;
  loginshow: boolean = false;
  constructor(private router: Router,private NS: NavigationService,private modalService: BsModalService,public admin: JsonPlaceHolderService) { }

  ngOnInit(): void {    
    let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 1];
    this.urlnumber1 = 'index';
    let authGard = localStorage.getItem("adminToken");
    if (authGard) {
      this.loginshow = true;
  
    } else {

    }

  }
  routherPath1(){    
    this.router.navigate(['path']);
  }
  Redirect(){    
    window.scrollTo(0, 0)
  }
  changeredeem1(event){
    this.Phonenum=event.target.value;
  }
  close(){
    
    this.modalRef.hide();
  }
  hasRoute(route: string) {
    return this.router.url.includes(route);
  }


  sendRefer(){  
    if(this.Phonenum != '' && this.Phonenum.length == 10){
      const data = {
        'phoneNumber': '+91' + this.Phonenum,
        // 'country':'+91'
        'refferalCode': this.allData.referralCode
      }
      this.NS.submitForm(data , apiUrl._postshareRefferalCode)
      .subscribe( async (res) => {
        this.modalRef.hide();
        
    this.admin.showAlert('Refer Code Send Successfully', '');
      })
    }else{
      this.showError1=true;
      setTimeout(()=>{
        this.showError1 = false;
       },5000);
    }
  }
  shareOpen(template){  
    this.modalRef = this.modalService.show(template);    
  }

  
}
