<body class="order_flow_page">
    <!-- <app-headerwithoutslick></app-headerwithoutslick> -->
    <app-header></app-header>
    <main class="main_cont">

        <!-- Pending Orders -->
        <section class="pending_orders">
            <div class="sidebar_order">
                <div class="nav-tp">
                    <a (click)="changeState('Seller')" class="{{value=='Seller'?'nav-link active':'nav-link'}}">Seller</a>
                    <a (click)="changeState('Buyer')" class="nav-link">Buyer</a>
                </div>
                <ul class="side_nav">
                    <li *ngIf="value=='Seller'" (click)="changeState1('Pending Deals')"><a class="{{value1 == 'Pending Deals' ? 'active':''}}">Pending Deals <span>5</span></a></li>
                    <li *ngIf="value=='Seller'" (click)="changeState1('Approved Deals')"><a class="{{value1 == 'Approved Deals' ? 'active':''}}">Approved Deals <span>5</span></a></li>
                    <li *ngIf="value=='Seller'" (click)="changeState1('Cancelled Deals')"><a class="{{value1 == 'Cancelled Deals' ? 'active':''}}">Cancelled Deals <span>5</span></a></li>
                    <li *ngIf="value=='Buyer'" (click)="changeState1('Pending Deals')"><a class="{{value1 == 'Pending Deals' ? 'active':''}}">Pending Deals <span>5</span></a></li>
                    <li *ngIf="value=='Buyer'" (click)="changeState1('Approved Deals')"><a class="{{value1 == 'Approved Deals' ? 'active':''}}">Approved Deals <span>5</span></a></li>
                    <li *ngIf="value=='Buyer'" (click)="changeState1('Cancelled Deals')"><a class="{{value1 == 'Cancelled Deals' ? 'active':''}}">Cancelled Deals <span>5</span></a></li>
                </ul>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="value=='Seller' && value1=='Pending Deals'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                                <div class="col-lg-6 col-md-4">
                                    <!-- <a class="m_btn_red btn_red prem_lead"> Premium Leads</a> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status">
                                        <p class="mn_head clr_ylw"><strong>Pending</strong></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                            <ul class="star_rate">
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                            </ul>
                          </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat</a> <a class="m_btn_red btn_red btn_brdr">Decline</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head clr_ylw"><strong>Pending</strong></p>
                                        <table class="table">

                                            <thead class="">

                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                            <ul class="star_rate">
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                            </ul>
                          </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat</a> <a class="m_btn_red btn_red btn_brdr">Decline</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head clr_ylw"><strong>Pending</strong></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                            <ul class="star_rate">
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                              <li><i class="fa fa-star"></i></li>
                            </ul>
                          </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat</a> <a class="m_btn_red btn_red btn_brdr">Decline</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Seller' && value1=='Approved Deals'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                                <div class="col-lg-6 col-md-4">
                                    <!-- <a [routerLink]="['/NewOrdersSeller']" class="m_btn_red btn_red prem_lead">Premium Leads</a> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr">
                                        <p class="mn_head"><strong>Approved</strong> on 21 Sep 2020</p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Approved</strong> on 21 Sep 2020</p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Approved</strong> on 21 Sep 2020</p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Seller' && value1=='Cancelled Deals'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                                <div class="col-lg-6 col-md-4">
                                    <!-- <a class="m_btn_red btn_red prem_lead"> Premium Leads</a> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr cancel_or">
                                        <p class="mn_head"><strong>Cancelled by Seller</strong> on 21 Sep 2020<span class="text_rd">For this order seller rating will imapct on cancelling orders after confirmation</span></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Cancelled by Seller</strong> on 21 Sep 2020<span class="text_rd">For this order seller rating will imapct on cancelling orders after confirmation</span></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Cancelled by Seller</strong> on 21 Sep 2020<span class="text_rd">For this order seller rating will imapct on cancelling orders after confirmation</span></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Buyer' && value1=='Cancelled Deals'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr cancel_or">
                                        <p class="mn_head"><strong>Cancelled by Seller</strong> on 21 Sep 2020<span class="text_rd">For this order seller rating will imapct on cancelling orders after confirmation</span></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Cancelled by Seller</strong> on 21 Sep 2020<span class="text_rd">For this order seller rating will imapct on cancelling orders after confirmation</span></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Cancelled by Seller</strong> on 21 Sep 2020<span class="text_rd">For this order seller rating will imapct on cancelling orders after confirmation</span></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Buyer' && value1=='Pending Deals'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status">
                                        <p class="mn_head clr_ylw"><strong>Pending</strong></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat</a> <a class="m_btn_red btn_red btn_brdr">Decline</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>

                                        </table>
                                        <p class="mn_head clr_ylw"><strong>Pending</strong></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat</a> <a class="m_btn_red btn_red btn_brdr">Decline</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>

                                        </table>
                                        <p class="mn_head clr_ylw"><strong>Pending</strong></p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat</a> <a class="m_btn_red btn_red btn_brdr">Decline</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="value=='Buyer' && value1=='Approved Deals'" class="content_sidebr">
                            <div class="row main_cont_serch">
                                <div class="col-lg-6 col-md-8">
                                    <span class="srch_order"><input type="search" name="" value="" placeholder="Search Order"><a ><i class="fa fa-search"></i></a></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="order_full_status confirm_ordr">
                                        <p class="mn_head"><strong>Approved</strong> on 21 Sep 2020</p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Approved</strong> on 21 Sep 2020</p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <p class="mn_head"><strong>Approved</strong> on 21 Sep 2020</p>
                                        <table class="table">
                                            <thead class="">
                                                <tr>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Deal<br><strong>#0942867</strong></td>
                                                    <td>Category<br><strong>White Layer Egg</strong></td>
                                                    <td>Quantity<br><strong>200 Eggs</strong></td>
                                                    <td>Unit Price<br><strong><i class="fa fa-rupee"></i> 4.00</strong></td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Seller Details<br><strong>Best Quality Poultry Eggs</strong></td>
                                                    <td>Seller Address<br><strong>Sector 21 C, Chandigarh</strong></td>
                                                    <td>Seller Rating<br>
                                                        <strong>
                                          <ul class="star_rate">
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                          </ul>
                                        </strong></td>
                                                    <td><a class="m_btn_red btn_red btncss">Chat with Seller</a></td>
                                                    <td><span class="dots_opt"><a ><img src="./assets/images/dots.svg"></a></span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
</body>