import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OwlModule } from 'ngx-owl-carousel';
import {NgxMaskModule} from 'ngx-mask'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareButtonsModule  } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import {AsyncPipe, CommonModule} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorService } from './services/InterceptorService';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './Common/header/header.component';
import { IndexPageComponent } from './external/index-page/index-page.component';
import { FooterComponent } from './Common/footer/footer.component';
import { PlanComponent } from './external/plan/plan.component';
import { AgmCoreModule } from '@agm/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HomePageComponent } from './internal/home-page/home-page.component';
import { BuyerProductDetailComponent } from './internal/buyer-product-detail/buyer-product-detail.component';
import { BuyerlistingComponent } from './internal/buyerlisting/buyerlisting.component';
import { TrendslistingComponent } from './internal/trendslisting/trendslisting.component';
import { Trendslisting2Component } from './internal/trendslisting2/trendslisting2.component';
import { Trendslisting3Component } from './internal/trendslisting3/trendslisting3.component';

import { BuyerComponent } from './internal/buyer/buyer.component';
import { DatePipe } from '@angular/common';
import { BuyerProductDetail3Component } from './internal/buyer-product-detail3/buyer-product-detail3.component';
import { Buyingquotesdetailspage1Component } from './internal/buyingquotesdetailspage1/buyingquotesdetailspage1.component';

import { Sellernewproductstep2Component } from './internal/sellernewproductstep2/sellernewproductstep2.component';
import { Sellerprofilenewregistration5Component } from './internal/sellerprofilenewregistration5/sellerprofilenewregistration5.component';
import { Sellerprofilenewregistration3Component } from './internal/sellerprofilenewregistration3/sellerprofilenewregistration3.component';
import { BuyerViewSellerProfileRatingComponent } from './internal/buyer-view-seller-profile-rating/buyer-view-seller-profile-rating.component';
import { PendingOrdersSellerComponent } from './internal/pending-orders-seller/pending-orders-seller.component';
import { NewOrdersSellerComponent } from './internal/new-orders-seller/new-orders-seller.component';
import { LoginModalComponent } from './Common/login-modal/login-modal.component';
import { ChatComponent } from './internal/chat/chat.component';
import { BusinessModalComponent } from './Common/business-modal/business-modal.component';
import { PaginationModule } from 'ngx-bootstrap/pagination'; 

////////////////////////////////////////////
import {FcmserviceService} from './services/fcmservice.service';
import{AngularFireMessagingModule}from'@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import{environment} from './../environments/firebase-environment';
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ServiceWorkerModule } from '@angular/service-worker';

import{DateAgoPipe} from './pipe/dateago.pipe'
import { CountdownModule } from 'ngx-countdown';
////////////////////////////////////////////
import { LoaderComponent } from './Common/loader/loader.component';
import { StarRatingModule } from 'angular-star-rating';
import { NgxSpinnerModule } from "ngx-spinner";
import { DataSharingService } from './services/data.share.service';
import { LightboxModule } from 'ngx-lightbox';
import { NotificationComponent } from './internal/notification/notification.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { PageNotfoundComponent } from './Common/page-notfound/page-notfound.component';
import { LangTranslateModule } from './appLanguage/lang-translate.module';
import { PriceComponent } from './external/price/price.component';
import { PriceInfoComponent } from './external/price/priceInfo';
import { ProductPriceComponent } from './external/price/product-price/product-price.component';
import { MandiComponent } from './external/price/mandi/mandi.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SearchComponent } from './internal/search/search.component';
import { RateComponent } from './external/rate/rate.component';
import { PrivacyPolicyComponent } from './external/privacy-policy/privacy-policy.component';
import { CharLimitPipe } from "./pipe/char-limit.pipe";
import { TermsConditionsComponent } from './external/terms-conditions/terms-conditions.component'
import { SubmitRequirementComponent } from './internal/submit-requirement/submit-requirement.component';
import { AboutComponent } from './external/about/about.component';
import { LeadDetailComponent } from './internal/lead-detail/lead-detail.component';
import { PartnerComponent } from './external/partner/partner.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DisclaimerComponent } from './external/disclaimer/disclaimer.component';
import { PrvurlService } from './services/prvurl.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SellerprofilenewregistrationComponent } from './internal/sellerprofilenewregistration/sellerprofilenewregistration.component';
import { MyprofileComponent } from './internal/myprofile/myprofile.component';
import { DealdetailsComponent } from './internal/pending-orders-seller/dealdetails/dealdetails.component';
import { DealInfoComponent } from './internal/pending-orders-seller/dealInfo';
import { MyRatesComponent } from './external/my-rates/my-rates.component';
@NgModule({
 
  declarations: [
    SellerprofilenewregistrationComponent,
    MyprofileComponent,
    DealdetailsComponent,
    DealInfoComponent,
    AppComponent,
    LoaderComponent,
    HeaderComponent,
    IndexPageComponent,
    FooterComponent,
    PlanComponent,
    HomePageComponent,
    BuyerProductDetailComponent,
    BuyerlistingComponent,
    TrendslistingComponent,
    Trendslisting2Component,
    Trendslisting3Component,
    BuyerComponent,
    PriceInfoComponent,
    BuyerProductDetail3Component,
    Buyingquotesdetailspage1Component,
  
    Sellernewproductstep2Component,
    Sellerprofilenewregistration5Component,
    Sellerprofilenewregistration3Component,
    BuyerViewSellerProfileRatingComponent,
    PendingOrdersSellerComponent,
    NewOrdersSellerComponent,
    // SellerchatEggComponent,
    LoginModalComponent,
    BusinessModalComponent,
    ChatComponent,
    NotificationComponent,
    DateAgoPipe,
    PageNotfoundComponent,
    PriceComponent,
    ProductPriceComponent,
    MandiComponent,
    SearchComponent,
    RateComponent,
    MyRatesComponent,
    PrivacyPolicyComponent,
    CharLimitPipe,
    TermsConditionsComponent,
    SubmitRequirementComponent,
    AboutComponent,
    LeadDetailComponent,
    PartnerComponent,
    DisclaimerComponent  
  ],
  imports: [
    ImageCropperModule,
    NgxSpinnerModule,
    ServiceWorkerModule,
    ModalModule.forRoot(),
    // CarouselModule,
    CountdownModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    DragDropModule,
    FormsModule,
    PaginationModule,
    StarRatingModule,
    ShareButtonsModule,
    ShareIconsModule,
    BsDropdownModule.forRoot(),
    LangTranslateModule,
    NgxIntlTelInputModule,
    NgxMaskModule.forRoot({
      showMaskTyped : true,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCtOnbQCsWkqBiVL_6Rk8d25TfhPPt-pBk',
      libraries: ["places"],
      apiVersion: 'weekly' 
      
      // apiVersion: 'quarterly'
    }),
    // FirebaseModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    // TooltipModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    OwlModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    LightboxModule,
    // SlickModule.forRoot()
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    RatingModule.forRoot(),
  ],
  providers: [FcmserviceService, AsyncPipe,DatePipe,
    LoginModalComponent,
    BusinessModalComponent,
    PrvurlService,
    // FirebaseProvider,
    {
   provide: HTTP_INTERCEPTORS,
   useClass: InterceptorService,   
   multi: true
 },DataSharingService,
 
],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
