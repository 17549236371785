import { TemplateRef, Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { NavigationService } from '../../Common/header/header.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { BusinessModalComponent } from './../../Common/business-modal/business-modal.component';


@Component({
  selector: 'app-buyer-product-detail',
  templateUrl: './buyer-product-detail.component.html',
  styleUrls: ['./buyer-product-detail.component.css']
})

export class BuyerProductDetailComponent implements OnInit {
  modalRef: BsModalRef;
  value = 1;
  search = '';
  id = '';
  callUs:any;
  BussinessVerified:any;
  NearData: any;
  allData: any;
  indexvalue: any = 0;
  samesellerlist: any;
  backUrl: any;
  canGoBack: any;
  /////////////////////
  currentUserId: any;
  chatDialog: any;
  participantId: any;
  userName: any;
  chatDiologId: any;
  messageList: any;
  userData: any;
  userDataS: any;
  dataArr: any;
  s3url: any;
  userImage: any;
  showChat = 0;
  mySlideImages1: any = [{ image: './assets/images/sld1.png' }];
  catigory = '';
  showImageArray: any = [];
  mySlideImagess: any;
  selectedVideoImage: any;
  step = 0;
  reviewShort: any;
  loopCheck: boolean = false;
  centerCheck: boolean = false;
  previousUrl: string;
  // mySlideImages:any;
  mySlideOptions = {
    margin: 10,

    singleItem: true,
    dots: true, nav: true,

    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    },
  };
  myCarouselOptions = {
    items: 1, margin: 10,
    loop: true, dots: false, nav: true
  };
  // mySlideImages = [1,2,3].map((i)=> `./assets/images/sld1.png`);
  // myCarouselImages =[1,2,3,4,5,6].map((i)=>`./assets/images/sld1.png`);
  mySlideImages = [

  ];
  slideConfig = {
    'slidesToShow': 3,
    'slidesToScroll': 1,
    'arrows': true,
    'swipeToSlide': true,
    'infinite': false,
    'responsive': [
      {
        'breakpoint': 0,
        'slidesToShow': 2
      },
      {
        'breakpoint': 767,
        'slidesToShow': 3
      },
      {
        'breakpoint': 1200,
        'slidesToShow': 4
      }
    ]
  };

  constructor(private _location: Location, private modalService: BsModalService, private NS: NavigationService, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private storage: AngularFireStorage,
    private route: ActivatedRoute,
    private router: Router,) {
  }


  ngOnInit(): void {
    this.BussinessVerified = localStorage.getItem('BussinessVerified');
    this.s3url = localStorage.getItem('s3BaseUrl');
    // this.backUrlHistory= this._location.back();
    var search1 = localStorage.getItem('search');
    if (search1 != null && search1 != '') {
      this.search = search1;
    }

    this.catigory = localStorage.getItem('buyerCategory');
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCategories();
    this.getproductDetail();
    this.currentUserId = localStorage.getItem('id');
    this.userName = localStorage.getItem('name');
    this.userImage = localStorage.getItem('image');
  }
  backClicked() {
    let url = localStorage.getItem('previousUrl');
    if (url == 'undefined' || !url) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/Buyer']);
    }
  }

  openModule(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  checkCall(business: BusinessModalComponent){
    this.BussinessVerified = localStorage.getItem('BussinessVerified');
    if (this.BussinessVerified == false || this.BussinessVerified == 'false') {
      business.openBusinessModel();
    } else {
      this.callUs='tel:'+this.NearData?.sellerDetail?.phoneNumber
    }
  }

  createChatDialog(id, business: BusinessModalComponent) {  
    this.BussinessVerified = localStorage.getItem('BussinessVerified');
    if (this.BussinessVerified == false || this.BussinessVerified == 'false') {
      business.openBusinessModel();
    } else {
      const data = {
        id: id,
      }
      let url = apiUrl._getProductDetails;
      this.NS.getList(data, url).subscribe(async (res) => {
        var dataArr = res.data[0];
        // console.log( res.data[0],' res.data[0]')
        // console.log(dataArr,'dataArr')
        if (dataArr) {
          if ((res.data.userId) > (this.currentUserId)) { 
            var chatDiologId = this.currentUserId + ',' + dataArr.userId + '-' + dataArr._id;
            var participantId = this.currentUserId + ',' + dataArr.userId;
          } else {
            var chatDiologId = dataArr.userId + ',' + this.currentUserId + '-' + dataArr._id;
            var participantId = dataArr.userId + ',' + this.currentUserId;
          }
          var profileArr = {};
          profileArr[dataArr.userId] = dataArr.sellerDetail.profilePic;
          profileArr[this.currentUserId] = this.userImage;

          var userNameArr = {};
          userNameArr[dataArr.userId] = dataArr.sellerDetail.name;
          userNameArr[this.currentUserId] = this.userName;

          var messageTimeArr = {};
          messageTimeArr[dataArr.userId] = new Date().getTime();
          messageTimeArr[this.currentUserId] = new Date().getTime();

          var messageTypeArr = {};
          messageTypeArr[dataArr.userId] = 1;
          messageTypeArr[this.currentUserId] = 1;

          var lastMessageArr = {};
          lastMessageArr[dataArr.userId] = "";
          lastMessageArr[this.currentUserId] = "";
          var deleteTime = {};
          deleteTime[dataArr.userId] = 0;
          deleteTime[this.currentUserId] = 0;

          var blockStatus = {};
          blockStatus[dataArr.userId] = 0;
          blockStatus[this.currentUserId] = 0;

          var unreadMessageArr = {};
          unreadMessageArr[dataArr.userId] = 0;
          unreadMessageArr[this.currentUserId] = 0;

          this.db.list(`Chats`,
            query => query
              .orderByChild("chat_dialog_id")
              .equalTo(chatDiologId)
              .limitToLast(100)
          ).valueChanges().subscribe(list => {
            this.messageList = list ? list : [];
            console.log("list.length", list.length);
            /////////////////////////////////////
            var tab = 1;
            if (list.length == 0) {
              var chatDialog = this.db.object(`Chats/${chatDiologId}`).set({
                "chat_dialog_id": chatDiologId.toString().trim(),
                "buyer_id": this.currentUserId.toString().trim(),
                "dialog_status": 0,
                "seller_id": dataArr.userId.toString().trim(),
                "product_id": dataArr._id.toString().trim(),
                "product_price": dataArr.price,
                "product_image": dataArr.images ? dataArr.images[0].image : "",
                "product_dec": dataArr.description.toString().trim(),
                "product_title": dataArr.description.toString().trim(),
                "participant_ids": participantId,
                "profile_pic": profileArr,
                "name": userNameArr,
                "last_message_time": messageTimeArr,
                "last_message_type": messageTypeArr,
                "last_message_sender_id": "",
                "last_message": lastMessageArr,
                "last_message_id": "",
                "delete_dialog_time": deleteTime,
                "block_status": blockStatus,
                "sub_category_id": dataArr.subCategoryId,
                "category_id": dataArr.categoryId,
                "premium_user": 0,
                "payment_request_time": 0,
                "unread_count": unreadMessageArr,
              });
              /////////////////////////////////
              var buyer_id_user = this.currentUserId.toString().trim();
              var seller_id_user = dataArr.userId.toString().trim();
              console.log('buyer_id_user:::::::',buyer_id_user);
              console.log('seller_id_user:::::::',seller_id_user);
              this.db.list(`Users`,query => query.orderByChild("user_id").equalTo(buyer_id_user)
              ).valueChanges().subscribe(userList => {
                this.userData = userList ? userList[0] : [];
                console.log("list.length", list.length);
                console.log("userList", this.userData);
                /////////////////////////////////////

                var chatDiologIdArr = this.userData? this.userData.chat_dialog_ids : {};
                chatDiologIdArr[chatDiologId] = chatDiologId;
                // buyer 
                this.db.object(`Users/${this.currentUserId}`).update({ "chat_dialog_ids": chatDiologIdArr });
                //seller
                this.db.list(`Users`,query => query.orderByChild("user_id").equalTo(seller_id_user)
                ).valueChanges().subscribe(userListS => {
                  this.userDataS = userListS ? userListS[0] : [];
                  console.log("userList", this.userData);
                  /////////////////////////////////////
                  
                  var chatDiologIdArr1 = this.userDataS? this.userDataS.chat_dialog_ids : {};
                  chatDiologIdArr1[chatDiologId] = chatDiologId;
                  // console.log('chatDiologIdArr',chatDiologIdArr1);
                  // buyer 
                  this.db.object(`Users/${dataArr.userId}`).update({ "chat_dialog_ids": chatDiologIdArr1 });
                  
                  //////////////////////////////////
                  this.router.navigateByUrl('chat/' + this.currentUserId + "/" + dataArr.userId + "/" + dataArr._id + "/" + tab);
                });
              });
            //////////////////////////////////////////////////
            } else {
              /////////////////////////////////
              var buyer_id_user = this.currentUserId.toString().trim();
              var seller_id_user = dataArr.userId.toString().trim();
              console.log('buyer_id_user:::::::',buyer_id_user);
              console.log('seller_id_user:::::::',seller_id_user);
              this.db.list(`Users`,query => query.orderByChild("user_id").equalTo(buyer_id_user)
              ).valueChanges().subscribe(userList => {
                this.userData = userList ? userList[0] : [];
                console.log("list.length", list.length);
                console.log("userList", this.userData);
                /////////////////////////////////////
 
                var chatDiologIdArr = this.userData? this.userData.chat_dialog_ids ? this.userData.chat_dialog_ids : {} : {};
              
                console.log('chatDiologIdArr',chatDiologIdArr);             
                chatDiologIdArr[chatDiologId] = chatDiologId; 
                // buyer 
                this.db.object(`Users/${this.currentUserId}`).update({ "chat_dialog_ids": chatDiologIdArr });
                //seller
                this.db.list(`Users`,query => query.orderByChild("user_id").equalTo(seller_id_user)
                ).valueChanges().subscribe(userListS => {
                  this.userDataS = userListS ? userListS[0] : [];
                  console.log("userList", this.userData);
                  /////////////////////////////////////

                  var chatDiologIdArr1 = this.userDataS? this.userDataS.chat_dialog_ids ? this.userDataS.chat_dialog_ids : {} : {};
                  chatDiologIdArr1[chatDiologId] = chatDiologId;  
                  // buyer 
                  this.db.object(`Users/${dataArr.userId}`).update({ "chat_dialog_ids": chatDiologIdArr1 });
                  
                  //////////////////////////////////
                  
              console.log('buyer_id_user:::::::',this.currentUserId);
              console.log('seller_id_user:::::::',dataArr.userId);
                  this.router.navigateByUrl('chat/' + this.currentUserId + "/" + dataArr.userId + "/" + dataArr._id + "/" + tab);
                  console.log('chat/' +  dataArr.userId + "/" + this.currentUserId + "/" +dataArr._id + "/" + tab,'chat redirect')
                });
              });
            //////////////////////////////////////////////////
            }
          });
        }
      }, err => {
        console.log(err);
      });
    }

  }

  getCategories() {
    let params = new HttpParams();
    // params = params.append('id', this.userId);
    this.NS.getList(params, apiUrl._getCategories)
      .subscribe(res => {
        this.allData = res.data;
        
      })
  }
  getproductDetail() {
    let params = new HttpParams();
    params = params.append('id', this.id);
    if (this.reviewShort) {
      params = params.append('sortBy', this.reviewShort);
    }
    this.NS.getList(params, apiUrl._getProductDetails)
      .subscribe(async (res) => {
        this.NearData = res.data[0];
        console.log(this.NearData,'    this.getproductDetail();')
        this.getsimilarProduct();

        for (var i = 0; i < this.NearData.images.length; i++) {
          let fileType;

          if (this.NearData.images[i].image) {
            fileType = this.NearData.images[i].image.split(".")[1];
          } else {
            fileType = this.NearData.images[i].video.split(".")[1];
          }

          let isVideo = true;
          if (this.fileTypeChecker(fileType)) {
            isVideo = false
          }
          let file = {
            fileType: isVideo,
            file: this.NearData.images[i].image ? this.NearData.images[i].image : this.NearData.images[i].video
          }
          if (this.showImageArray.lenght > 0) {
            this.loopCheck = true;
            this.centerCheck = false;
          } else {
            this.loopCheck = false;
            this.centerCheck = true;
          }
          this.showImageArray.push(file);
        }

        this.selectedVideoImage = this.showImageArray[0];
        if (this.catigory == 'null' || this.catigory == null) {
          this.catigory = res.data[0].categoryId
        }


        if (this.NearData.sellerDetail._id != this.currentUserId) {
          this.showChat = 1;
        }
        let userid = localStorage.getItem('id');
        this.getindex(this.catigory, userid);
      })
  }
  fileTypeChecker(fileType) {
    let valid = [
      "pdf", "tif", "tiff", "jpg", "jpeg", "gif", "png"
    ];

    if (valid.includes(fileType.toLowerCase())) {
      return true
    }
    return false;
  }

  selectImageVideo(i, fileTypes) {
    this.selectedVideoImage = fileTypes;
  }



  chat() {
    this.router.navigate(['Chat']);
  }
  index(i) {
    this.indexvalue = i;
  }
  getindex(id, userid) {
    let params = new HttpParams();
    params = params.append('userId', userid);
    params = params.append('categoryId', this.catigory);
    this.NS.submitForm(params, apiUrl._postproductList)
      .subscribe(async (res) => {
        this.samesellerlist = [];
        for (let i = 0; i < res.data.length; i++) {
          let dat = res.data[i];
          let loopData = res.data[i].productList;
          for (let j = 0; j < dat.productList.length; j++) {
            loopData[j].rating = 0;

            for (let k = 0; k < loopData[j].feedbackData.length; k++) {
              loopData[j].rating = loopData[j].rating + loopData[j].feedbackData[k].productRating
            }
            if (loopData[j].feedbackMessageData.length == 0) {

              loopData[j].rating = 0;
            } else {
              loopData[j].rating = loopData[j].rating / loopData[j].feedbackData.length;
            }

            // this.mySlideImages.push(dat.productList[j])

          }
        }


      })
  }
  riderRation(rating) {
    let riderRating = rating ? (rating) : '0';
    let styles = {
      'width': ((riderRating * 2) * 10) + '%'
    };
    return styles;
  }



  buyingquotesdetailspage1() {
    this.modalRef.hide();
    this.router.navigate(['buyingquotesdetailspage1']);
  }
  open(template) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template);
  }
  slickInit(e) {
  
  }
  addvalue() {
    this.value = this.value + 1;
 
  }
  subvalue() {
    if (this.value != 1) {
      this.value = this.value - 1;
    }   
  }
  ngAfterViewInit() {
    this.iniSlickJs();
  }
  private iniSlickJs() {
    const htmlScriptElement = document.createElement('script');
    htmlScriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js';

  }
  breakpoint(e) {
    
  }
  cancel() {
    this.modalRef.hide();
  }

  afterChange(e) { 
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  viewMore() {
    this.router.navigate(['Buyerlisting/ProductDetail/' + this.id + '/BuyerViewSeller']);
  }

  getsimilarProduct() {  
    let data = {
      'userId': localStorage.getItem('id'),
      'productId': this.id,
      'categoryId': this.NearData.categoryId,
      'subCategoryId': this.NearData.subCategoryId,
      'latitude': localStorage.getItem('latitude'),
      'longitude': localStorage.getItem('longitude'),
    }
    this.NS.getList(data, apiUrl._getsimilarProduct)
      .subscribe(async (res) => {
             this.mySlideImages = res.data;
      })
  }

  sortby(e) {
    this.reviewShort = e.target.value
    this.getproductDetail()

  }
}



