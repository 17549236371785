<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view profile_view2 seller_pro_n_reg">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <a [routerLink]="['/seller']" class="go_back"><img src="./assets/images/back.svg"> Back </a>

                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="right_profile_detail seller_new_register">
                        <ul class="chse_pln">
                            <!-- <li><a (click)="select('Free')"
                                    class="{{selectFree == 'Free' ?'m_btn_red btn_red btn_yellow':'m_btn_red btn_red btn_yellow btn_brdr'}}">Free</a>
                            </li> -->
                            <!-- <li><a (click)="select('Paid')" class="{{selectFree == 'Paid' ?'m_btn_red btn_red btn_yellow':'m_btn_red btn_red btn_yellow btn_brdr'}}">Paid</a></li> -->
                        </ul>
                        <h4>{{'homePage.buyer.chooseService' | translate}}</h4>

                        <div class="row slide_category">
                            <div class="col-md-12">

                                <ul class="owl-carousel displaycss owl-theme category_pro">
                                    <owl-carousel [options]="mySlideOptions" [items]="mySlideImages" [carouselClasses]="['owl-theme', 'sliding']">
                                        <div class="item" *ngFor="let slide of mySlideImages;let i = index">
                                            <li (click)="changeState(i,slide.id)" class="item current">
                                                <!-- <button *ngIf="slide.plan !=''" class="m_btn_red btn_red btn_grad" disabled>{{slide.plan}}</button> -->
                                                <a class="{{slide.plan != '' ? 'active1' : ''}}"><span><img
                                                            class="image_80"
                                                            src="{{slide.image}}"></span><br>{{slide.item}}<i
                                                        *ngIf="slide.plan !=''" class="fa fa-check"></i></a>
                                            </li>
                                        </div>
                                    </owl-carousel>
                                </ul>
                                <div class="slt_qant_prc" style="padding: 0px;">

                                    <a (click)="goToAddProduct()" class="m_btn_red btn_red">{{'header.popup.continue' | translate}}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>