import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JsonPlaceHolderService } from './json-place-holder.service';

@Injectable({
  providedIn: 'root'
})

export class InterceptorService  implements HttpInterceptor {

  constructor(private admin:JsonPlaceHolderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
  {
    // Clone the request to add the new header.
    let token = localStorage.getItem("adminToken");
    let googelMap = localStorage.getItem("mapToken") ? (localStorage.getItem("mapToken")) : '';
    if(token){
     // req = req.clone({ headers: req.headers.set("Authorization", 'Bearer ' + token.trim())});
      if(googelMap){
       // req = req.clone({ headers: req.headers.set("", "")});
      }else{
        req = req.clone({ headers: req.headers.set("Authorization", 'Bearer ' + token.trim())});
      }
      
    }

    if(this.admin.showLoader)
    this.admin.loader(true);
    else
    this.admin.showLoader = true;

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
      // console.log(event,"----------");
      if (event instanceof HttpResponse) {
       this.admin.loader(false);
      }
    },
    (err: any) => {
     
      this.admin.loader(false);

      if (err instanceof HttpErrorResponse) {
        console.log("_____________________ERROR ____________________________");
        // console.log(err);
        if(err.status == 401 || err.error.statusCode==401) {
        
          if(localStorage.getItem('adminToken')){
            this.admin.errorAlert(err.error.message,true);
            localStorage.removeItem('adminToken');

          }
    
        } else if(err.error.statusCode==403) {

        this.admin.errorAlert(err.error.message,true);
        }
        else {
            console.log("err");
        this.admin.errorAlert(err.error.message,false);
        }
      }
  }));
}
}
