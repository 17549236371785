<app-header></app-header>
<main class="main_cont">

    <!-- fast & easy -->
    <section class="fast_easy pdb_0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="plan_start_bx">
                        <h2>Fast And <br>Easy Start</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.</p>

                        <a class="m_btn_red btn_red">Let's Start</a>

                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="img_plan">
                        <img src="./assets/images/plan1.svg">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- market_trend -->
    <section class="fast_easy market_trend pdb_0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="img_plan">
                        <img src="./assets/images/plan2.svg">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="plan_start_bx">
                        <h2>Access To <br>market Trends</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.</p>

                        <a class="m_btn_red btn_red">Buy Subscription</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- post_service -->
    <section class="fast_easy post_service">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="plan_start_bx">
                        <h2>post Your<br>Services</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.</p>

                        <a class="m_btn_red btn_red">How it works</a>

                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="img_plan">
                        <img src="./assets/images/plan3.svg">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Newsletter -->
    <!-- <section class="news_l">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="news_bg">
                        <div class="form_letter">
                            <h2>Newsletter</h2>
                            <p>Let's begin Together with consetetur sadip.</p>
                            <form>
                                <div class="form-group">
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                                    <button class="m_btn_red btn_red">Subscribe Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</main>
<app-footer></app-footer>