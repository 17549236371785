<app-header></app-header>
<main class="main_cont">

    <!-- Trend & Pricing -->
    <section class="trend_pricing pdb_0">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="product_slt">
                        <h2>Trend & Pricing</h2>
                        <div class="form_cont">
                            <label>Select Product</label>
                            <span class="slt_wrap">
                <select>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                </select>
              </span>
                        </div>
                        <a class="m_btn_red btn_red btn_gry">Show Results</a>

                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="pricing_tbl">
                        <p class="result_base">Result based on your search</p>
                        <table class="table">
                            <thead class="thead-red">
                                <tr>
                                    <th scope="col">Mandi</th>
                                    <th scope="col">Today Price</th>
                                    <th scope="col">Weekly Price</th>
                                    <th scope="col">Monthly Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    <td><i class="fa fa-rupee"></i> 1421.60</td>
                                </tr>
                            </tbody>
                        </table>
                        <a [routerLink]="['/trendslisting']" class="m_btn_red btn_red"><img src="./assets/images/price-d.svg"> View Detailed pricing</a>

                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Category Section -->
    <section class="look_category pdb_0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="title_text">
                        <h2>What you are looking for</h2>
                        <a [routerLink]="['/Buyer']">View All Categories</a>
                    </div>
                    <div class="looking_for_cate">
                        <ul class="category_pro">
                            <li><a><span><img src="./assets/images/cat1.svg"></span><br>Egg</a></li>
                            <li><a><span><img src="./assets/images/cat2.svg"></span><br>Chicken</a></li>
                            <li><a><span><img src="./assets/images/cat3.svg"></span><br>Birds</a></li>
                            <li><a><span><img src="./assets/images/cat4.svg"></span><br>packing</a></li>
                            <li><a><span><img src="./assets/images/cat5.svg"></span><br>Medicine</a></li>
                            <li><a><span><img src="./assets/images/cat6.svg"></span><br>poultry Feed</a></li>
                            <li><a><span><img src="./assets/images/cat7.svg"></span><br>Chicks</a></li>
                            <li><a><span><img src="./assets/images/cat8.svg"></span><br>Cold Store</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- near by egg seller-->
    <section class="near_seller pdb_0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="title_text">
                        <h2>Near by egg Sellers</h2>
                        <a [routerLink]="['/Buyerlisting']">View All</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <a [routerLink]="['/BuyerProductDetail']" class="sell_near">
                        <h5>Hirakani Agro <img class="img_vr" src="./assets/images/checked.svg "></h5>
                        <div class="approved_s ">
                            <span class="verify "><i class="fa fa-check "></i> Verified</span>
                            <span><i class="fa fa-star "></i> 4.8</span>
                        </div>

                        <ul>
                            <h6>Hached Eggs</h6>
                            <li>Brown <i class="fa fa-circle "></i></li>
                            <li>Desi <i class="fa fa-circle "></i></li>
                            <li>Golden</li>
                        </ul>
                        <div class="commnt_s ">
                            <span><i class=" "><img src="./assets/images/star.svg"></i> 4.8</span>
                            <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <!-- Refer Code -->
    <section class="refer_friend">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="code_text">
                        <h2>Refer your Friend</h2>
                        <span><input type="text" name="" value="" placeholder="XXXXXX2573"><img class="code_copy" src="./assets/images/copy.svg"><button class="m_btn_red btn_red">Share</button></span>
                        <p>You earn 100 coins & your friend earns 50 coins on successful first subscription.</p>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="hen_img">
                        <img src="./assets/images/hen.png ">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Newsletter -->
    <!-- <section class="news_l ">
        <div class="container ">
            <div class="row ">
                <div class="col-lg-12 col-md-12 ">
                    <div class="news_bg ">
                        <div class="form_letter ">
                            <h2>Newsletter</h2>
                            <p>Let's begin Together with consetetur sadip.</p>
                            <form>
                                <div class="form-group ">
                                    <input type="email " class="form-control " id="exampleInputEmail1 " aria-describedby="emailHelp " placeholder="Enter email ">
                                    <button class="m_btn_red btn_red ">Subscribe Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</main>
<app-footer></app-footer>