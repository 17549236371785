import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-orders-seller',
  templateUrl: './new-orders-seller.component.html',
  styleUrls: ['./new-orders-seller.component.css']
})
export class NewOrdersSellerComponent implements OnInit {
  value='Seller';
  value1='Pending Deals';
  constructor() { }

  ngOnInit(): void {
  }
  changeState(state){
    this.value=state;
    this.value1='Pending Deals';
      }
      changeState1(state){
        this.value1=state;
      }

}
