import { Component, OnInit } from '@angular/core';
import { InternalService } from '../internal.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-sellerprofilenewregistration',
  templateUrl: './sellerprofilenewregistration.component.html',
  styleUrls: ['./sellerprofilenewregistration.component.css']
})

export class SellerprofilenewregistrationComponent implements OnInit {
  
  selectFree:any='Free';
  allData: any;
  s3url:any;
  categoryId : string;
  // mySlideImages : any;
  mySlideOptions={
    items: 6, 
    margin:10,
    loop: false, 
    dots: false, 
    nav: true,
    responsive: {
      0:{
        items:1
    },
      400:{
        items:2
    },
    600:{
      items:3
  },
    750:{
      items:4
  },
    1000:{
        items:5
    },
    1200:{
        items:6
    }
    },
  };

  myCarouselOptions = {
    items: 2, 
    margin:10,
    loop: false, dots: false, nav: true
  };

  // myCarouselImages =[1,2,3,4,5,6].map((i)=>`./assets/images/sld1.png`);

  mySlideImages = [];
  check1500:any;
  check1000:any;
  check700:any;
  check500:any;
  
  constructor(
    private IS: InternalService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.s3url= localStorage.getItem('s3BaseUrl');
    this.getCategories();
  }

  onlyOneValue(e){    
    if (e.target.id == "check3") {
        this.check1500= true;
        this.check1000 = false;
        this.check500=false;
        this.check700 = false;
    }else  if (e.target.id == "check2") {
        this.check1500= false;
        this.check1000 = true;
        this.check500=false;
        this.check700 = false;
    }else  if (e.target.id == "check1") {
      this.check1500= false;
      this.check1000 = false;
      
      this.check700 = true;
      this.check500=false;
    }else  if (e.target.id == "check4") {
        this.check1500= false;
        this.check1000 = false;
        
        this.check700 = false;
        this.check500=true;
    }
  }

  select(num){
    this.selectFree=num;
  }

  changeState(num, id){
    console.log("change id",id)
    this.categoryId = id;
    for (let i = 0; i < this.mySlideImages.length; i++) {
      this.mySlideImages[i].plan='';
    }
    console.log(this.mySlideImages)
    this.mySlideImages[num].plan='UPGRADE PLAN';
  }

  //////function to get categories////
  getCategories(){
        let params = new HttpParams();
        // params = params.append('id', this.userId);
        this.IS.getList(params, apiUrl._getCategories)
            .subscribe(res => {
              console.log("response",res)
                this.allData = res.data;
                if(this.allData.categoryData.length){
                  // let myArr = [];
                  let plan = '';
                  for (let i=0; i < this.allData.categoryData.length; i++){
                    this.categoryId = this.allData.categoryData[0]._id;
                    if(i==0){
                      plan = 'UPGRADE PLAN'
                    }else{
                      plan = '';
                    }
                    this.mySlideImages.push({'image':this.s3url+'categories/'+this.allData.categoryData[i].image,'item':this.allData.categoryData[i].name,'plan':plan,'id':this.allData.categoryData[i]._id})
                  }
                  // this.mySlideImages = myArr;
                }
                console.log(this.mySlideImages);
            },
                err => {
                  console.log("error",err)
                    // let root = this.router.url.indexOf("/editCustomer/" + this.userId);
                    // this.router.navigate([this.router.url.slice(0, root)]);
                });
  }

  goToAddProduct(){
    this.router.navigateByUrl('seller/category/add-product/'+this.categoryId);
  }
}
