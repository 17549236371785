<body class="order_flow_page step_2_frm_opt">
    <!-- <app-headerwithoutslick></app-headerwithoutslick> -->
    <app-header></app-header>
    <main class="main_cont fullWidth">

        <!-- Pending Orders -->
        <section class="pending_orders confirm_ordr chat_box_page seller_new_pro_dlt s_nw_pro2 pdb_0">
            <div class="sidebar_order">
                <div class="left_profile_detail">
                    <a *ngIf="productId =='' || productId == null" [routerLink]="['/seller/category']" class="go_back"><img src="./assets/images/back.svg"> Back </a>
                    <a *ngIf="productId !='' && productId != null" [routerLink]="['/seller/' + productId]" class="go_back"><img src="./assets/images/back.svg"> Back </a>

                    <div class="sell_near">
                        <div class="water_m">
                            <img src="./assets/images/h1.png">
                        </div>
                        <div class="detail_pro">
                            <h2>List your<br> product to sell</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="content_sidebr">
                            <div class="row">
                                <div class="col-xl-11 col-lg-12 col-md-12">
                                    <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm, planConfrmations, business)">
                                        <div class="right_profile_detail pro_to_sell">
                                            <div class="row">
                                                <div class="col-lg-6 col-12" *ngIf="subCategoryList && subCategoryList.length >1">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Type of
                                                            {{categoryIdname}}</label>
                                                        <span class="{{productId =='' || productId == null?'slt_wrap':''}}">
                                                            <select (change)="subCategoryChange($event)"
                                                                *ngIf="productId =='' || productId == null">
                                                                <option *ngFor="let opt of subCategoryList"
                                                                    [value]="opt.subCategory._id"
                                                                    [selected]="opt.subCategory._id == subCategoryId ? true : null">
                                                                    {{opt.subCategory.name}}
                                                                </option>
                                                            </select>

                                                            <select class="grayback"
                                                                *ngIf="productId !='' && productId != null"
                                                                [disabled]="true">
                                                                <option *ngFor="let opt of subCategoryList"
                                                                    [value]="opt.subCategory._id"
                                                                    [selected]="opt.subCategory._id == subCategoryId ? true : null">
                                                                    {{opt.subCategory.name}}
                                                                </option>
                                                            </select>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12" *ngIf="subCategoryName == 'Other'">
                                                    <div class="form_cont">
                                                        <label class="control-label">Type of
                                                            {{categoryIdname}}(Other)</label>
                                                        <input type="text" *ngIf="productId =='' || productId == null" formControlName="otherSubcategory" placeholder="">
                                                        <div class="text-danger" *ngIf="subCategoryNameError">
                                                            *Required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12 " *ngFor="let opt of attributesList1;let i = index">
                                                    <div class="form_cont motherBreedNamewrap{{i}}">
                                                        <label for="cc-number" class="control-label">{{opt.name}}</label>
                                                        <div *ngIf="productId =='' || productId == null">
                                                            <span class="slt_wrap" *ngIf="opt.fieldType == 0">
                                                                <select (change)="traitChange($event,i)"
                                                                    *ngIf="opt.fieldType == 0">
                                                                    <option value="" selected disabled>Select</option>
                                                                    <option *ngFor="let traits of opt.traitsData"
                                                                        [value]="traits._id"
                                                                       >
                                                                        {{traits.traitName}}</option>
                                                                </select>
                                                            </span>
                                                            <input class="motherBreedName" *ngIf="showMotherBreedName" (keyup)="valueset1(i,$event)" type="text">
                                                            <input *ngIf="opt.fieldType == 1 && opt.name !='Date' && opt.name !='Delivery Date' && opt.name !='Loading Date'  && opt.name !='Available Date'" (keyup)="valueset(i,$event)" type="text">


                                                            <input type="text" *ngIf="opt.fieldType == 1 && opt.name=='Date'" placeholder="" class="datepicker" bsDatepicker [minDate]="todayDate" (bsValueChange)="dateChange(i, $event)" [bsConfig]="{dateInputFormat: 'DD.MM.YYYY','customTodayClass': 'today', showWeekNumbers: false }"
                                                                readonly>
                                                            <input type="text" *ngIf="opt.fieldType == 1 && opt.name=='Loading Date'" placeholder="" class="datepicker" bsDatepicker [minDate]="todayDate" (bsValueChange)="dateChange(i, $event)" [bsConfig]="{dateInputFormat: 'DD.MM.YYYY','customTodayClass': 'today', showWeekNumbers: false }"
                                                                readonly>
                                                            <input type="text" *ngIf="opt.fieldType == 1 && opt.name=='Delivery Date'" placeholder="" class="datepicker" bsDatepicker [minDate]="todayDate" (bsValueChange)="dateChange(i, $event)" [bsConfig]="{dateInputFormat: 'DD.MM.YYYY','customTodayClass': 'today', showWeekNumbers: false }"
                                                                readonly>

                                                            <input *ngIf="opt.fieldType == 1 && opt.name=='Available Date'" placeholder="" class="datepicker" bsDatepicker [minDate]="todayDate" (bsValueChange)="dateChange(i, $event)" [bsConfig]="{dateInputFormat: 'DD.MM.YYYY','customTodayClass': 'today', showWeekNumbers: false }"
                                                                readonly>
                                                            <div class="" *ngIf="opt.fieldType == 2">
                                                                <div class="row">
                                                                    <div class="col-7">
                                                                        <input *ngIf="opt.fieldType == 2" min="1" (keyup)="type2Valueset(i,$event, opt.traitsData[0].traitName)" type="number" onkeypress="return event.charCode != 45 && event.charCode != 101 && event.charCode != 46">
                                                                        <div class="text-danger" *ngIf="showValueError">
                                                                            *Required
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <span class="slt_wrap" *ngIf="opt.fieldType == 2">
                                                                        <select (change)="type2ValueChange($event,i)">
                                                                            <option value="" selected disabled>Select</option>
                                                                            <option
                                                                                *ngFor="let traits of opt.traitsData"
                                                                                [value]="traits.traitName"
                                                                                [selected]="traits._id == attributesData1[i].traitId ? true : null">
                                                                                {{traits.traitName}}</option>
                                                                        </select>
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <input class="grayback" *ngIf="productId !='' && productId != null" value="{{attributesData1[i].traitName}}" type="text" readonly>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-12" *ngIf="showOfferValue=='Yes'">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Offer (in %)<span
                                                                class="asteriskIcon">*</span></label>
                                                        <input *ngIf="productId =='' || productId == null" type="text" placeholder="10%" min="1" formControlName="extraOffer" maxlength="4" (keypress)="isNumber($event)">
                                                        <input class="grayback" type="text" *ngIf="productId !='' && productId != null" formControlName="extraOffer" readonly>
                                                        <div class="text-danger" *ngIf="showOfferValueError">
                                                            *Required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-12" *ngIf="quantityUnitData.length > 0">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Selling Quantity<span
                                                                class="asteriskIcon">*</span></label>
                                                        <!-- Selling -->
                                                        <div class="row">
                                                            <div class="col-7">
                                                                <input type="text" formControlName="quantity" placeholder="Quantity" min="1" maxlength="9" (keypress)="decimalValue($event)">

                                                            </div>
                                                            <div class="col-5">
                                                                <span class="slt_wrap">
                                                                    <select formControlName="quantityType" (change)="selectedQuantityUnit($event)">
                                                                        <option value="" selected disabled>Select</option>
                                                                        <option
                                                                            *ngFor="let unitType of quantityUnitData"
                                                                            [value]="unitType.name">
                                                                            {{unitType.name}}</option>
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="text-danger" *ngIf="quantityerror">
                                                            *Selling Quantity not equal to 0
                                                        </div>
                                                        <div class="text-danger" *ngIf="quantityError">
                                                            *Required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-12" *ngIf="priceNegoShow">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Price (Nego or Not)<span class="asteriskIcon">*</span></label>
                                                        <span class="{{productId =='' || productId == null?'slt_wrap':''}}">
                                                            <select (click)="selectedValueForCompany($event)"
                                                                *ngIf="productId =='' || productId == null">
                                                                <option value="Yes" [selected]=true>Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </span>
                                                        <input class="grayback" *ngIf="productId !='' && productId != null" value="{{negotiable}}" type="text" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12" *ngIf="priceUnitData.length > 0">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Price<span
                                                                class="asteriskIcon">*</span></label>
                                                        <div class="row">
                                                            <div class="col-7">
                                                                <div class="input-icon">
                                                                    <i>&#8377;</i>
                                                                    <input type="text" formControlName="price" min="1" maxlength="9" (keypress)="decimalValue($event)" placeholder="0">
                                                                </div>
                                                            </div>
                                                            <div class="col-5">
                                                                <span class="slt_wrap">
                                                                    <select formControlName="priceType" (change)="selectedPriceUnit($event)">
                                                                        <option value="" selected disabled>Select</option>
                                                                        <option *ngFor="let unitType of priceUnitData"
                                                                            [value]="unitType.name">
                                                                            {{unitType.name}}</option>
                                                                    </select>
                                                                    
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="text-danger" *ngIf="sellingPricerReq">
                                                            *Price not equal to 0
                                                        </div>
                                                        <div class="text-danger" *ngIf="sellingPricerselectError">
                                                            *Required
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12" *ngIf="moqUnitData.length > 0">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Minimum Order Quantity<span class="asteriskIcon">*</span></label>
                                                        <div class="row">
                                                            <div class="col-12" *ngIf="productId !='' && productId != null">
                                                                <input type="text" class="grayback" formControlName="moq" placeholder="1000" readonly>
                                                            </div>
                                                            <div class="col-7" *ngIf="productId =='' || productId == null">
                                                                <input type="number" formControlName="moq" placeholder="Minimum quantity" min="1" maxlength="9" (keypress)="decimalValue($event)">

                                                            </div>
                                                            <div class="col-5" *ngIf="productId =='' || productId == null">
                                                                <span class="slt_wrap">
                                                                    <select formControlName="moqType" (change)="selectedMoqUnit($event)">
                                                                        <option value="" selected disabled>Select</option>
                                                                        <option *ngFor="let unitType of moqUnitData"
                                                                            [value]="unitType.name">
                                                                            {{unitType.name}}</option>
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="text-danger" *ngIf="moqErrorReq">
                                                            *Minimum Order Quantity not equal to 0
                                                        </div>
                                                        <div class="text-danger" *ngIf="moqselectError">
                                                            *Required
                                                        </div>

                                                        <div class="text-danger" *ngIf="showError && moqsellingError && !moqError && !userForm.controls.moq.errors?.required">
                                                            <!-- *Minimum Order Quantity should be less then selling quantity. -->
                                                            *Selling quantity can not be less then minimum Order quantity
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12" [ngClass]="{'coverClass': addressCheck == 1 }">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">{{changeAddresslable}}<span class="asteriskIcon">*</span></label>
                                                        <input type="text" class="form-control address" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search formControlName="googleAddress" [readonly]="isReadOnly">
                                                        <div class="text-danger" *ngIf="showError&&userForm.controls.googleAddress.errors?.required ">
                                                            *Required
                                                        </div>
                                                        <div class="text-danger" *ngIf="googleAddress">
                                                            *please select correct address
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12" *ngIf="hideDelivery">
                                                    <div class="form_cont">
                                                        <label for="cc-number" class="control-label">Is Delivery
                                                            available</label>

                                                        <span class="{{productId =='' || productId == null?'slt_wrap':''}}">
                                                            <select (click)="selectedValueForDelivery($event)"
                                                                *ngIf="productId =='' || productId == null">
                                                                <option value="Yes" [selected]=true>Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </span>
                                                        <input class="grayback" *ngIf="productId !='' && productId != null" value="{{Delivery}}" type="text" readonly>

                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12" *ngIf="Delivery=='Yes'" >
                                                    <div class="form_cont">
                                                        <label class="control-label">Area of Delivery (in KM)</label>
                                                        <input type="number" *ngIf="productId =='' || productId == null" formControlName="isDeliveryinkm" placeholder="Area of Delivery (in KM)" min="1" onkeypress="return event.charCode != 45 && event.charCode != 101 && event.charCode != 46">
                                                        <input class="grayback" *ngIf="productId !='' && productId != null" formControlName="isDeliveryinkm" type="text" readonly>
                                                        <div class="text-danger" *ngIf="isDeliveryError">
                                                            *Required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <!-- <p class="hint1" *ngIf="hintshow">{{hint}} </p> -->
                                                    <div class="form_cont1">
                                                        <label for="cc-number" class="control-label">Description<span
                                                                class="asteriskIcon">*</span></label>
                                                        <textarea class="form-control" data-toggle="tooltip" data-placement="top" title="{{hint}}" *ngIf="productId =='' || productId == null" formControlName="description" (keyup)="change()" id="exampleFormControlTextarea1" rows="3" placeholder="Type your message here.."
                                                            maxlength="500"></textarea>
                                                        <textarea class="form-control grayback" *ngIf="productId !='' && productId != null" formControlName="description" id="exampleFormControlTextarea1" rows="3" placeholder="Type your message here.." readonly></textarea>

                                                        <div class="text-danger" *ngIf="showError&&userForm.controls.description.errors?.required ">
                                                            *Required
                                                        </div>
                                                        <p class="hint1">{{hint}} </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row up_img_samp">
                                                <div class="col-lg-12">
                                                    <div class="form_cont1">
                                                        <ul>
                                                            <p class="controllabels">Add Max 3 (Image/Videos)
                                                                <span class="asteriskIcon">*</span>
                                                            </p>
                                                            <li class="handcursor">
                                                                <a class="img_uup inputcss"><img src="./assets/images/pls.svg">
                                                                    <input type="file" name="file" id="file-upload" class="" (change)="upload($event,1, business)" accept=".png, .jpg, .jpeg, .mp4,*" />
                                                                </a>
                                                            </li>
                                                            <li *ngFor="let slide of imageUpload;let i = index" style="margin-bottom:10px;">
                                                                <a *ngIf="!slide.fileType"><img class="img_height" src="{{s3url}}products/{{slide.file}}"><span *ngIf="!slide.fileType" class="cls_tb" (click)="delete1(i)"><img
                                                                            src="./assets/images/cls.svg"></span></a>
                                                                <a *ngIf="slide.fileType"><span class="cls_tb"
                                                                        (click)="delete1(i)"><img
                                                                            src="./assets/images/cls.svg"></span>
                                                                    <video class="imageheight w-100" #videoPlayer>
                                                                        <source
                                                                            src="{{s3url}}products/{{slide.file}}"
                                                                            type="video/mp4" />
                                                                    </video>
                                                                </a>
                                                            </li>

                                                        </ul>
                                                        <div class="text-danger" *ngIf="showError&&userForm.controls.image.errors?.required ">
                                                            *Required
                                                        </div>
                                                        <div class="text-danger" *ngIf="videoLengthError ">
                                                            *Maximum 1 videos upload
                                                        </div>

                                                        <div class="text-danger" *ngIf="imageLengthError ">
                                                            *Maximum images/videos upload length reached
                                                        </div>
                                                        <div class="text-danger" *ngIf="minimumLengthError">
                                                            *Minimum 1 image/video is mandatory
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="text-danger" style="padding:20px 0 0 0;" *ngIf="attributesError">
                                                *Pleae select all attributes
                                            </div>
                                            <div class="text-danger" style="padding:20px 0 0 0;" *ngIf="productId =='' || productId == null">
                                                * These are mandatory fields.
                                            </div>


                                            <div class="row up_img_samp" style="padding:20px 0 0 0;">
                                                <div class="col-md-6">
                                                    <div class="form_cont">
                                                        <!-- <button class="m_btn_red btn_red" data-toggle="modal"
                                                        (click)="open(loginModal32)" data-target="#loginModal32">Submit
                                                        to sell</button> -->
                                                        <button *ngIf="productId =='' || productId == null" href="javascript:;" class="m_btn_red btn_red">Submit </button>
                                                        <button *ngIf="productId !='' && productId != null" href="javascript:;" class="m_btn_red btn_red">Save</button>


                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="productId !='' && productId != null">
                                                    <div class="form_cont">

                                                        <a href="javascript:;" class="btn btn-info btn-block btn-round btn_red btn_brdr" (click)="deleteProduct(deletePopup)">Delete the Product
                                                        </a>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>


    <ng-template #planConfrmations>
        <div class="log_pop sign_pop pop_dbl_btn lgout pro_added_suc">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modulecss">
                    <div class="modal-header border-bottom-0">
                        <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-title text-center">
                            <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                            <h4>Please update your plan</h4>

                        </div>
                        <div class="d-flex flex-column text-center">
                            <form class="d-flex">
                                <button type="button" class="btn mr-3 btn-info btn-block btn-round btn_red col-6" (click)="planChoise(planselect)">Update</button>
                                <button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr" data-dismiss="modal" aria-label="Close" (click)="close()">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </ng-template>

    <ng-template #planselect>
        <div class="log_pop sign_pop pop_dbl_btn lgout pro_added_suc">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modulecss">
                    <div class="modal-header border-bottom-0">
                        <button type="button" class="close" (click)="close()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-title text-center">
                            <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                            <h4>Please update your plan</h4>

                        </div>
                        <div class="planList">
                            <ul>
                                <li *ngFor="let plan of plansList?.planData">
                                    <label class="check_buy choose_slt_plan_opt" *ngIf="plan.planPrice > 0">
                                        <div class="plan_qnt_txt"> {{plan?.quantity}} <small>Quantity</small></div>
                                        <div class="prc_mnth">
                                            <strong><i class="fa fa-rupee"></i> {{plan?.planPrice}}<small>
                                                    {{plan?.subscription}}</small></strong>

                                        </div>
                                        <p> {{plan?.planType}}</p>
                                        <input type="radio" name="checkbox" id={{plan?._id}}
                                            (change)="onlyOneValue($event,  plan)">
                                        <span class="checkmark"></span>
                                    </label>

                                </li>


                            </ul>
                            <button type="button" *ngIf="planPaymet != ''" class="btn mr-3 btn-info btn-block btn-round btn_red col-12" (click)="payment()">Pay <i class="fa fa-rupee"></i>
                                {{planPaymet}}</button>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </ng-template>



    <ng-template #deletePopup>
        <div class=" modal-dialog-centered dlt_edt_product" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Delete</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <p>Are you sure want to delete the entire product?</p>
                        <div class="form-group position_rel row">
                            <button type="button" (click)="delete()" class="btn btn-info btn-block btn-round btn_red" data-toggle="modal" data-target="">Done</button>
                            <button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr" (click)="close()" data-toggle="modal" data-target="">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>

    <app-business-modal #business>
    </app-business-modal>