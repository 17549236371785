import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexPageComponent } from './external/index-page/index-page.component';
import { NewOrdersSellerComponent } from './internal/new-orders-seller/new-orders-seller.component';
import { PageNotfoundComponent } from './Common/page-notfound/page-notfound.component';
import { PriceComponent } from './external/price/price.component';
import { PriceInfoComponent } from './external/price/priceInfo';
import { ProductPriceComponent } from './external/price/product-price/product-price.component';
import {PrivacyPolicyComponent} from './external/privacy-policy/privacy-policy.component';
import {RateComponent} from "./external/rate/rate.component"
import { TermsConditionsComponent } from './external/terms-conditions/terms-conditions.component'
import { PlanComponent } from './external/plan/plan.component';
import { ChatComponent } from './internal/chat/chat.component';
import { AuthGuard } from './services/auth.guard';
import { SearchComponent } from './internal/search/search.component';
import { from } from 'rxjs';
import { MandiComponent } from './external/price/mandi/mandi.component';
import { AboutComponent } from './external/about/about.component';
import { LeadDetailComponent } from './internal/lead-detail/lead-detail.component';
import { PartnerComponent } from './external/partner/partner.component';
import { DisclaimerComponent } from './external/disclaimer/disclaimer.component';
import { MyRatesComponent } from './external/my-rates/my-rates.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', component: IndexPageComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermsConditionsComponent },
  { path: 'aboutus', component: AboutComponent }, 
  { path: 'partner', component: PartnerComponent }, 
  { path: 'disclaimer', component: DisclaimerComponent }, 
  {
    path: 'price', component: PriceInfoComponent,
    children: [
      { path: '', component: PriceComponent },     
      { path: 'product-price', component: ProductPriceComponent },
      { path: 'mandi-price', component: MandiComponent },      
    ]
  },
  { path: 'plan',  canActivate: [AuthGuard], component: PlanComponent },
  { path: 'trendPrice/:rateName', component: RateComponent },
  { path: 'myRates', component: MyRatesComponent },
  { path: 'NewOrdersSeller', component: NewOrdersSellerComponent },  
  { path: 'chat', canActivate: [AuthGuard], component: ChatComponent },
  { path: 'chat/:buyer_id/:seller_id/:product_id/:tab', canActivate: [AuthGuard], component: ChatComponent },
  { path: 'chat/:tab', canActivate: [AuthGuard], component: ChatComponent },

  { path:'',
    canActivate: [AuthGuard], 
    loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule)},
  { path: '**', component: PageNotfoundComponent },

]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
