<app-header></app-header>
<main class="main_cont profile_page_view privacy">
    <div class="container">
        <h1 class="">Submit Requirement</h1>
        <div class="tech_support" style="width: 100%;">

            <form [formGroup]="requirementForm">

                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="form_cont">
                            <label>Select Product</label>
                            <span class="slt_wrap">
                                <select formControlName="product" (change)="selectProduct($event)">
                                    <option value="" selected disabled hidden>
                                        Select Product
                                    </option>
                                    <option *ngFor="let productName of allProduct"
                                        [value]="productName.subCategory[0].parentId">{{productName.name}}</option>
                                </select>
                            </span>
                            <div class="text-danger" *ngIf="requirementForm.controls['product'].hasError('required') && showError">
                                *Please select product.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="form_cont">
                            <label>Select sub Product</label>
                            <span class="slt_wrap">
                                <select formControlName="subProduct" (change)="selectSubProduct
                            ($event)">
                                    <option value="select" selected hidden>Select sub category</option>
                                    <option *ngFor="let data of subProduct" [value]="data.subCategory._id">
                                        {{data.subCategory.name}}</option>
                                </select>
                            </span>
                            <div class="text-danger" *ngIf="requirementForm.controls['subProduct'].hasError('required') && showError">
                                *Please select sub product
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="form_cont">
                            <label>Looking for supplier</label>
                            <span class="slt_wrap">
                                <select formControlName="supplier" (change)="selectSupplier($event)">
                                    <option value="" selected disabled>
                                        Select
                                    </option>
                                    <option value="1">Anywhere in India</option>
                                    <option value="0">Local</option>
                                </select>
                            </span>
                            <div class="text-danger" *ngIf="requirementForm.controls['supplier'].hasError('required') && showError">
                                *Please select supplier.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12" *ngIf="showState">
                        <div class="form_cont">
                            <label>State</label>
                            <span class="slt_wrap">
                                <select formControlName="state">
                                    <option value="" selected disabled>
                                        Select state
                                    </option>
                                    <option *ngFor="let state of stateslist" [value]="state.name">{{state.name}}
                                    </option>

                                </select>
                            </span>
                            <div class="text-danger" *ngIf="requirementForm.controls['state'].hasError('required') && showError">
                                *Please select state.
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="form_cont">
                            <label>Quantity</label>
                            <div class="row">
                                <div class="col-7">
                                    <input type="number" [min]="1" formControlName="quantity">
                                </div>
                                <div class="col-5">
                                    <span class="slt_wrap">
                                        <select formControlName="quantityType">
                                            <option value="select" selected hidden>Select</option>
                                            <option *ngFor="let unitType of quantityUnitData" [value]="unitType.name">
                                                {{unitType.name}}</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="requirementForm.controls['quantity'].hasError('required') && showError">
                                *Please enter Quantity.
                            </div>
                            <div class="text-danger" *ngIf="quantityError">
                                *Please select quantity unit
                            </div>
                            <div class="text-danger" *ngIf="quantityError1">
                                *Please enter Quantity.
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form_cont">
                            <label>Description</label>
                            <textarea rows="4" cols="50" formControlName="description" maxlength="150"></textarea>
                            <!-- <div class="text-danger">
                        *Please Select product.
                    </div> -->
                        </div>
                    </div>
                </div>
                <div class="form_cont">
                    <!-- <button   (click)="support()"></button> -->
                    <a class="m_btn_red btn_red" (click)="submitPost(requirementForm, business)">Post Now</a>

                </div>
            </form>
        </div>
    </div>
</main>

<app-business-modal #business>
</app-business-modal>
<app-footer></app-footer>