import { Injectable } from '@angular/core';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
  
  constructor(public api: JsonPlaceHolderService) { }

  getList(url, data) {
      return this.api.getData(url,data);
  }

  submitForm(data,url) {
      return this.api.postData(url, data);
  }

  updateForm(data,url) {
    return this.api.putData(url, data);
  }

  updateStatus(url,data)
  {
      return this.api.postData(url,data);
  }

  showAlert(title:string,text:string)
  {
    swal({
      title:title,
      text:text,
      type:'success',
      showConfirmButton: false,
      timer: 1500
    })
  }

} 