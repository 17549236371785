<app-header></app-header>
<main class="main_cont">
    <!-- Profile -->
    <section class="profile_page_view profile_view2 buyer_vw_seller buyer_pro_dtl buy_quotee pdb_0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="left_profile_detail">
                        <a class="go_back"><img src="./assets/images/back.svg"> Back </a>
                        <div class="single_product">
                            <div class="container-fluid" style=" background-color: #fff; padding: 11px;">
                                <div class="row">
                                    <div class="col-lg-5 col-md-12">
                                        <div class="sell_near left_cont_dtl">
                                            <div class="detail_pro">
                                                <h5>Hatched Eggs</h5>
                                                <!-- <span class="rds">(Radius 20 km)</span> -->
                                                <div class="dtl_tp">
                                                    <ul>
                                                        <li>Brown <i class="fa fa-circle"></i></li>
                                                        <li>Desi <i class="fa fa-circle"></i></li>
                                                        <li>Golden</li>
                                                    </ul>
                                                    <div class="approved_s">
                                                        <span><i class=""><img src="./assets/images/star.svg"></i> 4.8</span>
                                                        <span><i class=""><img src="./assets/images/chat-bx.svg"></i> 122</span>
                                                    </div>
                                                </div>
                                                <div class="quote_dtl">
                                                    <div class="detail_pro">
                                                        <span class="profile_pc"></span>
                                                        <h5>Hirakani Agro
                                                            <!-- <img class="veri_img" src="./assets/images/checked.svg"> -->
                                                        </h5>
                                                        <div class="approved_s">
                                                            <span class="code_red">#PB11ABC</span><span class="verify"><i class="fa fa-check"></i> Verified</span>
                                                            <span><i class="fa fa-star"></i> 4.8</span>
                                                            <div class="loc_mark"><img src="./assets/images/loc-gray.svg"> Chandigarh</div>
                                                        </div>
                                                    </div>
                                                    <div class="detail_cont"><a class="m_btn_red btn_red" (click)="chat()">Chat</a>
                                                        <!-- <a><img src="./assets/images/phone-res.svg"></a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12"><a class="btn btn-lg m_btn_red btn_red offr_dl disabled" role="button" aria-disabled="true">Cancel Order</a></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 order-3">
                                        <div class="product_description deal_cont">
                                            <div class="sell_near">
                                                <div class="detail_pro">

                                                    <h5>DEAL #9182353</h5>
                                                    <!-- <img class="veri_img" src="./assets/images/checked.svg"> -->
                                                    <div class="approved_s">
                                                        <span class="date_ordr">15 Sep 2020</span>
                                                    </div>
                                                </div>
                                                <div class="pending_stat"><a class="deal_quote m_btn_red btn_red btn_pending">Pending</a></div>
                                            </div>

                                            <div class="selectd_type">
                                                <ul>
                                                    <li>Egg Type <strong>Eggs</strong></li>
                                                    <li>Type Of Product<strong>White Eggs</strong></li>
                                                    <li>Packing<strong>Family Pack</strong></li>
                                                    <li>Maximum Quantity<strong>200</strong>
                                                        <li class="">Loading Address <strong class="bld">Sector 62, Mohali</strong></li>
                                                        <li class="">Delivery<strong>20 km area</strong></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<app-footer></app-footer>