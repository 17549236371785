<app-header></app-header>
<main class="main_cont">
    <!-- Profile -->
    <section class="profile_page_view rating_pro trend_dtl_page notify_list_pg">
        <div class="container small">
            <h2 class="titlePage">
                <a (click)="go_back()" href="javascript:;"><img src="./assets/images/back.svg"> Premium Lead Detail </a>
            </h2>
            <div class="col-bx-top">
                <div class="left-panel">
                    <img [src]="buyerData.profilePic ? (urlfile+buyerData.profilePic) : './assets/images/default.jpg'"></div>
                <div class="user-details">
                    <div class="repeatedrow">
                        <div class="rowbody">
                            <div class="listview">
                                <dl class="list">
                                    <dt>Name</dt>
                                    <dt>{{buyerData.name ? (buyerData.name):''}}</dt>
                                </dl>
                                <dl class="list">
                                    <dt>Product</dt>
                                    <dt>{{productName ? (productName):""}}</dt>
                                </dl>
                                <dl class="list">
                                    <dt>Sub Product</dt>
                                    <dt>{{subProductName ? (subProductName):""}}</dt>
                                </dl>
                                <dl class="list">
                                    <dt>Quantity</dt>
                                    <dt>{{allData.quantity?(allData.quantity):''}}</dt>
                                </dl>
                                <dl class="list" *ngIf="allData.stateName">
                                    <dt>State </dt>
                                    <dt>{{allData.stateName?(allData.stateName):''}}</dt>
                                </dl>
                                <dl class="list" *ngIf="allData.lookingSupplier==1">
                                    <dt>Looking in </dt>
                                    <dt>All India</dt>
                                </dl>
                                <dl class="list">
                                    <dt>Address</dt>
                                    <dt>{{buyerData.businessAddress?.addressLine1 ? (buyerData.businessAddress?.addressLine1):''}}</dt>
                                </dl>
                                <dl class="list">
                                    <dt>Description</dt>
                                    <dt>{{allData.description ? (allData.description):''}}</dt>
                                </dl>
                                <dl class="list">
                                    <dt>Call now</dt>
                                    <dt>{{buyerData.phoneNumber ? (buyerData.phoneNumber):''}}</dt>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>