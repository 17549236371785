<!-- <app-headerwithoutslick></app-headerwithoutslick> -->
<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view profile_view2 buyer_pro_dtl sell_pro4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="left_profile_detail">
                        <a [routerLink]="['/seller']" class="go_back"><img src="./assets/images/back.svg"> Back</a>

                        <div class="single_product">
                            <div class="container-fluid" style=" background-color: #fff; padding: 11px;">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="sell_near left_cont_dtl">
                                            <div class="detail_pro">
                                                <h5> {{Data?.subCategoryName}}</h5>
                                                <div class="selling_qnt">
                                                    <h3>Sell Now</h3>
                                                    <label class="switch">
                                                        <input type="checkbox" 
                                                            checked="{{Data?.isActive == true ? true : null}}"
                                                            (change)="enableOrDeleteProduct($event,Data._id,Data.isDeleted)">
                                                            <div class="slider round">
                                                                <span class="on">On</span>
                                                                <span class="off">Off</span>

                                                            </div>
                                                    </label>
                                                </div>

                                                <div class="dtl_tp">
                                                    <ul>
                                                        <li *ngFor="let opt of Data?.attributesData;let i = index"><i *ngIf="i != 0" class="fa fa-circle"></i>{{opt?.traitName}}</li>
                                                        <!-- <li>6cm <i class="fa fa-circle"></i></li>
                                                        <li>Family Pack</li> -->
                                                    </ul>
                                                    <div class="approved_s">
                                                        <span><i class=""><img src="./assets/images/star.svg"></i>
                                                            {{Data?.productRatingData == null? '0':
                                                            Data?.productRatingData | number:'1.1-1'}}</span>
                                                        <span><i class=""><img src="./assets/images/chat-bx.svg"></i>
                                                            {{Data?.feedbackDetail?.length}}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-9 col-md-12 col-sm-9">
                                                <div class="image_selected">
                                                    <img *ngIf="!selectedVideoImage?.fileType" class="height-100" src="{{selectedVideoImage?.file ? s3url+'products/' + selectedVideoImage?.file:'./assets/images/egg.png'}} " alt="">

                                                    <video id="video" #video *ngIf="selectedVideoImage?.fileType" class="imageheight w-100" controls="true" autoplay>
                                                        <source
                                                            src="{{selectedVideoImage?.file ? s3url+'products/'+ selectedVideoImage?.file :'./assets/images/egg.png'}}"
                                                            type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12 col-sm-3">
                                                <ul class="image_list">
                                                    <li data-image="" *ngFor="let opt of showImageArray;let i = index" class="active" [class.active]="step== i" (click)="step= i">
                                                        <span (click)="selectImageVideo(i, opt)">
                                                            <img src="{{ s3url+'products/' + opt?.file}}"
                                                                alt=" " *ngIf="!opt.fileType">

                                                            <video *ngIf="opt.fileType" class="imageheight w-100"
                                                                #videoPlayer>
                                                                <source
                                                                    src="{{ s3url+'products/' + opt?.file}}"
                                                                    type="video/mp4" />
                                                            </video>
                                                        </span>

                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-lg-9 col-md-12 col-sm-9"><a (click)="routh()" class="m_btn_red btn_red offr_dl btn_ylow">Edit Product</a></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7 order-3">
                                        <div class="product_description">
                                            <div class="sell_near">
                                                <div class="detail_pro fullWidth">
                                                    <!-- <span class="profile_pc"></span> -->
                                                    <img class="widthset profile_pc mr-2" src="{{allData?.businessDocuments?.length != 0 ?s3url+'users/' + allData?.businessDocuments : './assets/images/default.jpg'}}">

                                                    <h5>{{allData?.businessName}}
                                                        <!-- <img class="veri_img"  
                                                            src="./assets/images/checked.svg"> -->
                                                    </h5>
                                                    <div class="approved_s">
                                                        <span class="code_red">{{allData?.userUniqueId}}</span>
                                                        <span class="verify" *ngIf="allData?.businessVerified == true">
                                                            <i class="fa fa-check"></i>Verified
                                                        </span>
                                                        <span class="verify warning" *ngIf="allData?.businessVerified == false">
                                                            Unverified
                                                        </span>

                                                        <span><i class="fa fa-star"></i> {{allData?.userRating |
                                                            number:'1.1-1'}} </span>
                                                        <div class="loc_mark"><img src="./assets/images/loc-gray.svg"> {{allData?.businessAddress?.addressLine1}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="detail_cont">
                                                    <ul class="order_prem">

                                                    </ul>
                                                </div> -->
                                                <p>{{Data?.description}}</p>
                                            </div>

                                            <div class="selectd_type">
                                                <ul>
                                                    <li>Type Of {{Data?.categoryData?.name}}
                                                        <strong>{{Data?.subCategoryName}}</strong>
                                                    </li>
                                                    <li *ngFor="let opt of Data?.attributesData;let i = index">
                                                        {{opt?.attrName}}<strong>{{opt?.traitName ? opt?.traitName
                                                            :'-'}}</strong></li>



                                                    <li class="edit_opt" *ngIf="Data?.extraOffer>0 ">Offer (in %)

                                                        <strong>  {{Data?.extraOffer ? (Data?.extraOffer):''}}</strong>

                                                    </li>

                                                    <li *ngIf="Data?.quantity" class="edit_opt">Selling Quantity
                                                        <div class="input-group">
                                                            <strong>{{Data?.quantity}} <label >{{Data?.quantityUnit ? (Data?.quantityUnit):''}}</label></strong>

                                                        </div>

                                                    </li>
                                                    <li class="edit_opt" *ngIf="Data?.price>0">Price<strong class="bld">
                                                        <i class="fa fa-rupee"></i> {{Data?.price}}<label>/{{Data?.priceUnit ? (Data?.priceUnit):''}}</label>
                                                            
                                                            </strong>

                                                    </li>
                                                    <li class="edit_opt" *ngIf="Data?.moq>0">Minimum Order Quantity<strong class="bld">
                                                        {{Data?.moq}}<label>/{{Data?.moqUnit ? (Data?.moqUnit):''}}</label>
                                                            
                                                            </strong>

                                                    </li>

                                                    <li *ngIf="hidePriceNegotiate">Is Price Negotiable<strong>{{Data?.negotiatePrice}}</strong></li>
                                                    <li *ngIf="hideIsDelivery">is Delivery Available<strong>{{Data?.isDeliveryAvailable == true
                                                            ? 'Yes' :'No'}}</strong></li>
                                                    <li *ngIf="Data?.deliveryArea>0">Area of Delivery (in KM)<strong>{{Data?.deliveryArea ? (Data?.deliveryArea):''}}</strong></li>


                                                    <li *ngIf="Data?.loadingDate">Loading Date<strong>{{Data?.loadingDate}}</strong>
                                                    </li>
                                                    <li class=""><span *ngIf="!addressLabel">Business Address</span><span *ngIf="addressLabel">Clinic Address</span> <strong class="bld">{{Data?.address?.googleAddress}}</strong></li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="review_cst">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row revw_ttl">
                                <div class="col-md-6">
                                    <h2>Customer Reviews</h2>
                                </div>
                                <div class="col-md-6">

                                </div>
                            </div>
                            <div class="row">
                                <div class="main_rvw_div col-12" *ngFor="let review of Data?.feedbackDetail">
                                    <div class="rv_profl" *ngFor="let reviewDetails of review?.reviewUser">
                                        <span>
                                            <img src="{{reviewDetails?.profilePic ? s3url+'users/' + reviewDetails?.profilePic:'https://image.ibb.co/jw55Ex/def_face.jpg'}}"
                                                alt="">

                                        </span>
                                        <h6>{{reviewDetails?.name}}</h6>
                                    </div>
                                    <div class="rating_str">
                                        <div class="full-stars" [ngStyle]="riderRation(review.sellerRating | number:'1.0-0')"></div>


                                    </div>

                                    <p>{{review?.description}}</p>
                                </div>

                                <div class="clear"></div>
                                <!-- <a (click)="viewMore()" class="m_btn_red btn_red rvw_btn"
                                    *ngIf="Data?.feedbackDetail.length > 5">View More</a> -->

                                <div class="No_found" *ngIf="Data?.feedbackDetail?.length == 0">
                                    <span class="norecordIcon"></span> No Data Found !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<button type="button" class="m_btn_red btn_red calc_button add_pp" [routerLink]="['/seller/category']">
    Add Product
</button>
<app-footer></app-footer>

<ng-template #loginModal27>
    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            </div>
            <div class="modal-body">
                <div class="form-title text-center">
                    <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                    <h2>Update</h2>

                </div>
                <div class="d-flex flex-column text-center">
                    <form>
                        <div class="form-group position_rel row selectd_type">
                            <div class="col-12">
                                <label for="category">Update Quantity</label>

                                <div class="input-group">

                                    <input type="text" name="quant[1]" class="form-control input-number" value="1" min="1" max="500">

                                    <span class="input-group-btn btn_pls">
                                        <button type="button" class="btn btn-default btn-number" data-type="plus"
                                            data-field="quant[1]">
                                            <span><i class="fa fa-plus"></i></span>
                                    </button>
                                    </span>
                                    <span class="input-group-btn btn_minus">
                                        <button type="button" class="btn btn-default btn-number" disabled="disabled"
                                            data-type="minus" data-field="quant[1]">
                                            <span><i class="fa fa-minus"></i></span>
                                    </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group position_rel row">
                            <div class="col-12">
                                <label for="category">Update Price</label>

                                <span class="slt_wrap">
                                    <input type="text" value="" name="" placeholder="3000">
                                </span>

                            </div>
                        </div>





                        <div class="form-group position_rel row">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-info btn-block btn-round btn_red" data-dismiss="modal" aria-label="Close">Save</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</ng-template>