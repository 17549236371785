import { TemplateRef, Component, OnInit, Injectable, ViewChild, NgZone, ElementRef, ViewChildren, ContentChild, QueryList } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { NavigationService } from '../../Common/header/header.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FcmserviceService } from './../../services/fcmservice.service';
import { DataSharingService } from '../../services/data.share.service';
declare var Razorpay: any;

@Component({
  selector: 'app-business-modal',
  templateUrl: './business-modal.component.html',
  styleUrls: ['./business-modal.component.css']
})
@Injectable()

export class BusinessModalComponent implements OnInit {
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  form: FormGroup;
  cityError = false;
  updateProfileForm: FormGroup;
  loginsForm: FormGroup;
  busineesForm: FormGroup;
  busineesCode:any;
  varification = '';
  googleMapForm = true;
  password = '';
  password1 = '';
  BussinessVerified: any;
  showErrormatch1: boolean = false;
  showErrormatch2: boolean = false;
  resenderror: boolean = false;
  token = '';
  password2 = '';
  uploadImage = '';
  urlfile: any = '';
  uploadImage1 = '';
  urlfile1: any = '';
  image: any = [];
  headershow: boolean = false;
  uploadImage2 = '';
  urlfile2: any = '';
  admintoken1 = '';
  pinError = false;
  name = '';
  Commonsearch = '';
  showError: boolean = false;
  showError1: boolean = false;
  showErrormatch21: boolean = false;
  showErrormatch11: boolean = false;
  otperror: boolean = false;
  modalRef: BsModalRef;
  showhite: boolean = true;
  loginshow: boolean = false;
  getbusineeslist: any;
  urlnumber1: any;
  getuserlist: any;
  Countrylist: any;
  stateslist: any;
  editBussionProfile = false;
  latitude = 51.678418;
  longitude = 7.809007;
  zoom: number;
  private geoCoder;
  address: string;
  gMap: any;
  mapPlot: any
  mapSector: any;
  mapLocality: any;
  mapCity: any;
  mapState: any;
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapStateshort: any;
  feeCheck: any;
  pId: any;
  razorPayData: any;
  phoneNumber: any;
  userId: any;
  busineesId: any;
  busineesTyps: any = [];
  feeAmount: any;
  allData: any;
  editData: any;
  showPdf = 0;
  showPdf1 = 0;
  showLoaders = 0;
  showUnverifie = false;
  addressError = false;
  countryError = false;
  stateError = false;
  addressLine1: any;

  url: any;
  @ViewChild('business') private businessModal: TemplateRef<any>
  @ViewChild('paymentPopup') private paymentModal: TemplateRef<any>

  @ViewChild('search') public searchElementRef: ElementRef;
  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: BsModalService, public admin: JsonPlaceHolderService, private NS: NavigationService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private messagingService: FcmserviceService, private dataSharingService: DataSharingService) {
 
    this.busineesForm = this.formBuilder.group({
      userType: ['', Validators.compose([Validators.required])],
      BusinessType: ['', Validators.compose([Validators.required])],
      BusinessName: ['', Validators.compose([Validators.required,
      Validators.minLength(3), Validators.maxLength(45)])],
      AddressLine1: ['', Validators.compose([Validators.required])],
      AddressLine2: ['', Validators.compose([])],
      Country: ['', Validators.compose([])],
      state: ['', Validators.compose([])],
      city: ['', Validators.compose([Validators.required])],
      pincode: ['', Validators.compose([Validators.required])],
      googleAddress: ['']

    });


  }

  ngOnInit(): void {
    this.url = localStorage.getItem('s3BaseUrl');
    this.latitude = localStorage.getItem('latitude') ? (parseFloat(localStorage.getItem('latitude'))) : 30.7046486;
    this.longitude = localStorage.getItem('longitude') ? (parseFloat(localStorage.getItem('longitude'))) : 76.71787259999999;
    this.mapsAPILoader.load().then(() => {
      //this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: "IN" }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.gMap = place;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.geoCoder = new google.maps.Geocoder;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)

        });
      });
      autocomplete.setComponentRestrictions
    });
  }

  userTypeSelect(event) {
    console.log('select value');
    this.busineesTyps = [];
    this.busineesForm.value.userType = event.target.value;
    for (var i = 0; i < this.getuserlist.length; i++) {
      if (event.target.value == this.getuserlist[i].name) {
        this.busineesId = this.getuserlist[i].id;
      }
    }
    for (var j = 0; j < this.getbusineeslist.length; j++) {
      if (this.busineesId == this.getbusineeslist[j].userTypeId) {
        this.busineesTyps.push(this.getbusineeslist[j]);
      }
    }
  }


  businessTypeSelect(event) {
    this.busineesForm.value.BusinessType = event.target.value;
    for (let i = 0; i < this.busineesTyps.length; i++) {
      if (this.busineesTyps[i].name == event.target.value) {
        this.busineesCode = this.busineesTyps[i].code;
      }
    }


  }
  openBusinessModel() {
    let checkUnverified = localStorage.getItem('unverified') ? (localStorage.getItem('unverified')) : '';
    if (checkUnverified) {
      this.editBussionProfile = true;
      this.showUnverifie = true;
      setTimeout(() => {
        this.showUnverifie = false;
        localStorage.removeItem('unverified')
      }, 1500)
    }
    this.getappInitials();
    this.modalRef = this.modalService.show(this.businessModal, {
      animated: true,
      backdrop: 'static',
    });
    this.editbusiness()
  }
  Backopen1(template) {
    this.modalRef = this.modalService.show(template, {
      class: "my-class-modals", animated: true,
      backdrop: 'static',
    });

    // this.busineesForm.patchValue({
    //   'userType': this.busineesForm.value.userType,
    //   'BusinessType': this.busineesForm.value.BusinessType,
    // })

    // this.openBusinessModel();
    // 
  }
  getappInitials() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getappInitials)
      .subscribe(async (res) => {
        console.log('all data', res.data)
        this.Countrylist = res.data.countries;
        this.stateslist = res.data.states;
        this.getuserlist = res.data.userTypes;
        this.getbusineeslist = res.data.businessTypes;
      })
  }
  open(template, type, previousModal?) {
    this.userId = localStorage.getItem('id');
    this.phoneNumber = localStorage.getItem('phone').substring(localStorage.getItem('phone').length - 10, localStorage.getItem('phone').length)
    console.log('this.busineesForm.value.pincode:::::', this.busineesForm.value.pincode)
    if (type == 1) {
      if (this.busineesForm.value.userType == '' || this.busineesForm.value.userType == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.BusinessType == '' || this.busineesForm.value.BusinessType == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.BusinessName == '' || this.busineesForm.value.BusinessName == null || this.busineesForm.value.BusinessName.length < 3) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else {
        // this.modalRef.hide();
        // this.modalRef = template.show();
        this.modalRef.hide();
        setTimeout(() => {
          this.modalRef = this.modalService.show(template, {
            class: "my-class-modals", animated: true,
            backdrop: 'static',
          });
        }, 10);
      }

    } else if (type == 2) {
      if (this.busineesForm.value.AddressLine1 == '' || this.busineesForm.value.AddressLine1 == null) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      } else if (this.busineesForm.value.state == "" || this.busineesForm.value.state == null) {
        this.stateError = true;
        setTimeout(() => {
          this.stateError = false;
        }, 4000)
      } else if (this.busineesForm.value.pincode == "" || this.busineesForm.value.pincode == null) {
        this.pinError = true;
        setTimeout(() => {
          this.pinError = false;
        }, 4000)
      } else if (this.busineesForm.value.pincode.toString().length != 6) {
        console.log("2==", this.busineesForm.value.pincode.toString().length)
        this.pinError = true;
        setTimeout(() => {
          this.pinError = false;
        }, 4000)
      } else if (this.busineesForm.value.city == "" || this.busineesForm.value.city == null) {
        this.cityError = true;
        setTimeout(() => {
          this.cityError = false;
        }, 4000)
      } else if (this.busineesForm.value.Country != this.mapcountry) {
        this.countryError = true;
        setTimeout(() => {
          this.countryError = false;
        }, 4000)
      } else if (this.busineesForm.value.state != this.mapState) {
        this.stateError = true;
        setTimeout(() => {
          this.stateError = false;
        }, 4000)
      } else if (this.busineesForm.value.city != this.mapCity) {
        this.cityError = true;
        setTimeout(() => {
          this.cityError = false;
        }, 4000)
      } else if (this.mapPostal) {
        if (this.busineesForm.value.pincode != this.mapPostal) {
          this.pinError = true;
          setTimeout(() => {
            this.pinError = false;
          }, 4000)
        }
        else {
          if(previousModal){
            previousModal.hide();
          }else{
            this.modalRef.hide();
          }
          this.modalRef = this.modalService.show(template, {
            class: "my-class-modals", animated: true,
            backdrop: 'static',
          });
        }
      } else {
        previousModal.hide();
        // this.modalRef = template.hide();
        this.modalRef = this.modalService.show(template, {
          class: "my-class-modals", animated: true,
          backdrop: 'static',
        });
      }
    } else if (type == 4) {
      this.googleMapForm = true;
      this.modalRef.hide();
      this.modalRef = template.show();
    } else if (type == 5) {
      this.googleMapForm = false;
      this.setCurrentLocation();
      this.modalRef.hide();
      this.modalRef = template.show();
    } else if (type == 3) {
      if (type == 3) {
        // const fd = new FormData();

        let params = new HttpParams();
        if (this.uploadImage2 && !this.uploadImage1) {
          this.image = [];
          this.image.push(this.uploadImage2)
          // console.log(this.image)
          // fd.append('businessDocuments', this.image);
        } else if (this.uploadImage1 && !this.uploadImage2) {
          this.image = [];
          this.image.push(this.uploadImage1)
          // console.log(this.image)
          // fd.append('businessDocuments', this.image);
        } else if (this.uploadImage1 && this.uploadImage2) {
          this.image = [];
          this.image.push(this.uploadImage2)
          this.image.push(this.uploadImage1)
          // console.log(this.image)
          // fd.append('businessDocuments', this.image);
        }

        const data = {
          'userType': this.busineesForm.value.userType,
          'businessType': this.busineesForm.value.BusinessType,
          'businessName': this.busineesForm.value.BusinessName,
          'addressLine1': this.busineesForm.value.AddressLine1,
          'addressLine2': this.busineesForm.value.AddressLine2,
          'city': this.busineesForm.value.city,
          'state': this.busineesForm.value.state,
          'country': this.busineesForm.value.Country,
          'pinCode': this.busineesForm.value.pincode.toString(),
          'latitude': this.latitude.toString(),
          'longitude': this.longitude.toString(),
          'stateSortCode': this.mapStateshort,
          'businessDocuments': this.image,
          'code': this.busineesCode,
        };
        this.NS.updateForm(data, apiUrl._putBusinessInfo)
          .subscribe(async (res) => {
            localStorage.setItem('longitude', this.longitude.toString());
            localStorage.setItem('latitude', this.latitude.toString());
            localStorage.setItem('city', this.busineesForm.value.city);
            localStorage.setItem('state', this.busineesForm.value.state);
            this.dataSharingService.isUser.next(true);
            this.getuserdetails();
            this.reloadCurrentRoute();
            // this.modalRef = this.modalService.show(template);
          })
      }
    }
  }

  getuserdetails() {
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));
    this.NS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => {
        console.log(res.data)
        this.allData = res.data;
        this.feeCheck = this.allData.onetimeFee[0].status;
        if (localStorage.getItem("adminToken") != res.data.accessToken) {
          localStorage.setItem('adminToken', res.data.accessToken)
        }
        if (this.feeCheck == 0) {
          if (this.allData.registrationFeeVerified == false) {
            this.modalRef.hide();
            this.admin.showAlert('Bussiness Profile Added Successfully', '');
            setTimeout(() => {
              this.modalRef = this.modalService.show(this.paymentModal, { class: "modalLg" })
            }, 1000);
          } else {
            this.modalRef.hide();
            localStorage.setItem('BussinessVerified', 'true');
            this.reloadCurrentRoute();
          }

        } else {
          this.modalRef.hide();
          this.admin.showAlert('Bussiness Profile Added Successfully', '');
          localStorage.setItem('BussinessVerified', 'true');
          this.BussinessVerified = true;
          this.reloadCurrentRoute();
        }
      })
  }

  editbusiness() {

    this.uploadImage1 = '';
    this.uploadImage2 = '';
    let params = new HttpParams();
    params = params.append('accessToken', localStorage.getItem("adminToken"));
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());

    this.NS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => {
        localStorage.setItem('adminToken', res.data.accessToken);
        this.editData = res.data;
        console.log('edit data', this.editData);

        if (localStorage.getItem("adminToken") != res.data.accessToken) {
          localStorage.setItem('adminToken', res.data.accessToken)
        }
        this.busineesForm.patchValue({
          userType: this.editData.userType ? (this.editData.userType) : '',
          BusinessType: this.editData.businessType ? (this.editData.businessType) : '',
          BusinessName: this.editData.businessName ? (this.editData.businessName) : '',
          AddressLine1: this.editData.businessAddress.addressLine1 ? (this.editData.businessAddress.addressLine1) : '',
          AddressLine2: this.editData.businessAddress.addressLine2 ? (this.editData.businessAddress.addressLine2) : '',
          state: this.editData.businessAddress.state ? (this.editData.businessAddress.state) : '',
          Country: this.editData.businessAddress.country ? (this.editData.businessAddress.country) : '',
          city: this.editData.businessAddress.city ? (this.editData.businessAddress.city) : '',
          pincode: this.editData.businessAddress.pinCode ? (this.editData.businessAddress.pinCode) : '',
        })

        this.mapCity = this.editData.businessAddress.city ? (this.editData.businessAddress.city) : ''
        this.mapState = this.editData.businessAddress.state ? (this.editData.businessAddress.state) : ''
        this.mapcountry = this.editData.businessAddress.country ? (this.editData.businessAddress.country) : ''
        this.mapPostal = this.editData.businessAddress.pinCode ? (this.editData.businessAddress.pinCode) : '';
        this.latitude = this.editData.location ? (this.editData.location.coordinates[1]) : this.latitude;
        this.longitude = this.editData.location ? (this.editData.location.coordinates[0]) : this.longitude;
        this.mapStateshort=  this.editData.businessAddress.stateSortCode ? (this.editData.businessAddress.stateSortCode) : ''
       
        // let newAddress;
        // if (this.mapCity) {
        //   newAddress = this.mapCity + ', ' + this.mapState;
        //   this.busineesForm.patchValue({ 'googleAddress': newAddress });
        // } else {
        //   if(this.mapState){
        //     newAddress = this.mapState + ','
        //   }          
        //   let newAddress1 = newAddress + this.mapcountry
        //   this.busineesForm.patchValue({ 'googleAddress': newAddress1 });
        // }
        this.editDatabusiness(this.editData.userType);
        if (this.editData.businessDocuments.length != 0) {
          this.uploadImage2 = this.editData.businessDocuments[0];
          let fileType = this.editData.businessDocuments[0].split(".")[1];
          if (this.fileTypeChecker(fileType)) {
            this.showPdf = 0;
          } else {
            this.showPdf = 1;
          }
        }
        if (this.editData.businessDocuments.length == 2) {
          this.uploadImage1 = this.editData.businessDocuments[1];
          let fileType = this.editData.businessDocuments[1].split(".")[1];
          if (this.fileTypeChecker(fileType)) {
            this.showPdf1 = 0;
          } else {
            this.showPdf1 = 1;
          }
        }
      })
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  /*edit business*/
  editDatabusiness(data) {
    this.busineesTyps = [];
    this.busineesForm.value.userType = data;
    for (var i = 0; i < this.getuserlist.length; i++) {
      if (data == this.getuserlist[i].name) {
        this.busineesId = this.getuserlist[i].id;
      }
    }
    for (var j = 0; j < this.getbusineeslist.length; j++) {
      if (this.busineesId == this.getbusineeslist[j].userTypeId) {
        this.busineesTyps.push(this.getbusineeslist[j]);
      }
    }
  }

  fileTypeChecker(fileType) {
    let valid = [
      "tif", "tiff", "jpg", "jpeg", "gif", "png"
    ];

    if (valid.includes(fileType.toLowerCase())) {
      return true
    }
    return false;
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    // this.loading = false;
  }
  routherPath(path, template) {
    console.log(this.BussinessVerified)
    if (this.BussinessVerified == true || this.BussinessVerified == 'true') {
      console.log(path)
      this.router.navigate([path]);
    } else {

      this.modalRef = this.modalService.show(template);
    }
  }

  routherPath1(path) {
    console.log(path)
    this.router.navigate([path]);
  }
  Backopen(template) {
    this.modalRef.hide();
    this.modalRef = template.show();
  }
  cancel() {
    this.modalRef.hide();
    this.latitude = localStorage.getItem('latitude') ? (parseFloat(localStorage.getItem('latitude'))) : 30.7046486;
    this.longitude = localStorage.getItem('longitude') ? (parseFloat(localStorage.getItem('longitude'))) : 76.71787259999999;
  }


  upload1(event, num) {
    if (event.target.files && event.target.files.length && num == 1) {
      this.showLoaders = 1;
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          if (res.message == 'Success') {
            this.showLoaders = 0;
          } else {
            setTimeout(() => {
              this.showLoaders = 0;
            }, 50000);
          }
          this.uploadImage2 = res.data;
          const reader = new FileReader();

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          if (files[0].type == "image/tif" || files[0].type == "image/tiff" || files[0].type == "image/jpg" || files[0].type == "image/jpeg" || files[0].type == "image/gif" || files[0].type == "image/png") {
            this.showPdf = 0;
          } else {
            this.showPdf = 1;
          }
          reader.onload = (_event) => {
            this.urlfile2 = reader.result;

          }
        })
    } else if (event.target.files && event.target.files.length && num == 2) {
      this.showLoaders = 1;
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          if (res.message == 'Success') {
            this.showLoaders = 0;
          } else {
            setTimeout(() => {
              this.showLoaders = 0;
            }, 50000);
          }
          this.uploadImage1 = res.data;
          const reader = new FileReader();

          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          if (files[0].type == "image/tif" || files[0].type == "image/tiff" || files[0].type == "image/jpg" || files[0].type == "image/jpeg" || files[0].type == "image/gif" || files[0].type == "image/png") {
            this.showPdf1 = 0;
          } else {
            this.showPdf1 = 1;
          }
          reader.onload = (_event) => {
            this.urlfile1 = reader.result;
          }
        })
    }


  }
  /*remove image */
  removeImage(type) {
    if (type == 2) {
      if (this.image.length > 0) {
        this.image.splice(this.image.indexOf(this.uploadImage2), 1);
      }
      this.urlfile2 = '';
      this.uploadImage2 = '';
      this.showPdf = 0;
    } else {
      if (this.image.length > 0) {
        this.image.splice(this.image.indexOf(this.uploadImage1), 1);
      }
      this.urlfile1 = '';
      this.uploadImage1 = '';
      this.showPdf1 = 0;
    }

  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.geoCoder = new google.maps.Geocoder;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  /*google map */
  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.geoCoder = new google.maps.Geocoder;
    this.zoom = 16;
    this.getAddress(this.latitude, this.longitude)

  }

  getAddress(latitude, longitude) {   
    this.busineesForm.patchValue({ 'pincode': '', })
    this.mapPostal = "";
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(status);
      if (status === 'OK') {
        for (let i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types
          console.log(types);
          //plot no

          for (var j = 0; j < types.length; j++) {
            if (types.indexOf('subpremise') != -1) {
              this.mapPlot = results[0].address_components[i].long_name
              console.log(this.mapPlot);
            }
            //Sector
            if (types.indexOf('neighborhood') != -1) {
              this.mapSector = results[0].address_components[i].long_name
            }

            if (types.indexOf('sublocality_level_1') != -1) {
              this.mapLandmark = results[0].address_components[i].long_name
            }
            if (types.indexOf('locality') != -1) {
              this.mapLocality = results[0].address_components[i].long_name
            }
            //city
            if (types.indexOf('administrative_area_level_2') != -1) {
              this.mapCity = results[0].address_components[i].long_name,
                console.log(this.mapCity);
            }
            //state
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.mapState = results[0].address_components[i].long_name,
                this.mapStateshort = results[0].address_components[i].short_name
              console.log(this.mapState);
            }
            if (types.indexOf('country') != -1) {
              this.mapcountry = results[0].address_components[i].long_name
            }
            if (types.indexOf('postal_code') != -1) {
              this.mapPostal = results[0].address_components[i].long_name,
                console.log('23', this.mapPostal);
            }
          }
        }

        this.busineesForm.patchValue({
          'AddressLine1': results[0].formatted_address,
          // 'addressLine2': this.busineesForm.value.AddressLine2,
          'city': this.mapCity,
          'state': this.mapState,
          'Country': this.mapcountry,
          'pincode': this.mapPostal ? (this.mapPostal) : '',
        })

        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          let newAddress;
          if (this.mapCity) {
            newAddress = this.mapCity + ', ' + this.mapState;
            this.busineesForm.patchValue({ 'googleAddress': newAddress });
          } else {
            newAddress = this.mapState + ', ' + this.mapcountry;
            this.busineesForm.patchValue({ 'googleAddress': newAddress });
          }
          console.log(this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  /*amount pay and razerpay */
  payment() {
    const data = {
      'planId': this.allData.onetimeFee[0]._id,
      'planType': 'ONETIME',

    };
    this.NS.submitForm(data, apiUrl._postPayment)
      .subscribe(async (res) => {
        console.log('payment data', res.data)
        this.modalRef.hide();
        this.buyRazorPay(res.data);
        console.log(res.data.amount);
        this.feeAmount = res.data.amount;

      })
  }

  buyRazorPay(razorPaData) {

    const data = {
      "key": "rzp_test_i3r1BLKbRv61mu",
      "id": razorPaData.id,
      "entity": razorPaData.entity,
      "amount": razorPaData.amount,
      "amount_paid": razorPaData.amount_paid,
      "amount_due": razorPaData.amount_due,
      "currency": razorPaData.currency,
      "receipt": razorPaData.receipt,
      "status": razorPaData.status,
      "attempts": razorPaData.attempts,
      "notes": [],
      "created_at": razorPaData.reated_at,
      "prefill": {
        "email": "egiiyok@gmail.com",
        "contact": this.phoneNumber,
      },
      "theme": {
        "color": "#ae0019"
      },
      "handler": (response) => {
        const data = {
          "userId": this.userId,
          "registrationFeeVerified": true,
          "razorpayPaymentId": response.razorpay_payment_id,
          "razorpayOrderId": response.razorpay_order_id,
          "razorpaySignature": response.razorpay_signature,
          "amount": razorPaData.amount,
          "paymentType": "ONETIMEREGISTRATIONFEE",

        }

        this.NS.updateForm(data, apiUrl._ontimePayment)
          .subscribe(async (res) => {
            localStorage.setItem('BussinessVerified', 'true');
            this.BussinessVerified = true;
            this.reloadCurrentRoute()
            this.admin.showAlert('Payment Successfully Done', '');
            this.pointRefer();
          })

        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
      },
    }
    var rzp1 = new Razorpay(data);
    rzp1.on('payment.failed', function (data) {

      alert(data.error.metadata.order_id);
      alert(data.error.metadata.payment_id);
    });


    var rezp1 = new Razorpay(data)
    rezp1.open()
    console.log('opened');


  }
  /*Refer a Friend */
  pointRefer() {
    let params = new HttpParams();
    this.NS.getList(params, apiUrl._getPoints)
      .subscribe(async (res) => {
        console.log('points redeen', res.data)

      })
  }


  googlePinCode() {
    let pinCode = this.busineesForm.value.pincode;
   if (pinCode.toString().length != 6) {
     this.pinError = true;
     setTimeout(() => {
       this.pinError = false;
     }, 4000)
     return false
   }
   let googleKey = localStorage.getItem("g_id") 

   let params = new HttpParams();
   params = params.append('components', 'postal_code:' + pinCode);
   params = params.append('sensor', "false");
   params = params.append('key', "AIzaSyCtOnbQCsWkqBiVL_6Rk8d25TfhPPt-pBk");
   setTimeout(() => {
     this.admin.searchPinCode(params)
       .subscribe(res => {
         this.latitude= res.results[0].geometry.location.lat
         this.longitude=res.results[0].geometry.location.lng
         console.log('00000000000', res);
         localStorage.removeItem('mapToken')
         this.getAddress(this.latitude, this.longitude)
       },
         err => {
           localStorage.removeItem('mapToken');
         });
   }, 10);

 }
 keyPressNumbers(event) {
   var charCode = (event.which) ? event.which : event.keyCode;
   // Only Numbers 0-9
   if ((charCode < 48 || charCode > 57)) {
     event.preventDefault();
     return false;
   } else {
     return true;
   }
 }



}