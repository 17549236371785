<app-header></app-header>
<main class="main_cont profile_page_view privacy">

    <div class="container">
        <h1 class="">Privacy Policy</h1>
        <span>Updated at 2021-10-17</span>
        <p>EGIYOK (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by EGIYOK.</p>
        <p>This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application, EGIYOK. By accessing or using our Service, you signify that you have read, understood, and agree to our collection,
            storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</p>

        <h2 class="m-t-10">Definitions and key terms</h2>

        <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</p>
        <ul class="list">
            <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information</li>
            <li> Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to AllAGRIONLINE PRIVATE LIMITED,F-126/Balaji Tower, Phase 7, Industrial Area, Sahibzada Ajit Singh Nagar, Punjab 160055 that is responsible for your information
                under this Privacy Policy.</li>
            <li> Country: where EGIYOK or the owners/founders of EGIYOK are based, in this case is India</li>
            <li> Customer: refers to the company, organization or person that signs up to use the EGIYOK Service to manage the relationships with your consumers or service users.</li>
            <li> Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit EGIYOK and use the services.</li>
            <li> IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which
                a device is connecting to the Internet.</li>
            <li> Personnel: refers to those individuals who are employed by EGIYOK or are under contract to perform a service on behalf of one of the parties.</li>
            <li> Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
            <li> Service: refers to the service provided by EGIYOK as described in the relative terms (if available) and on this platform.</li>
            <li> Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>

            <li> Website: EGIYOK."’s" site, which can be accessed via this URL: <a href=""> www.egiyok.com</a></li>
            <li> You: a person or entity that is registered with EGIYOK to use the Services.</li>

        </ul>

        <h2 class="m-t-10">What Information Do We Collect?</h2>

        <p>We collect information from you when you visit our website/app, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
        <ul class="list">
            <li>Name / Username</li>
            <li> Phone Numbers</li>
            <li> Email Addresses</li>
            <li> Mailing Addresses</li>
            <li>Billing Addresses</li>
        </ul>
    </div>


</main>
<app-footer></app-footer>