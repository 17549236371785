import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSharingService {
    public isProfileChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoginChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public addressUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);   
    public busniess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
    public selectedLang = new BehaviorSubject(localStorage.getItem('language') ? localStorage.getItem('language'): 'EN');  
   
}