import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, ElementRef, ViewChildren, QueryList, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpParams } from '@angular/common/http';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { apiUrl } from '../../global/global';
import { ChatService } from '../service/chat.service';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { BusinessModalComponent } from './../../Common/business-modal/business-modal.component';

@Component({
  selector: 'app-submit-requirement',
  templateUrl: './submit-requirement.component.html',
  styleUrls: ['./submit-requirement.component.css']
})
export class SubmitRequirementComponent implements OnInit {
  userId: any;
  currentUserId: any;
  showError = false;
  subProductID: any;
  showState = false;
  subCategoryList: any;
  quantityUnitData: any = [];
  stateslist: any = [];
  allProduct: any = [];
  subProduct: any = [];
  quantityError = false;
  quantityError1 = false;
  public requirementForm: FormGroup;

  constructor(private modalService: BsModalService, private CS: ChatService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, public admin: JsonPlaceHolderService,) {
    this.requirementForm = this.formBuilder.group({
      product: ['', Validators.required],
      subProduct: ['', Validators.required],
      supplier: ['', Validators.required],
      quantity: ['', Validators.required],
      description: [''],
      quantityType: [''],
      state: ['']
    });
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('id');
    this.currentUserId = localStorage.getItem('id');
    this.getProductList();
    this.getappInitials();
  }


  //product ===
  getProductList() {
    let params = new HttpParams();
    // params = params.append('userId', this.userId);
    this.CS.getList(apiUrl._categoryList, params)
      .subscribe(res => {
        this.allProduct = res.data.categoryData;
        console.log(res.data, "allProduct");
      },
        err => {
        });
  }
  selectProduct(event) {
    this.subProductID = event.target.value;
    this.getSubCategories();
  }
  selectSupplier(event) {
    let value = event.target.value;
    if (value == 0) {
      this.showState = true;
      this.requirementForm.get('state').setValidators(Validators.required);
    } else {
      this.requirementForm.get('state').setValidators([]);
      this.showState = false;

    }
  }
  getSubCategories() {
    let params = new HttpParams();
    params = params.append('categoryId', this.subProductID);
    this.CS.getList(apiUrl._getSubCategories, params)
      .subscribe(res => {
        this.subProduct = res.data;
        console.log(this.subProduct, "this.subProduct==")
      },
        err => {
          console.log("error", err)

        });
  }
  selectSubProduct(event) {
    let selectedSubProduct = event.target.value;
    console.log(selectedSubProduct)
    for (let i = 0; i < this.subProduct.length; i++) {
      if (this.subProduct[i].subCategory._id == selectedSubProduct) {
        this.quantityUnitData = this.subProduct[i].subCategory.quantityUnitData
      }
    }
  }

  // getSubCategories() {
  //   let params = new HttpParams();
  //   params = params.append('categoryId', this.subProductID);
  //   this.CS.getList(apiUrl._getSubCategories, params)
  //     .subscribe(res => {
  //       this.subProduct = res.data;
  //       console.log(this.subProduct, "res.data ")
  //     },
  //       err => {
  //         console.log("error", err)

  //       });
  // }

  getappInitials() {
    let params = new HttpParams();
    this.CS.getList(apiUrl._getappInitials, params)
      .subscribe(async (res) => {
        this.stateslist = res.data.states;
      })
  }

  submitPost(form, business: BusinessModalComponent) {
    let quantityValue
    if (form.value.quantity < 1) {
      this.quantityError1 = true;
      setTimeout(() => {
        this.quantityError1 = false;
      }, 5000);
      return false
    }
    if (this.quantityUnitData.length > 0) {
      if (!form.value.quantityType) {
        this.quantityError = true;
        setTimeout(() => {
          this.quantityError = false;
        }, 5000);
        return false
      } else {
        quantityValue = form.value.quantity + ' ' + form.value.quantityType
      }
    } else {
      quantityValue = form.value.quantity
    }
    if (form.valid) {
      let BussinessVerified: any
      BussinessVerified = localStorage.getItem('BussinessVerified');
      if (BussinessVerified == false || BussinessVerified == 'false') {
        business.openBusinessModel();
        return false
      } else {
        const data = {
          'userId': this.userId,
          'categoryId': form.value.product,
          'subCategoryId': form.value.subProduct,
          'lookingSupplier': form.value.supplier,
          'stateName': form.value.state ? (form.value.state) : '',
          'quantity': quantityValue.toString(),
          'description': form.value.description,
        }
        console.log(data, "data")
        this.CS.submitForm(data, apiUrl._postRequirement,)
          .subscribe(async (res) => {
            console.log(res, "sign up============================");
            this.requirementForm.patchValue({
              'product': "",
              'subProduct': "",
              'supplier': "",
              'quantity': "",
              'description': "",
              'quantityType': "",
              'state': "",
            })
            this.admin.showAlert('', 'Your requirement has been submitted successfully');
          },
            err => {
              console.log(err, "errr");
              // this.CS.errorAlert(err.error.message, '');

            });
      }

    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }



}
