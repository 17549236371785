export var tokenFromUI = '0123456789123456';

export var DATE_REGEX = 'dd/MM/yyyy, hh:mm a';

export var MOBILE_REGEX = '^[0-9]*$';

export var MOBILE_REGEX_NEW = /^([a-zA-Z0-9 _-]+)$/;

export var MOBILE_REGEX_NEW_SPACE = '^[0-9 ]*$';

// export var NO_SPACE_REGEX = '[0-9a-zA-Z][0-9a-zA-Z ]+';

// export var FIRSTNAME_REGEX = '^[a-zA-Z ]*$';
export var FIRSTNAME_REGEX = /^([a-zA-Z0-9 _@$%./#&''"+-,]+)$/;

export var EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// export var SPACE_REGEX =  /^[a-zA-Z][a-zA-Z0-9. ]+$/;
export var SPACE_REGEX = /^[a-zA-Z0-9]{1}/;
export var Percentage_REGEX = /^([1-9]|[1-9][0-9]|100)$/;

export var PROPERTY_STATUS = [{
  'value': 'All Properties',
  'selected': true,
  index: 1
},{
  'value': 'Appraisal Booked',
  'selected': false,
  index: 2
}, {
  'value': 'Valued',
  'selected': false,
  index: 3
}, {
  'value': 'On The Market',
  'selected': false,
  index: 4
}, {
  'value': 'Under Offer',
  'selected': false,
  index: 5
}, {
  'value': 'Sold STC',
  'selected': false,
  index: 6
}, {
  'value': 'Exchanged',
  'selected': false,
  index: 7
}, {
  'value': 'Completions',
  'selected': false,
  index: 8
},  {
  'value': 'Awaiting Payment',
  'selected': false,
  index: 9
}, {
  'value': 'Archived',
  'selected': false,
  index: 10
}
];

export var PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,20}$/g;

export let URL_REGEX = /^(http?|ftp|https):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

export let UK_POSTAL_CODE = /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/ig;

export var LIMIT = 10;
export var LIMITS = 20;
export var MAX_SIZE = 3;

export var MAX_IMAGE_SIZE = 5000000;

export const USER_STATUS_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Active Users', selected: false }, { key: 2, value: 'Deactivated Users', selected: false }];

export const GENDER_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Male', selected: false },
{ key: 2, value: 'Female', selected: false }];

export const AGE_FILTER_FROM = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '10', selected: false },
{ key: 2, value: '20', selected: false }];

export const LIMIT_RECORD_LIST = [{ key: 1, value: 10, selected: true },
{ key: 2, value: 20, selected: false }, { key: 3, value: 50, selected: false }, { key: 4, value: 100, selected: false },
{ key: 5, value: 200, selected: false }];

export const PLAN_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Free', selected: false },
{ key: 2, value: 'Premium', selected: false }];

export const AGE_FILTER_TO = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '20', selected: false },
{ key: 2, value: '30', selected: false }];

export const USER_ACTION = [{ key: 1, value: 'Send Notification', selected: true },
{ key: 2, value: 'Email', selected: true }, { key: 3, value: 'Invite to Event', selected: true },
{ key: 4, value: 'Add To Ambassadors', selected: true }];

export const PROPERTY_TYPE = [{ key: 1, value: 'Apartment'}, 
{ key: 2, value: 'House' }, 
{ key: 3, value: 'Bungalow'}, { key: 4, value: 'Others'}];


export const PROPERTY_TYPE_VALUE = {
  "Apartment": [{
    key: 1, value: 'Apartment'
   }, {
   key: 2, value: 'Ground Floor Flat'
  }, { 
    key: 3, value: 'Flat'
  }, { 
    key: 4, value: 'Ground Floor Masionette'
  }, { 
    key: 5, value: 'Masionette'
  }, { 
    key: 6, value: 'Penthouse'
  }, { 
    key: 7, value: 'Studio Apartment'
  }, { 
    key: 8, value: 'Studio'
  }],
  "House": [{
    key: 1, value: 'Barn Conversion'
  }, {
    key: 2, value: 'Cottage'
  },{
    key: 3, value: 'Chalet'
  },{
    key: 4, value: 'Detached House'
  },{
    key: 5, value: 'Semi-Detached House'
  },{
    key: 6, value: 'Terraced House'
  },{
    key: 7, value: 'Link Detached'
  },{
    key: 8, value: 'Town House'
  },{
    key: 9, value: 'Villa'
  },{
    key: 10, value: 'Shared House'
  }, {
    key: 11, value: 'Mews'
  }],
  "Bungalow": [{
    key: 1, value: 'Detached Bungalow'
  },{
    key: 2, value: 'Semi-Detached Bungalow'
  }, {
    key: 3, value: 'Terraced Bungalow'
  }],
  "Others": [{
    key: 1, value: 'Building Plot/Land'
  }, {
    key: 2, value: 'Garage'
  }, {
    key: 3, value: 'House Boat'
  }, {
    key: 4, value: 'Mobile Home'
  }, {
    key: 5, value: 'Parking'
  }, {
    key: 6, value: 'Equestrain'
  },{
    key: 7, value: 'Unconverted Barn'
  }, {
    key: 8, value: 'Commercial'
  }]

};

export const GIFTER_MONEY = [{ key: 1, value: 'Parent'}, 
{ key: 2, value: 'Grandparent' }, 
{ key: 3, value: 'Brother'}, { key: 4, value: 'Sister'},{ key: 5, value: 'Friend'}];

export const ADD_PROPERTY_STATUS = [{ key: 1, value: 'Appraisal Booked'}, 
{ key: 2, value: 'Valued' }, 
{ key: 3, value: 'On The Market'}, { key: 4, value: 'Under Offer'}];

export const PROPERTY_AGE = [{ key: 1, value: 'New Build'}, 
{ key: 2, value: 'Modern' }, 
{ key: 3, value: '1980’s to 1990’s'}, { key: 4, value: '1960’s to 1970’s'}, 
{ key: 5, value: '1940’s to 1950’s'}, { key: 6, value: '1920’s to 1930’s'}, { key: 7, value: 'Edwardian (1901-1910)'},
{ key: 8, value: 'Victorian (1837-1901)'}, { key: 9, value: 'Georgian (1714-1837)'}, { key: 10, value: 'Pre 18th Century'}];

export const PROPERTY_TENURE = [{ key: 1, value: 'Freehold'}, 
{ key: 2, value: 'Leasehold' }, 
{ key: 3, value: 'Commonhold'}, { key: 4, value: 'Share Of Freehold'}, 
{ key: 5, value: 'Flying Freehold'}, { key: 6, value: 'Share Transfer'}, { key: 7, value: 'Unknown'}];

export const TITLE = [ 'Mr.', 'Mrs.', 'Dr.','Miss.','Ms.','Mx.', 'Other'];

export const NO_OF_BUYERS = [
{ key: 1, value: '1' }, 
{ key: 2, value: '2' }, 
{ key: 3, value: '3' }, 
{ key: 4, value: '3+'}];

export const PROPERTY_BUYER = [
    { key: 1, value: 'Partner' }, 
    { key: 2, value: 'Spouse' }, 
    { key: 3, value: 'Relative' }, 
    { key: 4, value: 'Friend'},
    { key: 5, value: 'Business partner' }
];

export const BUYER_POISTION = [{
  value: 'First time buyer'
}, {
  value: 'In rented'
}, { value: 'No chain' }, {
  value: 'Investor'
}, {
  value: 'Has a property to sell'
}];

export const PURCHASE_TYPE = [{
  value: 'Main residence'
}, {
  value: 'Additional residence'
}, {
  value: 'Buy to let'
}, {
  value: 'Investment'
}];

export const YEAR_LIVE_IN_UK = [{
  value: 'Less than 1 year'
},{
  value: '1 year'
},{
  value: '2 years'
},{
  value: '3 years'
},{
  value: '4 years'
}, {
  value: '5 years'
},{
  value: '6 years'
},{
  value: '7 years'
},{
  value: '8 years'
},{
  value: '9 years'
},{
  value: '10 years'
},{
  value: '10+ years'
}];

export let IDENTIFY_DOCS = [{
  value: 'Valid Passport (of full Machine Readable Zone)' },
  {
    value: 'Valid Photo Card Driving Licence'
  },
  {
    value: 'Valid Full UK Paper Driving Licence (non-photo) before 1998'
  },
  {
    value: 'Valid EU/EEA/Switzerland photo driving licence'
  },
  {
    value: 'Valid EU/EEA/Switzerland National Identity Card'
},{
  value: 'Firearms certificate or shotgun licence'
}];


export let SECOND_IDENTIFY_DOCS = [{
  value: 'Valid Photo Card Driving Licence' },
  {
    value: 'Valid Full UK Paper Driving Licence (non-photo) before 1998'
  },
  {
    value: 'Local Council Tax Bill (within last 12 months)'
  },
  {
    value: 'UK Bank/Building Society statement (within last 6 months)'
  },
  {
    value: 'UK Mortgage Statement (within last 12 months)'
},{
  value: 'Utility Bill (within last 6 months)'
},{
  value: 'HMRC Letter'
},{
  value: 'DWP Letter (Confirming pension details)'
}];

export const PROPERTY_LONG_WEEK =[{
  value: '1 Week'
},{
  value: '2 Weeks'
},{
  value: '3 Weeks'
},{
  value: '4 Weeks'
},{
  value: '5 Weeks'
},{
  value: '6 Weeks'
},{
  value: '7 Weeks'
},{
  value: '8 Weeks'
},{
  value: '8+ Weeks'
}];

export const PROPERTY_OFFER = [{
  value: 'Pending Offer Check',
  selected: true
}, {
  value: 'Make Revised Offer',
  selected: true
}, {
  value: 'With Vendor',
  selected: true
}, {
  value: 'Best and Final',
  selected: true
}, {
  value: 'Dependant Offer',
  selected: true
}, {
  value: 'With Applicant',
  selected: true
}, {
  value: 'Declined Offer',
  type: true
}, {
  value: 'Withdraw Offer',
  type: true
}];

export const CURRENCY_CODE = [
  { "cc": "AED", "symbol": "\u062f.\u0625;", "name": "UAE dirham" },
  { "cc": "AFN", "symbol": "Afs", "name": "Afghan afghani" },
  { "cc": "ALL", "symbol": "L", "name": "Albanian lek" },
  { "cc": "AMD", "symbol": "AMD", "name": "Armenian dram" },
  { "cc": "ANG", "symbol": "NA\u0192", "name": "Netherlands Antillean gulden" },
  { "cc": "AOA", "symbol": "Kz", "name": "Angolan kwanza" },
  { "cc": "ARS", "symbol": "$", "name": "Argentine peso" },
  { "cc": "AUD", "symbol": "$", "name": "Australian dollar" },
  { "cc": "AWG", "symbol": "\u0192", "name": "Aruban florin" },
  { "cc": "AZN", "symbol": "AZN", "name": "Azerbaijani manat" },
  { "cc": "BAM", "symbol": "KM", "name": "Bosnia and Herzegovina konvertibilna marka" },
  { "cc": "BBD", "symbol": "Bds$", "name": "Barbadian dollar" },
  { "cc": "BDT", "symbol": "\u09f3", "name": "Bangladeshi taka" },
  { "cc": "BGN", "symbol": "BGN", "name": "Bulgarian lev" },
  { "cc": "BHD", "symbol": ".\u062f.\u0628", "name": "Bahraini dinar" },
  { "cc": "BIF", "symbol": "FBu", "name": "Burundi franc" },
  { "cc": "BMD", "symbol": "BD$", "name": "Bermudian dollar" },
  { "cc": "BND", "symbol": "B$", "name": "Brunei dollar" },
  { "cc": "BOB", "symbol": "Bs.", "name": "Bolivian boliviano" },
  { "cc": "BRL", "symbol": "R$", "name": "Brazilian real" },
  { "cc": "BSD", "symbol": "B$", "name": "Bahamian dollar" },
  { "cc": "BTN", "symbol": "Nu.", "name": "Bhutanese ngultrum" },
  { "cc": "BWP", "symbol": "P", "name": "Botswana pula" },
  { "cc": "BYR", "symbol": "Br", "name": "Belarusian ruble" },
  { "cc": "BZD", "symbol": "BZ$", "name": "Belize dollar" },
  { "cc": "CAD", "symbol": "$", "name": "Canadian dollar" },
  { "cc": "CDF", "symbol": "F", "name": "Congolese franc" },
  { "cc": "CHF", "symbol": "Fr.", "name": "Swiss franc" },
  { "cc": "CLP", "symbol": "$", "name": "Chilean peso" },
  { "cc": "CNY", "symbol": "\u00a5", "name": "Chinese/Yuan renminbi" },
  { "cc": "COP", "symbol": "Col$", "name": "Colombian peso" },
  { "cc": "CRC", "symbol": "\u20a1", "name": "Costa Rican colon" },
  { "cc": "CUC", "symbol": "$", "name": "Cuban peso" },
  { "cc": "CVE", "symbol": "Esc", "name": "Cape Verdean escudo" },
  { "cc": "CZK", "symbol": "K\u010d", "name": "Czech koruna" },
  { "cc": "DJF", "symbol": "Fdj", "name": "Djiboutian franc" },
  { "cc": "DKK", "symbol": "Kr", "name": "Danish krone" },
  { "cc": "DOP", "symbol": "RD$", "name": "Dominican peso" },
  { "cc": "DZD", "symbol": "\u062f.\u062c", "name": "Algerian dinar" },
  { "cc": "EEK", "symbol": "KR", "name": "Estonian kroon" },
  { "cc": "EGP", "symbol": "\u00a3", "name": "Egyptian pound" },
  { "cc": "ERN", "symbol": "Nfa", "name": "Eritrean nakfa" },
  { "cc": "ETB", "symbol": "Br", "name": "Ethiopian birr" },
  { "cc": "EUR", "symbol": "\u20ac", "name": "European Euro" },
  { "cc": "FJD", "symbol": "FJ$", "name": "Fijian dollar" },
  { "cc": "FKP", "symbol": "\u00a3", "name": "Falkland Islands pound" },
  { "cc": "GBP", "symbol": "\u00a3", "name": "British pound" },
  { "cc": "GEL", "symbol": "GEL", "name": "Georgian lari" },
  { "cc": "GHS", "symbol": "GH\u20b5", "name": "Ghanaian cedi" },
  { "cc": "GIP", "symbol": "\u00a3", "name": "Gibraltar pound" },
  { "cc": "GMD", "symbol": "D", "name": "Gambian dalasi" },
  { "cc": "GNF", "symbol": "FG", "name": "Guinean franc" },
  { "cc": "GQE", "symbol": "CFA", "name": "Central African CFA franc" },
  { "cc": "GTQ", "symbol": "Q", "name": "Guatemalan quetzal" },
  { "cc": "GYD", "symbol": "GY$", "name": "Guyanese dollar" },
  { "cc": "HKD", "symbol": "HK$", "name": "Hong Kong dollar" },
  { "cc": "HNL", "symbol": "L", "name": "Honduran lempira" },
  { "cc": "HRK", "symbol": "kn", "name": "Croatian kuna" },
  { "cc": "HTG", "symbol": "G", "name": "Haitian gourde" },
  { "cc": "HUF", "symbol": "Ft", "name": "Hungarian forint" },
  { "cc": "IDR", "symbol": "Rp", "name": "Indonesian rupiah" },
  { "cc": "ILS", "symbol": "\u20aa", "name": "Israeli new sheqel" },
  { "cc": "INR", "symbol": "\u20B9", "name": "Indian rupee" },
  { "cc": "IQD", "symbol": "\u062f.\u0639", "name": "Iraqi dinar" },
  { "cc": "IRR", "symbol": "IRR", "name": "Iranian rial" },
  { "cc": "ISK", "symbol": "kr", "name": "Icelandic kr\u00f3na" },
  { "cc": "JMD", "symbol": "J$", "name": "Jamaican dollar" },
  { "cc": "JOD", "symbol": "JOD", "name": "Jordanian dinar" },
  { "cc": "JPY", "symbol": "\u00a5", "name": "Japanese yen" },
  { "cc": "KES", "symbol": "KSh", "name": "Kenyan shilling" },
  { "cc": "KGS", "symbol": "\u0441\u043e\u043c", "name": "Kyrgyzstani som" },
  { "cc": "KHR", "symbol": "\u17db", "name": "Cambodian riel" },
  { "cc": "KMF", "symbol": "KMF", "name": "Comorian franc" },
  { "cc": "KPW", "symbol": "W", "name": "North Korean won" },
  { "cc": "KRW", "symbol": "W", "name": "South Korean won" },
  { "cc": "KWD", "symbol": "KWD", "name": "Kuwaiti dinar" },
  { "cc": "KYD", "symbol": "KY$", "name": "Cayman Islands dollar" },
  { "cc": "KZT", "symbol": "T", "name": "Kazakhstani tenge" },
  { "cc": "LAK", "symbol": "KN", "name": "Lao kip" },
  { "cc": "LBP", "symbol": "\u00a3", "name": "Lebanese lira" },
  { "cc": "LKR", "symbol": "Rs", "name": "Sri Lankan rupee" },
  { "cc": "LRD", "symbol": "L$", "name": "Liberian dollar" },
  { "cc": "LSL", "symbol": "M", "name": "Lesotho loti" },
  { "cc": "LTL", "symbol": "Lt", "name": "Lithuanian litas" },
  { "cc": "LVL", "symbol": "Ls", "name": "Latvian lats" },
  { "cc": "LYD", "symbol": "LD", "name": "Libyan dinar" },
  { "cc": "MAD", "symbol": "MAD", "name": "Moroccan dirham" },
  { "cc": "MDL", "symbol": "MDL", "name": "Moldovan leu" },
  { "cc": "MGA", "symbol": "FMG", "name": "Malagasy ariary" },
  { "cc": "MKD", "symbol": "MKD", "name": "Macedonian denar" },
  { "cc": "MMK", "symbol": "K", "name": "Myanma kyat" },
  { "cc": "MNT", "symbol": "\u20ae", "name": "Mongolian tugrik" },
  { "cc": "MOP", "symbol": "P", "name": "Macanese pataca" },
  { "cc": "MRO", "symbol": "UM", "name": "Mauritanian ouguiya" },
  { "cc": "MUR", "symbol": "Rs", "name": "Mauritian rupee" },
  { "cc": "MVR", "symbol": "Rf", "name": "Maldivian rufiyaa" },
  { "cc": "MWK", "symbol": "MK", "name": "Malawian kwacha" },
  { "cc": "MXN", "symbol": "$", "name": "Mexican peso" },
  { "cc": "MYR", "symbol": "RM", "name": "Malaysian ringgit" },
  { "cc": "MZM", "symbol": "MTn", "name": "Mozambican metical" },
  { "cc": "NAD", "symbol": "N$", "name": "Namibian dollar" },
  { "cc": "NGN", "symbol": "\u20a6", "name": "Nigerian naira" },
  { "cc": "NIO", "symbol": "C$", "name": "Nicaraguan c\u00f3rdoba" },
  { "cc": "NOK", "symbol": "kr", "name": "Norwegian krone" },
  { "cc": "NPR", "symbol": "NRs", "name": "Nepalese rupee" },
  { "cc": "NZD", "symbol": "NZ$", "name": "New Zealand dollar" },
  { "cc": "OMR", "symbol": "OMR", "name": "Omani rial" },
  { "cc": "PAB", "symbol": "B./", "name": "Panamanian balboa" },
  { "cc": "PEN", "symbol": "S/.", "name": "Peruvian nuevo sol" },
  { "cc": "PGK", "symbol": "K", "name": "Papua New Guinean kina" },
  { "cc": "PHP", "symbol": "\u20b1", "name": "Philippine peso" },
  { "cc": "PKR", "symbol": "Rs.", "name": "Pakistani rupee" },
  { "cc": "PLN", "symbol": "z\u0142", "name": "Polish zloty" },
  { "cc": "PYG", "symbol": "\u20b2", "name": "Paraguayan guarani" },
  { "cc": "QAR", "symbol": "QR", "name": "Qatari riyal" },
  { "cc": "RON", "symbol": "L", "name": "Romanian leu" },
  { "cc": "RSD", "symbol": "din.", "name": "Serbian dinar" },
  { "cc": "RUB", "symbol": "R", "name": "Russian ruble" },
  { "cc": "SAR", "symbol": "SR", "name": "Saudi riyal" },
  { "cc": "SBD", "symbol": "SI$", "name": "Solomon Islands dollar" },
  { "cc": "SCR", "symbol": "SR", "name": "Seychellois rupee" },
  { "cc": "SDG", "symbol": "SDG", "name": "Sudanese pound" },
  { "cc": "SEK", "symbol": "kr", "name": "Swedish krona" },
  { "cc": "SGD", "symbol": "S$", "name": "Singapore dollar" },
  { "cc": "SHP", "symbol": "\u00a3", "name": "Saint Helena pound" },
  { "cc": "SLL", "symbol": "Le", "name": "Sierra Leonean leone" },
  { "cc": "SOS", "symbol": "Sh.", "name": "Somali shilling" },
  { "cc": "SRD", "symbol": "$", "name": "Surinamese dollar" },
  { "cc": "SYP", "symbol": "LS", "name": "Syrian pound" },
  { "cc": "SZL", "symbol": "E", "name": "Swazi lilangeni" },
  { "cc": "THB", "symbol": "\u0e3f", "name": "Thai baht" },
  { "cc": "TJS", "symbol": "TJS", "name": "Tajikistani somoni" },
  { "cc": "TMT", "symbol": "m", "name": "Turkmen manat" },
  { "cc": "TND", "symbol": "DT", "name": "Tunisian dinar" },
  { "cc": "TRY", "symbol": "TRY", "name": "Turkish new lira" },
  { "cc": "TTD", "symbol": "TT$", "name": "Trinidad and Tobago dollar" },
  { "cc": "TWD", "symbol": "NT$", "name": "New Taiwan dollar" },
  { "cc": "TZS", "symbol": "TZS", "name": "Tanzanian shilling" },
  { "cc": "UAH", "symbol": "UAH", "name": "Ukrainian hryvnia" },
  { "cc": "UGX", "symbol": "USh", "name": "Ugandan shilling" },
  { "cc": "USD", "symbol": "US$", "name": "United States dollar" },
  { "cc": "UYU", "symbol": "$U", "name": "Uruguayan peso" },
  { "cc": "UZS", "symbol": "UZS", "name": "Uzbekistani som" },
  { "cc": "VEB", "symbol": "Bs", "name": "Venezuelan bolivar" },
  { "cc": "VND", "symbol": "\u20ab", "name": "Vietnamese dong" },
  { "cc": "VUV", "symbol": "VT", "name": "Vanuatu vatu" },
  { "cc": "WST", "symbol": "WS$", "name": "Samoan tala" },
  { "cc": "XAF", "symbol": "CFA", "name": "Central African CFA franc" },
  { "cc": "XCD", "symbol": "EC$", "name": "East Caribbean dollar" },
  { "cc": "XDR", "symbol": "SDR", "name": "Special Drawing Rights" },
  { "cc": "XOF", "symbol": "CFA", "name": "West African CFA franc" },
  { "cc": "XPF", "symbol": "F", "name": "CFP franc" },
  { "cc": "YER", "symbol": "YER", "name": "Yemeni rial" },
  { "cc": "ZAR", "symbol": "R", "name": "South African rand" },
  { "cc": "ZMK", "symbol": "ZK", "name": "Zambian kwacha" },
  { "cc": "ZWR", "symbol": "Z$", "name": "Zimbabwean dollar" }
];
export const TIME_ZONE = [
  {
    "value": "Dateline Standard Time",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "text": "(UTC-12:00) International Date Line West",
    "utc": [
      "Etc/GMT+12"
    ]
  },
  {
    "value": "UTC-11",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "text": "(UTC-11:00) Coordinated Universal Time-11",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  {
    "value": "Hawaiian Standard Time",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "text": "(UTC-10:00) Hawaii",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    "value": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "text": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "value": "Pacific Standard Time (Mexico)",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-08:00) Baja California",
    "utc": [
      "America/Santa_Isabel"
    ]
  },
  {
    "value": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-07:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse"
    ]
  },
  {
    "value": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(UTC-08:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "PST8PDT"
    ]
  },
  {
    "value": "US Mountain Standard Time",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "text": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "Etc/GMT+7"
    ]
  },
  {
    "value": "Mountain Standard Time (Mexico)",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "utc": [
      "America/Chihuahua",
      "America/Mazatlan"
    ]
  },
  {
    "value": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "value": "Central America Standard Time",
    "abbr": "CAST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "value": "Central Standard Time (Mexico)",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "utc": [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ]
  },
  {
    "value": "Canada Central Standard Time",
    "abbr": "CCST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Saskatchewan",
    "utc": [
      "America/Regina",
      "America/Swift_Current"
    ]
  },
  {
    "value": "SA Pacific Standard Time",
    "abbr": "SPST",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Bogota, Lima, Quito",
    "utc": [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ]
  },
  {
    "value": "Eastern Standard Time",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT"
    ]
  },
  {
    "value": "US Eastern Standard Time",
    "abbr": "UEDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Indiana (East)",
    "utc": [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ]
  },
  {
    "value": "Venezuela Standard Time",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "text": "(UTC-04:30) Caracas",
    "utc": [
      "America/Caracas"
    ]
  },
  {
    "value": "Paraguay Standard Time",
    "abbr": "PYT",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Asuncion",
    "utc": [
      "America/Asuncion"
    ]
  },
  {
    "value": "Atlantic Standard Time",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "value": "Central Brazilian Standard Time",
    "abbr": "CBST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Cuiaba",
    "utc": [
      "America/Campo_Grande",
      "America/Cuiaba"
    ]
  },
  {
    "value": "SA Western Standard Time",
    "abbr": "SWST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "utc": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ]
  },
  {
    "value": "Pacific SA Standard Time",
    "abbr": "PSST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Santiago",
    "utc": [
      "America/Santiago",
      "Antarctica/Palmer"
    ]
  },
  {
    "value": "Newfoundland Standard Time",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "text": "(UTC-03:30) Newfoundland",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "value": "E. South America Standard Time",
    "abbr": "ESAST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Brasilia",
    "utc": [
      "America/Sao_Paulo"
    ]
  },
  {
    "value": "Argentina Standard Time",
    "abbr": "AST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Buenos Aires",
    "utc": [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    "value": "SA Eastern Standard Time",
    "abbr": "SEST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Cayenne, Fortaleza",
    "utc": [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ]
  },
  {
    "value": "Greenland Standard Time",
    "abbr": "GDT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-03:00) Greenland",
    "utc": [
      "America/Godthab"
    ]
  },
  {
    "value": "Montevideo Standard Time",
    "abbr": "MST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Montevideo",
    "utc": [
      "America/Montevideo"
    ]
  },
  {
    "value": "Bahia Standard Time",
    "abbr": "BST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Salvador",
    "utc": [
      "America/Bahia"
    ]
  },
  {
    "value": "UTC-02",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "text": "(UTC-02:00) Coordinated Universal Time-02",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ]
  },
  {
    "value": "Mid-Atlantic Standard Time",
    "abbr": "MDT",
    "offset": -1,
    "isdst": true,
    "text": "(UTC-02:00) Mid-Atlantic - Old",
    "utc": []
  },
  {
    "value": "Azores Standard Time",
    "abbr": "ADT",
    "offset": 0,
    "isdst": true,
    "text": "(UTC-01:00) Azores",
    "utc": [
      "America/Scoresbysund",
      "Atlantic/Azores"
    ]
  },
  {
    "value": "Cape Verde Standard Time",
    "abbr": "CVST",
    "offset": -1,
    "isdst": false,
    "text": "(UTC-01:00) Cape Verde Is.",
    "utc": [
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ]
  },
  {
    "value": "Morocco Standard Time",
    "abbr": "MDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Casablanca",
    "utc": [
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ]
  },
  {
    "value": "UTC",
    "abbr": "UTC",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Coordinated Universal Time",
    "utc": [
      "America/Danmarkshavn",
      "Etc/GMT"
    ]
  },
  {
    "value": "Greenwich Mean Time",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "British Summer Time",
    "abbr": "BST",
    "offset": 1,
    "isdst": true,
    "text": "(UTC+01:00) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "GMT Standard Time",
    "abbr": "GDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Dublin, Lisbon",
    "utc": [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon"
    ]
  },
  {
    "value": "Greenwich Standard Time",
    "abbr": "GST",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Monrovia, Reykjavik",
    "utc": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ]
  },
  {
    "value": "W. Europe Standard Time",
    "abbr": "WEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "utc": [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ]
  },
  {
    "value": "Central Europe Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "utc": [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ]
  },
  {
    "value": "Romance Standard Time",
    "abbr": "RDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "utc": [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ]
  },
  {
    "value": "Central European Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "utc": [
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Warsaw",
      "Europe/Zagreb"
    ]
  },
  {
    "value": "W. Central Africa Standard Time",
    "abbr": "WCAST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) West Central Africa",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    "value": "Namibia Standard Time",
    "abbr": "NST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) Windhoek",
    "utc": [
      "Africa/Windhoek"
    ]
  },
  {
    "value": "GTB Standard Time",
    "abbr": "GDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Athens, Bucharest",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ]
  },
  {
    "value": "Middle East Standard Time",
    "abbr": "MEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Beirut",
    "utc": [
      "Asia/Beirut"
    ]
  },
  {
    "value": "Egypt Standard Time",
    "abbr": "EST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Cairo",
    "utc": [
      "Africa/Cairo"
    ]
  },
  {
    "value": "Syria Standard Time",
    "abbr": "SDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Damascus",
    "utc": [
      "Asia/Damascus"
    ]
  },
  {
    "value": "E. Europe Standard Time",
    "abbr": "EEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) E. Europe",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"

    ]
  },
  {
    "value": "South Africa Standard Time",
    "abbr": "SAST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Harare, Pretoria",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ]
  },
  {
    "value": "FLE Standard Time",
    "abbr": "FDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "utc": [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ]
  },
  {
    "value": "Turkey Standard Time",
    "abbr": "TDT",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Istanbul",
    "utc": [
      "Europe/Istanbul"
    ]
  },
  {
    "value": "Israel Standard Time",
    "abbr": "JDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Jerusalem",
    "utc": [
      "Asia/Jerusalem"
    ]
  },
  {
    "value": "Libya Standard Time",
    "abbr": "LST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Tripoli",
    "utc": [
      "Africa/Tripoli"
    ]
  },
  {
    "value": "Jordan Standard Time",
    "abbr": "JST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Amman",
    "utc": [
      "Asia/Amman"
    ]
  },
  {
    "value": "Arabic Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Baghdad",
    "utc": [
      "Asia/Baghdad"
    ]
  },
  {
    "value": "Kaliningrad Standard Time",
    "abbr": "KST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kaliningrad, Minsk",
    "utc": [
      "Europe/Kaliningrad",
      "Europe/Minsk"
    ]
  },
  {
    "value": "Arab Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kuwait, Riyadh",
    "utc": [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ]
  },
  {
    "value": "E. Africa Standard Time",
    "abbr": "EAST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Nairobi",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ]
  },
  {
    "value": "Moscow Standard Time",
    "abbr": "MSK",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
    "utc": [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd"
    ]
  },
  {
    "value": "Samara Time",
    "abbr": "SAMT",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": [
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Ulyanovsk"
    ]
  },
  {
    "value": "Iran Standard Time",
    "abbr": "IDT",
    "offset": 4.5,
    "isdst": true,
    "text": "(UTC+03:30) Tehran",
    "utc": [
      "Asia/Tehran"
    ]
  },
  {
    "value": "Arabian Standard Time",
    "abbr": "AST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Abu Dhabi, Muscat",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "value": "Azerbaijan Standard Time",
    "abbr": "ADT",
    "offset": 5,
    "isdst": true,
    "text": "(UTC+04:00) Baku",
    "utc": [
      "Asia/Baku"
    ]
  },
  {
    "value": "Mauritius Standard Time",
    "abbr": "MST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Port Louis",
    "utc": [
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ]
  },
  {
    "value": "Georgian Standard Time",
    "abbr": "GST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Tbilisi",
    "utc": [
      "Asia/Tbilisi"
    ]
  },
  {
    "value": "Caucasus Standard Time",
    "abbr": "CST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Yerevan",
    "utc": [
      "Asia/Yerevan"
    ]
  },
  {
    "value": "Afghanistan Standard Time",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "text": "(UTC+04:30) Kabul",
    "utc": [
      "Asia/Kabul"
    ]
  },
  {
    "value": "West Asia Standard Time",
    "abbr": "WAST",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Ashgabat, Tashkent",
    "utc": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ]
  },
  {
    "value": "Pakistan Standard Time",
    "abbr": "PKT",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Islamabad, Karachi",
    "utc": [
      "Asia/Karachi"
    ]
  },
  {
    "value": "India Standard Time",
    "abbr": "IST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": [
      "Asia/Kolkata"
    ]
  },
  {
    "value": "Sri Lanka Standard Time",
    "abbr": "SLST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Sri Jayawardenepura",
    "utc": [
      "Asia/Colombo"
    ]
  },
  {
    "value": "Nepal Standard Time",
    "abbr": "NST",
    "offset": 5.75,
    "isdst": false,
    "text": "(UTC+05:45) Kathmandu",
    "utc": [
      "Asia/Katmandu"
    ]
  },
  {
    "value": "Central Asia Standard Time",
    "abbr": "CAST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Astana",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    "value": "Bangladesh Standard Time",
    "abbr": "BST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Dhaka",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "value": "Ekaterinburg Standard Time",
    "abbr": "EST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Ekaterinburg",
    "utc": [
      "Asia/Yekaterinburg"
    ]
  },
  {
    "value": "Myanmar Standard Time",
    "abbr": "MST",
    "offset": 6.5,
    "isdst": false,
    "text": "(UTC+06:30) Yangon (Rangoon)",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "value": "SE Asia Standard Time",
    "abbr": "SAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "utc": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ]
  },
  {
    "value": "N. Central Asia Standard Time",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Novosibirsk",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ]
  },
  {
    "value": "China Standard Time",
    "abbr": "CST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "utc": [
      "Asia/Hong_Kong",
      "Asia/Macau",
      "Asia/Shanghai"
    ]
  },
  {
    "value": "North Asia Standard Time",
    "abbr": "NAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Krasnoyarsk",
    "utc": [
      "Asia/Krasnoyarsk"
    ]
  },
  {
    "value": "Singapore Standard Time",
    "abbr": "MPST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Kuala Lumpur, Singapore",
    "utc": [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ]
  },
  {
    "value": "W. Australia Standard Time",
    "abbr": "WAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Perth",
    "utc": [
      "Antarctica/Casey",
      "Australia/Perth"
    ]
  },
  {
    "value": "Taipei Standard Time",
    "abbr": "TST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Taipei",
    "utc": [
      "Asia/Taipei"
    ]
  },
  {
    "value": "Ulaanbaatar Standard Time",
    "abbr": "UST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Ulaanbaatar",
    "utc": [
      "Asia/Choibalsan",
      "Asia/Ulaanbaatar"
    ]
  },
  {
    "value": "North Asia East Standard Time",
    "abbr": "NAEST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Irkutsk",
    "utc": [
      "Asia/Irkutsk"
    ]
  },
  {
    "value": "Japan Standard Time",
    "abbr": "JST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ]
  },
  {
    "value": "Korea Standard Time",
    "abbr": "KST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Seoul",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ]
  },
  {
    "value": "Cen. Australia Standard Time",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Adelaide",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "value": "AUS Central Standard Time",
    "abbr": "ACST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Darwin",
    "utc": [
      "Australia/Darwin"
    ]
  },
  {
    "value": "E. Australia Standard Time",
    "abbr": "EAST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Brisbane",
    "utc": [
      "Australia/Brisbane",
      "Australia/Lindeman"
    ]
  },
  {
    "value": "AUS Eastern Standard Time",
    "abbr": "AEST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": [
      "Australia/Melbourne",
      "Australia/Sydney"
    ]
  },
  {
    "value": "West Pacific Standard Time",
    "abbr": "WPST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Guam, Port Moresby",
    "utc": [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ]
  },
  {
    "value": "Tasmania Standard Time",
    "abbr": "TST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Hobart",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ]
  },
  {
    "value": "Yakutsk Standard Time",
    "abbr": "YST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Yakutsk",
    "utc": [
      "Asia/Chita",
      "Asia/Khandyga",
      "Asia/Yakutsk"
    ]
  },
  {
    "value": "Central Pacific Standard Time",
    "abbr": "CPST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Solomon Is., New Caledonia",
    "utc": [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ]
  },
  {
    "value": "Vladivostok Standard Time",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Vladivostok",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ]
  },
  {
    "value": "New Zealand Standard Time",
    "abbr": "NZST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Auckland, Wellington",
    "utc": [
      "Antarctica/McMurdo",
      "Pacific/Auckland"
    ]
  },
  {
    "value": "UTC+12",
    "abbr": "U",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Coordinated Universal Time+12",
    "utc": [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "value": "Fiji Standard Time",
    "abbr": "FST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Fiji",
    "utc": [
      "Pacific/Fiji"
    ]
  },
  {
    "value": "Magadan Standard Time",
    "abbr": "MST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Magadan",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "value": "Kamchatka Standard Time",
    "abbr": "KDT",
    "offset": 13,
    "isdst": true,
    "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "utc": [
      "Asia/Kamchatka"
    ]
  },
  {
    "value": "Tonga Standard Time",
    "abbr": "TST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Nuku'alofa",
    "utc": [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ]
  },
  {
    "value": "Samoa Standard Time",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Samoa",
    "utc": [
      "Pacific/Apia"
    ]
  }
];

export let COUNTRY_LIST = [
  {
  "name": "Afghanistan",
  "dial_code": "+93",
  "code": "AF"
  },
  {
  "name": "Aland Islands",
  "dial_code": "+358",
  "code": "AX"
  },
  {
  "name": "Albania",
  "dial_code": "+355",
  "code": "AL"
  },
  {
  "name": "Algeria",
  "dial_code": "+213",
  "code": "DZ"
  },
  {
  "name": "AmericanSamoa",
  "dial_code": "+1684",
  "code": "AS"
  },
  {
  "name": "Andorra",
  "dial_code": "+376",
  "code": "AD"
  },
  {
  "name": "Angola",
  "dial_code": "+244",
  "code": "AO"
  },
  {
  "name": "Anguilla",
  "dial_code": "+1264",
  "code": "AI"
  },
  {
  "name": "Antarctica",
  "dial_code": "+672",
  "code": "AQ"
  },
  {
  "name": "Antigua and Barbuda",
  "dial_code": "+1268",
  "code": "AG"
  },
  {
  "name": "Argentina",
  "dial_code": "+54",
  "code": "AR"
  },
  {
  "name": "Armenia",
  "dial_code": "+374",
  "code": "AM"
  },
  {
  "name": "Aruba",
  "dial_code": "+297",
  "code": "AW"
  },
  {
  "name": "Australia",
  "dial_code": "+61",
  "code": "AU"
  },
  {
  "name": "Austria",
  "dial_code": "+43",
  "code": "AT"
  },
  {
  "name": "Azerbaijan",
  "dial_code": "+994",
  "code": "AZ"
  },
  {
  "name": "The Bahamas",
  "dial_code": "+1242",
  "code": "BS"
  },
  {
  "name": "Bahrain",
  "dial_code": "+973",
  "code": "BH"
  },
  {
  "name": "Bangladesh",
  "dial_code": "+880",
  "code": "BD"
  },
  {
  "name": "Barbados",
  "dial_code": "+1246",
  "code": "BB"
  },
  {
  "name": "Belarus",
  "dial_code": "+375",
  "code": "BY"
  },
  {
  "name": "Belgium",
  "dial_code": "+32",
  "code": "BE"
  },
  {
  "name": "Belize",
  "dial_code": "+501",
  "code": "BZ"
  },
  {
  "name": "Benin",
  "dial_code": "+229",
  "code": "BJ"
  },
  {
  "name": "Bermuda",
  "dial_code": "+1441",
  "code": "BM"
  },
  {
  "name": "Bhutan",
  "dial_code": "+975",
  "code": "BT"
  },
  {
  "name": "Bolivia, Plurinational State of",
  "dial_code": "+591",
  "code": "BO"
  },
  {
  "name": "Bosnia and Herzegovina",
  "dial_code": "+387",
  "code": "BA"
  },
  {
  "name": "Botswana",
  "dial_code": "+267",
  "code": "BW"
  },
  {
  "name": "Brazil",
  "dial_code": "+55",
  "code": "BR"
  },
  {
  "name": "British Indian Ocean Territory",
  "dial_code": "+246",
  "code": "IO"
  },
  {
  "name": "Brunei Darussalam",
  "dial_code": "+673",
  "code": "BN"
  },
  {
  "name": "Bulgaria",
  "dial_code": "+359",
  "code": "BG"
  },
  {
  "name": "Burkina Faso",
  "dial_code": "+226",
  "code": "BF"
  },
  {
  "name": "Burma",
  "dial_code": "+95",
  "code": "MM"
  },
  {
  "name": "Burundi",
  "dial_code": "+257",
  "code": "BI"
  },
  {
  "name": "Cambodia",
  "dial_code": "+855",
  "code": "KH"
  },
  {
  "name": "Cameroon",
  "dial_code": "+237",
  "code": "CM"
  },
  {
  "name": "Canada",
  "dial_code": "+1",
  "code": "CA"
  },
  {
  "name": "Cape Verde",
  "dial_code": "+238",
  "code": "CV"
  },
  {
  "name": "Cayman Islands",
  "dial_code": "+345",
  "code": "KY"
  },
  {
  "name": "Central African Republic",
  "dial_code": "+236",
  "code": "CF"
  },
  {
  "name": "Chad",
  "dial_code": "+235",
  "code": "TD"
  },
  {
  "name": "Chile",
  "dial_code": "+56",
  "code": "CL"
  },
  {
  "name": "China",
  "dial_code": "+86",
  "code": "CN"
  },
  {
  "name": "Christmas Island",
  "dial_code": "+61",
  "code": "CX"
  },
  {
  "name": "Cocos (Keeling) Islands",
  "dial_code": "+61",
  "code": "CC"
  },
  {
  "name": "Colombia",
  "dial_code": "+57",
  "code": "CO"
  },
  {
  "name": "Comoros",
  "dial_code": "+269",
  "code": "KM"
  },
  {
  "name": "Republic of the Congo",
  "dial_code": "+242",
  "code": "CG"
  },
  {
  "name": "Congo, The Democratic Republic of the Congo",
  "dial_code": "+243",
  "code": "CD"
  },
  {
  "name": "Cook Islands",
  "dial_code": "+682",
  "code": "CK"
  },
  {
  "name": "Costa Rica",
  "dial_code": "+506",
  "code": "CR"
  },
  {
  "name": "Cote d'Ivoire",
  "dial_code": "+225",
  "code": "CI"
  },
  {
  "name": "Croatia",
  "dial_code": "+385",
  "code": "HR"
  },
  {
  "name": "Cuba",
  "dial_code": "+53",
  "code": "CU"
  },
  {
  "name": "Cyprus",
  "dial_code": "+357",
  "code": "CY"
  },
  {
  "name": "Czech Republic",
  "dial_code": "+420",
  "code": "CZ"
  },
  {
  "name": "Denmark",
  "dial_code": "+45",
  "code": "DK"
  },
  {
  "name": "Djibouti",
  "dial_code": "+253",
  "code": "DJ"
  },
  {
  "name": "Dominica",
  "dial_code": "+1767",
  "code": "DM"
  },
  {
  "name": "Dominican Republic",
  "dial_code": "+1849",
  "code": "DO"
  },
  {
  "name": "East Timor",
  "dial_code": "+670",
  "code": "EAT"
  },
  {
  "name": "Ecuador",
  "dial_code": "+593",
  "code": "EC"
  },
  {
  "name": "Egypt",
  "dial_code": "+20",
  "code": "EG"
  },
  {
  "name": "El Salvador",
  "dial_code": "+503",
  "code": "SV"
  },
  {
  "name": "Equatorial Guinea",
  "dial_code": "+240",
  "code": "GQ"
  },
  {
  "name": "Eritrea",
  "dial_code": "+291",
  "code": "ER"
  },
  {
  "name": "Estonia",
  "dial_code": "+372",
  "code": "EE"
  },
  {
  "name": "Ethiopia",
  "dial_code": "+251",
  "code": "ET"
  },
  {
  "name": "Falkland Islands (Malvinas)",
  "dial_code": "+500",
  "code": "FK"
  },
  {
  "name": "Faroe Islands",
  "dial_code": "+298",
  "code": "FO"
  },
  {
  "name": "Fiji",
  "dial_code": "+679",
  "code": "FJ"
  },
  {
  "name": "Finland",
  "dial_code": "+358",
  "code": "FI"
  },
  {
  "name": "France",
  "dial_code": "+33",
  "code": "FR"
  },
  {
  "name": "French Guiana",
  "dial_code": "+594",
  "code": "GF"
  },
  {
  "name": "French Polynesia",
  "dial_code": "+689",
  "code": "PF"
  },
  {
  "name": "Gabon",
  "dial_code": "+241",
  "code": "GA"
  },
  {
  "name": "The Gambia",
  "dial_code": "+220",
  "code": "GM"
  },
  {
  "name": "Gaza Strip",
  "dial_code": "+970",
  "code": "G"
  },
  {
  "name": "Georgia",
  "dial_code": "+995",
  "code": "GE"
  },
  {
  "name": "Germany",
  "dial_code": "+49",
  "code": "DE"
  },
  {
  "name": "Ghana",
  "dial_code": "+233",
  "code": "GH"
  },
  {
  "name": "Gibraltar",
  "dial_code": "+350",
  "code": "GI"
  },
  {
  "name": "Greece",
  "dial_code": "+30",
  "code": "GR"
  },
  {
  "name": "Greenland",
  "dial_code": "+299",
  "code": "GL"
  },
  {
  "name": "Grenada",
  "dial_code": "+1473",
  "code": "GD"
  },
  {
  "name": "Guadeloupe",
  "dial_code": "+590",
  "code": "GP"
  },
  {
  "name": "Guam",
  "dial_code": "+1671",
  "code": "GU"
  },
  {
  "name": "Guatemala",
  "dial_code": "+502",
  "code": "GT"
  },
  {
  "name": "Guernsey",
  "dial_code": "+44",
  "code": "GG"
  },
  {
  "name": "Guinea",
  "dial_code": "+224",
  "code": "GN"
  },
  {
  "name": "Guinea-Bissau",
  "dial_code": "+245",
  "code": "GW"
  },
  {
  "name": "Guyana",
  "dial_code": "+592",
  "code": "GY"
  },
  {
  "name": "Haiti",
  "dial_code": "+509",
  "code": "HT"
  },
  {
  "name": "Holy See (Vatican City State)",
  "dial_code": "+379",
  "code": "VA"
  },
  {
  "name": "Honduras",
  "dial_code": "+504",
  "code": "HN"
  },
  {
  "name": "Hong Kong",
  "dial_code": "+852",
  "code": "HK"
  },
  {
  "name": "Hungary",
  "dial_code": "+36",
  "code": "HU"
  },
  {
  "name": "Iceland",
  "dial_code": "+354",
  "code": "IS"
  },
  {
  "name": "India",
  "dial_code": "+91",
  "code": "IN"
  },
  {
  "name": "Indonesia",
  "dial_code": "+62",
  "code": "ID"
  },
  {
  "name": "Iran",
  "dial_code": "+98",
  "code": "IR"
  },
  {
  "name": "Iraq",
  "dial_code": "+964",
  "code": "IQ"
  },
  {
  "name": "Ireland",
  "dial_code": "+353",
  "code": "IE"
  },
  {
  "name": "Isle of Man",
  "dial_code": "+44",
  "code": "IM"
  },
  {
  "name": "Israel",
  "dial_code": "+972",
  "code": "IL"
  },
  {
  "name": "Italy",
  "dial_code": "+39",
  "code": "IT"
  },
  {
  "name": "Jamaica",
  "dial_code": "+1876",
  "code": "JM"
  },
  {
  "name": "Japan",
  "dial_code": "+81",
  "code": "JP"
  },
  {
  "name": "Jersey",
  "dial_code": "+44",
  "code": "JE"
  },
  {
  "name": "Jordan",
  "dial_code": "+962",
  "code": "JO"
  },
  {
  "name": "Kazakhstan",
  "dial_code": "+7",
  "code": "KZ"
  },
  {
  "name": "Kenya",
  "dial_code": "+254",
  "code": "KE"
  },
  {
  "name": "Kiribati",
  "dial_code": "+686",
  "code": "KI"
  },
  {
  "name": "North Korea",
  "dial_code": "+850",
  "code": "KP"
  },
  {
  "name": "South Korea",
  "dial_code": "+82",
  "code": "KR"
  },
  {
  "name": "Kuwait",
  "dial_code": "+965",
  "code": "KW"
  },
  {
  "name": "Kyrgyzstan",
  "dial_code": "+996",
  "code": "KG"
  },
  {
  "name": "Laos",
  "dial_code": "+856",
  "code": "LA"
  },
  {
  "name": "Latvia",
  "dial_code": "+371",
  "code": "LV"
  },
  {
  "name": "Lebanon",
  "dial_code": "+961",
  "code": "LB"
  },
  {
  "name": "Lesotho",
  "dial_code": "+266",
  "code": "LS"
  },
  {
  "name": "Liberia",
  "dial_code": "+231",
  "code": "LR"
  },
  {
  "name": "Libya",
  "dial_code": "+218",
  "code": "LY"
  },
  {
  "name": "Liechtenstein",
  "dial_code": "+423",
  "code": "LI"
  },
  {
  "name": "Lithuania",
  "dial_code": "+370",
  "code": "LT"
  },
  {
  "name": "Luxembourg",
  "dial_code": "+352",
  "code": "LU"
  },
  {
  "name": "Macao",
  "dial_code": "+853",
  "code": "MO"
  },
  {
  "name": "Macedonia",
  "dial_code": "+389",
  "code": "MK"
  },
  {
  "name": "Madagascar",
  "dial_code": "+261",
  "code": "MG"
  },
  {
  "name": "Malawi",
  "dial_code": "+265",
  "code": "MW"
  },
  {
  "name": "Malaysia",
  "dial_code": "+60",
  "code": "MY"
  },
  {
  "name": "Maldives",
  "dial_code": "+960",
  "code": "MV"
  },
  {
  "name": "Mali",
  "dial_code": "+223",
  "code": "ML"
  },
  {
  "name": "Malta",
  "dial_code": "+356",
  "code": "MT"
  },
  {
  "name": "Marshall Islands",
  "dial_code": "+692",
  "code": "MH"
  },
  {
  "name": "Martinique",
  "dial_code": "+596",
  "code": "MQ"
  },
  {
  "name": "Mauritania",
  "dial_code": "+222",
  "code": "MR"
  },
  {
  "name": "Mauritius",
  "dial_code": "+230",
  "code": "MU"
  },
  {
  "name": "Mayotte",
  "dial_code": "+262",
  "code": "YT"
  },
  {
  "name": "Mexico",
  "dial_code": "+52",
  "code": "MX"
  },
  {
  "name": "Micronesia, Federated States of Micronesia",
  "dial_code": "+691",
  "code": "FM"
  },
  {
  "name": "Moldova",
  "dial_code": "+373",
  "code": "MD"
  },
  {
  "name": "Monaco",
  "dial_code": "+377",
  "code": "MC"
  },
  {
  "name": "Mongolia",
  "dial_code": "+976",
  "code": "MN"
  },
  {
  "name": "Montenegro",
  "dial_code": "+382",
  "code": "ME"
  },
  {
  "name": "Montserrat",
  "dial_code": "+1664",
  "code": "MS"
  },
  {
  "name": "Morocco",
  "dial_code": "+212",
  "code": "MA"
  },
  {
  "name": "Mozambique",
  "dial_code": "+258",
  "code": "MZ"
  },
  {
  "name": "Namibia",
  "dial_code": "+264",
  "code": "NA"
  },
  {
  "name": "Nauru",
  "dial_code": "+674",
  "code": "NR"
  },
  {
  "name": "Nepal",
  "dial_code": "+977",
  "code": "NP"
  },
  {
  "name": "Netherlands",
  "dial_code": "+31",
  "code": "NL"
  },
  {
  "name": "Netherlands Antilles",
  "dial_code": "+599",
  "code": "AN"
  },
  {
  "name": "New Caledonia",
  "dial_code": "+687",
  "code": "NC"
  },
  {
  "name": "New Zealand",
  "dial_code": "+64",
  "code": "NZ"
  },
  {
  "name": "Nicaragua",
  "dial_code": "+505",
  "code": "NI"
  },
  {
  "name": "Niger",
  "dial_code": "+227",
  "code": "NE"
  },
  {
  "name": "Nigeria",
  "dial_code": "+234",
  "code": "NG"
  },
  {
  "name": "Niue",
  "dial_code": "+683",
  "code": "NU"
  },
  {
  "name": "Norfolk Island",
  "dial_code": "+672",
  "code": "NF"
  },
  {
  "name": "Northern Mariana Islands",
  "dial_code": "+1670",
  "code": "MP"
  },
  {
  "name": "Norway",
  "dial_code": "+47",
  "code": "NO"
  },
  {
  "name": "Oman",
  "dial_code": "+968",
  "code": "OM"
  },
  {
  "name": "Pakistan",
  "dial_code": "+92",
  "code": "PK"
  },
  {
  "name": "Palau",
  "dial_code": "+680",
  "code": "PW"
  },
  {
  "name": "Palestinian Territory, Occupied",
  "dial_code": "+970",
  "code": "PS"
  },
  {
  "name": "Panama",
  "dial_code": "+507",
  "code": "PA"
  },
  {
  "name": "Papua New Guinea",
  "dial_code": "+675",
  "code": "PG"
  },
  {
  "name": "Paraguay",
  "dial_code": "+595",
  "code": "PY"
  },
  {
  "name": "Peru",
  "dial_code": "+51",
  "code": "PE"
  },
  {
  "name": "Philippines",
  "dial_code": "+63",
  "code": "PH"
  },
  {
  "name": "Pitcairn",
  "dial_code": "+872",
  "code": "PN"
  },
  {
  "name": "Poland",
  "dial_code": "+48",
  "code": "PL"
  },
  {
  "name": "Portugal",
  "dial_code": "+351",
  "code": "PT"
  },
  {
  "name": "Puerto Rico",
  "dial_code": "+1939",
  "code": "PR"
  },
  {
  "name": "Qatar",
  "dial_code": "+974",
  "code": "QA"
  },
  {
  "name": "Romania",
  "dial_code": "+40",
  "code": "RO"
  },
  {
  "name": "Russia",
  "dial_code": "+7",
  "code": "RU"
  },
  {
  "name": "Rwanda",
  "dial_code": "+250",
  "code": "RW"
  },
  {
  "name": "Reunion",
  "dial_code": "+262",
  "code": "RE"
  },
  {
  "name": "Saint Barthelemy",
  "dial_code": "+590",
  "code": "BL"
  },
  {
  "name": "Saint Helena",
  "dial_code": "+290",
  "code": "SH"
  },
  {
  "name": "Saint Kitts and Nevis",
  "dial_code": "+1869",
  "code": "KN"
  },
  {
  "name": "Saint Lucia",
  "dial_code": "+1758",
  "code": "LC"
  },
  {
  "name": "Saint Martin",
  "dial_code": "+590",
  "code": "MF"
  },
  {
  "name": "Saint Pierre and Miquelon",
  "dial_code": "+508",
  "code": "PM"
  },
  {
  "name": "Saint Vincent and the Grenadines",
  "dial_code": "+1784",
  "code": "VC"
  },
  {
  "name": "Samoa",
  "dial_code": "+685",
  "code": "WS"
  },
  {
  "name": "San Marino",
  "dial_code": "+378",
  "code": "SM"
  },
  {
  "name": "Sao Tome and Principe",
  "dial_code": "+239",
  "code": "ST"
  },
  {
  "name": "Saudi Arabia",
  "dial_code": "+966",
  "code": "SA"
  },
  {
  "name": "Senegal",
  "dial_code": "+221",
  "code": "SN"
  },
  {
  "name": "Serbia",
  "dial_code": "+381",
  "code": "RS"
  },
  {
  "name": "Seychelles",
  "dial_code": "+248",
  "code": "SC"
  },
  {
  "name": "Sierra Leone",
  "dial_code": "+232",
  "code": "SL"
  },
  {
  "name": "Singapore",
  "dial_code": "+65",
  "code": "SG"
  },
  {
  "name": "Slovakia",
  "dial_code": "+421",
  "code": "SK"
  },
  {
  "name": "Slovenia",
  "dial_code": "+386",
  "code": "SI"
  },
  {
  "name": "Solomon Islands",
  "dial_code": "+677",
  "code": "SB"
  },
  {
  "name": "Somalia",
  "dial_code": "+252",
  "code": "SO"
  },
  {
  "name": "South Africa",
  "dial_code": "+27",
  "code": "ZA"
  },
  {
  "name": "South Sudan",
  "dial_code": "+211",
  "code": "SS"
  },
  {
  "name": "South Georgia and the South Sandwich Islands",
  "dial_code": "+500",
  "code": "GS"
  },
  {
  "name": "Spain",
  "dial_code": "+34",
  "code": "ES"
  },
  {
  "name": "Sri Lanka",
  "dial_code": "+94",
  "code": "LK"
  },
  {
  "name": "St Pierre & Miquelon",
  "dial_code": "+94",
  "code": "SP"
  },
  {
  "name": "Sudan",
  "dial_code": "+249",
  "code": "SD"
  },
  {
  "name": "Suriname",
  "dial_code": "+597",
  "code": "SR"
  },
  {
  "name": "Svalbard and Jan Mayen",
  "dial_code": "+47",
  "code": "SJ"
  },
  {
  "name": "Swaziland",
  "dial_code": "+268",
  "code": "SZ"
  },
  {
  "name": "Sweden",
  "dial_code": "+46",
  "code": "SE"
  },
  {
  "name": "Switzerland",
  "dial_code": "+41",
  "code": "CH"
  },
  {
  "name": "Syria",
  "dial_code": "+963",
  "code": "SY"
  },
  {
  "name": "Taiwan",
  "dial_code": "+886",
  "code": "TW"
  },
  {
  "name": "Tajikistan",
  "dial_code": "+992",
  "code": "TJ"
  },
  {
  "name": "Tanzania",
  "dial_code": "+255",
  "code": "TZ"
  },
  {
  "name": "Thailand",
  "dial_code": "+66",
  "code": "TH"
  },
  {
  "name": "Timor-Leste",
  "dial_code": "+670",
  "code": "TL"
  },
  {
  "name": "Togo",
  "dial_code": "+228",
  "code": "TG"
  },
  {
  "name": "Tokelau",
  "dial_code": "+690",
  "code": "TK"
  },
  {
  "name": "Tonga",
  "dial_code": "+676",
  "code": "TO"
  },
  {
  "name": "Trinidad and Tobago",
  "dial_code": "+1868",
  "code": "TT"
  },
  {
  "name": "Tunisia",
  "dial_code": "+216",
  "code": "TN"
  },
  {
  "name": "Turkey",
  "dial_code": "+90",
  "code": "TR"
  },
  {
  "name": "Turkmenistan",
  "dial_code": "+993",
  "code": "TM"
  },
  {
  "name": "Turks and Caicos Islands",
  "dial_code": "+1649",
  "code": "TC"
  },
  {
  "name": "Tuvalu",
  "dial_code": "+688",
  "code": "TV"
  },
  {
  "name": "Uganda",
  "dial_code": "+256",
  "code": "UG"
  },
  {
  "name": "Ukraine",
  "dial_code": "+380",
  "code": "UA"
  },
  {
  "name": "United Arab Emirates",
  "dial_code": "+971",
  "code": "AE"
  },
  {
  "name": "United Kingdom",
  "dial_code": "+44",
  "code": "GB"
  },
  {
  "name": "United States",
  "dial_code": "+1",
  "code": "US"
  },
  {
  "name": "Uruguay",
  "dial_code": "+598",
  "code": "UY"
  },
  {
  "name": "Uzbekistan",
  "dial_code": "+998",
  "code": "UZ"
  },
  {
  "name": "Vanuatu",
  "dial_code": "+678",
  "code": "VU"
  },
  {
  "name": "Venezuela",
  "dial_code": "+58",
  "code": "VE"
  },
  {
  "name": "Vietnam",
  "dial_code": "+84",
  "code": "VN"
  },
  {
  "name": "Virgin Islands",
  "dial_code": "+1340",
  "code": "VI"
  },
  {
  "name": "Wallis and Futuna",
  "dial_code": "+681",
  "code": "WF"
  },
  {
  "name": "West Bank",
  "dial_code": "+681",
  "code": "WB"
  },
  {
  "name": "Western Sahara",
  "dial_code": "+681",
  "code": "WT"
  },
  {
  "name": "Yemen",
  "dial_code": "+967",
  "code": "YE"
  },
  {
  "name": "Zambia",
  "dial_code": "+260",
  "code": "ZM"
  },
  {
  "name": "Zimbabwe",
  "dial_code": "+263",
  "code": "ZW"
  }
  ];
export let NAMELENDER = [
  {
    "name": "Accord buy to let",
    "_id": "5e748eb49954890b771f5b16"
  },
  {
    "name": "Accord Mortgages Ltd",
    "_id": "5e748eb49954890b771f5b17"
  },
  {
    "name": "Adam & Company",
    "_id": "5e748eb49954890b771f5b18"
  },
  {
    "name": "Adam & Company International",
    "_id": "5e748eb49954890b771f5b19"
  },
  {
    "name": "Ahli United Bank (UK) plc",
    "_id": "5e748eb49954890b771f5b1a"
  },
  {
    "name": "Aldermore Bank PLC",
    "_id": "5e748eb49954890b771f5b1b"
  },
  {
    "name": "Allied Irish Bank (GB), a trading name of AIB Group (UK)",
    "_id": "5e748eb49954890b771f5b1c"
  },
  {
    "name": "Atom Bank plc",
    "_id": "5e748eb49954890b771f5b1d"
  },
  {
    "name": "Aviva Equity Release UK Ltd",
    "_id": "5e748eb49954890b771f5b1e"
  },
  {
    "name": "Bank of Cyprus UK",
    "_id": "5e748eb49954890b771f5b1f"
  },
  {
    "name": "Bank of Ireland (UK) plc",
    "_id": "5e748eb49954890b771f5b20"
  },
  {
    "name": "Bank of Ireland as Bank of Ireland Mortgages",
    "_id": "5e748eb49954890b771f5b21"
  },
  {
    "name": "Bank of Scotland Beginning A",
    "_id": "5e748eb49954890b771f5b22"
  },
  {
    "name": "Bank of Scotland Beginning O",
    "_id": "5e748eb49954890b771f5b23"
  },
  {
    "name": "Banks and Clients Plc",
    "_id": "5e748eb49954890b771f5b24"
  },
  {
    "name": "Barclays Bank UK PLC",
    "_id": "5e748eb49954890b771f5b25"
  },
  {
    "name": "Barnsley Building Soc, a trading name Yorkshire Building Soc",
    "_id": "5e748eb49954890b771f5b26"
  },
  {
    "name": "Birmingham Midshires",
    "_id": "5e748eb49954890b771f5b27"
  },
  {
    "name": "Bradford & Bingley Plc",
    "_id": "5e748eb49954890b771f5b28"
  },
  {
    "name": "Britannia, a trading name of The Co-operative Bank plc",
    "_id": "5e748eb49954890b771f5b29"
  },
  {
    "name": "Buckinghamshire Building Society",
    "_id": "5e748eb49954890b771f5b2a"
  },
  {
    "name": "Capital Home Loans",
    "_id": "5e748eb49954890b771f5b2b"
  },
  {
    "name": "Chelsea Building Society (a trading name of Yorkshire BS)",
    "_id": "5e748eb49954890b771f5b2c"
  },
  {
    "name": "Clydesdale Bank plc",
    "_id": "5e748eb49954890b771f5b2d"
  },
  {
    "name": "Co-operative Bank plc",
    "_id": "5e748eb49954890b771f5b2e"
  },
  {
    "name": "Coutts",
    "_id": "5e748eb49954890b771f5b2f"
  },
  {
    "name": "Coutts Finance Co",
    "_id": "5e748eb49954890b771f5b30"
  },
  {
    "name": "Coventry Building Society",
    "_id": "5e748eb49954890b771f5b31"
  },
  {
    "name": "Darlington Building Society",
    "_id": "5e748eb49954890b771f5b32"
  },
  {
    "name": "DB UK Bank Ltd",
    "_id": "5e748eb49954890b771f5b33"
  },
  {
    "name": "Dudley Building Society",
    "_id": "5e748eb49954890b771f5b34"
  },
  {
    "name": "Earl Shilton Building Society",
    "_id": "5e748eb49954890b771f5b35"
  },
  {
    "name": "Ecology Building Society",
    "_id": "5e748eb49954890b771f5b36"
  },
  {
    "name": "Family Building Soc (a trading name of National Counties BS)",
    "_id": "5e748eb49954890b771f5b37"
  },
  {
    "name": "Fleet Mortgages",
    "_id": "5e748eb49954890b771f5b38"
  },
  {
    "name": "Foundation Home Loans",
    "_id": "5e748eb49954890b771f5b39"
  },
  {
    "name": "Furness Building Society",
    "_id": "5e748eb49954890b771f5b3a"
  },
  {
    "name": "Godiva Mortgages Ltd",
    "_id": "5e748eb49954890b771f5b3b"
  },
  {
    "name": "Halifax",
    "_id": "5e748eb49954890b771f5b3c"
  },
  {
    "name": "Halifax Loans Ltd",
    "_id": "5e748eb49954890b771f5b3d"
  },
  {
    "name": "Hampden & Co. plc",
    "_id": "5e748eb49954890b771f5b3e"
  },
  {
    "name": "Handelsbanken",
    "_id": "5e748eb49954890b771f5b3f"
  },
  {
    "name": "Harpenden Building Society",
    "_id": "5e748eb49954890b771f5b40"
  },
  {
    "name": "Hinckley and Rugby Building Society",
    "_id": "5e748eb49954890b771f5b41"
  },
  {
    "name": "HSBC Bank plc",
    "_id": "5e748eb49954890b771f5b42"
  },
  {
    "name": "HSBC UK Bank plc",
    "_id": "5e748eb49954890b771f5b43"
  },
  {
    "name": "Intelligent Finance",
    "_id": "5e748eb49954890b771f5b44"
  },
  {
    "name": "ITL Mortgages",
    "_id": "5e748eb49954890b771f5b45"
  },
  {
    "name": "JPMorgan Chase Bank, N.A",
    "_id": "5e748eb49954890b771f5b46"
  },
  {
    "name": "Kensington Mortgage Company Ltd",
    "_id": "5e748eb49954890b771f5b47"
  },
  {
    "name": "Keystone Property Finance",
    "_id": "5e748eb49954890b771f5b48"
  },
  {
    "name": "Landbay",
    "_id": "5e748eb49954890b771f5b49"
  },
  {
    "name": "Landmark Mortgages Limited",
    "_id": "5e748eb49954890b771f5b4a"
  },
  {
    "name": "Leeds Building Society",
    "_id": "5e748eb49954890b771f5b4b"
  },
  {
    "name": "Legal & General Home Finance Ltd",
    "_id": "5e748eb49954890b771f5b4c"
  },
  {
    "name": "LendInvest",
    "_id": "5e748eb49954890b771f5b4d"
  },
  {
    "name": "Lloyds Bank plc pre fixed 20/40",
    "_id": "5e748eb49954890b771f5b4e"
  },
  {
    "name": "Lloyds Bank plc pre fixed 50/30/77",
    "_id": "5e748eb49954890b771f5b4f"
  },
  {
    "name": "Lloyds TSB Scotland plc",
    "_id": "5e748eb49954890b771f5b50"
  },
  {
    "name": "M&S Bank",
    "_id": "5e748eb49954890b771f5b51"
  },
  {
    "name": "Magellan Homeloans",
    "_id": "5e748eb49954890b771f5b52"
  },
  {
    "name": "Market Harborough Building Society",
    "_id": "5e748eb49954890b771f5b53"
  },
  {
    "name": "Metro Bank plc",
    "_id": "5e748eb49954890b771f5b54"
  },
  {
    "name": "Molo Finance Buy to Let Mortgages",
    "_id": "5e748eb49954890b771f5b55"
  },
  {
    "name": "Monmouthshire Building Society",
    "_id": "5e748eb49954890b771f5b56"
  },
  {
    "name": "Mortgage Agency Services",
    "_id": "5e748eb49954890b771f5b57"
  },
  {
    "name": "Mortgage Express",
    "_id": "5e748eb49954890b771f5b58"
  },
  {
    "name": "National Counties Building Society",
    "_id": "5e748eb49954890b771f5b59"
  },
  {
    "name": "National Westminster Bank plc",
    "_id": "5e748eb49954890b771f5b5a"
  },
  {
    "name": "Nationwide Building Society",
    "_id": "5e748eb49954890b771f5b5b"
  },
  {
    "name": "Nedbank Private Wealth Ltd",
    "_id": "5e748eb49954890b771f5b5c"
  },
  {
    "name": "New Street Mortgages",
    "_id": "5e748eb49954890b771f5b5d"
  },
  {
    "name": "NRAM Ltd",
    "_id": "5e748eb49954890b771f5b5e"
  },
  {
    "name": "Paragon Buy to Let Mortgages",
    "_id": "5e748eb49954890b771f5b5f"
  },
  {
    "name": "Paragon Residential Owner-Occupied Mortgage",
    "_id": "5e748eb49954890b771f5b60"
  },
  {
    "name": "Paratus AMC Ltd",
    "_id": "5e748eb49954890b771f5b61"
  },
  {
    "name": "Parity Trust",
    "_id": "5e748eb49954890b771f5b62"
  },
  {
    "name": "Pepper Homeloans",
    "_id": "5e748eb49954890b771f5b63"
  },
  {
    "name": "Pepper Money",
    "_id": "5e748eb49954890b771f5b64"
  },
  {
    "name": "Platform (a trading name of The Co-operative Bank p.l.c.)",
    "_id": "5e748eb49954890b771f5b65"
  },
  {
    "name": "Precise Mortgages (Charter Court Financial Services Ltd",
    "_id": "5e748eb49954890b771f5b66"
  },
  {
    "name": "Principality Building Society",
    "_id": "5e748eb49954890b771f5b67"
  },
  {
    "name": "Saffron Building Society",
    "_id": "5e748eb49954890b771f5b68"
  },
  {
    "name": "Sainsbury's Bank",
    "_id": "5e748eb49954890b771f5b69"
  },
  {
    "name": "Santander UK plc",
    "_id": "5e748eb49954890b771f5b6a"
  },
  {
    "name": "Santander UK plc for Alliance & Leicester mortgages only",
    "_id": "5e748eb49954890b771f5b6b"
  },
  {
    "name": "Scottish Building Society",
    "_id": "5e748eb49954890b771f5b6c"
  },
  {
    "name": "Scottish Widows Bank",
    "_id": "5e748eb49954890b771f5b6d"
  },
  {
    "name": "Secure Trust Bank PLC",
    "_id": "5e748eb49954890b771f5b6e"
  },
  {
    "name": "Skipton Building Society",
    "_id": "5e748eb49954890b771f5b6f"
  },
  {
    "name": "St James Place Bank",
    "_id": "5e748eb49954890b771f5b70"
  },
  {
    "name": "State Bank of India UK",
    "_id": "5e748eb49954890b771f5b71"
  },
  {
    "name": "Swansea Building Society",
    "_id": "5e748eb49954890b771f5b72"
  },
  {
    "name": "Tesco Bank (a trading name of Tesco Personal Finance plc)",
    "_id": "5e748eb49954890b771f5b73"
  },
  {
    "name": "The Mortgage Business",
    "_id": "5e748eb49954890b771f5b74"
  },
  {
    "name": "The Mortgage Lender Limited",
    "_id": "5e748eb49954890b771f5b75"
  },
  {
    "name": "The Mortgage Works",
    "_id": "5e748eb49954890b771f5b76"
  },
  {
    "name": "The Royal Bank of Scotland plc",
    "_id": "5e748eb49954890b771f5b77"
  },
  {
    "name": "The Royal Bank of Scotland plc Direct Line Mortgages",
    "_id": "5e748eb49954890b771f5b78"
  },
  {
    "name": "The Royal Bank of Scotland plc Direct Line One",
    "_id": "5e748eb49954890b771f5b79"
  },
  {
    "name": "The Royal Bank of Scotland plc Natwest one Account",
    "_id": "5e748eb49954890b771f5b7a"
  },
  {
    "name": "The Royal Bank of Scotland plc One Account",
    "_id": "5e748eb49954890b771f5b7b"
  },
  {
    "name": "The Royal Bank of Scotland plc Virgin One",
    "_id": "5e748eb49954890b771f5b7c"
  },
  {
    "name": "The Tipton & Coseley Building Society",
    "_id": "5e748eb49954890b771f5b7d"
  },
  {
    "name": "Topaz Finance Ltd",
    "_id": "5e748eb49954890b771f5b7e"
  },
  {
    "name": "TSB Bank plc",
    "_id": "5e748eb49954890b771f5b7f"
  },
  {
    "name": "Ulster Bank Ltd",
    "_id": "5e748eb49954890b771f5b80"
  },
  {
    "name": "Vida Homeloans",
    "_id": "5e748eb49954890b771f5b81"
  },
  {
    "name": "Virgin Money plc",
    "_id": "5e748eb49954890b771f5b82"
  },
  {
    "name": "Whistletree (a trading name of TSB Bank plc)",
    "_id": "5e748eb49954890b771f5b83"
  },
  {
    "name": "Yorkshire Bank Home Loans Ltd",
    "_id": "5e748eb49954890b771f5b84"
  },
  {
    "name": "Yorkshire Building Society",
    "_id": "5e748eb49954890b771f5b85"
  },
  {
    "name": "Zephyr Mortgages",
    "_id": "5e748eb49954890b771f5b86"
  },
  {
    "name": "Other",
    "_id": "1234567891234567891234"
  }
];

