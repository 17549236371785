<ng-template #login>
    <div class="log_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close closePop" (click)="close()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Welcome to <span>Egiyok</span></h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <button id="btnid" type="button" class="btn btn-info btn-block btn-round btn_red usr_login" (click)="loginpage(loginModal1)" data-toggle="modal" data-target="#loginModal1">{{'header.popup.login' | translate}}</button>
                            <button id="btnid" type="button" class="btn btn-info btn-block btn-round btn_red btn_border sign_up" (click)="loginpage(loginModal3)" data-toggle="modal" data-target="#loginModal3">Sign Up</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<!-- login (enter phone no) -->
<ng-template #loginModal1>
    <div class="log_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>{{'header.popup.login' | translate}}</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form [formGroup]="form">
                            <div class="form-group position_rel row">
                                <label for="example-tel-input" class="col-12 col-form-label">Enter Mobile Number</label>
                                <div class="col-12 change_no cont_cd">
                                    <div class="mob_code_fld">
                                        <span class="cont_code">+91</span>
                                        <input class="form-control" formControlName="phoneNumber" type="tel" value="" placeholder="5555555555" id="example-tel-input" (keydown.enter)="loginEnter(loginModal2,form)">
                                    </div>
                                    <!-- <a>Change?</a> -->
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('required') && showError">
                                        *Please enter phone number.
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('minlength') && showError">
                                        *Minimum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('maxlength') && showError">
                                        *Maximum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('maxlength') && !showError">
                                        *Phone number should be 10 digit.
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('pattern') && showError">
                                        *Please enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                            <!-- <button type="button" class="btn btn-info btn-block btn-round btn_red btn_border otp_req" (click)="resend()">Request OTP on Mobile</button>
                        <div class="text-danger" *ngIf="resenderror">
                            *please Enter mobile No.
                        </div>
                        <span class="or_option"><sup>or</sup></span> -->
                            <button id="btnid" type="button" class="btn btn-info btn-block btn-round btn_red pass_enter" (click)="loginpagelogin(loginModal2,form)" data-toggle="modal" data-target="#loginModal2">{{'header.popup.login' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<!-- login popup  -->
<ng-template #loginModal2>
    <div class="log_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>{{'header.popup.login' | translate}}</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form [formGroup]="loginsForm">
                            <div class="form-group position_rel row">
                                <label for="example-tel-input" class="col-12 col-form-label">Enter Mobile Number</label>
                                <div class="col-12 change_no cont_cd">
                                    <div class="mob_code_fld">
                                        <span class="cont_code">+91</span>
                                        <input class="form-control" formControlName="phoneNumber" type="tel" value="" placeholder="5555555555" id="example-tel-input" (keydown.enter)="loginPwd($event, loginPin)">

                                    </div>
                                    <div class="text-danger" *ngIf="loginsForm.controls['phoneNumber'].hasError('required') && showError">
                                        *Please enter phone number.
                                    </div>
                                    <div class="text-danger" *ngIf="!loginsForm.controls['phoneNumber'].hasError('pattern') && loginsForm.controls['phoneNumber'].hasError('minlength') && showError">
                                        *Minimum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="!loginsForm.controls['phoneNumber'].hasError('pattern') && loginsForm.controls['phoneNumber'].hasError('maxlength') && showError">
                                        *Maximum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="loginsForm.controls['phoneNumber'].hasError('maxlength') && !showError">
                                        *Phone number should be 10 digit.
                                    </div>
                                    <div class="text-danger" *ngIf="loginsForm.controls['phoneNumber'].hasError('pattern') && showError">
                                        *Please enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">{{'header.popup.pin' | translate}}</label>
                                <div class="col-12 change_no">
                                    <input #loginPin type="password" class="form-control" mask="000000" formControlName="password" id="exampleInputPassword1" placeholder="******" (keydown)="onKeydown($event, loginsForm, loginModal5)">
                                    <div class="text-danger" *ngIf="loginsForm.controls['password'].hasError('required') && showError">
                                        *Please enter pin.
                                    </div>

                                    <div class="text-danger" *ngIf="loginsForm.controls['password'].hasError('minlength') && showError">
                                        *Pin should be 4 or 6 digits long
                                    </div>
                                    <div class="text-danger" *ngIf="!loginsForm.controls['password'].hasError('minlength') && !loginsForm.controls['password'].hasError('required') && showError">
                                        *Invalid PIN Length, PIN should be of 4 or 6 digit long
                                    </div>
                                </div>
                            </div>
                            <div class="">{{'homePage.buyer.forgotPin' | translate}} <a class="frgt_pass" (click)="ResetPin(resendopt)">Reset</a></div>

                            <div class="text-danger" *ngIf="resenderror">
                                *please Enter mobile No.
                            </div>
                            <button type="button" class="btn btn-info btn-block btn-round btn_red" (click)="loginForm(loginsForm, loginModal5)">{{'header.popup.login' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<!-- create new account -->
<ng-template #loginModal3>
    <div class="log_pop sign_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" (click)="close()" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Sign Up</h2>
                    </div>
                    <div class="d-flex flex-column text-center">
                        <form [formGroup]="form">
                            <div class="form-group position_rel row">
                                <label for="example-tel-input" class="col-12 col-form-label">Enter Mobile Number</label>
                                <div class="col-12 change_no cont_cd">
                                    <div class="mob_code_fld">
                                        <span class="cont_code">+91</span>
                                        <input class="form-control" formControlName="phoneNumber" type="tel" value="" placeholder="5555555555" id="example-tel-input" (keydown.enter)="createAccount(loginModal4,form)">
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('required') && showError">
                                        *Please enter phone number.
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('minlength') && showError">
                                        *Minimum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('maxlength') && showError">
                                        *Maximum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('maxlength') && !showError">
                                        *Phone number should be 10 digit.
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('pattern') && showError">
                                        *Please enter a valid phone number.
                                    </div>
                                    <!-- <div class="text-danger" *ngIf="!registerForm.controls['phoneNumber'].hasError('required') && !registerForm.controls['phoneNumber'].hasError('minlength') && 
                                !registerForm.controls['phoneNumber'].hasError('maxlength') && registerForm.controls['phoneNumber'].hasError('whitespace') && showError">
                                   *Please enter a valid phone number.
                                </div>
                                <div class="text-danger" *ngIf="showErrorInPhoneNumber && !showError">
                                    *Minimum 10 numeric digits
                            </div> -->
                                </div>
                            </div>
                            <!-- <p class="regs_no">This number is not registered with us. <br> We will send you an OTP.</p> -->
                            <button id="btnid" type="button" class="btn btn-info btn-block btn-round btn_red mobile_log" (click)="loginpage1(loginModal4,form)" data-toggle="modal" data-target="#loginModal4"> {{'header.popup.continue' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>


<!-- Verification Code  popup-->
<ng-template #loginModal4>
    <div class="log_pop otp_scrn">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Sign Up</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form [formGroup]="form">
                            <div class="form-group position_rel row">
                                <label for="example-tel-input" class="col-12 col-form-label">Enter Mobile Number</label>
                                <div class="col-12 change_no cont_cd">
                                    <div class="mob_code_fld">
                                        <span class="cont_code">+91</span>
                                        <input class="form-control" type="tel" formControlName="phoneNumber" value="" placeholder="5555555555" id="example-tel-input" readonly>
                                        <a (click)="Goback(loginModal3)">Change?</a>
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('required') && showError">
                                        *Please enter phone number.
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('minlength') && showError">
                                        *Minimum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('maxlength') && showError">
                                        *Maximum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('maxlength') && !showError">
                                        *Phone number should be 10 digit.
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('pattern') && showError">
                                        *Please enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="validationDefault05">Verification Code</label>
                                <div class="col-12 change_no">
                                    <input type="text" class="form-control" mask="0000" value="{{varification}}" (keyup)="change($event, loginModal6,form)" required>

                                    <div class="text-danger" *ngIf="otperror">
                                        *Please enter a valid otp.
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <countdown class="timerWrap" [config]="config"></countdown>
                                <div class="resendBtn">Didn't received code yet? <a *ngIf="isDisable" href="javascript:;" class="frgt_pass" (click)="resend()">Resend</a></div>
                            </div>
                            <div class="text-danger" *ngIf="resenderror">
                                *please Enter mobile No.
                            </div>
                            <button type="button" class="btn btn-info btn-block btn-round btn_red log_varify" (click)="loginpage2(loginModal6,form)"> {{'header.popup.continue' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #resendopt>

    <div class="log_pop otp_scrn">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <!-- <h2>{{'header.popup.login' | translate}}</h2> -->
                        <h2>Reset PIN</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form [formGroup]="form">
                            <div class="form-group position_rel row">
                                <label for="example-tel-input" class="col-12 col-form-label">Enter Mobile Number</label>
                                <div class="col-12 change_no cont_cd">
                                    <div class="mob_code_fld">
                                        <span class="cont_code">+91</span>
                                        <input class="form-control" type="tel" formControlName="phoneNumber" value="" placeholder="5555555555" id="example-tel-input" readonly>
                                        <!-- <a (click)="Goback(loginModal2)">Change?</a> -->
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('required') && showError">
                                        *Please enter phone number.
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('minlength') && showError">
                                        *Minimum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="!form.controls['phoneNumber'].hasError('pattern') && form.controls['phoneNumber'].hasError('maxlength') && showError">
                                        *Maximum length of 10 characters
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('maxlength') && !showError">
                                        *Phone number should be 10 digit.
                                    </div>
                                    <div class="text-danger" *ngIf="form.controls['phoneNumber'].hasError('pattern') && showError">
                                        *Please enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="validationDefault05">Verification Code</label>
                                <div class="col-12 change_no">
                                    <input type="text" class="form-control" mask="0000" value="{{varification}}" (keyup)="changeOtp($event, resendpwd, form)" required>
                                    <div class="text-danger" *ngIf="otperror">
                                        *Please enter a valid valid otp.
                                    </div>
                                </div>
                            </div>


                            <div class="d-flex">
                                <countdown class="timerWrap" [config]="config"></countdown>
                                <div class="resendBtn">Didn't received code yet? <a *ngIf="isDisable" href="javascript:;" class="frgt_pass" (click)="resend()">Resend</a></div>
                            </div>
                            <div class="text-danger" *ngIf="resenderror">
                                *please Enter mobile No.
                            </div>
                            <button type="button" class="btn btn-info btn-block btn-round btn_red log_varify" (click)="loginResetOtp(resendpwd, form)"> {{'header.popup.continue' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>


<ng-template #resendpwd>
    <div class="log_pop otp_scrn submit_acc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>let's reset a pin</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">{{'header.popup.pin' | translate}}</label>
                                <div class="col-12 change_no">
                                    <input type="password" value="{{password1}}" mask="000000" (keyup)="resetPin1($event,resetPin)" class="form-control" id="exampleInputPassword1" placeholder="******">
                                    <div class="text-danger" *ngIf="showErrormatch1">
                                        *Please Enter pin.
                                    </div>
                                    <div class="text-danger" *ngIf="showErrormatch11">
                                        *Invalid PIN Length, PIN should be of 4 or 6 digit long
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1"> {{'header.popup.confirmpin' | translate}}</label>
                                <div class="col-12 change_no">
                                    <input #resetPin type="password" value="{{password2}}" mask="000000" (keyup)="resetPin2($event,loginModal2, loginModal1)" class="form-control" id="exampleInputPassword1" placeholder="******">
                                    <div class="text-danger" *ngIf="showError">
                                        *Pins and confirm pin not matching
                                    </div>
                                    <div class="text-danger" *ngIf="showErrormatch2">
                                        *Please Enter pin.
                                    </div>
                                    <div class="text-danger" *ngIf="showErrormatch21">
                                        *Invalid PIN Length, PIN should be of 4 or 6 digit long
                                    </div>
                                </div>
                            </div>
                            <button type="button" (click)="openModal15(loginModal2, loginModal1)" class="btn btn-info btn-block btn-round btn_red">{{'header.popup.submit' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<!-- //#loginModal5 login -->
<ng-template #loginModal5>
    <div class="log_pop otp_scrn update_prf">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>let's create a Profile</h2>
                    </div>
                    <div class="d-flex flex-column text-center row update_prf">
                        <div class="create_prof">
                            <div class="upld_img upld_fl">
                                <div class="file btn btn-lg btn-primary attach_f">
                                    <img *ngIf="uploadImage == null || uploadImage == undefined || uploadImage == ''" src="./assets/images/upload.png">
                                    <img *ngIf="uploadImage != null && uploadImage != undefined && uploadImage != ''" src="{{urlfile}}">

                                    <input type="file" name="file" (change)="upload($event, preview)" accept=".png, .jpg, .jpeg" />

                                </div>
                                <div class="text-danger" *ngIf="showError1">
                                    *Please upload Image.
                                </div>

                            </div>
                            <form [formGroup]="updateProfileForm">
                                <div class="form-group position_rel row">
                                    <label for="fullname" class="col-md-4 col-form-label text-md-right">Full Name<span class="asteriskIcon">*</span></label>
                                    <div class="col-12 change_no">

                                        <input type="text" name="fullname" value="" placeholder="John Williams" formControlName="name" id="fullname" class="form-control" (keydown.enter)="enterFullName($event, userEmailid)">
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('required') && showError">
                                            *Please enter name.
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('minlength') && showError">
                                            *Minimum length of 3 characters
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('maxlength') && showError">
                                            *Maximum length of 45 characters
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('pattern') && showError ">*Please enter Valid name</div>
                                    </div>
                                </div>
                                <div class="form-group position_rel row">
                                    <label for="email_address" class="col-form-label text-md-right">E-Mail (Optional)</label>
                                    <div class="col-12">
                                        <input #userEmailid type="text" value="" placeholder="johnwilliams@gmail.com" formControlName="email" id="email_address" class="form-control" name="email-address" (keydown.enter)="enterEmailid($event, referralCode)">
                                        <!-- <div class="text-danger" *ngIf="updateProfileForm.controls['email'].hasError('required') && showError">
                                            *Please enter email.
                                        </div> -->
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['email'].hasError('pattern') && showError">
                                            *Please enter a valid email.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group position_rel row">
                                    <label for="username" class="col-md-12 col-form-label text-md-right">Referral (Optional)</label><br>
                                    <div class="col-12">
                                        <input #referralCode type="text" formControlName="referredBy" value="" placeholder="John" name="username" id="username" class="form-control" (keydown.enter)="enterReferralCode($event,updateProfileForm)">

                                        <div class="text-danger" *ngIf="updateProfileForm.controls['referredBy'].hasError('minlength') && showError">
                                            *Minimum length of 2 characters
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['referredBy'].hasError('maxlength') && showError">
                                            *Maximum length of 45 characters
                                        </div>
                                    </div>
                                </div>
                                <p>Enter valid referral code
                                    <!-- and you can earn 50 coins on successful first transaction -->
                                </p>
                                <button type="button" class="btn btn-info btn-block btn-round btn_red create_prof" (click)="updateProfile(updateProfileForm)"> {{'header.popup.continue' | translate}}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>


<ng-template #loginModal6>
    <div class="log_pop otp_scrn submit_acc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <!-- <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div> -->
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>let's create a Profile</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">{{'header.popup.pin' | translate}}</label>
                                <div class="col-12 change_no">
                                    <input type="password" value="{{password1}}" mask="000000" (keyup)="resetPin1($event,createPin2)" class="form-control" id="exampleInputPassword1" placeholder="******">
                                    <div class="text-danger" *ngIf="showErrormatch1">
                                        *Please Enter pin.
                                    </div>
                                    <div class="text-danger" *ngIf="showErrormatch11">
                                        *Invalid PIN Length, PIN should be of 4 or 6 digit long
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="exampleInputPassword1">{{'header.popup.confirmpin' | translate}}</label>
                                <div class="col-12 change_no">
                                    <input #createPin2 type="password" value="{{password2}}" mask="000000" (keyup)="resetPin2($event,loginModal5, loginModal1)" class="form-control" id="exampleInputPassword1" placeholder="******">
                                    <div class="text-danger" *ngIf="showError">
                                        *Pins and confirm pin not matching
                                    </div>
                                    <div class="text-danger" *ngIf="showErrormatch2">
                                        *Please Enter pin.
                                    </div>
                                    <div class="text-danger" *ngIf="showErrormatch21">
                                        *Invalid PIN Length, PIN should be of 4 or 6 digit long
                                    </div>
                                </div>
                            </div>
                            <button type="button" (click)="openModal15(loginModal5, loginModal1)" class="btn btn-info btn-block btn-round btn_red">{{'header.popup.submit' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>


<ng-template #preview>
    <div class="" role="document">
        <div class="modal-content cropperPopup">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Crop Image</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="croperHide(loginModal5)">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" format="png" (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
                <!-- <img [src]="croppedImage" /> -->
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal" (click)="cropImages(loginModal5)">OK</button>
            </div>
        </div>
    </div>
</ng-template>