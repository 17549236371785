import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService,private loader:JsonPlaceHolderService) { }
  ngOnInit() {  
    console.log(this.loader.loaderState.subscribe(data=>{}), "ajdksjdkasd==============")
    this.loader.loaderState.subscribe(data=>{
      if(data)
      this.spinner.show();
      else
      this.spinner.hide();
    });    
  }
}
