
import { TemplateRef, Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as CONSTANT from '../../services/constants';
import { NoWhitespaceValidator } from '../../services/NoWhiteSpace.validator';
import { IndexService } from '../../external/index-page/index.service';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data.share.service';
import { TranslateService } from '@ngx-translate/core';
import { FcmserviceService } from './../../services/fcmservice.service'; 
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";
import * as CryptoJS from 'crypto-js';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
@Injectable()
export class LoginModalComponent implements OnInit {
  modalRef: BsModalRef;
  password = '';
  loginsForm: FormGroup;
  token = '';
  showErrormatch21: boolean = false;
  showErrormatch11: boolean = false;
  showErrormatch1: boolean = false;
  showErrormatch2: boolean = false;
  updateProfileForm: FormGroup;
  password2 = '';
  password1 = '';
  otperror: boolean = false;
  resenderror: boolean = false;
  uploadImage = '';
  urlfile: any = '';
  varification = '';
  allDataProduct = '';
  showError1: boolean = false;
  showError: boolean = false;
  form: FormGroup;
  tokenCheck: any;
  loginResetPwd=false;
  phoneNumber: any;
  browserLang: any;
  config = {};
  showLoginPopup=false;
  waitingInterval: any;
  otpValue: any;
  isDisable = false;
  secretCode = "0123456789123456";
  encrypted:any;
  decrypted:any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  selectedFile: any = null;


    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.urlfile = event.base64;
        console.log(event);
        var block = this.croppedImage.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];

        var blob = this.b64toBlob(realData, contentType);

        this.selectedFile = new File([blob], new Date().getTime() + "." + blob.type.split("/")[1]);

        console.log(this.selectedFile);
    }

    b64toBlob(b64Data, contentType, sliceSize?) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
  
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
  
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
  
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
  
        var byteArray = new Uint8Array(byteNumbers);
  
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    imageLoaded(image: HTMLImageElement) {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }
  @ViewChild('login') private loginModal: TemplateRef<any>

  constructor(private modalService: BsModalService, private formBuilder: FormBuilder, private IS: IndexService, private admin: JsonPlaceHolderService, private router: Router,private dataSharingService: DataSharingService,  private messagingService: FcmserviceService,private db: AngularFireDatabase, private afAuth: AngularFireAuth, private storage: AngularFireStorage,) {


    this.form = this.formBuilder.group({
      // email: ['', Validators.compose([ Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX) ])],
      // password : ['',  Validators.compose([Validators.required, Validators.minLength(8)])]
      phoneNumber: ['', Validators.compose([Validators.required,
      Validators.minLength(10), Validators.maxLength(10),
      Validators.pattern(CONSTANT.MOBILE_REGEX_NEW_SPACE), NoWhitespaceValidator()])],

    });
    this.loginsForm = this.formBuilder.group({
      // email: ['', Validators.compose([ Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX) ])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],

      phoneNumber: ['', Validators.compose([Validators.required,
      Validators.minLength(10), Validators.maxLength(10),
      Validators.pattern(CONSTANT.MOBILE_REGEX_NEW_SPACE), NoWhitespaceValidator()])],

    });
    this.updateProfileForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(45),
      Validators.pattern('[A-Za-z ]*'), NoWhitespaceValidator()])],
      referredBy: ['', Validators.compose([
        Validators.minLength(2), Validators.maxLength(45)])],
      // password : ['',  Validators.compose([Validators.required, Validators.minLength(8)])]
      phoneNumber: [''],

    });

    this.dataSharingService.selectedLang.subscribe(res => {
      this.browserLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN';
    });
    // this.dataSharingService.selectedLang.subscribe(res => {

    //   

    //    console.log('browser login',this.browserLang);
    //    this.translate.setDefaultLang(this.browserLang);
    //    this.translate.use(this.browserLang);
    //    this.languageChange();
    //  });

  }
  // languageChange(){
  //   this.translate.use(this.browserLang.match(/hi|en/) ? this.browserLang : 'en');
  // }

  ngOnInit(): void {
    this.getDeviceToken();
  }

 
  setPin(event, template, loginTemplate ) {   
    this.password2 = event.target.value;
    this.openModal15(template, loginTemplate)
  }


  resetPin1(event , resetPin){
    this.password1 = event.target.value;
    if (event.key === "Enter" || event.key === 13) {
      resetPin.focus();
    }    
  }  
  resetPin2(event, template, form){
    this.password2 = event.target.value;
    if (event.key === "Enter" || event.key === 13) {
      this.openModal15(template, form)
    }
    console.log(this.varification, "this.varification")
  }

  openModal15(template, loginTemplate) {
    if (this.password2 == this.password1 && this.password1 != '' && this.password2 != '' && (this.password1.length == 4 || this.password1.length == 6)) {
      this.password = this.password1

      if (this.password != '') {
        const data = {
          'phoneNumber': '+91-' + this.form.value.phoneNumber,
          'token': this.token,
          'password': this.password,
          'platform': 'WEB',
          'deviceToken': this.getDeviceToken(),
        };
        this.IS.submitForm(data, apiUrl._postResetPassword)
          .subscribe(async (res) => {
            console.log(res, "login")
            this.modalRef.hide();
            this.admin.showAlert('Pin set Successfully', '');
            localStorage.setItem('id', res.data._id);
            if(!this.loginResetPwd){
              localStorage.setItem('adminToken', res.data.accessToken);
            }

            setTimeout(() => {
              this.loginResetPwd=false;
            }, 30000);
            
            this.phoneNumber = '';
            if(this.showLoginPopup){
              setTimeout(() => {
                this.modalRef = this.modalService.show(loginTemplate, {
                  backdrop: 'static',
                  animated: true,
                });
              }, 1000);

            }else{
              setTimeout(() => {
               
                this.modalRef = this.modalService.show(template, {
                  backdrop: 'static',
                  animated: true,
                });
                
              }, 1000);
            }

            setTimeout(() => {
              this.showLoginPopup=false
            }, 3000);
            
            
          })
      }
    } else if (this.password1 == '') {
      this.showErrormatch1 = true;
      setTimeout(() => {
        this.showErrormatch1 = false;
      }, 5000);

    } else if (this.password2 == '') {
      this.showErrormatch2 = true;
      setTimeout(() => {
        this.showErrormatch2 = false;
      }, 5000);

    } else if (this.password1.length == 5 || this.password1.length == 3 || this.password1.length == 2 || this.password1.length == 1) {
      this.showErrormatch11 = true;
      setTimeout(() => {
        this.showErrormatch11 = false;
      }, 5000);

    } else if (this.password2.length == 5 || this.password2.length == 3 || this.password2.length == 2 || this.password2.length == 1) {
      this.showErrormatch21 = true;
      setTimeout(() => {
        this.showErrormatch21 = false;
      }, 5000);

    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }

  }
  change(event, template, form){
    this.varification = event.target.value;
    if (event.key === "Enter" || event.key === 13) {
      this.loginpage2(template, form)
    }
    console.log(this.varification, "this.varification")
  }

  verificationCode(event,template, form ) {
    this.varification = event.target.value;
    this.loginpage2(template, form)
  }

  loginpage2(template, form) {
    console.log("send")
    if (this.varification != '' && this.varification.length > 3) {
      console.log(this.varification)
      const data = {        
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'otp': this.varification,
        'otpRequestType': 1,
        'langform':this.browserLang.toString()
      };
      this.IS.updateForm(data, apiUrl._PUTVarifyOTP)
        .subscribe(async (res) => {
          if (this.varification != '') {
            this.updateProfileForm.patchValue({
              phoneNumber: form.value.phoneNumber,
            });
            if (res.message == 'Success') {
              this.token = res.data.token;

              // localStorage.setItem('adminToken', this.token);
            }
            this.modalRef.hide();
            this.modalRef = this.modalService.show(template, { class: "my-class-modal",animated: true,
            backdrop: 'static', });
          }
        })
    } else if (this.varification.length < 3 || this.varification.length == 3) {
      this.otperror = true;
      setTimeout(() => {
        this.otperror = false;
      }, 5000);
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
 

  upload(event, template) {
    if (event.target.files && event.target.files.length) {
      this.selectedFile = <File>event.target.files[0];
      console.log(event.target.files);
      console.log(this.selectedFile);
      this.modalRef.hide();
      this.modalRef = this.modalService.show(template,{
        animated: true,
        backdrop: 'static',
      });
      this.imageChangedEvent = event; 
    }


  }
cropImages(Editprofile){
  const fd = new FormData();
  fd.append('directory', 'users');
  fd.append('image', this.selectedFile);
  // const data = {
  //   'directory':'users',
  //   'image':event.target.files[0],
  // }
  this.IS.submitForm(fd, apiUrl._postupload)
    .subscribe(async (res) => {
      this.uploadImage = res.data;
      this.modalRef.hide();
      this.modalRef = this.modalService.show(Editprofile,{
        animated: true,
        backdrop: 'static',
      });
    })
}
croperHide(template: TemplateRef<any>){
   this.urlfile = '';
   this.uploadImage ='';
  this.modalRef.hide();
  this.modalRef = this.modalService.show(template,{
    animated: true,
    backdrop: 'static',
  });
}


  Goback(template) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template,{
      animated: true,
      backdrop: 'static',
    });
  }
  // openOptpopup(template){
  //   this.modalRef = this.modalService.show(template);  
  // }

  openOptpopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
      // class: 'modal-dialog-centered'
    });
  }

  createAccount(template, form) {
    this.loginpage1(template, form);
  }

  loginpage1(template, form) {
 
    const otppopup = template;
    if (form.valid) {
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,  
        'isLogin':'0',
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken(),

      };
      this.IS.submitForm(data, apiUrl._postvalidationphno)
        .subscribe(async (res) => {
          this.varification = '';
          this.modalRef.hide();
          if (res.data.customMessage) {
            this.admin.errorAlert(res.data.customMessage, true);
          } else {
      
            const phoneData = {
            phoneNumber: '+91-' + form.value.phoneNumber,   
              otpBy: 1,
              otpRequestType: 2
            }
            this.IS.updateForm(phoneData, apiUrl._putGenrateOTP)
              .subscribe(async (res) => {
                this.isDisable = false;
                this.waitingInterval = res.data.waitingInterval;
                this.openOptpopup(otppopup);
                this.config = {
                  leftTime: "45",
                  format: `mm:ss`
                }              
              
                setTimeout(() => {
                  this.isDisable = true;
                }, 30000);
               

              },
                err => {

                });
          }
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  onKeydown(event, form, template) {
    if (event.key === "Enter" || event.key === 13) {
      this.loginForm(form, template)
    }
  }

  loginForm(form, template) {
    if (form.valid && form.value.password.length != 5) {
      let params = new HttpParams();
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'password': form.value.password,
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken()
      };
      this.IS.updateForm(data, apiUrl._postLogin)
        .subscribe(async (res) => {
          console.log(res.data , "login====")
          // if(res.data.businessAddress){
          //   localStorage.setItem('name', res.data.businessAddress.state);

          // }
          if (res.data.name == '' || res.data.name == null) {
            this.modalRef.hide();
            this.modalRef = this.modalService.show(template,{
                backdrop: 'static',              
            });
            this.tokenCheck = res.data.accessToken;
            this.phoneNumber = res.data.phoneNumber;
            localStorage.setItem('id', res.data._id);
            localStorage.setItem('adminToken', res.data.accessToken);
          } else if (res.data.businessName == '' || res.data.businessName == null) {
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('profilePic', res.data.profilePic);
            localStorage.setItem('id', res.data._id);
            localStorage.setItem('BussinessVerified', res.data.businessVerified);
            localStorage.setItem('adminToken', res.data.accessToken);
            this.modalRef.hide();
            if(localStorage.getItem('bannerLink')){
              this.bannerRedirect(localStorage.getItem('bannerLink'), 
              localStorage.getItem('bannerSearchtext'), localStorage.getItem('bannerCategoryid'))
              
            }else{
              this.router.navigate(['Buyer']);
            }
            
          } else if (!res.data.registrationFeeVerified) {
            if (res.data.onetimeFee[0].status == 0) {
              if (!res.data.businessVerified) {
                localStorage.setItem('name', res.data.name);
                localStorage.setItem('profilePic', res.data.profilePic);
                localStorage.setItem('id', res.data._id);
                localStorage.setItem('BussinessVerified', res.data.businessVerified);
                localStorage.setItem('adminToken', res.data.accessToken);
                this.modalRef.hide();
                if(localStorage.getItem('bannerLink')){
                  this.bannerRedirect(localStorage.getItem('bannerLink'), 
                  localStorage.getItem('bannerSearchtext'), localStorage.getItem('bannerCategoryid'))
                }else{
                  this.router.navigate(['Buyer']);
                }
              } else {
                localStorage.setItem('name', res.data.name);
                localStorage.setItem('profilePic', res.data.profilePic);
                localStorage.setItem('id', res.data._id);
                localStorage.setItem('BussinessVerified', res.data.businessVerified);
                localStorage.setItem('adminToken', res.data.accessToken);
                this.modalRef.hide();
                localStorage.setItem('BussinessVerified', 'true');
                localStorage.setItem('state', 'true');
                if(localStorage.getItem('bannerLink')){
                  this.bannerRedirect(localStorage.getItem('bannerLink'), 
                  localStorage.getItem('bannerSearchtext'), localStorage.getItem('bannerCategoryid'))
                }else{
                  this.router.navigate(['Buyer']);
                }
              }

            } else {
              localStorage.setItem('name', res.data.name);
              localStorage.setItem('profilePic', res.data.profilePic);
              localStorage.setItem('id', res.data._id);
              localStorage.setItem('BussinessVerified', res.data.businessVerified);
              localStorage.setItem('adminToken', res.data.accessToken);
              this.modalRef.hide();
              localStorage.setItem('BussinessVerified', 'true');
              if(localStorage.getItem('bannerLink')){
                this.bannerRedirect(localStorage.getItem('bannerLink'), localStorage.getItem('bannerSearchtext'), localStorage.getItem('bannerCategoryid'))
              }else{
                this.router.navigate(['Buyer']);
              }
              localStorage.setItem('city', res.data.businessAddress.city);
              localStorage.setItem('state', res.data.businessAddress.state);
              localStorage.setItem('latitude', res.data.location ? (res.data.location.coordinates[1].toString()):'');
              localStorage.setItem('longitude', res.data.location ? (res.data.location.coordinates[0].toString()):'');
              
            }
          }
          else {
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('profilePic', res.data.profilePic);
            localStorage.setItem('id', res.data._id);
            localStorage.setItem('BussinessVerified', res.data.businessVerified);
            localStorage.setItem('adminToken', res.data.accessToken);
            this.modalRef.hide();
            localStorage.setItem('BussinessVerified', 'true');
            if(localStorage.getItem('bannerLink')){
              this.bannerRedirect(localStorage.getItem('bannerLink'), localStorage.getItem('bannerSearchtext'), localStorage.getItem('bannerCategoryid'))
              
            }else{
              this.router.navigate(['Buyer']);
            }
            this.phoneNumber = '';
          }
        })
       

    } else {

      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }



  bannerRedirect(link, searchText, categoryId){
    setTimeout(() => {
      localStorage.removeItem('bannerLink'), 
      localStorage.removeItem('bannerSearchtext'), 
      localStorage.removeItem('bannerCategoryid')
    }, 10000);

    if(searchText != ''){
      console.log(link);
      console.log('s',searchText);
      let search = link.split('/')
      let host = search[0]+'//'+search[2]+'/';
      let path = link.replace(host,'');
      console.log('p',path);
      let search2 = path.split('?')[0];
      console.log('p1',search2);
      if(search2 == 'buyerlistingSearch'){
        var decrypted = CryptoJS.AES.decrypt(searchText, this.secretCode);
        this.decrypted = decrypted.toString(CryptoJS.enc.Utf8);

        localStorage.setItem('searchText1', this.decrypted)
        localStorage.setItem('searchText', '')
        this.router.navigateByUrl('buyerlistingSearch');

       }else{
        let id = search2.split('/')[1];
        console.log(id);
        localStorage.setItem('searchText1', '')
        localStorage.setItem('searchText', searchText)
        this.router.navigateByUrl('Buyerlisting/'+id);
      }
      // let id = search2.split('/')[1];
      // console.log(id);
      // localStorage.setItem('searchText', searchText)
  

    }else if(categoryId != ''){
      this.router.navigateByUrl('Buyerlisting/ProductDetail/'+ categoryId);
    }else{
      let search = link.split('/')
      let host = search[0]+'//'+search[2]+'/';
      let path = link.replace(host,'');
      this.router.navigate([path]);
    }

  }
  loginEnter(template, form) {
    this.loginpagelogin(template, form);
  }


  loginpagelogin(template, form) {
    console.log(form.valid)
    if (form.valid) {
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,         
        'platform': 'WEB',
        'isLogin':'1',
        'deviceToken': this.getDeviceToken()
      };
      this.IS.submitForm(data, apiUrl._postvalidationphno)
        .subscribe(async (res) => {
          // this.varification='';
          this.loginsForm.patchValue({
            phoneNumber: form.value.phoneNumber,
          });
          this.modalRef.hide();
          this.modalRef = this.modalService.show(template,{
            animated: true,
            backdrop: 'static',
          });
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
 
  enterFullName(event , next){   
    if (event.key === "Enter" || event.key === 13) {
      next.focus();
    }    
  }  
  enterEmailid(event,next){
    if (event.key === "Enter" || event.key === 13) {
      next.focus();
    }   
  }
  enterReferralCode(event,form){
    if (event.key === "Enter" || event.key === 13) {
      this.updateProfile(form)
    }   
  }



  updateProfile(form) {
    console.log(form.valid)

    if (form.valid) {
      // let accestoken = localStorage.getItem('adminToken');
      // var authorization  = accestoken;
      const data = {
        // 'authorization':'Bearer ' + this.tokenCheck,
        'name': form.value.name ? (form.value.name) : '',
        'email': form.value.email,
        'phoneNumber': this.phoneNumber || '+91-' + form.value.phoneNumber,
        'image': this.uploadImage ? ( this.uploadImage):'',
        'referredBy': form.value.referredBy ? (form.value.referredBy):'',
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken()
      };
 
      console.log(data, "data")
      // delete data.authorization;

      this.IS.updateForm(data, apiUrl._putUpdateprofile)
        .subscribe(async (res) => {
          this.admin.loader(true);
         this.admin.showLoader = true;
          localStorage.setItem('BussinessVerified', res.data.businessVerified);
          localStorage.setItem('name', res.data.name);
          localStorage.setItem('profilePic', res.data.profilePic);
          localStorage.setItem('adminToken', res.data.accessToken);
          //this.modalRef.hide();
          //this.router.navigate(['Buyer']);
          ////////////////////////////////////////
          var userId = res.data._id.toString().trim();
          this.db.list(`Users`,
            query => query
              .orderByChild("user_id")
              .equalTo(userId)
              .limitToLast(10)
          ).valueChanges().subscribe(list => {
            /*this.messageList = list ? list : [];*/
            console.log('Mohit here:::::',list);
            /////////////////////////////////////
            if (list.length == 0) {
              var userId = res.data._id.toString().trim();
              var accessToken = res.data.accessToken;
              var deviceToken = this.getDeviceToken();
              var plateformStatus = 3;
              var userName = res.data.name;
              var profilePic = res.data.profilePic;
              //console.log('Mohit here:::::',list);
              var chatDialog = this.db.object(`Users/${userId}`).set({
                "access_token": accessToken.toString().trim(),
                "device_token": deviceToken.toString().trim(),
                "platform_status": plateformStatus,
                "user_id": userId.toString().trim(),
                "user_name":userName ? (userName.toString().trim()):'',
                "user_pic": profilePic? (profilePic.toString().trim()):'',
              });
              this.modalRef.hide();
              this.admin.loader(false);
              this.admin.showLoader = false;
              /////////////////////////////////
              this.router.navigate(['Buyer']);
             
            } else {
              this.modalRef.hide();
              this.admin.loader(false);
              this.admin.showLoader = false;
              this.router.navigate(['Buyer']);
              
            }
          });
          /////////////////////////////////////////

          // this.modalRef = this.modalService.show(template);
        })
      // }else if(this.uploadImage == null || this.uploadImage == undefined || this.uploadImage == ''){
      //   this.showError1=true;
      //   console.log(form.valid)
      //   setTimeout(()=>{
      //     this.showError1 = false;
      //    },5000);
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  resend() {
    console.log(1);
    if (this.form.value.phoneNumber) {
      const data = {
        'phoneNumber': '+91-' + this.form.value.phoneNumber,      
        'otpBy': 1,
        'otpRequestType': 2
      };
      this.IS.updateForm(data, apiUrl._putGenrateOTP)
        .subscribe(async (res) => {
          this.isDisable = false;
          this.admin.showAlert('OTP Resent Successfully', '');
          this.waitingInterval = res.data.waitingInterval;
          this.config = {
            leftTime:"45",
            format: `mm:ss`
          }         
          setTimeout(() => {
            this.isDisable = true;
          }, 30000);
        })
    } else {
      this.resenderror = true;
      setTimeout(() => {
        this.resenderror = false;
      }, 5000);
    }

  }
  ResetPin(template) {
    if (this.form.value.phoneNumber) {
      const data = {
        'phoneNumber': '+91-' + this.form.value.phoneNumber,
        'otpBy': 1,
        'otpRequestType': 1
      };
      this.IS.updateForm(data, apiUrl._putGenrateOTP)
        .subscribe(async (res) => {
          this.modalRef.hide();
          this.varification='';
          this.modalRef = this.modalService.show(template,{
            animated: true,
            backdrop: 'static',
          });
          //this.admin.showAlert('OTP Resent Successfully', '');
          this.waitingInterval = res.data.waitingInterval;
          this.isDisable = false;
          this.config = {
            leftTime: "45",
            format: `mm:ss`
          }
         
          setTimeout(() => {
            this.isDisable = true;
          }, 30000);

        })
    } else {
      this.resenderror = true;
      setTimeout(() => {
        this.resenderror = false;
      }, 5000);
    }
  }
  openModal() {
    this.modalRef = this.modalService.show(this.loginModal,{
      animated: true,
      backdrop: 'static',
    });

    this.form.patchValue({
      phoneNumber: '',
    })
    this.loginsForm.patchValue({
      phoneNumber: '',
      password: '',
    })
    this.updateProfileForm.patchValue({
      name: '',
      email: '',
      referredBy: '',
    })
    this.uploadImage = '';
    this.urlfile = '';
    this.password1 = '';
    this.password2 = '';
    this.varification = '';
  }
  loginpage(template) {
    this.form.patchValue({
      phoneNumber: '',
    })
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template,{
      animated: true,
      backdrop: 'static',
    });
  }
  close() {
    this.modalRef.hide();
  }
  openModule(template) {
    this.modalRef = this.modalService.show(template,{
      animated: true,
      backdrop: 'static',
    });
  }


  changeOtp(event, template, form){
    this.varification = event.target.value;
    if (event.key === "Enter" || event.key === 13) {
      this.loginResetOtp(template, form); 
    }
    console.log(this.varification, "this.varification")
  }

  
  // login resetotp check

  loginResetOtp(template, form) {
    
    if (this.varification != '' && this.varification.length > 3) {
      console.log(this.varification)
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'otp': this.varification,
        'otpRequestType': 2,
        'langform':this.browserLang.toString()

      };
      this.IS.updateForm(data, apiUrl._PUTVarifyOTP)
        .subscribe(async (res) => {
          this.loginResetPwd=true;
          this.password1="";
          this.password2="";
          if (this.varification != '') {
            this.updateProfileForm.patchValue({
              phoneNumber: form.value.phoneNumber,
            });
            if (res.message == 'Success') {
              this.token = res.data.token;

              // localStorage.setItem('adminToken', this.token);
            }
             this.showLoginPopup=true;
            this.modalRef.hide();
           
            this.modalRef = this.modalService.show(template, { class: "my-class-modal", animated: true,
            backdrop: 'static', });
          }
        })
    } else if (this.varification.length < 3 || this.varification.length == 3) {
      this.otperror = true;
      setTimeout(() => {
        this.otperror = false;
      }, 5000);
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if(deviceToken)
      return deviceToken.toString() 
      else 
      return  "NOTOKEN";
    }
  }
  loginPwd(event, loginPin){
    console.log(loginPin)
    loginPin.focus();
  }






}
