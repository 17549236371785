<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view rating_pro pdb_0 trend_dtl_page">
        <div class="container-fluid">
            <div class="row g_bk_trends">
                <div class="col-md-12 trend_ss">
                    <a [routerLink]="['/trendslisting']" class="go_back"><img src="./assets/images/back.svg"> Back </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="left_profile_detail">
                        <div class="product_slt">
                            <h5>Select Product & Service</h5>
                            <div class="form_cont" (click)="trendslisting3()">
                                <label>Select Product</label>
                                <span class="slt_wrap">
                <select>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                </select>
              </span>
                            </div>
                            <a class="m_btn_red btn_red btn_gry">Show Results</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="right_profile_detail">
                        <div class="pricing_tbl">
                            <p class="para_trend">Result based on your search</p>
                            <table class="table">
                                <thead class="thead-red">
                                    <tr>
                                        <th scope="col">Mandi</th>
                                        <th scope="col">Today Price</th>
                                        <th scope="col">Weekly Price</th>
                                        <th scope="col">Monthly Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mandi Name, Sector 26, Chandigarh</th>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                        <td><i class="fa fa-rupee"></i> 1421.60</td>
                                    </tr>
                                    <tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>