<app-header></app-header>
<main class="main_cont">
    <!-- Profile -->
    <section class="profile_page_view profile_view2 buyer_pro_dtl sell_pro4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="left_profile_detail">
                        <a (click)="goBack()" href="javascript:;" class="go_back"><img src="./assets/images/back.svg"> Back</a>

                        <div class="single_product">
                            <div class="container-fluid" style=" background-color: #fff; padding: 11px;">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="sell_near left_cont_dtl">
                                            <div class="detail_pro">
                                                <h5> {{allData.productdata?.subCategoryName?(allData.productdata?.subCategoryName):''}}</h5>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-9 col-md-12 col-sm-9">
                                                <div class="image_selected">
                                                    <img *ngIf="!selectedVideoImage?.fileType" class="height-100" src="{{selectedVideoImage?.file ? s3url+'products/' + selectedVideoImage?.file:'./assets/images/egg.png'}} " alt="">

                                                    <video id="video" #video *ngIf="selectedVideoImage?.fileType" class="imageheight w-100" controls="true" autoplay>
                                                        <source
                                                            src="{{selectedVideoImage?.file ? s3url+'products/'+ selectedVideoImage?.file :'./assets/images/egg.png'}}"
                                                            type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>


                                            <div class="col-lg-3 col-md-12 col-sm-3">
                                                <ul class="image_list">
                                                    <li data-image="" *ngFor="let opt of showImageArray;let i = index" class="active" [class.active]="step== i" (click)="step= i">
                                                        <span (click)="selectImageVideo(i, opt)">
                                                            <img src="{{ s3url+'products/' + opt?.file}}" alt=" "
                                                                *ngIf="!opt.fileType">

                                                            <video *ngIf="opt.fileType" class="imageheight w-100"
                                                                #videoPlayer>
                                                                <source src="{{ s3url+'products/' + opt?.file}}"
                                                                    type="video/mp4" />
                                                            </video>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-9 col-sm-9">
                                                <a (click)="createChatDialog(allData.productId)" class="m_btn_red btn_red offr_dl btn_ylow">Chat</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-7 order-3">
                                        <div class="product_description">
                                            <div class="sell_near">
                                                <div class="detail_pro fullWidth">

                                                    <!-- <img class="widthset profile_pc mr-2" src="{{allData.buyerData[0]?. = this.showImageArray[0];?.length != 0 ?s3url+'users/' + allData?.businessDocuments : './assets/images/default.jpg'}}"> -->

                                                    <!-- <h5>{{allData.buyerData[0]?.businessName}}
                                                       
                                                    </h5> -->
                                                    <!-- <div class="approved_s">
                                                        <span class="code_red">{{allData?.userUniqueId}}</span>
                                                        <span class="verify" *ngIf="allData?.businessVerified == true">
                                                            <i class="fa fa-check"></i>Verified
                                                        </span>
                                                        <span class="verify warning" *ngIf="allData?.businessVerified == false">
                                                            Unverified
                                                        </span>

                                                        <span><i class="fa fa-star"></i> {{allData?.userRating |
                                                            number:'1.1-1'}} </span>
                                                        <div class="loc_mark"><img src="./assets/images/loc-gray.svg"> {{allData?.businessAddress?.addressLine1}}
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <p>{{allData.productdata?.description}}</p>
                                            </div>

                                            <div class="selectd_type">
                                                <ul>
                                                    <li>Type Of {{categoryData?.name}}
                                                        <strong>{{allData.productdata?.subCategoryName?(allData.productdata?.subCategoryName):''}}</strong>
                                                    </li>
                                                    <li *ngFor="let opt of allData.productdata?.attributesData;let i = index">
                                                        {{opt?.attrName}}<strong>{{opt?.traitName ? opt?.traitName
                                                            :'-'}}</strong></li>



                                                    <li class="edit_opt" *ngIf="allData.productdata?.extraOffer>0 ">Offer (in %)

                                                        <strong> {{allData.productdata?.extraOffer ? (allData.productdata?.extraOffer):''}}</strong>

                                                    </li>

                                                    <li *ngIf="allData.productdata?.quantity" class="edit_opt">Selling Quantity
                                                        <div class="input-group">
                                                            <strong>{{allData.productdata?.quantity}} <label>{{allData.productdata?.quantityUnit ?
                                                                    (allData.productdata?.quantityUnit):''}}</label></strong>

                                                        </div>

                                                    </li>
                                                    <li class="edit_opt" *ngIf="allData.productdata?.price>0">Price<strong class="bld">
                                                            <i class="fa fa-rupee"></i>
                                                            {{allData.productdata?.price}}<label>/{{allData.productdata?.priceUnit ?
                                                                (allData.productdata?.priceUnit):''}}</label>
                                                        </strong>

                                                    </li>
                                                    <li class="edit_opt" *ngIf="allData.productdata?.moq>0">Minimum Order Quantity
                                                        <strong class="bld">
                                                            {{allData.productdata?.moq}}<label>/{{allData.productdata?.moqUnit ?
                                                                (allData.productdata?.moqUnit):''}}</label>

                                                        </strong>

                                                    </li>

                                                    <li *ngIf="hidePriceNegotiate">Is Price Negotiable
                                                        <strong>{{allData.productdata?.negotiatePrice}}</strong></li>
                                                    <li *ngIf="hideIsDelivery">is Delivery Available
                                                        <strong>{{allData.productdata?.isDeliveryAvailable == true
                                                            ? 'Yes' :'No'}}</strong></li>
                                                    <li *ngIf="Data?.deliveryArea>0">Area of Delivery (in KM)
                                                        <strong>{{allData.productdata?.deliveryArea ?
                                                            (allData.productdata?.deliveryArea):''}}</strong></li>


                                                    <li *ngIf="allData?.loadingDate">Loading Date
                                                        <strong>{{allData?.loadingDate}}</strong>
                                                    </li>
                                                    <li class=""><span *ngIf="!addressLabel">Business
                                                            Address</span><span *ngIf="addressLabel">Clinic
                                                            Address</span> <strong class="bld">{{allData.productdata?.address?.googleAddress}}</strong></li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>