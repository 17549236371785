import { TemplateRef, Component, OnInit, Injectable, ViewChild, NgZone, ElementRef, ViewChildren, ContentChild, QueryList } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { seller2Service } from './seller2.service'
import { InternalService } from '../internal.service';
import { NoWhitespaceValidator } from '../../services/NoWhiteSpace.validator';
import * as moment from 'moment'
// import { DatePipe } from '@angular/common';
import { apiUrl } from '../../global/global';
import { HttpParams } from '@angular/common/http';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FcmserviceService } from './../../services/fcmservice.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BusinessModalComponent } from './../../Common/business-modal/business-modal.component';
declare var Razorpay: any;

@Component({
  selector: 'app-sellernewproductstep2',
  templateUrl: './sellernewproductstep2.component.html',
  styleUrls: ['./sellernewproductstep2.component.css']
})
export class Sellernewproductstep2Component implements OnInit {
  showMotherBreedName = false;
  attributesError = false;
  modalRef: BsModalRef;
  allData: any = [];
  s3url: any;
  isDeliveryError = false;
  showValueError = false;
  changeAddresslable = "Loading Address";
  hintshow: boolean = false;
  priceError: boolean = false;
  moqError: boolean = false;
  showCalander = false;

  datePickerConfig: Partial<BsDatepickerConfig>;
  quantityerror: boolean = false;
  moqsellingError: boolean = false;
  hint: any = '';
  sellingPricerselectError = false;
  moqselectError = false;
  priceNegoShow = true;
  minimumLengthError = false
  categoryList: any;
  todayDate = new Date();
  currentPlan: any;
  subCategoryList: any;
  priceUnitData: any = [];
  quantityUnitData: any = [];
  moqUnitData: any = [];
  attributesList: any = [];
  traitsData: any = [];
  traitsList: any = [];
  subTraitsData: any = [];
  subCategoryHeading = '';
  subCategoryNameError = false;
  userForm: FormGroup;
  showError = false;
  productId: string;
  category: string;
  categoryId = '';
  categoryIdname = '';
  subCategoryId = '';
  negotiable = 'Yes';
  subCategoryName = '';
  imageUpload: any = [];
  attributesList1: any = [];
  attributesData1: any = [];
  attributesData: any = [];
  addImageArray = [];
  Delivery = 'Yes';
  imageArray = [];
  imageLengthError = false;
  videoLengthError = false
  googleAddress = false;
  imageExtensionError = false;
  validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
  myDate = new Date();
  startDate;
  planAmount: any;
  basicAmount: any;
  minDate = moment(new Date()).format('YYYY-MM-DD');
  plansList: any;
  planPaymet = '';
  showPayment = 0;
  phoneNumber: any;
  userId: any;
  planId: any;
  checkCategoryName: any;
  zoom: number;
  private geoCoder;
  address: string;
  gMap: any;
  mapPlot: any
  mapSector: any;
  mapLocality: any;
  mapCity: any;
  mapState: any;
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapStateshort: any;
  latitude = 51.678418;
  longitude = 7.809007;
  planSubscription: any;
  planVisibility: any;
  planName: any;
  planTrade: any;
  isReadOnly: boolean = false;
  addressCheck = 0;
  showOfferValue = "No";
  showOfferValueError = false;
  hideQuantity = true;
  hideSellingPrice = true;
  hideMoq = true;
  hideDelivery = true;
  moqErrorReq = false;
  sellingPricerReq = false;
  quantityError = false;
  quantityUnit: any;
  priceUnit: any;
  maqUnit: any;

  // date = new FormControl('', [Validators.required, Validators.min(moment(new Date()).millisecond())])

  todaydate = '';
  @ViewChild('search')
  public searchElementRef: ElementRef;
  constructor(
    // private datePipe: DatePipe,
    private modalService: BsModalService,
    private router: Router,
    private ss: seller2Service,
    public admin: JsonPlaceHolderService,
    private IS: InternalService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private messagingService: FcmserviceService,
  ) {

    // this.todaydate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.userForm = formBuilder.group({
      isDeliveryAvailable: [''],
      isDeliveryinkm: [''],
      otherSubcategory: [''],
      estimatedLoadingDays: [''],
      quantity: [''],
      quantityType: [''],
      priceType: [''],
      moq: [''],
      price: [''],
      moqType: [''],
      extraOffer: [''],
      image: ['', Validators.required],
      description: [''],
      googleAddress: ['', Validators.required,],
    });
  }

  ngOnInit(): void {
    this.s3url = localStorage.getItem('s3BaseUrl');
    this.todaydate = new Date(this.myDate).toLocaleDateString("en-us")
    this.category = this.route.snapshot.paramMap.get('category');
    this.productId = this.route.snapshot.paramMap.get('id');
    console.log(this.productId)
    if (this.productId) {

      this.getProductDetails();
      this.isReadOnly = true;
      this.addressCheck = 1;
    } else {
      this.userForm.patchValue({
        'isDeliveryAvailable': true
      })
      /*get user plan details*/
      this.currentPlandata(this.category);
      this.getCategories(1);
      this.getuserdetails();
    }

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: "IN" }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.gMap = place;
          console.log('place', place);
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)

        });
      });
      autocomplete.setComponentRestrictions

    });
  }
  /*current plan api */
  currentPlandata(category) {
    let params = new HttpParams();
    params = params.append('categoryId', category);
    this.IS.getList(params, apiUrl._getCurrentplan)
      .subscribe(res => {
        this.currentPlan = res.data;
        if (res.data.purchaseQuantity != null) {
          this.planAmount = res.data.purchaseQuantity;
        }
        if (res.data.basicPlan != null) {
          if (res.data.basicPlan.planPrice == '0') {
            this.basicAmount = res.data.basicPlan.quantity;
          } else {
            this.basicAmount = 0;
          }
        }

        this.planAmount = this.planAmount + this.basicAmount
        this.planAmount = this.planAmount - res.data.usedQuantity
      },
        err => {

        });
  }
  /*current plan api end */

  /*get plan list*/
  getPlanlist(category) {
    let params = new HttpParams();
    params = params.append('categoryId', category);
    this.IS.getList(params, apiUrl._getPlanlisting)
      .subscribe(res => {
        this.plansList = res.data[0];
        // this.planId = res.data[0]._id;       
      },
        err => {

        });
  }
  /*get plan list end*/

  /*plan api hit */
  planChoise(template) {
    this.close();
    this.planPaymet = '';
    this.getPlanlist(this.category)
    this.modalRef = this.modalService.show(template, { class: "my-class-modals" });
  }

  /*plan select */
  onlyOneValue(event, plan) {
    console.log(event.target.value);
    this.showPayment = 1;
    this.planPaymet = plan.planPrice;
    this.planId = plan._id;
    this.planName = plan.planType;
    this.planVisibility = plan.visibility;
    this.planTrade = plan.quantity;
    this.planSubscription = plan.subscription;
    this.userId = localStorage.getItem('id');
    this.phoneNumber = localStorage.getItem('phone').substring(localStorage.getItem('phone').length - 10, localStorage.getItem('phone').length)

  }
  /*plan buy api */
  /*amount pay and razerpay */
  payment() {
    const data = {
      'planId': this.planId,
      'planType': 'PLAN',
    };
    this.IS.submitForm(data, apiUrl._postPayment)
      .subscribe(async (res) => {
        console.log('payment data', res.data)
        this.modalRef.hide();
        this.buyRazorPay(res.data);
      })
  }

  buyRazorPay(razorPaData) {
    const data = {
      "key": "rzp_test_i3r1BLKbRv61mu",
      "id": razorPaData.id,
      "entity": razorPaData.entity,
      "amount": razorPaData.amount,
      "amount_paid": razorPaData.amount_paid,
      "amount_due": razorPaData.amount_due,
      "currency": razorPaData.currency,
      "receipt": razorPaData.receipt,
      "status": razorPaData.status,
      "attempts": razorPaData.attempts,
      "notes": [],
      "created_at": razorPaData.reated_at,
      "prefill": {
        "email": "egiiyok@gmail.com",
        "contact": this.phoneNumber,
      },
      "theme": {
        "color": "#ae0019"
      },
      "handler": (response) => {
        console.log('razorPay response', response);
        const data = {
          "planData": [{
            "planId": this.planId,
            "planName": this.planName,
            "visibility": this.planVisibility.toString(),
            "trade": this.planTrade.toString(),
            "price": razorPaData.amount.toString(),
            "subscriptionType": this.planSubscription,

          }],
          "planId": this.planId,
          "userId": this.userId,
          "razorpayPaymentId": response.razorpay_payment_id,
          "razorpayOrderId": response.razorpay_order_id,
          "razorpaySignature": response.razorpay_signature,
          "amount": razorPaData.amount,
          "paymentType": "SUBSCRIPTION",
        }



        this.IS.submitForm(data, apiUrl._purchasePlan)
          .subscribe(async (res) => {
            this.admin.showAlert('Payment Successfully Done', '');
            this.pointRefer();
            setTimeout(() => {
              this.router.url.indexOf("/seller");
            }, 2000);
            this.currentPlandata(this.category);
          })

      },
    }
    var rzp1 = new Razorpay(data);
    rzp1.on('payment.failed', function (data) {
      alert(data.error.metadata.order_id);
      alert(data.error.metadata.payment_id);
    });
    var rezp1 = new Razorpay(data)
    rezp1.open()
    console.log('opened');

  }
  /*amount pay and razerpay end*/

  onValueChange(event, type) {

  }
  open(template) {
    this.modalRef = this.modalService.show(template);
  }

  close() {
    this.modalRef.hide();
  }
  selectedValueForDelivery(event) {
    this.Delivery = event.target.value;
    if (event.target.value == 'Yes') {
      this.userForm.patchValue({
        'isDeliveryAvailable': true
      })
    } else {
      this.userForm.patchValue({
        'isDeliveryAvailable': false
      })
    }
  }
  getProductDetails() {
    let params = new HttpParams();
    params = params.append('id', this.productId);
    this.IS.getList(params, apiUrl._getProductDetails)
      .subscribe(res => {

        this.allData = res.data[0];
        this.currentPlandata(this.allData.categoryId);
        console.log("product details ===", this.allData)

        let deliveryType = this.allData.isDeliveryAvailable.toString();
        if (deliveryType == 'true') {
          this.Delivery = 'Yes';
          this.userForm.patchValue({ 'isDeliveryinkm': this.allData.deliveryArea });

        } else {
          this.Delivery = 'No';
        }

        console.log(this.Delivery, " this.Delivery")
        this.negotiable = this.allData.negotiatePrice;
        this.quantityUnit = this.allData.quantityUnit;
        this.priceUnit = this.allData.priceUnit;
        this.maqUnit = this.allData.moqUnit;
        this.categoryIdname = this.allData.categoryData.name
        //extraOffer


        let categoryData = this.categoryIdname.split(' ').filter(s => s).join(' ').toLowerCase();
        if (categoryData == "doctor consultancy") {
          this.priceNegoShow = false;
          this.hideDelivery = false;
        }
        if (categoryData == "cold store") {
          const catSubName = this.allData.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
          if (catSubName == "chicken") {
            this.priceNegoShow = false;
          } else {
            this.priceNegoShow = true;
          }
        }
        if (categoryData == "lease/sale/rent services") {
          this.hideDelivery = false;
          this.Delivery = 'No';
        }
        if (categoryData == "manpower") {
          const catSubName = this.allData.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
          if (catSubName == "poultry manpower") {
            this.priceNegoShow = false;
            this.hideDelivery = false;
            this.Delivery = 'No';
          } else if (catSubName == 'poulitrey manpower ') {
            this.priceNegoShow = false;
            this.hideDelivery = false;
            this.Delivery = 'No';
          } else {
            this.priceNegoShow = true;
            this.hideDelivery = false;
            this.Delivery = 'No';
          }
        }

        for (let i = 0; i < this.allData.attributesData.length; i++) {
          let attrName = this.allData.attributesData[i].attrName;
          attrName = attrName.split(' ').filter(s => s).join(' ').toLowerCase();
          if (attrName == "provide extra (offer)") {
            if (this.allData.attributesData[i].traitName == "Yes") {
              this.userForm.patchValue({ 'extraOffer': this.allData.extraOffer });
              this.showOfferValue = "Yes";
            } else {
              this.showOfferValue = "No";
            }
          }
        }

        this.userForm.patchValue({
          'estimatedLoadingDays': this.allData.estimatedLoadingDays,
          'Date': this.allData.loadingDate,
          'subCategoryId': this.allData.subCategoryId,
          'subCategoryName': this.allData.subCategoryName,
          // 'attributesData': this.attributesData,

          'quantity': this.allData.quantity,
          'moq': this.allData.moq + ' ' + this.allData.moqUnit,
          'price': this.allData.price,
          'quantityType': this.allData.quantityUnit,
          'priceType': this.allData.priceUnit,
          'description': this.allData.description,
          'googleAddress': this.allData.address.googleAddress,
          'streetAddress': this.allData.address.streetAddress,
          'city': this.allData.address.city,
          "state": this.allData.address.state,
          'country': this.allData.address.country,
          'pinCode': this.allData.address.pinCode,
          'latitude': this.allData.location.coordinates[1],
          'longitude': this.allData.location.coordinates[0],
        });
        this.address = this.allData.address.googleAddress;
        this.subCategoryName = this.allData.subCategoryName,
          // data['images'] = this.addImageArray;
          this.allData.subCategoryName,
          this.mapCity = this.allData.address.city;
        this.mapPlot = this.allData.address.streetAddress ? (this.allData.address.streetAddress) : '';
        this.mapPostal = this.allData.address.pinCode;
        this.mapState = this.allData.address.state;
        this.mapcountry = this.allData.address.country;
        this.latitude = this.allData.location.coordinates[1];
        this.longitude = this.allData.location.coordinates[0];
        //this.imageArray = this.allData.images;
        for (var i = 0; i < this.allData.images.length; i++) {
          let fileType = this.allData.images[i].image.split(".")[1];
          let isVideo = true;
          if (this.fileTypeChecker(fileType)) {
            isVideo = false
          }
          let file = {
            fileType: isVideo,
            file: this.allData.images[i].image
          }
          this.imageUpload.push(file);
        }
        this.userForm.patchValue({
          'image': this.imageUpload,
        })
        for (let i = 0; i < this.imageUpload.length; i++) {
          this.addImageArray.push({ 'image': this.imageUpload[i].file })
        }

        this.getCategories(2);
        // this.imageSrc = res.data.admin.profile_pic;
      },
        err => {
          // let root = this.router.url.indexOf("/editCustomer/" + this.userId);
          // this.router.navigate([this.router.url.slice(0, root)]);
        });
  }
  decimalValue(e: any) {
    var character = String.fromCharCode(e.keyCode)
    console.log(character, "---")
    var newValue;
    newValue = e.target.value + character;
    console.log(newValue, "====")
    if (isNaN(newValue) || this.hasDecimalPlace(newValue, 3)) {
      e.preventDefault();
      return false;
    }
  }

  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return pointIndex >= 0 && pointIndex < value.length - x;
  }


  onSubmit(form, template, business: BusinessModalComponent) {
    let subCategoryName1 = this.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
    if (subCategoryName1 == 'other') {
      if (form.value.otherSubcategory == '') {
        this.subCategoryNameError = true;
        setTimeout(() => {
          this.subCategoryNameError = false;
        }, 5000);
        return false
      } else {
        this.subCategoryName = form.value.otherSubcategory;
      }
    }
    for (let i = 0; i < this.attributesData1.length; i++) {
      let subCatname = this.attributesData1[i].traitName.split(' ').filter(s => s).join(' ').toLowerCase();
      if (subCatname == "") {
        this.attributesError = true;
        console.log(this.attributesData1, "this.attributesData1===")
        setTimeout(() => {
          this.attributesError = false;
        }, 5000);
        return false
      }
      if (subCatname == "other") {
        this.attributesError = true;
        console.log(this.attributesData1, "this.attributesData1===")
        setTimeout(() => {
          this.attributesError = false;
        }, 5000);
        return false
      }
    }


    if (this.showOfferValue == "Yes") {
      if (form.value.extraOffer < 1) {
        this.showOfferValueError = true;
        setTimeout(() => {
          this.showOfferValueError = false;
        }, 4000);
        return false
      }
    }

    if (this.moqUnitData.length == 0) {

      this.moqErrorReq = false;
    } else {
      if (form.value.moq == 0) {
        this.moqErrorReq = true;
        setTimeout(() => {
          this.moqErrorReq = false;
        }, 4000);
        return false
      }
    }

    if (this.quantityUnitData.length > 0) {

      if (this.quantityUnitData.length == 0) {
        this.quantityError = false;
      } else {
        if (form.value.quantity == 0) {
          this.quantityError = true;
          setTimeout(() => {
            this.quantityError = false;
          }, 4000);
          return false
        }
        if (!this.quantityUnit) {
          this.quantityError = true;
          setTimeout(() => {
            this.quantityError = false;
          }, 4000);
          return false
        }
      }



      if (form.value.moq > form.value.quantity) {
        this.moqsellingError = true;
        this.showError = true;
        setTimeout(() => {
          this.moqsellingError = false;
          this.showError = false;
        }, 4000);
        return false
      }
    }
    if (this.moqUnitData.length > 0) {
      if (!this.maqUnit) {
        this.moqselectError = true;
        setTimeout(() => {
          this.moqselectError = false;
        }, 4000);
        return false
      }
    }
    if (this.priceUnitData.length > 0) {
      if (this.priceUnitData.length == 0) {
        this.sellingPricerReq = false;
      } else {
        if (form.value.price == 0) {
          this.sellingPricerReq = true;
          setTimeout(() => {
            this.sellingPricerReq = false;
          }, 4000);
          return false
        }
        if (!this.priceUnit) {
          this.sellingPricerselectError = true;
          setTimeout(() => {
            this.sellingPricerselectError = false;
          }, 4000);
          return false
        }
      }
    }
    if (this.Delivery == 'Yes') {
      if (form.value.isDeliveryinkm < 1) {
        this.isDeliveryError = true;
        setTimeout(() => {
          this.isDeliveryError = false;
        }, 5000);
        return false
      }
    }

    if (this.addImageArray.length || this.imageArray.length) {
      this.userForm.patchValue({ 'image': 1 })
    } else this.userForm.patchValue({ 'image': '' })

    console.log(this.attributesData1, "this.attributesData1==")
    //?traitsData ?
    if (form.valid) {
      if (this.address == '' || this.address == null) {
        this.googleAddress = true;
        setTimeout(() => {
          this.googleAddress = false;
        }, 2000);
        return;
      }
      let BussinessVerified: any
      BussinessVerified = localStorage.getItem('BussinessVerified');
      if (BussinessVerified == false || BussinessVerified == 'false') {
        business.openBusinessModel();
        return false
      } else {

        let data = {
          'categoryId': this.categoryId,
          'userId': localStorage.getItem('id'),
          'estimatedLoadingDays': String(form.value.estimatedLoadingDays),
          'subCategoryId': this.subCategoryId,
          'price': form.value.price ? String(form.value.price) : '0',
          'description': form.value.description,
          'longitude': this.longitude,
          'latitude': this.latitude,
          'googleAddress': this.address,
          'streetAddress': this.mapPlot,
          'pinCode': this.mapPostal,
          'city': this.mapCity,
          'state': this.mapState,
          'country': this.mapcountry,
          'isDeliveryAvailable': this.Delivery == 'Yes' ? 'true' : 'false',
          'subCategoryName': this.subCategoryName,
          'images': this.addImageArray,
        };
        const url = (this.productId) ? apiUrl._puteditProductDetail : apiUrl._addProduct;
        if (this.productId) {
          console.log('edit', form.value.quantity);
          console.log('edit', this.planAmount);
          let total = this.allData.quantity + this.planAmount;
          if (form.value.quantity > total) {
            this.IS.errorAlerts(total);
            return
          }

          data['quantity'] = String(form.value.quantity);
          data['loadingDate'] = form.value.Date;
          data['productId'] = this.productId;
          data['priceUnit'] = this.priceUnit ? (this.priceUnit.toString()) : '';
          data['quantityUnit'] = this.quantityUnit ? (this.quantityUnit.toString()) : '';
          this.IS.updateForm(data, url)
            .subscribe(res => {
              console.log("response", res);
              if (this.category) {
                this.IS.showAlert('Product Added successfully', '');
                // let root = this.router.url.indexOf("seller/category/add-product");
                this.router.navigateByUrl('seller');
              }
              else {
                this.IS.showAlert('Product Updated successfully', '');
                this.router.navigateByUrl('seller');
                // let root = this.router.url.indexOf("/seller");
                // this.router.navigate([this.router.url.slice(0, root)]);
              }
            });
        } else {

          //check current Plan 
          // if (this.currentPlan.purchaseQuantity <= this.currentPlan.usedQuantity) {      
          //   this.modalRef = this.modalService.show(template, { class: "my-class-modals" });
          //   return false

          // }
          data['negotiatePrice'] = this.negotiable;
          data['loadingDate'] = form.value.Date ? form.value.Date : '';
          data['attributesData'] = this.attributesData1;
          data['moq'] = String(form.value.moq);
          data['quantity'] = String(form.value.quantity);
          data['deliveryArea'] = form.value.isDeliveryinkm ? (String(form.value.isDeliveryinkm)) : '0';
          data['priceUnit'] = this.priceUnit ? (this.priceUnit.toString()) : '';
          data['moqUnit'] = this.maqUnit ? (this.maqUnit.toString()) : '';
          data['quantityUnit'] = this.quantityUnit ? (this.quantityUnit.toString()) : '';
          data['extraOffer'] = form.value.extraOffer ? (form.value.extraOffer.toString()) : "";
          this.IS.submitForm(data, url)
            .subscribe(res => {
              console.log("response", res);
              if (this.category) {
                this.IS.showAlert('Product Added successfully', '');
                // let root = this.router.url.indexOf("seller/category/add-product");
                this.router.navigateByUrl('seller');
              }
              else {
                this.IS.showAlert('Product Updated successfully', '');
                let root = this.router.url.indexOf("/seller");
                this.router.navigate([this.router.url.slice(0, root)]);
              }
            });
        }
      }
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  selectedValueForCompany(event) {
    this.negotiable = event.target.value;
  }
  upload(event, text, business: BusinessModalComponent) {
    let BussinessVerified: any
    BussinessVerified = localStorage.getItem('BussinessVerified');
    if (BussinessVerified == false || BussinessVerified == 'false') {
      business.openBusinessModel();
      return false
    } else {

      if (this.imageUpload.length == 3) {
        this.imageLengthError = true;
        setTimeout(() => {
          this.imageLengthError = false;
        }, 5000);
        return false;
      }
      if (event.target.files && event.target.files.length) {
        const file = event.target.files[0];
        const fd = new FormData();
        fd.append('directory', 'products');
        fd.append('image', event.target.files[0]);

        this.IS.submitForm(fd, apiUrl._postupload)
          .subscribe(async (res) => {

            let fileType = res.data.split(".")[1];
            let isVideo = true;
            if (this.fileTypeChecker(fileType)) {
              isVideo = false
            }
            let file = {
              fileType: isVideo,
              file: res.data
            }
            this.imageUpload.push(file);
            this.addImageArray = [];
            for (let i = 0; i < this.imageUpload.length; i++) {
              this.addImageArray.push({ 'image': this.imageUpload[i].file })
            }
          })
      }
    }
  }

  fileTypeChecker(fileType) {
    let valid = [
      "pdf", "tif", "tiff", "jpg", "jpeg", "gif", "png"
    ];

    if (valid.includes(fileType.toLowerCase())) {
      return true
    }
    return false;
  }

  delete1(i) {
    console.log(this.imageUpload.length, "this.imageUpload.length")
    if (this.productId) {
      if (this.imageUpload.length < 2) {
        this.minimumLengthError = true;
        setTimeout(() => {
          this.minimumLengthError = false;
        }, 5000);
        return false;

      } else {
        this.imageUpload.splice(i, 1);
        this.addImageArray.splice(i, 1);
      }

    } else {
      this.imageUpload.splice(i, 1);
      this.addImageArray.splice(i, 1);
    }

  }


  conform(id) {
    this.modalRef.hide();
    this.router.navigate(['seller/' + id]);
  }
  getProductDetail() {
    let params = new HttpParams();
    params = params.append('id', this.productId);
    this.ss.getList(params, apiUrl._getProductDetails)
      .subscribe(async (res) => {
        console.log(res)
      })
  }

  getCategories(num) {
    this.attributesData = [];
    let params = new HttpParams();
    // params = params.append('categoryId', this.category);
    // console.log("url category",this.category);
    this.IS.getList(params, apiUrl._getCategories)
      .subscribe(res => {
        this.categoryList = res.data.categoryData;
        var subCategoryName = [];
        if (this.categoryList.length) {
          for (let i = 0; i < this.categoryList.length; i++) {
            if (this.categoryList[i]._id == this.category) {
              console.log(1, this.categoryList[i])
              this.categoryIdname = this.categoryList[i].name;
              subCategoryName = this.categoryList[i].subCategory;

            }
          }
          let category = this.category;
          let subName = this.categoryList.find(function (o) {
            if (o._id == '' + category) {
              return o;
            }
          });


          // console.log(subName)
          if (this.productId && num == 2) {
            this.categoryId = this.allData.categoryId;

            this.getSubCategories1(this.categoryId);
          } else {
            this.categoryId = this.category;
            this.subCategoryId = subName._id;
            // this.subCategoryName = subName.name;
            this.subCategoryHeading = subName.name;
            this.getSubCategories(this.categoryId);
          }
          this.categoryIdname = this.categoryIdname.trim();
          this.checkCategoryName = this.categoryIdname.split(' ').filter(s => s).join(' ').toLowerCase();
          console.log(this.checkCategoryName != 'Transportation (rent/sell)', "this.checkCategoryName===")
          if (this.checkCategoryName != 'Transportation (rent/sell)'){
            this.hideDelivery = false;
            this.priceNegoShow = false;
            this.Delivery = 'No';
          }
          if (this.checkCategoryName == 'manpower') {
            for (let i = 0; i < subCategoryName.length; i++) {
              let subCatName = subCategoryName[i].name.split(' ').filter(s => s).join(' ').toLowerCase();
              console.log(subCatName, "subCatName===")
              if (subCatName == 'poultry manpower') {
                this.priceNegoShow = false;
                this.hideDelivery = false;
                this.Delivery = 'No';
              } else if (subCatName == 'poulitrey Manpower ') {
                this.priceNegoShow = false;
                this.hideDelivery = false;
                this.Delivery = 'No';
              } else {
                this.priceNegoShow = true;
                this.hideDelivery = false;
                this.Delivery = 'No';
              }
            }
          }
          if (this.checkCategoryName == 'lease/sale/rent services') {
            this.hideDelivery = false;
            this.Delivery = 'No';
          }

          if (this.checkCategoryName == 'doctor consultancy') {
            for (let i = 0; i < subCategoryName.length; i++) {
              let subCatName = subCategoryName[i].name.split(' ').filter(s => s).join(' ').toLowerCase();

              if (subCatName == 'doctor consultancy') {
                this.priceNegoShow = false;
                this.hideDelivery = false;
                this.Delivery = 'No';
                this.changeAddresslable = "Clinic Address";
              } else {
                this.priceNegoShow = true;
                this.hideDelivery = true;
                this.Delivery = 'Yes';
                this.changeAddresslable = "Business Address";
              }
            }
          }
          if (this.checkCategoryName == 'cold store') {
            for (let i = 0; i < subCategoryName.length; i++) {
              let subCatName = subCategoryName[i].name.split(' ').filter(s => s).join(' ').toLowerCase();

              if (subCatName == 'chicken') {
                this.priceNegoShow = false;
              } else {
                this.priceNegoShow = true;
              }
            }
          }


        }
      },
        err => {
          console.log("error", err)
          // let root = this.router.url.indexOf("/editCustomer/" + this.productId);
          // this.router.navigate([this.router.url.slice(0, root)]);
        });
  }

  getSubCategories1(categoryId) {

    let params = new HttpParams();
    params = params.append('categoryId', categoryId);
    this.IS.getList(params, apiUrl._getSubCategories)
      .subscribe(res => {
        this.subCategoryList = res.data;
        if (res.data.length) {
          this.subCategoryId = this.allData.subCategoryId;
          this.subCategoryName = this.allData.subCategoryName;
        }
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].subCategory._id == this.allData.subCategoryId) {
            console.log(res.data[i].subCategory, "alldata===")
            this.hint = res.data[i].subCategory.hints;
            this.hint = this.hint.replace(/ +(?= )/g, ', ').trim();

            this.priceUnitData = res.data[0].subCategory.priceUnitData;
            this.quantityUnitData = res.data[0].subCategory.quantityUnitData;
            this.moqUnitData = res.data[0].subCategory.moqUnitData;

            var datalist = res.data[i].subCategory.attributesData;
            for (let i = 0; i < datalist.length; i++) {
              this.attributesList.push(datalist[i]);
              this.attributesList1.push(datalist[i]);
              var list = datalist[i];
              for (let i = 0; i < list.traitsData.length; i++) {
                this.traitsList.push(list.traitsData[i])
              }
            }
          }
        }
        this.attributesData = this.allData.attributesData;
        this.attributesData1 = this.allData.attributesData;
        console.log(this.attributesData)
        console.log(this.attributesList1)


      },
        err => {
          console.log("error", err)
          // let root = this.router.url.indexOf("/editCustomer/" + this.productId);
          // this.router.navigate([this.router.url.slice(0, root)]);
        });
  }
  getSubCategories(categoryId) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);
    this.IS.getList(params, apiUrl._getSubCategories)
      .subscribe(res => {

        this.subCategoryList = res.data;
        if (res.data.length) {
          // console.log(this.allData.subCategoryId, res.data[0].subCategory._id)
          this.subCategoryId = res.data[0].subCategory._id;
          // console.log(this.subCategoryId)
          this.subCategoryName = res.data[0].subCategory.name;
        }
        if (res.data[0] && res.data[0].subCategory[0] && res.data[0].subCategory.attributesData && res.data[0].subCategory.attributesData.length) {
          this.attributesData[0].traitId = res.data[0].subCategory.attributesList[0]._id;
          this.attributesData[0].traitName = res.data[0].subCategory.attributesList[0].traitName;
        }
        if (res.data[0] && res.data[0].subCategory && res.data[0].subCategory.attributesData && res.data[0].subCategory.attributesData.length) {
          for (let i = 0; i < res.data[0].subCategory.attributesData.length; i++) {
            this.hint = res.data[0].subCategory.hints;
            this.hint = this.hint.replace(/ +(?= )/g, ', ').trim();

            this.priceUnitData = res.data[0].subCategory.priceUnitData;
            this.quantityUnitData = res.data[0].subCategory.quantityUnitData;
            this.moqUnitData = res.data[0].subCategory.moqUnitData;
            // if (this.priceUnitData.length != 0) {
            //   this.priceUnit = this.priceUnitData[0].name;
            // }
            // if (this.quantityUnitData.length != 0) {
            //   this.quantityUnit = this.quantityUnitData[0].name;
            // }
            // if (this.moqUnitData.length != 0) {
            //   this.maqUnit = this.moqUnitData[0].name;
            // }
            this.attributesList.push(res.data[0].subCategory.attributesData[i]);
            this.attributesList1.push(res.data[0].subCategory.attributesData[i]);
          }
          console.log(this.attributesList1, "data===")
          // this.attributesList1.forEach((item, index) => {
          for (let i = 0; i < this.attributesList1.length; i++) {
            this.attributesData1.push({ 'attrId': this.attributesList1[i]._id, 'attrName': this.attributesList1[i].name, 'traitId': this.attributesList1[i].fieldType == 0 ? this.attributesList1[i].traitsData[0]._id : '', 'traitName': this.attributesList1[i].fieldType == 0 ? this.attributesList1[i].traitsData[0].traitName : '' });
          }
          for (let i = 0; i < this.attributesList1.length; i++) {
            let nameTrait = this.attributesList1[i].name.split(' ').filter(s => s).join(' ').toLowerCase();
            let nameTrait2 = nameTrait.split(" ").find(word => word === "date");
            if (nameTrait2 == 'date') {
              this.showCalander = true;
            }

          }


          // });
          //   console.log(this.attributesData1)
          //   this.attributesData = [{ 'attrId': res.data[0].subCategory.attributesData[0]._id, 'attrName': res.data[0].subCategory.attributesData[0].name, 'traitId': '', traitName: '' }];
          // if(res.data[0].subCategory.attributesData[0].traitsData.length != 0){
          //   for (let i = 0; i < res.data[0].subCategory.attributesData[0].traitsData.length; i++) {
          //   this.traitsList.push(res.data[0].subCategory.attributesData[0].traitsData[i]);

          //   this.attributesData[0].traitId = res.data[0].subCategory.attributesData[0].traitsData[0]._id;
          //   this.attributesData[0].traitName = res.data[0].subCategory.attributesData[0].traitsData[0].traitName;
          // }
          // }
        }
      },
        err => {
          console.log("error", err)
          // let root = this.router.url.indexOf("/editCustomer/" + this.productId);
          // this.router.navigate([this.router.url.slice(0, root)]);
        });
  }

  categoryChange(event) {
    this.categoryId = event.target.value;
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i]._id == this.categoryId) {
        console.log(1, this.categoryList[i])
        this.categoryIdname = this.categoryList[i].name;
      }
    }
    // if (res.data.length) {
    //   // console.log(this.allData.subCategoryId, res.data[0].subCategory._id)
    //   this.subCategoryId = res.data[0].subCategory._id;
    //   // console.log(this.subCategoryId)
    //   this.subCategoryName =  res.data[0].subCategory.name;
    // }
    this.subCategoryList = [];
    this.attributesList = [];
    this.traitsList = [];
    this.getSubCategories(event.target.value)
  }
  change() {
    this.hintshow = true;
    setTimeout(() => {
      this.hintshow = false;
    }, 15000);
  }
  subCategoryChange(event) {
    // this.priceNegoShow = true;
    this.subCategoryId = event.target.value;
    let subCategory = this.subCategoryList.find(function (o) {
      if (o.subCategory && o.subCategory._id == '' + event.target.value) {
        return o;
      }
    });
    this.subCategoryName = subCategory.subCategory.name;
    this.attributesList = [];
    this.attributesData = [];
    this.attributesData1 = [];
    this.attributesList1 = [];
    this.traitsList = [];

    for (let i = 0; i < this.subCategoryList.length; i++) {
      if (event.target.value == this.subCategoryList[i].subCategory._id) {
        console.log(this.subCategoryList[i], "alldata===")
        console.log(this.subCategoryList[i])
        this.hint = this.subCategoryList[i].subCategory.hints;
        this.hint = this.hint.replace(/ +(?= )/g, ', ').trim();

        this.priceUnitData = this.subCategoryList[i].subCategory.priceUnitData;
        this.quantityUnitData = this.subCategoryList[i].subCategory.quantityUnitData;
        this.moqUnitData = this.subCategoryList[i].subCategory.moqUnitData;
        if (this.priceUnitData.length != 0) {
          this.priceUnit = this.priceUnitData[0].name;
        }
        if (this.quantityUnitData.length != 0) {
          this.quantityUnit = this.quantityUnitData[0].name;
        }
        if (this.moqUnitData.length != 0) {
          this.maqUnit = this.moqUnitData[0].name;
        }

        var attribute1 = this.subCategoryList[i].subCategory;
        const mainCategoryName = this.subCategoryList[i].name.split(' ').filter(s => s).join(' ').toLowerCase();
        const subCategoryName = this.subCategoryList[i].subCategory.name.split(' ').filter(s => s).join(' ').toLowerCase();;
        console.log(mainCategoryName, "mainCategoryName===")
        console.log(subCategoryName, "sub cat")
        if (mainCategoryName == 'poultry feed') {
          if (subCategoryName == 'grains') {
            this.priceNegoShow = false;
          } else {
            this.priceNegoShow = true;
          }
        }
        if (mainCategoryName == 'manpower') {
          if (subCategoryName == 'poulitrey manpower') {
            this.priceNegoShow = false;
          } else {
            this.priceNegoShow = true;
          }
        }


        for (let i = 0; i < attribute1.attributesData.length; i++) {
          this.attributesList.push(attribute1.attributesData[i]);
          this.attributesList1.push(attribute1.attributesData[i]);
        }

        for (let i = 0; i < this.attributesList1.length; i++) {
          this.attributesData1.push({
            'attrId': this.attributesList1[i]._id,
            'attrName': this.attributesList1[i].name,
            'traitId': this.attributesList1[i].fieldType == 0 ? (this.attributesList1[i].traitsData.length == 0 ? '' : this.attributesList1[i].traitsData[0]._id) : '',
            'traitName': this.attributesList1[i].fieldType == 0 ? (this.attributesList1[i].traitsData.length == 0 ? '' : this.attributesList1[i].traitsData[0].traitName) : ''
          });
        }
        //
        console.log(this.attributesList1, "this.attributesList1====")
      }
    }
    this.showOfferValue = "No";
    this.showMotherBreedName = false;

  }



  attributeChange(event) {
    // this.traitsData = [];
    console.log(this.attributesList)
    this.traitsList = [];
    let match = false;
    for (let i = 0; i < this.attributesList.length; i++) {
      if (event.target.value == this.attributesList[i]._id) {
        var attribute2 = this.attributesList[i];
        console.log(this.attributesList[i])
        this.attributesData = [{ 'attrId': attribute2._id, 'attrName': attribute2.name, 'traitId': '', 'traitName': '' }]
      }
    }
    for (let i = 0; i < attribute2.traitsData.length; i++) {
      if (event.target.value == attribute2.traitsData[i].attributeId) {
        this.traitsList.push(attribute2.traitsData[i]);
        if (match == false) {
          this.attributesData[0].traitId = attribute2.traitsData[0]._id;
          this.attributesData[0].traitName = attribute2.traitsData[0].traitName;
          match = true;
        }

      }
    }
  }
  valueset(int, event) {
    console.log(event.target.value, "event.target.value")
    this.attributesData1[int].traitName = event.target.value;
    console.log(this.attributesData1, "this.attributesData1==")
  }
  valueset1(int, event) {
    this.attributesData1[0].traitName = event.target.value;
    console.log(this.attributesData1, "this.attributesData1==")
  }

  dateChange(int, e) {
    let date = moment(e).format('DD.MM.YYYY');
    console.log(date, "event.target.value")
    this.attributesData1[int].traitName = date
    console.log(this.attributesData1, "this.attributesData1==")
  }


  type2Valueset(int, event, unit) {
    const unitType = unit;
    const unitValue = event.target.value ? (event.target.value) : "";
    if (unitValue) {
      const txtValue = unitValue + ' ' + unitType;
      this.attributesData1[int].traitName = txtValue;
    }
    console.log(this.attributesData1, "this.attributesData1==")

  }
  type2ValueChange(event, int) {
    const traitName = this.attributesData1[int].traitName;
    const traitName1 = traitName.split(' ');
    const traitUint = event.target.value ? (event.target.value) : "";
    if (traitName1.length < 2) {
      this.showValueError = true;
      setTimeout(() => {
        this.showValueError = false;
      }, 3000);
      return false
    }
    else {
      const value = traitName1[0];
      const txtValue = value + ' ' + traitUint;
      this.attributesData1[int].traitName = txtValue;
    }

    console.log(this.attributesData1, "this.attributesData1==")
  }

  traitChange(event, int) {
    console.log(this.attributesData1, "this.attributesData1==")
    console.log(event, "event======")
    for (let i = 0; i < this.attributesList1[int].traitsData.length; i++) {
      if (event.target.value == this.attributesList1[int].traitsData[i]._id) {
        this.attributesData1[int].traitId = this.attributesList1[int].traitsData[i]._id;
        this.attributesData1[int].traitName = this.attributesList1[int].traitsData[i].traitName;
      }
    }
    const gettraitName = this.attributesData1[0].traitName.split(' ').filter(s => s).join(' ').toLowerCase();
    if (gettraitName == 'other') {
      this.showMotherBreedName = true;
    } else if (gettraitName == 'other if any') {
      this.showMotherBreedName = true;
    }
    else {
      this.showMotherBreedName = false;
    }
    for (let i = 0; i < this.attributesData1.length; i++) {
      let attrName = this.attributesData1[i].attrName;
      attrName = attrName.split(' ').filter(s => s).join(' ').toLowerCase();
      if (attrName == "provide extra (offer)") {
        if (this.attributesData1[int].traitName == "Yes") {
          this.showOfferValue = "Yes";
        } else {
          this.showOfferValue = "No";
        }
      }
    }
  }

  /*address */
  getuserdetails() {
    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));
    this.IS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => {
        if (localStorage.getItem("adminToken") != res.data.accessToken) {
          localStorage.setItem('adminToken', res.data.accessToken)
        }
        console.log('user data', res.data)
        localStorage.setItem('adminToken', res.data.accessToken);
        let lat1 = Number(localStorage.getItem('latitude'));
        let lon1 = Number(localStorage.getItem('longitude'));
        this.latitude = res.data.location ? (res.data.location.coordinates[1]) : lat1;
        this.longitude = res.data.location ? (res.data.location.coordinates[0]) : lon1;
        this.getCurrent(this.latitude, this.longitude)
      })
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  getCurrent(latitude, longitude) {
    console.log('map-----', this.gMap);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status === 'OK') {
        for (let i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types
          for (var j = 0; j < types.length; j++) {
            if (types.indexOf('subpremise') != -1) {
              this.mapPlot = results[0].address_components[i].long_name
            }
            //Sector
            if (types.indexOf('neighborhood') != -1) {
              this.mapSector = results[0].address_components[i].long_name
            }

            if (types.indexOf('sublocality_level_1') != -1) {
              this.mapLandmark = results[0].address_components[i].long_name
            }
            if (types.indexOf('locality') != -1) {
              this.mapLocality = results[0].address_components[i].long_name

            }
            //city
            if (types.indexOf('administrative_area_level_2') != -1) {
              this.mapCity = results[0].address_components[i].long_name

            }
            //state
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.mapState = results[0].address_components[i].long_name,
                this.mapStateshort = results[0].address_components[i].short_name

            }
            if (types.indexOf('country') != -1) {
              this.mapcountry = results[0].address_components[i].long_name

            }
            // if (types.indexOf('postal_code') != -1) {
            //   this.mapPostal = results[0].address_components[i].long_name,
            //   console.log(this.mapPostal);
            // }    
            // if (types.indexOf('postal_code') != -1) {
            //   this.mapPostal = results[0].address_components[i].long_name,
            //   console.log('2', this.mapPostal); 
            // }

            if (types.indexOf('postal_code') != -1) {
              this.mapPostal = results[0].address_components[i].long_name
            }
          }
        }


        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.ngZone.run(() => {
            this.address = results[0].formatted_address;
          });

          this.userForm.patchValue({
            'googleAddress': results[0].formatted_address,
            'streetAddress': this.mapPlot,
            'pinCode': this.mapPostal,
            'city': this.mapCity,
            'latitude': this.latitude,
            'state': this.mapState,
            'country': this.mapcountry,
            'longitude': this.longitude,
          })

          console.log('df', this.userForm);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  /*get location*/
  getAddress(latitude, longitude) { 
    console.log('map', this.gMap);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        for (let i = 0; i < this.gMap.address_components.length; i++) {
          let types = this.gMap.address_components[i].types
          console.log(types);
          for (var j = 0; j < types.length; j++) {
            if (types.indexOf('subpremise') != -1) {
              this.mapPlot = this.gMap.address_components[i].long_name
              console.log(this.mapPlot);
            }
            //Sector
            if (types.indexOf('neighborhood') != -1) {
              this.mapSector = this.gMap.address_components[i].long_name
            }

            if (types.indexOf('sublocality_level_1') != -1) {
              this.mapLandmark = this.gMap.address_components[i].long_name
            }
            if (types.indexOf('locality') != -1) {
              this.mapLocality = this.gMap.address_components[i].long_name
              console.log('mapLocality', this.mapLocality);
            }
            //city
            if (types.indexOf('administrative_area_level_2') != -1) {
              this.mapCity = this.gMap.address_components[i].long_name,
                console.log('city', this.mapCity);
            }
            //state
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.mapState = this.gMap.address_components[i].long_name,
                this.mapStateshort = this.gMap.address_components[i].short_name
              console.log('state', this.mapState);
            }
            if (types.indexOf('country') != -1) {
              this.mapcountry = this.gMap.address_components[i].long_name,
                console.log('country', this.mapcountry);
            }
            // if (types.indexOf('postal_code') != -1) {
            //   this.mapPostal = this.gMap.address_components[i].long_name,
            //   console.log(this.mapPostal);
            // }    
            // if (types.indexOf('postal_code') != -1) {
            //   this.mapPostal = this.gMap.address_components[i].long_name,
            //   console.log('2', this.mapPostal); 
            // }

            if (types.indexOf('postal_code') != -1) {
              this.mapPostal = this.gMap.address_components[i].long_name,
                console.log('23', this.mapPostal);
            }
          }

        }

        console.log('ch', results[0].formatted_address);
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          console.log('adasd', this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  /*product delete*/
  deleteProduct(template) {

    this.modalRef = this.modalService.show(template);
  }
  delete() {
    let data = {
      'productId': this.productId,
      'userId': localStorage.getItem('id'),
      'isDeleted': 'true',
    }
    this.IS.updateForm(data, apiUrl._putenableOrDeleteProduct)
      .subscribe(async (res) => {
        this.modalRef.hide();
        setTimeout(() => {
          this.admin.showAlert('Product Delete Successfully', '');
        }, 500);
        setTimeout(() => {
          this.router.navigateByUrl('/seller');
        }, 800);


      })
  }
  /*Refer a Friend */
  pointRefer() {
    let params = new HttpParams();
    this.IS.getList(params, apiUrl._getPoints)
      .subscribe(async (res) => {
        console.log('points redeen', res.data)

      })
  }

  selectedQuantityUnit(event) {
    this.quantityUnit = event.target.value;
    this.maqUnit = event.target.value;
    console.log(this.maqUnit)
    this.userForm.patchValue({ 'moqType': this.maqUnit });

  }
  selectedPriceUnit(event) {
    this.priceUnit = event.target.value;
  }
  selectedMoqUnit(event) {
    this.maqUnit = event.target.value;
    this.quantityUnit = event.target.value;
    console.log(this.quantityUnit)
    this.userForm.patchValue({ 'quantityType': this.quantityUnit });

  }
  isNumber(event) {
    if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 37) {
    }
    else {
      // Ensure that it is a number and stop the keypress
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    }
    return true;
  }
}


