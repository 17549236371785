<app-header></app-header>
<main class="main_cont profile_page_view privacy">

    <div class="container">
        <h1 class="">Egiyok - Indian Poultry Under One Umbrella</h1>

        <p>Egiyok is a one-stop destination for all poultry industry needs. It is a conscious effort to transform
            poultry into a digitally empowered industry and knowledge economy. Set to be India's largest digital portal
            for poultry products and services, it aims at providing an effortless experience for people involved in it
            such as producers, farmers, dealers, Poultry service providers & consumers across the country. It’s a
            marketplace to fill the gap between demand and supply of poultry products & services. Egiyok is the pioneer
            in revolutionizing the backyard Poultry industry, the sector which exists in small villages and towns,
            however its betterment is yet to be done either by government or corporate sector. Fortunately, Egiyok has
            just picked it up under our umbrella.</p>
        <p>The Aim of Egiyok is to overhaul the traditional poultry trading. We, at Egiyok, are increasing the reach of
            common buyers/sellers and empowering them to perform their respective business activities beyond their usual
            boundaries. This will indeed open-up new business opportunities as well as, would be instrumental in
            generating employment.</p>
        <p>The Egiyok platform would enable its users to carry out any of the following activities:
        <p><b>Poultry Trading:</b> – Users can buy/sell/inquire/provide poultry products & services on this platform.</p>
        <p><b>Authentic Poultry Rates:</b> - Egiyok has collaborated with registered poultry related organizations throughout
            the country to get the correct and live rates. We are PROUD to say that Egiyok is the FIRST organization to
            provide PAN India authentic rates for the following products – All types of Broiler, Chicks & hatching eggs,
            Eggs & Feed.</p>

        <p><b>Verified Business:</b> - Users can verify their accounts by providing the necessary documentation and can earn
            “Verified Poultry Businesses” tags. The accounts having Verified tags would be more trustful providers and
            will provide ease of trading to the parties dealing with them.</p>
        <p><b>Real time reach:</b> - Egiyok will generate real time connection between buyers/sellers/service providers via the
            option of chat/call, which will enable a smooth user experience in doing business. </p>
        <p><b>Egiyok for EVERYONE:</b> - As India is a country of different culture and languages, Egiyok upholds the same
            feeling by providing a MULTILINGUAL PLATFORM to its user. Now users won’t be bounded by just Hindi & English
            and would have an option to use the app in 4 major regional languages, viz – Telugu, Bengali, Tamil &
            Marathi. </p>
    </div>




</main>
<app-footer></app-footer>