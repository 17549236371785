import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { permission } from '../shared/models/general.model';
import { JsonPlaceHolderService } from './json-place-holder.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private admin:JsonPlaceHolderService){}
  canActivate()
  {
    let token = localStorage.getItem("adminToken");
    if (token) {
      return true;
    }
    localStorage.clear();
    this.router.navigate(['/index']);
    return false;
  }
}
