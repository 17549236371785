import { Injectable } from '@angular/core';
import { TemplateRef,Component, OnInit,ViewChild } from '@angular/core';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';


@Injectable({
    providedIn: 'root'
})
export class NavigationService {

  constructor(public api: JsonPlaceHolderService) { 
   
  }


  getList(data,url) {
      return this.api.getData(url,data);
  }

  submitForm(data,url) {
      return this.api.postData(url, data);
  }

  updateForm(data,url) {
    return this.api.putData(url, data);
  }

  updateStatus(url,data)
  {
      return this.api.postData(url,data);
  }
  
  



}