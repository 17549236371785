import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trendslisting3',
  templateUrl: './trendslisting3.component.html',
  styleUrls: ['./trendslisting3.component.css']
})
export class Trendslisting3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
