import { Component, OnInit } from '@angular/core';
import { IndexService } from './../index-page/index.service';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { AppComponent } from '../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FcmserviceService } from './../../services/fcmservice.service';
import { interval } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { cashfreeSandbox, cashfreeProd } from 'cashfree-dropjs';
import { myProfileService } from 'src/app/internal/myprofile/myprofile.service';
import * as moment from 'moment';
@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent implements OnInit {
  currentDate = new Date();
  todayDate = new Date();
  filterData: any;
  showCustomLoader = false;
  tomorrowDate = this.todayDate.setDate(this.todayDate.getDate() + 1);
  appPage = false;
  hideLoader = false;
  productName: any = [];
  rateType = 1;
  bgBlink = false;
  bgBlinkM = false;
  birdAllRate: any = [];
  birdAllRateRaw: any = [];
  eggAllRate: any = [];
  chickAllRate: any = [];
  grainkAllRate: any = [];

  tempNewlyAddedRates_ids: any = {};

  hideBird = true;
  hideEgg = true;
  hideChick = true;
  hideGains = true;
  isLoggedIn = false;
  myStationsRaw: object[];
  filterMyRates = false;

  isLoading = true;
  userRatesPlan = null;
  ratesSelectedPlan = '';
  ratesPlan = [];

  priceConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "dots": false,
    'infinite': true,
    "autoplay": true,
    "autoplaySpeed": 1000,
    "vertical": false,
    'pauseOnHover': true,
    'centerMode': false,
    'cssEase': 'linear',
    // 'variableWidth': true,
    "responsive": [
      {
        "breakpoint": 1023,
        "settings": {
          "slidesToShow": 4,
          "slidesToScroll": 1
        }
      },
      {
        "breakpoint": 767,
        "settings": {
          'pauseOnHover': false,
          "slidesToShow": 3,
          "slidesToScroll": 1
        }
      }
    ]
  };

  constructor(private route: ActivatedRoute, private router: Router, private IS: IndexService, private admin: JsonPlaceHolderService, private parent: AppComponent, private messagingService: FcmserviceService,private NS: myProfileService) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const adminToken = urlParams.get('token');

    if (adminToken) {
      localStorage.setItem("adminToken", adminToken);
    }

    let token = localStorage.getItem("adminToken");
    if (token) {
      this.isLoggedIn = true;
    }

    this.getMyStations();
    this.submitBrowserId();
    this.getRatesPlanData();
    this.getuserdetails()

    this.route.queryParams.subscribe(params => {
      let appUrl = this.route.snapshot.paramMap.get('rateName');
      if (appUrl) {
        this.appPage = true;
        localStorage.setItem('mobileApp', 'true');
      }
    });

    this.getProductName();
    let typerate = localStorage.getItem('rateType') ? (localStorage.getItem('rateType')) : 1;
    this.rateType = Number(typerate);
    this.getRate(this.rateType);
    localStorage.removeItem('rateType');
    interval(5 * 60 * 1000).subscribe(x => {
      this.getRateStatus();
    });
  }

  getuserdetails() {
    if (!this.isLoggedIn) {
      this.isLoading = false;
      return;
    } 

    let params = new HttpParams();
    params = params.append('platform', 'WEB');
    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('accessToken', localStorage.getItem("adminToken"));

    this.IS.getList(params, apiUrl._getuserDetail)
      .subscribe(async (res) => { 
        if (res.data && res.data.ratesSubscriptionDetails && res.data.ratesSubscriptionDetails.length > 0) {
          this.userRatesPlan = res.data.ratesSubscriptionDetails[0].plan || null;
          this.isLoading = false;
        }
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getEndDate () {
    var endDate = ''
    if (this.ratesSelectedPlan == "1M") {
      endDate = moment().add('M', 1).format('yyyy-MM-DD HH:mm:ss')
    } else if (this.ratesSelectedPlan == "3M") {
      endDate = moment().add('M', 3).format('yyyy-MM-DD HH:mm:ss')
    } else if (this.ratesSelectedPlan == "6M") {
      endDate = moment().add('M', 6).format('yyyy-MM-DD HH:mm:ss')
    } else if (this.ratesSelectedPlan == "1Y") {
      endDate = moment().add('M', 12).format('yyyy-MM-D HH:mm:ss')
    }

    return endDate;
  }

  doSubscription () {
    if (!this.isLoggedIn) {
      return alert('Please login to proceed.')
    }

    // get token 
    this.IS
      .getList({}, apiUrl._get_payment_token + '?plan=' + this.ratesSelectedPlan)
      .subscribe(
        (res: any) => {
          let orderToken = res.data.orderToken;

          if (orderToken) {
            //let cashfree  = new cashfreeSandbox.Cashfree();
            let cashfree  = new cashfreeProd.Cashfree();
            
            const paymentDom = document.getElementById("payment-form");
            paymentDom.innerHTML = '';

            const dropConfig = {
              "components": [
                  "order-details",
                  "card",
                  "netbanking",
                  "app",
                  "upi"
              ],
              "orderToken": orderToken,
              "onSuccess": (paymentRes) => {
                console.log('paymentRes onSuccess', paymentRes);

                // if scusses

                if (paymentRes.order && paymentRes.order.status == 'PAID') {
                  alert('Payment completed successfully! Your subscription will start in less than 15 minutes.');
                  this.reloadCurrentRoute();
                  // const data = {
                  //   'platform': 'WEB',
                  //   'deviceToken': this.getDeviceToken(),
                  //   'ratesSubscriptionDetails': {
                  //     ...res.data,
                  //     ...paymentRes,
                  //     endDate: this.getEndDate()
                  //   }
                  // };
  
                  // this.NS.updateForm(data, apiUrl._putUpdateprofile)
                  //   .subscribe(async (res) => {
                    
                  //   console.log('Plan saved. reloading...');

                  //   if(localStorage.getItem("adminToken") != res.data.accessToken){
                  //     localStorage.setItem('adminToken', res.data.accessToken)
                  //   }                   
                    
                  //   this.reloadCurrentRoute();
                  // })
                }
              },
              onFailure: (paymentRes) => {
                console.log('paymentRes onFailure', JSON.stringify(paymentRes));
                alert('Payment failed. Please try again.')
              },
              "style": {
                  "backgroundColor": "lightyellow",
                  "color": "#11385b",
                  "fontFamily": "Lato",
                  "fontSize": "14px",
                  "errorColor": "#ff0000",
                  "theme": "light", //(or dark)
              }
            }

            cashfree.initialiseDropin(paymentDom, dropConfig);
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  onPlanSelection(e) {
    if (e.target.checked) {
      for (let i = 0; i < this.ratesPlan.length; i++) {
        this.ratesPlan[i].checkbox = false;
        
        if (this.ratesPlan[i].name == e.target.id) {
          this.ratesPlan[i].checkbox = true
        }
      }
      this.ratesSelectedPlan = e.target.id;
    } else {
      this.ratesSelectedPlan = "";

      for (let i = 0; i < this.ratesPlan.length; i++) {
        this.ratesPlan[i].checkbox = false;
      }
    }

    console.log('this.ratesSelectedPlan', this.ratesSelectedPlan);
  }

  getRatesPlanData() {
    this.IS
      .getList({
      }, apiUrl._getRatesPlan + '?key=RATES_PLAN&isUserData=NO')
      .subscribe(
        (res: any) => {
          this.isLoading = false;

          for (let index = 0; index < res.data.values.length; index++) {
            const plan = res.data.values[index];

            this.ratesPlan.push(
              {
                name: plan.name,
                displayName: plan.displayName,
                price: plan.price,
                status: [1],
                id: [index],
              }
            );
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  getMyStations() {
    if (!this.isLoggedIn) return;

    this.IS.getList({}, apiUrl._getMyRates).toPromise().then((res) => {
      this.myStationsRaw = res.data;
      this.isLoading = false;
    });
  }

  addToMyRates(event, mandiRate) {
    if (this.hideLoader) {
      this.admin.loader(false);
      this.admin.showLoader = false;
    }

    if (event.target.checked) {
      let url = apiUrl._getMyRates 
      + '?mandi='+ encodeURIComponent(mandiRate._id.mandi)
      + '&product=' + encodeURIComponent(mandiRate.rateData.product)
      + '&state=' + encodeURIComponent(mandiRate._id.state) 
      + '&subproduct=' + encodeURIComponent(mandiRate._id.subproduct);

      this.IS.submitForm({}, url)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            this.tempNewlyAddedRates_ids[`${res.data.success.mandi}_${res.data.success.subproduct}_${res.data.success.state}_${res.data.success.product}`] = res.data.success._id;
          }
          
          this.hideLoader = false;
          this.getMyStations();
      })
    } else {
      let wishListId = mandiRate.wishListId;
      if (!wishListId) {
        wishListId = this.tempNewlyAddedRates_ids[`${mandiRate.rateData.mandi}_${mandiRate.rateData.subproduct}_${mandiRate.rateData.state}_${mandiRate.rateData.product}`];
      }

      if (wishListId) {
        this.IS.deleteList({}, apiUrl._getMyRates + '?wishlistId='+ wishListId)
        .subscribe((res) => {
            this.hideLoader = false;
            this.getMyStations();
        })
      } else {
        this.hideLoader = false;
      }
    }

  }

  viewMyRates(event) {
    event.stopPropagation();

    if (!this.isLoggedIn) {
      return;
    }

    this.filterMyRates = event.target.checked;
    this.getRate(this.rateType);
  }

  // rate and price 
  getProductName() {
    let data = {}
    this.IS.getList(data, apiUrl._getProductName)
      .subscribe(async (res) => {
        this.productName = res.data;
      })
  }

  submitBrowserId() {
    let data = {
      'browserId': this.getDeviceToken()
    }
    this.IS.submitForm(data, apiUrl._addBrowserId)
      .subscribe(async (res) => {

      })
  }

  getRateStatus() {   
    this.admin.loader(false);
    this.admin.showLoader = false;    
    let data = {
      'browserId': this.getDeviceToken()
    }
    this.IS.getList(data, apiUrl._getRateStatus)
      .subscribe(async (res) => {
        if(res.data.status==1){
          this.hideLoader=true;
          this.getRate(this.rateType);
        }else{
        }
        
      })
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  updateRate(type) {
    if (type == 3) {
      if (this.bgBlink) {
        this.rateType = type;
        this.getRate(this.rateType)
      }
      else {
        return false
      }
    } else if (type == 4) {
      if (this.bgBlinkM) {
        this.rateType = type;
        this.getRate(this.rateType)
      }
      else {
        return false
      }
    }
    else {
      this.rateType = type;
      this.getRate(this.rateType)
    }

  }

  getRate(type) {
    if (this.hideLoader) {
      this.admin.loader(false);
      this.admin.showLoader = false;
    }
    let rateType = type;
    this.birdAllRate = [];
    this.eggAllRate = [];
    this.chickAllRate = [];
    this.grainkAllRate = [];
    let data;
    data = {
      'rateType': rateType,
      'birdPage': 1,
      'chickPage': 1,
      'eggPage': 1,
      'grainPage': 1,
      'browserId':this.getDeviceToken()
    }
    this.IS.getList(data, apiUrl._getRate)
      .subscribe(async (res) => {
        this.hideLoader = false;
        if (res.data.color == true) {
          this.bgBlink = true
        } else {
          this.bgBlink = false
        }
        if (res.data.colorM == true) {
          this.bgBlinkM = true
        } else {
          this.bgBlinkM = false
        }

        this.hideBird = true;
        this.hideEgg = true;
        this.hideChick = true;
        this.hideGains = true;
        if (this.rateType == 3) {
          if (res.data.Bird.length == 1) {
            this.birdAllRate = res.data.Bird.length > 0 ? (res.data.Bird[0].productData) : [];
          } else {
            this.hideBird = false;
          }
          if (res.data.Egg.length == 1) {
            this.eggAllRate = res.data.Egg.length > 0 ? (res.data.Egg[0].productData) : [];
          } else {
            this.hideEgg = false;
          }
          if (res.data.Chick.length == 1) {
            this.chickAllRate = res.data.Chick.length > 0 ? (res.data.Chick[0].productData) : [];
          }
          else {
            this.hideChick = false;
          }
          if (res.data.Grain.length == 1) {
            this.grainkAllRate = res.data.Grain.length > 0 ? (res.data.Grain[0].productData) : [];
          } else {
            this.hideGains = false;
          }
        }
        else if (this.rateType == 4) {
          if (res.data.Bird.length == 1) {
            this.birdAllRate = res.data.Bird.length > 0 ? (res.data.Bird[0].productData) : [];
          } else {
            this.hideBird = false;
          }
          if (res.data.Egg.length == 1) {
            this.eggAllRate = res.data.Egg.length > 0 ? (res.data.Egg[0].productData) : [];
          } else {
            this.hideEgg = false;
          }
          if (res.data.Chick.length == 1) {
            this.chickAllRate = res.data.Chick.length > 0 ? (res.data.Chick[0].productData) : [];
          }
          else {
            this.hideChick = false;
          }
          if (res.data.Grain.length == 1) {
            this.grainkAllRate = res.data.Grain.length > 0 ? (res.data.Grain[0].productData) : [];
          } else {
            this.hideGains = false;
          }
        }
        else {
          this.birdAllRate = res.data.Bird.length > 0 ? (res.data.Bird[0].productData) : [];
          this.eggAllRate = res.data.Egg.length > 0 ? (res.data.Egg[0].productData) : [];
          this.chickAllRate = res.data.Chick.length > 0 ? (res.data.Chick[0].productData) : [];
          this.grainkAllRate = res.data.Grain.length > 0 ? (res.data.Grain[0].productData) : [];
        }

        function filterRecords(current, allData, addToArr) {
          for (let index = 0; index < allData.length; index++) {
            const st = allData[index];
  
            if(st['_id'].mandi.toLowerCase() == current['mandi'].toLowerCase() && st['_id'].subproduct.toLowerCase() == current['subproduct'].toLowerCase()) {
              st.isInMyRatesList = true;
              st.wishListId = current._id;
              addToArr.push(st);
              break;
            }
          }
        }

        if (this.filterMyRates) {
          let tempBirds = []
          let tempEgg = []
          let tempChick = []
          let tempGrain = []
          
          this.myStationsRaw && this.myStationsRaw.map((mst) => {
            filterRecords(mst, this.birdAllRate, tempBirds);
            filterRecords(mst, this.eggAllRate, tempEgg);
            filterRecords(mst, this.chickAllRate, tempChick);
            filterRecords(mst, this.grainkAllRate, tempGrain);
          })

          this.birdAllRate = tempBirds;
          this.eggAllRate = tempEgg;
          this.chickAllRate = tempChick;
          this.grainkAllRate = tempGrain;
        } else {
          this.myStationsRaw && this.myStationsRaw.map((mst) => {
            filterRecords(mst, this.birdAllRate, []);
            filterRecords(mst, this.eggAllRate, []);
            filterRecords(mst, this.chickAllRate, []);
            filterRecords(mst, this.grainkAllRate, []);
          })
        }
      })
  }

  // getUniqueId(index) {
  //   return this.filterData.subFilter[index].id + index;
  // }



  gotoPricePage(e) {
    let pName = e
    const data = [];
    if (pName) {
      data.push({ 'productName': e })
      this.parent.setNavtionData(data);
      this.router.navigateByUrl(`/price`);
    }
  }
  pricePgee(e) {
    const data = [];
    data.push({ 'productName': e, 'rateType': 1 });
    this.parent.setNavtionData(data);
    this.router.navigateByUrl(`/price`);

  }
  viewDetailsPgae(product) {
    if (product) {
      const data = [];
      data.push({ 'productName': product, 'rateType': this.rateType })
      this.parent.setNavtionData(data);
      this.router.navigateByUrl(`/price`);
    }
  }
  checkValue(value) {
    let rate = parseInt(value);
    if (rate == value) {
      let type = '2'
      return type
    } else {
      let type = '1'
      return type
    }
  }
  checkText(text) {
    let type;
    if (text) {
      let checkTxt = text.split(' ').filter(s => s).join(' ').toLowerCase();
      if (checkTxt == 'coming soon') {
        type = '1'
        return type
      } else if (checkTxt == 'same normal') {
        type = '2'
        return type
      } else if (checkTxt == 'slow down') {
        type = '3'
        return type
      }
      else {
        type = '4'
        return type
      }
    } else {
      type = '5'
      return type
    }
  }
}
