<app-header></app-header>
<main class="main_cont">
    <!-- Category Section -->
    <section class="look_category bfore_login_cate">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="title_text">
                        <h2>What you are looking for</h2>
                        <!-- <a [routerLink]="['/Buyer']">View All Categories</a> -->
                        <a *ngIf="allData1.length != 0" class="handcursul" (click)="openLoginModal()">{{'homePage.buyer.viewAllcategory' | translate}}</a>
                    </div>

                    <ul class="category_pro category_list_look">
                        <li *ngFor="let item of allData1; let i = index"><a (click)="openLoginModal()"><span><img
                                        src="{{s3url+'categories/' + item.image}}"></span><br>{{item.name}}</a>
                        </li>
                    </ul>

                    <div class="errorcss" *ngIf="allData1.length == 0"> No Data Found!</div>
                </div>

            </div>
        </div>
        <div #rates id="rates"></div>
    </section>




    <!-- Trend & Pricing -->
    <section class="trend_pricing">

        <app-rate></app-rate>

        <div id="about"> </div>
    </section>

    <!-- About Section -->
    <section class="about_sec ">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="abt_text">
                        <h2>{{'homePage.IndexPage.title' | translate}}</h2>
                        <p>Egiyok is a one-stop destination for all poultry industry needs. It is a conscious effort to transform poultry into a digitally empowered industry and knowledge economy. Set to be India&#39;s largest digital portal for poultry
                            products and services, it aims at providing an effortless experience for people involved in it such as producers, farmers, dealers, Poultry service providers &amp; consumers across the country. It’s a marketplace to fill the
                            gap between demand and supply of poultry products &amp; services.</p>

                        <a href="javascript:;" class="m_btn_red btn_red" routerLink="/aboutus">{{'homePage.IndexPage.btn' | translate}}</a>

                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="abt_video">
                        <!-- <video controls autoplay>
                <source src="video/buck.mp4" type="video/mp4">
                <source src="video/buck.ogg" type="video/ogg">
              </video> -->
                        <a href="javascript:;" (click)="playPause(video)"><img src="./assets/images/vd.png"><img class="ply" src="./assets/images/play-btn.svg"></a>
                    </div>
                </div>
            </div>
        </div>
        <div id="partner"> </div>
    </section>

    <!-- Testimonial-->
    <section class="slide-wrapper testi">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 posi_r">
                    <div class="testi_txt">
                        <h2>Testimonials</h2>

                        <p>This is what our customers are saying about us!!! We thrive for perfection and your valuable feedback goes a long way to help us do that!!</p>


                    </div>
                    <img src="./assets/images/icn.svg">
                </div>
                <div class="col-lg-6 col-md-12">
                    <!-- Indicators -->

                    <!-- Wrapper for slides -->
                    <div class="hero__title">
                        <ngx-slick-carousel class="carousel ngxcss" #slickModal="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                <!-- <img src="{{ slide.img }}" alt="" width="100%"> -->
                                <div class="">
                                    <span class="hero__title-misc  |  animate">
                                        <div class="fill">
                                            <img class="quote_sign" src="{{ slide.img }}">
                                            <p>{{slide.dis}} <img class="quote_sign2" src="{{ slide.img2 }}"></p>
                                            <strong>{{slide.title}}</strong>
                                            <p class="spaceSet">{{slide.state}}</p>
                                        </div>
                                    </span>
                                </div>

                            </div>
                        </ngx-slick-carousel>

                    </div>
                </div>
            </div>

        </div>
    </section>
</main>
<!-- <button (click)="openModule(Calculator)"  data-toggle="tooltip" data-placement="bottom" title="Work in Progress" class="m_btn_red btn_red calc_button">
    Calculator
 </button> -->
<app-footer></app-footer>


<!-- video_modal  -->


<ng-template #video>


    <div class="modal-header border-bottom-0">

    </div>
    <div class="modal-body">
        <video id="egg_video" width="640" height="480" controls muted>
            <source src="../../../assets/video/Video1.mp4" type="video/mp4">
            <source src="../../../assets/video/Video1.ogg" type="video/ogg">
        </video>

        <!-- <iframe id="egg_video" width="560" height="315" src="https://www.youtube.com/embed/yifZtA3uF-E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    </div>


</ng-template>



<app-login-modal #model>
</app-login-modal>