<body class="order_flow_page">
    <!-- <app-headerwithoutslick></app-headerwithoutslick> -->
    <app-header></app-header>

    <main class="main_cont chatbx">
        <button class="btnFulls bxIcon" type="button" data-toggle="" data-target="" aria-controls="" aria-expanded="false" aria-label="Toggle navigation" (click)=openSidebar(1)>
            <span class="fa fa-list"></span>
        </button>
        <!-- Pending Orders -->
        <section class="pending_orders confirm_ordr chat_box_page fullBxfilter">
            <div class="sidebar_order" id="sidenav" [ngClass]="{
                'activeSlidebar':slideCheck == 1}">
                <button _ngcontent-ulf-c135="" type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                        _ngcontent-ulf-c135="" aria-hidden="true" (click)=openSidebar(0)>×</span></button>
                <div class="nav-tp">
                    <a (click)="changeState('Seller')" class="{{tab == 'Seller'?'nav-link active':'nav-link'}}">{{'homePage.buyer.Selling' |
                        translate}}</a>
                    <a (click)="changeState('Buyer')" class="{{tab == 'Buyer'?'nav-link active':'nav-link'}}">{{'homePage.buyer.Buying' |
                        translate}}</a>
                </div>
                <!-- <div class="search_br chat_srch"><input type="text" name="" placeholder="{{'homePage.buyer.search' | translate}}"> <a href="#_"><i class="fa fa-search"></i></a></div> -->
                <div class="search_br chat_srch"></div>
                <ul class="side_nav" *ngIf="tab == 'Seller'">
                    <li class="main_m" *ngFor="let data of allData; let i = index">
                        <a class="{{ i==0 ? 'active' : ''}}" data-toggle="collapse" href="#collapseExample{{i}}" (click)="openProductSeller(data._id);" role="button" aria-expanded="false" aria-controls="collapseExample" id="category_tab_{{data._id}}"><img class="cat_im" src="{{s3url}}categories/{{data.image}}"> {{data.name}} </a>

                        <div id="collapseExample{{i}}" class="main_drp collapse">
                            <div class="tab-content" id="myTabContent">

                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row premiumWrap">
                                        <div class="col-6"><span [ngClass]="showNormalChat ? 'active':''" (click)="showNormel()">Normal</span></div>
                                        <div class="col-6"><span [ngClass]="showPremiumChat ? 'active':''" (click)="showPremium()">Premium</span></div>
                                    </div>
                                    <div *ngIf="showNormalChat">
                                        <ul class="chatuser">
                                            <li *ngFor="let buyer of BuyerListing; let i = index">
                                                <a *ngIf="(buyer.category_id == data._id)" class="{{ (participantId==buyer?.participant_ids) ? 'active' : ''}}" (click)="gotoChatWindow(buyer.buyer_id,buyer.seller_id,buyer.product_id,0);"><sup
                                                        *ngIf="buyer.unread_count && buyer.unread_count[buyer.seller_id] !=0 "></sup>
                                                    <div class="cht_usr"><img
                                                            src="{{s3url}}products/{{buyer.product_image}}">
                                                    </div>
                                                    <div class="chatuserDetails">{{buyer.name[buyer.buyer_id]}} </div>
                                                    
                                                    <p>{{buyer.last_message ?
                                                        (buyer.last_message[buyer.seller_id]) : ""}}</p>
                                                    <div class="btn_red btn_brdr">
                                                        {{buyer.last_message_time[buyer.seller_id] | dateAgo }}</div>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="No_found padTopBottom" *ngIf="BuyerListing?.length == 0"><span class="norecordIcon"></span> No Chat Yet!!</div>
                                    </div>
                                    <div *ngIf="showPremiumChat">
                                        <ul>
                                            <li *ngFor="let lead of premiumLead">
                                                <a class="" [routerLink]="'/premium-lead/'+lead._id">
                                                    <div class="cht_usr">
                                                        <img [src]="lead.buyerData[0].profilePic ? (s3url+lead.buyerData[0].profilePic) : './assets/images/default.jpg'">
                                                    </div> {{lead.buyerData[0].name ? (lead.buyerData[0].name):''}}
                                                    <p>{{lead.quantity ? (lead.quantity):''}} {{premiumDealName}}</p>
                                                    <div class="btn_red btn_brdr">
                                                        {{lead.createdAt| dateAgo }}</div>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="No_found padTopBottom" *ngIf="premiumLead?.length == 0 && !showPremiumbuy"><span class="norecordIcon"></span> No Premium lead Yet!!</div>

                                        <div class="No_found padTopBottom" *ngIf="showPremiumbuy"><span class="norecordIcon"></span> No Premium Leads yet
                                            <!-- To get access to <strong>Premium Leads</strong>, you have to Purchase Premium Plan
                                             <div class="buttonWrap">
                                                <span (click)="buyplanNow(planselect)" class="bTN btn_red">Buy Now</span>
                                                <span (click)="showNormel()" class="bTN gray">Not Now</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="side_nav" *ngIf="tab == 'Buyer'">
                    <div class="tab-content" id="myTabContent">
                        <div class="No_found" *ngIf="buyeringList?.length == 0"><span class="norecordIcon"></span> No Chat Yet!!</div>
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <ul>
                                <li *ngFor="let buyerli of buyeringList; let i = index">
                                    <a class="{{ (participantId==buyerli.participant_ids) ? 'active' : ''}}" (click)="gotoChatWindow(buyerli.buyer_id,buyerli.seller_id,buyerli.product_id,1);">
                                        <sup *ngIf="buyerli.unread_count && buyerli.unread_count[buyerli.buyer_id] !=0 "></sup>
                                        <div class="cht_usr"><img src="{{s3url}}products/{{buyerli.product_image}}">
                                        </div> {{buyerli.name[buyerli.seller_id]}}
                                        <p>
                                            {{buyerli.last_message[buyerli.buyer_id]}}</p>

                                        <div class="btn_red btn_brdr">
                                            {{buyerli.last_message_time[buyerli.buyer_id] | dateAgo }}
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </ul>
            </div>
            <div class="container-fluid">
                <div class="row" *ngIf="tab2 != null" style="height: 100%;">
                    <div class="col-md-12 chatWrap">
                        <div class="content_sidebr" #scrollMe>
                            <div class="No_found" *ngIf="messageData1?.length == 0 && messageData?.length == 0"><span class="norecordIcon"></span> No Data Found !</div>
                            <!----------------------------------------->
                            <div *ngFor="let message1 of messageData1; let i = index" class="row msg_rw {{ message1.senderId == currentUserId ? 'col_right_row' : 'col_left_row' }}">

                                <div class="chat_left_usr" *ngIf="message1.messageType==1">
                                    <div class="persn_dt">
                                        <!-- <h6 class="name_per">{{message1.sender_id == currentUserId ? otherUserName : userName }}</h6> -->
                                        <span class="time_per">
                                            {{message1.firebaseMessageTime | date:'dd-MMM-yyyy h:mm a'}}</span>
                                    </div>
                                    <p class="chat_red">{{message1.message}}</p>
                                </div>

                                <div class="chat_left_usr" *ngIf="message1.messageType==2">
                                    <div class="persn_dt">
                                        <span class="time_per">{{message1.message_time | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <!-- <p class="chat_red">{{message.attachment_url}}</p> -->
                                    <div class="img_egg_lft quote_mg img_set"><img src="{{message1.attachmentUrl}}" />
                                    </div>
                                </div>


                                <div class="chat_left_usr" *ngIf="message1.messageType==3">
                                    <div class="persn_dt">
                                        <!-- <h6 class="name_per">Himanshi  </h6> -->
                                        <span class="time_per">{{message1.firebaseMessageTime | date:'dd-MMM-yyyy h:mm
                                            a'}} </span>
                                    </div>
                                    <div class="box-quat">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4 class="txt_red">Placed Deal</h4>
                                                <!-- <a class="m_btn_re btn_red btn_pending">Pending</a> -->
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="quat_txt">
                                                    <a href="javascript:;" (click)="redirectDealDetails(message1?.attachmentUrl?.split('_')[1])">
                                                        <ul>
                                                            <li>Category
                                                                <br><strong>{{productData?.categoryData?.name}}</strong>
                                                            </li>
                                                            <!-- <li>Selected Sub Category<br><strong>Broiler Farmer</strong></li> -->
                                                            <li>Quantity<br><strong>{{message1?.message?.split("shipping")[1]}}</strong>
                                                            </li>
                                                            <li class="borderbottom">Service Date
                                                                <br><strong>{{message1.shipping | date:'dd-MMM-yyyy'
                                                                }}</strong>
                                                            </li>
                                                        </ul>
                                                        <ul>

                                                            <li>Sub Category
                                                                <br><strong>{{productData?.subCategoryName}}</strong>
                                                            </li>
                                                            <li>Price<br><strong>{{message1?.message?.split("shipping")[0]}}</strong>
                                                            </li>
                                                            <li>Client's Location<br><strong>{{message1?.attachmentUrl?.split("_")[0]}}</strong>
                                                            </li>
                                                            <!-- <li>Selected Delivery Date<br><strong>28 Sep 2020</strong></li> -->
                                                        </ul>
                                                    </a>
                                                    <!-- <div class="btns_cnfrm_dec">
                                                            <a class="m_btn_red btn_red btn_small">Cancel</a>
                                                        </div> -->

                                                    <!-- <div class="btns_cnfrm_dec" *ngIf="message1.receiverId==currentUserId && message1.firebaseMessageTime==chatDialog.payment_request_time"> -->

                                                    <!-- 29-oct-2021 -->
                                                    <div class="btns_cnfrm_dec" *ngIf="message1.receiverId==currentUserId && message1.firebaseMessageTime==chatDialog?.payment_request_time">

                                                        <!-- <div class="btns_cnfrm_dec" *ngIf="message1.receiverId==currentUserId"> -->
                                                        <a class="m_btn_red btn_red" (click)="orderAcceptedDeclined(message1?.attachmentUrl?.split('_')[1],1)"> Accept </a>
                                                        <a class="m_btn_red btn_red btn_brdr" (click)="orderAcceptedDeclined(message1?.attachmentUrl?.split('_')[1],2)">Decline </a>
                                                    </div>

                                                    <!--  <div class="btns_cnfrm_dec" *ngIf="message1.receiverId==currentUserId && message1.firebaseMessageTime==chatDialog.payment_request_time">
                                                        <a class="m_btn_red btn_red" (click)="orderAcceptedDeclined(message1?.attachmentUrl?.split('_')[1],1)">
                                                            Accept </a>
                                                        <a class="m_btn_red btn_red btn_brdr" (click)="orderAcceptedDeclined(message1?.attachmentUrl?.split('_')[1],2)">Decline
                                                        </a>

                                                    </div> -->
                                                </div>
                                            </div>
                                            <!--  <div class="col-lg-2 col-md-2">
                                                    <div class="img_egg_lft quote_mg">
                                                        <img src="./assets/images/quo.svg">
                                                    </div>
                                                </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="chat_left_usr" *ngIf="message1.messageType==4">
                                    <div class="persn_dt">
                                        <!--  <h6 class="name_per">{{message.sender_id == currentUserId ? otherUserName : userName }}</h6> -->
                                        <span class="time_per">{{message1.firebaseMessageTime | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <p class="chat_red">ACCEPTED</p>
                                </div>
                                <div class="chat_left_usr" *ngIf="message1.messageType==5">
                                    <div class="persn_dt">
                                        <!--  <h6 class="name_per">{{message.sender_id == currentUserId ? otherUserName : userName }}</h6> -->
                                        <span class="time_per">{{message1.firebaseMessageTime | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <p class="chat_red">DECLINE</p>
                                </div>

                            </div>
                            <!----------------------------------------->
                            <!----------------------fire base hostory --------------------------------------->
                            <div *ngFor="let message of messageData; let i = index" class="row msg_rw {{ message.sender_id == currentUserId ? 'col_right_row' : 'col_left_row' }}">

                                <div class="chat_left_usr" *ngIf="message.message_type==1">
                                    <div class="persn_dt">
                                        <!--  <h6 class="name_per">{{message.sender_id == currentUserId ? otherUserName : userName }}</h6> -->
                                        <span class="time_per">{{message.message_time | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <p class="chat_red">{{message.message}}</p>
                                </div>

                                <div class="chat_left_usr" *ngIf="message.message_type==2">
                                    <div class="persn_dt">
                                        <span class="time_per">{{message.message_time | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <!-- <p class="chat_red">{{message.attachment_url}}</p> -->
                                    <div class="img_egg_lft quote_mg img_set"><img src="{{message.attachment_url}}" />
                                    </div>
                                </div>


                                <div class="chat_left_usr" *ngIf="message.message_type==3">
                                    <div class="persn_dt">
                                        <!-- <h6 class="name_per">Himanshi  </h6> -->
                                        <span class="time_per">{{message.message_time | date:'dd-MMM-yyyy h:mm a'}}
                                        </span>
                                    </div>
                                    <div class="box-quat">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4 class="txt_red">Placed Deal</h4>
                                                <!-- <a class="m_btn_re btn_red btn_pending">Pending</a> -->
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="offset-xl-2 col-xl-10 offset-xl-0 col-lg-12">
                                                <div class="quat_txt">
                                                    <ul>
                                                        <li>Category
                                                            <br><strong>{{productData?.categoryData?.name}}</strong>
                                                        </li>
                                                        <!-- <li>Selected Sub Category<br><strong>Broiler Farmer</strong></li> -->
                                                        <li>Quantity<br><strong>{{message?.message?.split("shipping")[1]}}</strong>
                                                        </li>
                                                        <li class="borderbottom">Service Date
                                                            <br><strong>{{message?.shipping | date:'dd-MMM-yyyy'
                                                                }}</strong>
                                                        </li>
                                                    </ul>
                                                    <ul>

                                                        <li>Sub Category
                                                            <br><strong>{{productData?.subCategoryName}}</strong>
                                                        </li>
                                                        <li>Price<br><strong>{{message?.message?.split("shipping")[0]}}</strong>
                                                        </li>
                                                        <li>Client's Location<br><strong>{{message?.attachment_url?.split("_")[0]}}</strong>
                                                        </li>
                                                        <!-- <li>Selected Delivery Date<br><strong>28 Sep 2020</strong></li> -->
                                                    </ul>
                                                    <!-- <div class="btns_cnfrm_dec">
                                                            <a class="m_btn_red btn_red btn_small">Cancel</a>
                                                        </div> -->
                                                    <div class="btns_cnfrm_dec" *ngIf="message.receiver_id == currentUserId && message.message_time == chatDialog?.payment_request_time">
                                                        <a class="m_btn_red btn_red" (click)="orderAcceptedDeclined(message?.attachment_url?.split('_')[1],1)">
                                                            Accept </a>
                                                        <a class="m_btn_red btn_red btn_brdr" (click)="orderAcceptedDeclined(message?.attachment_url?.split('_')[1],2)">Decline
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--  <div class="col-lg-2 col-md-2">
                                                    <div class="img_egg_lft quote_mg">
                                                        <img src="./assets/images/quo.svg">
                                                    </div>
                                                </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="chat_left_usr" *ngIf="message.message_type==4">
                                    <div class="persn_dt">
                                        <!--  <h6 class="name_per">{{message.sender_id == currentUserId ? otherUserName : userName }}</h6> -->
                                        <span class="time_per">{{message.message_time | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <p class="chat_red">ACCEPTED </p>
                                </div>
                                <div class="chat_left_usr" *ngIf="message.message_type==5">
                                    <div class="persn_dt">
                                        <!--  <h6 class="name_per">{{message.sender_id == currentUserId ? otherUserName : userName }}</h6> -->
                                        <span class="time_per">{{message.message_time | date:'dd-MMM-yyyy h:mm
                                            a'}}</span>
                                    </div>
                                    <p class="chat_red">DECLINE</p>
                                </div>
                            </div>
                            <!----------------------fire base hostory --------------------------------------->

                        </div>
                    </div>
                    <div class=" chat_send" *ngIf="tab2 != null">
                        <!-- <form (submit)="sendMessage($event);" id="chat_form"> -->
                        <form [formGroup]="chatForm" (ngSubmit)="sendMessage(chatForm)">
                            <div class="col-lg-12">
                                <fieldset>
                                    <div class="bx_text_entr">
                                        <div class="txt_msg_area">
                                            <a class="arrow_bk" (click)="openModule(loginModal28)" *ngIf="seller_id == currentUserId && dialogStatus!=1"><i
                                                class="fa fa-angle-right"></i></a>
                                            <div class="input_ara">
                                                <!-- <input type="chat_send" id="userMessage" name="userMessage" placeholder="Start typing here.."> -->
                                                <textarea id="userMessage" formControlName="userMessage" name="userMessage" placeholder="Start typing here.." (window:keypress)="onSomeAction($event)"></textarea>
                                                <ul>
                                                    <li>
                                                        <input type="file" (change)="upload($event)" accept=".png,.jpg,.gif" />
                                                        <a><img src="./assets/images/att.svg"></a>
                                                    </li>
                                                    <li><button>{{'homePage.buyer.send' | translate}} <img
                                                            src="./assets/images/sh.svg"></button></li>

                                                </ul>
                                            </div>

                                        </div>
                                        <input type="hidden" value="{{buyer_id}}" id="chatBuyerId">
                                        <input type="hidden" value="{{seller_id}}" id="chatSellerId">
                                        <input type="hidden" value="{{product_id}}" id="chatProductId">
                                        <input type="hidden" value="{{chatDiologId}}" id="chatDiologId1">

                                        <input type="hidden" value="{{unReadMessageCount ? unReadMessageCount : 0 }}" id="unreadMessageCount">
                                        <input type="hidden" value="{{(otherUserUnreadCount) ? otherUserUnreadCount : 0 }}" id="otherUserUnreadCounttext">
                                        <input type="hidden" value="{{(lastMessageTimeOther) ? lastMessageTimeOther : 0 }}" id="lastMessageTimeOthertext">
                                        <input type="hidden" value="{{(lastMessageOther) ? lastMessageOther : 0 }}" id="lastMessageOthertext">

                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section>

    </main>
</body>
<ng-template #loginModal28>
    <div class="log_pop otp_scrn submit_acc add_pop pop_off_deal" id="loginModal28" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Add your requirement</h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form (submit)="placeOrder($event);">
                            <div class="form-group position_rel row">
                                <div class="col-6">
                                    <label for="category">Category</label>
                                    <span>
                                        <input type="text" value="{{productData?.categoryData?.name}}" name=""
                                            readonly="" id="product_category">
                                    </span>
                                </div>
                                <div class="col-6">
                                    <label for="category">Sub Category</label>
                                    <span>
                                        <input type="text" value="{{productData?.subCategoryName}}" name="" readonly="">
                                    </span>

                                </div>
                            </div>
                            <div class="form-group position_rel row" *ngIf="productData?.categoryData?.name=='Doctor Consultancy'">

                                <div class="col-6">
                                    <label for="address" *ngIf="productData?.categoryData?.name=='Doctor Consultancy'">Consultancy Fees</label>
                                    <span><input type="text" value="{{consultancyFee ? consultancyFee.split(' ')[0] : 0}}" name="" placeholder="Price"
                                            id="consultancy_fee" (keypress)="numberOnly($event)" maxlength="7"></span>
                                    <div class="text-danger">{{error_consultancy_fee}}</div>
                                </div>


                                <div class="col-6">
                                    <label for="address">Unit</label>
                                    <span><input type="text" value="{{consultancyFee ? consultancyFee.split(' ')[1]+' '+consultancyFee.split(' ')[2] : 0}}" name=""
                                            placeholder="Quantity" id="product_consultancyfee_unit" 
                                            maxlength="20" readonly=""></span>
                                    <div class="text-danger">{{error_name_quantity}}</div>
                                </div>

                            </div>
                            <div class="form-group position_rel row" *ngIf="productData?.categoryData?.name=='Doctor Consultancy'">

                                <div class="col-6">
                                    <label for="address" *ngIf="productData?.categoryData?.name=='Doctor Consultancy'">Visit Charges</label>
                                    <span><input type="text" value="{{visitCharges ? visitCharges.split(' ')[0] : 0 }}" name="" placeholder="Price"
                                            id="visit_charges" (keypress)="numberOnly($event)" maxlength="7"></span>
                                    <div class="text-danger">{{error_visit_charges}}</div>
                                </div>


                                <div class="col-6">
                                    <label for="address">Unit</label>
                                    <span><input type="text" value="{{visitCharges ? visitCharges.split(' ')[1]+' '+visitCharges.split(' ')[2] : 0 }}" name=""
                                            placeholder="Unit" id="visit_charges_unit"
                                            maxlength="20" readonly=""></span>
                                    <div class="text-danger">{{error_name_quantity}}</div>
                                </div>

                            </div>
                            <div class="form-group position_rel row" *ngIf="productData?.categoryData?.name!='Doctor Consultancy'">

                                <div class="col-6">
                                    <label for="address" *ngIf="productData?.categoryData?.name=='Doctor Consultancy'">Consultancy Fees</label>
                                    <label for="address" *ngIf="productData?.categoryData?.name!='Doctor Consultancy'">Price</label>
                                    <span><input type="text" value="{{productData?.price}}" name="" placeholder="Price"
                                            id="product_price" (keypress)="numberOnly($event)" maxlength="7"></span>
                                    <div class="text-danger">{{error_name_price}}</div>
                                </div>


                                <div class="col-6">
                                    <label for="address">Quantity</label>
                                    <span><input type="text" value="{{ (productData?.categoryData?.name=='Poultry Medicine') ? productData?.priceUnit : productData?.quantity}}" name=""
                                            placeholder="Quantity" id="product_quantity" (keypress)="numberOnly($event)"
                                            maxlength="6"></span>
                                    <div class="text-danger">{{error_name_quantity}}</div>
                                </div>

                            </div>
                            <div class="form-group position_rel row">
                                <label for="address">Location</label>
                                <div class="col-12">
                                    <span><input type="text" value="{{productData?.address?.googleAddress}}" name=""
                                            placeholder="Sector 21, Chandigarh" id="shipping_address"></span>
                                    <div class="text-danger">{{error_name_address}}</div>
                                </div>
                            </div>

                            <div class="form-group position_rel row dt_pick" *ngIf="productData?.categoryData?.name != 'Doctor Consultancy'">

                                <div class="col-12">
                                    <label for="address">Service Date loadingDate</label>
                                    <span><input type="date" [min]="minDate" value="{{loadingDate ? (loadingDate)  : ''}}" name=""
                                            id="est_delivery_date"></span>
                                    <div class="text-danger">{{error_name_date}}</div>
                                </div>
                            </div>
                            <div class="form-group position_rel row" *ngIf="productData?.categoryData?.name == 'Poultry Medicine'">
                                <div class="col-6">
                                    <label for="address">MOQ</label>
                                    <div class="col-12">
                                        <span><input type="text" value="{{productData?.moq}}" name="" placeholder="" id="moq"></span>
                                        <div class="text-danger"></div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="address">Quantity</label>
                                    <span><input type="text" value="{{productData?.moqUnit}}" name=""
                                            placeholder="MOQ Quantity" id="moq_product_quantity" (keypress)="numberOnly($event)"
                                            maxlength="6"></span>
                                    <div class="text-danger">{{error_name_quantity}}</div>
                                </div>
                            </div>
                            <input type="hidden" value="{{productData?.userId}}" id="product_sender_id">
                            <input type="hidden" value="{{buyer_id}}" id="product_buyer_id">
                            <input type="hidden" value="{{buyer_id}}" id="chatBuyerId1">
                            <input type="hidden" value="{{seller_id}}" id="chatSellerId1">
                            <input type="hidden" value="{{product_id}}" id="chatProductId1">
                            <input type="hidden" value="{{chatDiologId}}" id="chatDiologId2">
                            <input type="hidden" value="{{unReadMessageCount ? unReadMessageCount : 0 }}" id="unreadMessageCount1">
                            <input type="hidden" value="{{(otherUserUnreadCount) ? otherUserUnreadCount : 0 }}" id="otherUserUnreadCounttext1">
                            <input type="hidden" value="{{(lastMessageTimeOther) ? lastMessageTimeOther : 0 }}" id="lastMessageTimeOthertext1">
                            <input type="hidden" value="{{(lastMessageOther) ? lastMessageOther : 0 }}" id="lastMessageOthertext1">

                            <!-- Latitude and longitude-->
                            <input type="hidden" value="{{productData?.location?.coordinates[1]}}" id="product_latitude">
                            <input type="hidden" value="{{productData?.location?.coordinates[0]}}" id="product_longitude">
                            <input type="hidden" value="{{productData?.address?.city}}" id="product_city">
                            <input type="hidden" value="{{productData?.address?.state}}" id="product_state">
                            <input type="hidden" value="{{productData?.address?.country}}" id="product_country">
                            <input type="hidden" value="{{productData?.address?.country}}" id="remaining_quantity">
                            <input type="hidden" value="{{productData?.moq}}" id="moq">
                            <!--End of the location aatribute here-->


                            <div class="form-group position_rel row">
                                <div class="col-12" style="text-align: center;">
                                    <!-- <button style="width: 400px;" type="button" class="btn btn-info btn-block btn-round btn_red" onclick="location.href='seller-chat2.html'">Send Offer</button> -->
                                    <button style="width: 400px;" type="submit" class="btn btn-info btn-block btn-round btn_red">Send Offer</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #planselect>
    <div class="log_pop sign_pop pop_dbl_btn lgout pro_added_suc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h4>Please update your plan</h4>

                    </div>
                    <div class="planList">
                        <ul>
                            <li *ngFor="let plan of plansList?.planData">
                                <label class="check_buy choose_slt_plan_opt" *ngIf="plan.planPrice > 0">
                                    <div class="plan_qnt_txt"> {{plan?.quantity}} <small>Quantity</small></div>
                                    <div class="prc_mnth">
                                        <strong><i class="fa fa-rupee"></i> {{plan?.planPrice}}<small>
                                                {{plan?.subscription}}</small></strong>

                                    </div>
                                    <p> {{plan?.planType}}</p>
                                    <input type="radio" name="checkbox" id={{plan?._id}}
                                        (change)="onlyOneValue($event,  plan)">
                                    <span class="checkmark"></span>
                                </label>
                            </li>

                        </ul>
                        <button type="button" *ngIf="planPaymet != ''" class="btn mr-3 btn-info btn-block btn-round btn_red col-12" (click)="payment()">Pay <i class="fa fa-rupee"></i>
                            {{planPaymet}}</button>

                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>