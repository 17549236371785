import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, ElementRef, ViewChildren, QueryList, NgZone, AfterViewChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpParams } from '@angular/common/http';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { apiUrl } from '../../global/global';
import { ChatService } from '../service/chat.service';
import * as moment from 'moment'
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';

/////////////////////////////////
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";
//import { ChatMessage } from '../../shared/models/chatMessage';
import { take, finalize } from 'rxjs/operators';
///////////////////////////////////////////
//import { BehaviorSubject } from 'rxjs';
//import { Observable } from 'rxjs/Observable';
declare var Razorpay: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit, AfterViewChecked {



  @ViewChildren('item') itemElements: QueryList<any>
  private items = [];
  tab = 'Seller';
  planPaymet: any;
  premiumDealName: any;
  s3url: any
  modalRef: BsModalRef;
  userId = "";
  showPayment = 0;
  categoryID: any;
  page: any = 1;
  plansList: any = [];
  allData: any = [];
  BuyerListing: any = [];
  buyeringList: any = [];
  premiumLead: any = [];
  currentTime: any;
  buyer_id: any;
  seller_id: any;
  product_id: any;
  tab1: any;
  tab2: any;
  participantId: any = "";
  chatDiologId: any;
  chatDiologId1: any;
  chatDialog: any = [];

  showNormalChat = true;
  showPremiumChat = false;

  phoneNumber: any;
  planSubscription: any;
  planVisibility: any;
  planName: any;
  planTrade: any;
  planId: any;
  chatForm: FormGroup;
  ///////////////////////////
  otherUserIdChat: any;
  messageData: any = [];
  messageData1: any = [];
  unReadMessageCount: any;
  lastMessageTimeOther: any;
  lastMessageOther: any;
  otherUserUnreadCount: any;
  product_price: any;
  otherUserName: any;
  currentUserId: any;
  userName: any;
  productData: any;
  minDate: any;
  dialogStatus: any;
  consultancyFee: any;
  visitCharges: any;
  loadingDate: any;
  ////////////////////////
  error_name_loading_ad: any;
  error_name_date: any;
  error_consultancy_fee: any;
  error_visit_charges: any;
  error_name_address: any;
  error_name_quantity: any;
  error_name_price: any;
  ref: any;
  showPremiumbuy = false;
  task: any;
  //chatDialog: any;
  slideCheck = 0;
  //uploadProgress: any;
  downloadURL: any;
  private isNearBottom = true;
  private itemContainer: any;
  private scrollContainer: any;
  //afStorage: any;
  ////////////////////////////
  //private _ChatMessages = new BehaviorSubject<any[]>([]);
  //readonly ChatMessages = this._ChatMessages.asObservable();
  //private dataStore: { messages: ChatMessage[]} = { messages: [] };
  //////////////////////////

  @ViewChild('scrollframe', { static: false } as any) scrollFrame: ElementRef;
  // @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  constructor(private modalService: BsModalService, private CS: ChatService, private router: Router, private route: ActivatedRoute, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private storage: AngularFireStorage, public admin: JsonPlaceHolderService, private formBuilder: FormBuilder,) {
    this.chatForm = formBuilder.group({
      userMessage: ['',],
    });
  }

  ngOnInit(): void {
    this.s3url = localStorage.getItem('s3BaseUrl');
    this.scrollToBottom();
    this.userId = localStorage.getItem('id');
    this.currentUserId = localStorage.getItem('id');
    this.getSellingProductList();
    this.getBuying('');
    this.buyer_id = this.route.snapshot.paramMap.get('buyer_id');
    this.seller_id = this.route.snapshot.paramMap.get('seller_id');
    this.product_id = this.route.snapshot.paramMap.get('product_id');
    this.tab1 = this.route.snapshot.paramMap.get('tab');
    this.tab2 = this.product_id;
    if (this.route.snapshot.paramMap.get('tab') != '' && this.route.snapshot.paramMap.get('tab') == '1') {
      this.tab = 'Buyer';
      // }else if(this.route.snapshot.paramMap.get('tab')!='' && this.route.snapshot.paramMap.get('tab')=='2'){
      //     this.tab = 'Seller';
    } else {
      this.tab = 'Seller';
    }

    if ((this.buyer_id) < (this.seller_id)) {
      console.log('aaaaaa');
      this.participantId = this.buyer_id + ',' + this.seller_id;
      this.chatDiologId = this.buyer_id + ',' + this.seller_id + '-' + this.product_id;
    } else {
      console.log('bbbbb');
      this.participantId = this.seller_id + ',' + this.buyer_id;
      this.chatDiologId = this.seller_id + ',' + this.buyer_id + '-' + this.product_id;

    }
    console.log("this.participantId", this.participantId);

    if (this.route.snapshot.paramMap.get('product_id') != '' || this.route.snapshot.paramMap.get('product_id') != null) {
      console.log("this.participantId", this.chatDiologId);
      this.getChatsDialog(this.chatDiologId);
    }
    if (this.route.snapshot.paramMap.get('product_id') != null) {
      this.openProductSeller(this.product_id);
    }
    if (this.route.snapshot.paramMap.get('product_id') != null || this.route.snapshot.paramMap.get('product_id') != '') {
      this.getMessages(this.chatDiologId);
    }
    if (this.route.snapshot.paramMap.get('product_id') != null && this.route.snapshot.paramMap.get('product_id') != '') {
      //this.getMessages(this.chatDiologId);
      this.getProductList();
      this.getChatHistory(this.chatDiologId, this.page);
    }
    /*if(this.route.snapshot.paramMap.get('product_id')!=null || this.route.snapshot.paramMap.get('product_id')!=''){
      //this.getChatHistory(this.chatDiologId, this.page);
    }*/
    this.minDate = moment(new Date()).format('YYYY-MM-DD');


  }

  getSellingProductList() {
    let params = new HttpParams();
    params = params.append('userId', this.userId);
    //this.imgUrl = 'https://edee-dev.s3.amazonaws.com/product/';
    this.CS.getList(apiUrl._categoryList, params)
      .subscribe(res => {
        this.allData = res.data.categoryData;
        console.log(this.allData, "this.allData");
      },
        err => {
        });

  }
  openProductSeller(product_id: string) {
    var product_id = product_id.toString().trim();
    this.categoryID = product_id;
    let userId = this.userId.toString().trim();

    console.log(product_id, userId, "adasdasdasd")
    this.db.list(`Chats`,
      query => query
        // .orderByChild("category_id")
        // .equalTo(product_id)
        .orderByChild("seller_id")
        .equalTo(userId)
      //.queryEqualTo(product_id,"category_id")
    ).valueChanges().subscribe(listNew => {
      console.log('sdfsdfsdfdfsdf', listNew);
      //this.BuyerListing = listNew ? listNew : [];
      this.BuyerListing = Object.assign([], listNew)
      console.log('I am here', this.BuyerListing);

      //document.getElementById("category_tab_"+product_id).click();
      //return this.BuyerListing;
      //console.log('sdfsdfsdfdfsdf',this.BuyerListing);
    });
    //this.chatOpen = true;
    /////////////////////////////////
  }

  getBuying(value: string) {
    let userId = this.userId;
    this.db.list(`Chats`,
      query => query
        .orderByChild("buyer_id")
        //.equalTo(id)
        .equalTo(userId)
      //.limitToLast(10)
    ).valueChanges().subscribe(listNew => {
      listNew.sort(function (a, b) {
        a = Object.values(a['last_message_time'])[0];
        b = Object.values(b['last_message_time'])[0];
        return Number(b) - Number(a);
      });
      this.buyeringList = listNew ? listNew : [];
      console.log('this.buyeringList:::::', this.buyeringList);
      var d = new Date();
      this.currentTime = d.valueOf();
      //this.itemsCopy = listNew ? listNew : [];
      /*if(value!=''){
        this.buyeringList = this.itemsCopy.filter(function(tag) {
          return tag.name[tag.seller_id].indexOf(value.toLowerCase()) >= 0;
        }); 
      }*/
    });
    /////////////////////////////////
  }

  getMessages(chat_dialog_id: string) {
    //alert(chat_dialog_id);
    let userId = String(this.userId);
    this.db.list(`Messages/${chat_dialog_id}`,
      query => query
        .orderByChild("chat_dialog_id")
        .equalTo(chat_dialog_id)
        .limitToLast(100)
    ).valueChanges().subscribe(list => {
      //console.log(list);
      //this.messageData = list ? <Array<ChatMessage>>list : [];
      this.messageData = list ? list : [];
      console.log(this.messageData);
      //this.dataStore.messages = list ? <Array<ChatMessage>>list : [];
      //this._ChatMessages.next(Object.assign({}, this.dataStore).messages);
    });
  }

  public sendMessage(form) {
    //if (event) event.preventDefault();
    console.log(form.value, "adsdasdasd")
    //if (event.srcElement['userMessage'].value.toString().trim() != '') {
    if (form.value.userMessage.toString().trim() != '') {
      var msg = form.value.userMessage.toString().trim();
      var chatBuyerId = this.buyer_id.toString().trim();
      var chatSellerId = this.seller_id.toString().trim();
      var chatProductId = this.product_id.toString().trim();
      var chatDiologId = this.chatDiologId.toString().trim();
      var unreadMessageCount = this.unReadMessageCount ? this.unReadMessageCount.toString().trim() : 0;
      var otherUserUnreadCounttext = this.otherUserUnreadCount ? this.otherUserUnreadCount.toString().trim() : 0;
      var lastMessageTimeOthertext = this.lastMessageTimeOther ? this.lastMessageTimeOther.toString().trim() : 0;
      var lastMessageOthertext = this.lastMessageOther ? this.lastMessageOther.toString().trim() : 0;
      ////////////////////////////////////////////////////////////
      var userId = String(this.userId);
      var otherUserId = (userId == chatBuyerId) ? chatSellerId : chatBuyerId
      var readStatusArr = {};

      var last_message_time = new Date().getTime()
      var receiver_id = (userId == chatBuyerId) ? chatSellerId : chatBuyerId;
      readStatusArr[receiver_id] = receiver_id;
      var messagePush = this.db.list(`Messages/${chatDiologId}`).push({
        "attachment_url": "",
        "chat_dialog_id": chatDiologId,
        "firebase_message_time": new Date().getTime(),
        "message": msg,
        "message_time": last_message_time,
        "message_type": 1,
        "product_id": chatProductId,
        "receiver_id": receiver_id,
        "sender_id": userId,
        "message_read_status": readStatusArr
      });
      var aa = this.db.object(`Messages/${chatDiologId}/${messagePush.key}/`).update({
        "message_id": messagePush.key
      });
      this.db.list(`Notifications`).push({
        "attachment_url": "",
        "chat_dialog_id": chatDiologId,
        "firebase_message_time": new Date().getTime(),
        "message": msg,
        "message_id": messagePush.key,//"-MDTRUjeevCCQYUhvZv9",
        "message_time": last_message_time,
        "message_type": 1,
        "product_id": chatProductId,
        "receiver_id": receiver_id,
        "sender_id": userId,
        "message_read_status": readStatusArr
      });
      var messageTimeArr = {};
      //if(myblock_statys == '0' ){
      messageTimeArr[otherUserId] = last_message_time;
      /*}else{
          messageTimeArr[otherUserId] = lastMessageTimeOthertext;  
      }*/
      messageTimeArr[userId] = last_message_time;

      var messageTypeArr = {};
      messageTypeArr[otherUserId] = 1;
      messageTypeArr[userId] = 1;

      var lastMessageArr = {};
      //if(myblock_statys == '0' ){
      lastMessageArr[otherUserId] = msg;
      /*}else{
          lastMessageArr[otherUserId] = lastMessageOthertext;
      }*/
      lastMessageArr[userId] = msg;
      var deleteTime = {};
      deleteTime[otherUserId] = 0;
      deleteTime[userId] = 0;
      var unreadMessageArr = {};
      var message_count = '1';
      //if(myblock_statys == '0' ){
      unreadMessageArr[otherUserId] = parseInt(unreadMessageCount) + parseInt(message_count);
      /*}else{
          unreadMessageArr[otherUserId] = parseInt(unreadMessageCount);
      }*/
      unreadMessageArr[userId] = 0;

      this.db.object(`Chats/${chatDiologId}`).update({
        "last_message_time": messageTimeArr,
        "last_message_type": messageTypeArr,
        "last_message_sender_id": userId,
        "last_message": lastMessageArr,
        "last_message_id": messagePush.key,
        "unread_count": unreadMessageArr,
        //"dialog_status": 1,
        //"payment_request_time":""
      });
      //event.srcElement['userMessage'].value = '';
      this.chatForm.patchValue({ 'userMessage': "" });
      ////////////////////////////////////////////////////////////
    }
  }
  getChatsDialog(chat_dialog_id: string) {
    console.log('chat_dialog_id::::', chat_dialog_id);
    if (chat_dialog_id != "" && chat_dialog_id != null) {
      let userId = this.userId;
      //console.log('MMMMMM:::::', userId);
      this.db.list(`Chats`,
        query => query
          .orderByChild("chat_dialog_id")
          .equalTo(chat_dialog_id)
        //.limitToLast(10)
      ).valueChanges().subscribe(list => {
        this.chatDialog = list ? list[0] : [];
        console.log('list:::::', list);
        if (this.chatDialog) {
          console.log('this.chatDialog:::::', this.chatDialog);
          this.chatDiologId1 = (this.chatDialog?.chat_dialog_id) ? this.chatDialog?.chat_dialog_id : chat_dialog_id;
          var otherUserId = (this.chatDialog && userId == this.chatDialog.buyer_id) ? this.chatDialog.seller_id : this.chatDialog.buyer_id;
          this.otherUserIdChat = otherUserId;
          this.unReadMessageCount = this.chatDialog.unread_count ? this.chatDialog.unread_count[otherUserId] : 0;
          this.otherUserName = this.chatDialog.name[otherUserId.toString().trim()];
          this.lastMessageTimeOther = this.chatDialog.last_message_time ? this.chatDialog.last_message_time[otherUserId] : 0;
          this.lastMessageOther = this.chatDialog.last_message ? this.chatDialog.last_message[otherUserId] : '';
          this.otherUserUnreadCount = this.chatDialog.unread_count ? this.chatDialog.unread_count[otherUserId] : 0;
          this.dialogStatus = this.chatDialog.dialog_status ? this.chatDialog.dialog_status : 0;
          this.userName = this.chatDialog.name ? this.chatDialog.name[this.userId] : '';
          //this.otherUserName = this.chatDialog.name ? this.chatDialog.name[otherUserId] : '';              
          this.product_price = this.chatDialog.product_price ? (this.chatDialog.product_price) : '';
          var unreadMessageArr = {};
          unreadMessageArr[userId] = 0;
          unreadMessageArr[otherUserId] = this.chatDialog.unread_count[otherUserId];
          var statusU = this.db.object(`Chats/${chat_dialog_id}`).update({
            "unread_count": unreadMessageArr
          });
        }

        //var ddd = this.db.list(`Messages/${chat_dialog_id}`).orderByChild('receiver_id');//.chil("receiver_id",userId).remove();

        setTimeout(() => {
          this.db.list(`Messages/${chat_dialog_id}`, query => query.orderByChild("receiver_id").equalTo(userId)).remove();
          this.getChatHistory(chat_dialog_id, 1);
        }, 2500);
        // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      });
    }
  }
  openModule(template) {
    this.modalRef = this.modalService.show(template);
  }

  getProductList() {
    let params = new HttpParams();
    params = params.append('id', this.product_id);
    //this.imgUrl = 'https://edee-dev.s3.amazonaws.com/product/';
    this.CS.getList(apiUrl._getProductDetails, params)
      .subscribe(res => {
        this.productData = res.data[0];
        for (var i = 0; i < res.data[0].attributesData.length; i++) {
          if (res.data[0].attributesData[i].attrName == 'Consultancy Fees (in Rs.)') {
            this.consultancyFee = res.data[0].attributesData[i].traitName
          }
          if (res.data[0].attributesData[i].attrName == 'Visit Charges (in Rs.)') {
            this.visitCharges = res.data[0].attributesData[i].traitName
          }
          if (res.data[0].attributesData[i].attrName == 'Loading Date') {
            var lDate = res.data[0].attributesData[i].traitName;
            this.loadingDate = lDate ? lDate.split('.')[2].trim() + '-' + lDate.split('.')[1].trim() + '-' + lDate.split('.')[0].trim() : ''
            console.log(this.loadingDate);
          }
          if (res.data[0].attributesData[i].attrName == 'Delivery Date') {
            var lDate = res.data[0].attributesData[i].traitName;
            this.loadingDate = lDate ? lDate.split('.')[2].trim() + '-' + lDate.split('.')[1].trim() + '-' + lDate.split('.')[0].trim() : ''
            console.log(this.loadingDate);
          }
          if (res.data[0].attributesData[i].attrName == 'Available Date') {
            var lDate = res.data[0].attributesData[i].traitName;
            this.loadingDate = lDate ? lDate.split('.')[2].trim() + '-' + lDate.split('.')[1].trim() + '-' + lDate.split('.')[0].trim() : ''
            console.log(this.loadingDate);
          }
          if (res.data[0].attributesData[i].attrName == 'Date') {
            var lDate = res.data[0].attributesData[i].traitName;
            this.loadingDate = lDate ? lDate.split('.')[2].trim() + '-' + lDate.split('.')[1].trim() + '-' + lDate.split('.')[0].trim() : ''
            console.log(this.loadingDate);
          }
        }
        console.log("this.productData", this.productData)
      },
        err => {
        });
  }
  placeOrder(event: Event) {
    if (event) event.preventDefault();
    this.error_name_loading_ad = "";
    this.error_name_date = "";
    this.error_consultancy_fee = "";
    this.error_visit_charges = "";
    this.error_name_address = "";
    this.error_name_quantity = "";
    this.error_name_price = "";
    if (event.srcElement['product_price']) {
      var product_price = event.srcElement['product_price'].value.trim();
    } else {
      var product_price = '';
    }
    if (event.srcElement['product_quantity']) {
      var product_quantity = event.srcElement['product_quantity'].value.trim();
    } else {
      var product_quantity = '';
    }

    var shipping_address = event.srcElement['shipping_address'].value.trim();
    if (event.srcElement['est_delivery_date']) {
      var est_delivery_date = event.srcElement['est_delivery_date'].value.trim();
    } else {
      var est_delivery_date = '';
    }

    var loading_address = event.srcElement['shipping_address'].value.trim();
    var product_category = event.srcElement['product_category'].value.trim();
    //event.srcElement['loading_address'].value.trim();
    var sender_id = event.srcElement['product_sender_id'].value.trim();
    var buyer_id = event.srcElement['product_buyer_id'].value.trim();
    var product_city = event.srcElement['product_city'].value.trim();
    var product_state = event.srcElement['product_state'].value.trim();
    var product_country = event.srcElement['product_country'].value.trim();
    var product_latitude = event.srcElement['product_latitude'].value.trim();
    var product_longitude = event.srcElement['product_longitude'].value.trim();
    /////////////////////////////////////////////////////
    if (event.srcElement['moq']) {
      var moq = event.srcElement['moq'].value.trim();
    } else {
      var moq = '';
    }
    if (event.srcElement['moq_product_quantity']) {
      var moq_unit = event.srcElement['moq_product_quantity'].value.trim();
    } else {
      var moq_unit = '';
    }
    if (event.srcElement['consultancy_fee']) {
      var consultancy_fee = event.srcElement['consultancy_fee'].value.trim();
      var product_consultancyfee_unit = event.srcElement['product_consultancyfee_unit'].value.trim();
    } else {
      var consultancy_fee = '';
      var product_consultancyfee_unit = '';
    }
    if (event.srcElement['visit_charges']) {
      var visit_charges = event.srcElement['visit_charges'].value.trim();
      var visit_charges_unit = event.srcElement['visit_charges_unit'].value.trim();
    } else {
      var visit_charges = '';
      var visit_charges_unit = '';
    }

    ////////////////////////////////////////////////////
    if (event.srcElement['product_price'] && product_price == '') {
      this.error_name_price = "Product price is required";
      return false;
    } else if (event.srcElement['product_quantity'] && product_quantity == '' && product_quantity.length == 0) {
      this.error_name_quantity = "Quantity is required";
      return false;
    } else if (parseInt(moq) > parseInt(product_quantity)) {
      this.error_name_quantity = "Please enter Minimum Quantity " + moq;
      return false;
    } else if (shipping_address == '') {
      this.error_name_address = "Shipping address is required";
      return false;
    } else if (event.srcElement['est_delivery_date'] && est_delivery_date == '') {
      this.error_name_date = "Estimate Delivery Date is required";
      return false;
    } else if (loading_address == '') {
      this.error_name_loading_ad = "Loading address is required";
      return false;
    } else if (event.srcElement['consultancy_fee'] && consultancy_fee <= '0') {
      this.error_consultancy_fee = "Consultancy Fee is required";
      return false;
    } else if (event.srcElement['visit_charges'] && visit_charges <= '0') {
      this.error_visit_charges = "Visit Charges is required";
      return false;
    } else {
      let params = new HttpParams();
      params = params.append('buyerId', buyer_id);
      params = params.append('sellerId', sender_id);
      params = params.append('productId', this.product_id);
      var count = '0';
      if (product_quantity == '') {
        params = params.append('quantity', count);
      } else {
        if (product_category == 'Poultry Medicine') {
          params = params.append('quantity', count);
        } else {
          params = params.append('quantity', product_quantity);
        }
      }
      if (product_price == '') {
        params = params.append('dealAmount', count);
      } else {
        params = params.append('dealAmount', product_price);
      }
      if (est_delivery_date == '') {
        params = params.append('deliveryDate', moment(new Date()).format('DD-MM-YYYY'));
      } else {
        params = params.append('deliveryDate', est_delivery_date);
      }

      params = params.append('googleAddress', shipping_address);
      params = params.append('streetAddress', loading_address);
      params = params.append('city', product_city);
      params = params.append('state', product_state);
      params = params.append('country', product_country);
      params = params.append('latitude', product_latitude);
      params = params.append('longitude', product_longitude);
      params = params.append('dealMoq', moq);
      params = params.append('dealConsultancyFee', consultancy_fee + ' ' + product_consultancyfee_unit);
      params = params.append('dealVisitCharge', visit_charges + ' ' + visit_charges_unit);
      //this.imgUrl = 'https://edee-dev.s3.amazonaws.com/product/';
      console.log('params::::::::::', params);
      this.CS.submitForm(params, apiUrl._placeOrder)
        .subscribe(res => {
          console.log(res.data._id);
          //////////////////////////////////////////
          if (product_price == '') {
            var msg = consultancy_fee + "shipping" + product_consultancyfee_unit;
          } else {
            var msg = product_price + "shipping" + product_quantity;
          }
          var chatBuyerId = event.srcElement['chatBuyerId1'].value.toString().trim();
          var chatSellerId = event.srcElement['chatSellerId1'].value.toString().trim();
          var chatProductId = event.srcElement['chatProductId1'].value.toString().trim();
          var chatDiologId = event.srcElement['chatDiologId2'].value.toString().trim();

          var unreadMessageCount = event.srcElement['unreadMessageCount1'].value.toString().trim();
          var otherUserUnreadCounttext = event.srcElement['otherUserUnreadCounttext1'].value.toString().trim();
          var lastMessageTimeOthertext = event.srcElement['lastMessageTimeOthertext1'].value.toString().trim();
          var lastMessageOthertext = event.srcElement['lastMessageOthertext1'].value.toString().trim();
          ////////////////////////////////////////////////////////////
          var userId = String(this.userId);
          var otherUserId = (userId == chatBuyerId) ? chatSellerId : chatBuyerId
          var readStatusArr = {};

          var last_message_time = new Date().getTime()
          var receiver_id = (userId == chatBuyerId) ? chatSellerId : chatBuyerId;
          readStatusArr[receiver_id] = receiver_id;
          if (est_delivery_date == '') {
            var shipping = moment(new Date()).format('YYYY-MM-DD');
          } else {
            var shipping = moment(est_delivery_date).format('YYYY-MM-DD')
          }
          var messagePush = this.db.list(`Messages/${chatDiologId}`).push({
            "attachment_url": shipping_address + '_' + res.data._id,
            "chat_dialog_id": chatDiologId,
            "firebase_message_time": last_message_time,//new Date().getTime(),
            "message": msg,
            "message_time": last_message_time,
            "message_type": 3,
            "product_id": chatProductId,
            "receiver_id": receiver_id,
            "sender_id": userId,
            "message_read_status": readStatusArr,
            "shipping": shipping
          });
          var aa = this.db.object(`Messages/${chatDiologId}/${messagePush.key}/`).update({
            "message_id": messagePush.key
          });


          this.db.list(`Notifications`).push({
            "attachment_url": shipping_address + '_' + res.data._id,
            "chat_dialog_id": chatDiologId,
            "firebase_message_time": last_message_time,//new Date().getTime(),
            "message": msg,
            "message_id": messagePush.key,//"-MDTRUjeevCCQYUhvZv9",
            "message_time": last_message_time,
            "message_type": 3,
            "product_id": chatProductId,
            "receiver_id": receiver_id,
            "sender_id": userId,
            "message_read_status": readStatusArr,
            "shipping": shipping
          });
          var messageTimeArr = {};
          messageTimeArr[otherUserId] = last_message_time;
          messageTimeArr[userId] = last_message_time;

          var messageTypeArr = {};
          messageTypeArr[otherUserId] = 1;
          messageTypeArr[userId] = 1;

          var lastMessageArr = {};
          lastMessageArr[otherUserId] = msg;
          lastMessageArr[userId] = msg;



          var deleteTime = {};
          deleteTime[otherUserId] = 0;
          deleteTime[userId] = 0;

          var unreadMessageArr = {};
          var message_count = '1';
          unreadMessageArr[otherUserId] = parseInt(unreadMessageCount) + parseInt(message_count);
          unreadMessageArr[userId] = 0;

          this.db.object(`Chats/${chatDiologId}`).update({
            "last_message_time": messageTimeArr,
            "last_message_type": messageTypeArr,
            "last_message_sender_id": userId,
            "last_message": lastMessageArr,
            "last_message_id": messagePush.key,
            "unread_count": unreadMessageArr,
            "dialog_status": 1,
            "payment_request_time": last_message_time
          });
          if (event.srcElement['product_price']) {
            event.srcElement['product_price'].value = "";
          }
          if (event.srcElement['product_quantity']) {
            event.srcElement['product_quantity'].value = "";
          }
          if (event.srcElement['est_delivery_date']) {
            event.srcElement['est_delivery_date'].value = "";
          }


          event.srcElement['shipping_address'].value = "";
          //event.srcElement['loading_address'].value   ="";

          this.modalRef.hide();
          ////////////////////////////////////////////////////////////
          //////////////////////////////////////////////////
        },
          err => {
          });
    }
  }

  upload(event) {
    /*const randomId = Math.random().toString(36).substring(2);
    this.ref = this.storage.ref('chat_uploads/' + randomId);
    this.task = this.ref.put(event.target.files[0]);*/
    //this.uploadProgress = this.task.percentageChanges();
    //this.downloadURL = this.task.downloadURL();
    //var url = 'https://firebasestorage.googleapis.com/v0/b/egiyok.appspot.com/o/chat_uploads%2F' + randomId + '?alt=media';
    //////////////////////////////////////////

    var mimeType = event.target.files[0].type;
    var mimeName = event.target.files[0].name;
    const filePath = `chat_uploads/${mimeName}`;
    //const filePath = `chat_uploads/${messagePush.key}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, event.target.files[0]);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          console.log('downloadURL:::::', downloadURL);
          var url = downloadURL;

          //////////////////////////////////////////
          var msg = '';
          var chatBuyerId = this.buyer_id;
          var chatSellerId = this.seller_id;
          var chatProductId = this.product_id;
          var chatDiologId = this.chatDiologId;

          var unreadMessageCount = this.unReadMessageCount;
          var otherUserUnreadCounttext = this.otherUserUnreadCount;
          var lastMessageTimeOthertext = this.lastMessageTimeOther;
          var lastMessageOthertext = this.lastMessageOther;

          ////////////////////////////////////////////////////////////
          var userId = String(this.userId);
          var otherUserId = (userId == chatBuyerId) ? chatSellerId : chatBuyerId
          var readStatusArr = {};
          var last_message_time = new Date().getTime()
          var receiver_id = (userId == chatBuyerId) ? chatSellerId : chatBuyerId;
          readStatusArr[receiver_id] = receiver_id;
          var messagePush = this.db.list(`Messages/${chatDiologId}`).push({
            "attachment_url": url,
            "chat_dialog_id": chatDiologId,
            "firebase_message_time": last_message_time,//new Date().getTime(),
            "message": msg,
            "message_time": last_message_time,
            "message_type": 2,
            "product_id": chatProductId,
            "receiver_id": receiver_id,
            "sender_id": userId,
            "message_read_status": readStatusArr,
            "shipping": ''
          });

          var aa = this.db.object(`Messages/${chatDiologId}/${messagePush.key}/`).update({
            "message_id": messagePush.key
          });
          this.db.list(`Notifications`).push({
            "attachment_url": url,
            "chat_dialog_id": chatDiologId,
            "firebase_message_time": last_message_time,//new Date().getTime(),
            "message": msg,
            "message_id": messagePush.key,//"-MDTRUjeevCCQYUhvZv9",
            "message_time": last_message_time,
            "message_type": 2,
            "product_id": chatProductId,
            "receiver_id": receiver_id,
            "sender_id": userId,
            "message_read_status": readStatusArr,
            "shipping": ''
          });
          var messageTimeArr = {};

          messageTimeArr[otherUserId] = last_message_time;

          messageTimeArr[userId] = last_message_time;

          var messageTypeArr = {};
          messageTypeArr[otherUserId] = 1;
          messageTypeArr[userId] = 1;

          var lastMessageArr = {};

          lastMessageArr[otherUserId] = msg;

          lastMessageArr[userId] = msg;



          var deleteTime = {};
          deleteTime[otherUserId] = 0;
          deleteTime[userId] = 0;

          var unreadMessageArr = {};
          var message_count = '1';
          //unreadMessageArr[otherUserId] = parseInt(unreadMessageCount)+ parseInt(message_count);
          unreadMessageArr[otherUserId] = parseInt(message_count);
          unreadMessageArr[userId] = 0;


          this.db.object(`Chats/${chatDiologId}`).update({
            "last_message_time": messageTimeArr,
            "last_message_type": messageTypeArr,
            "last_message_sender_id": userId,
            "last_message": lastMessageArr,
            "last_message_id": messagePush.key,
            //"dialog_status": 1,
            "unread_count": unreadMessageArr
          });
          ////////////////////////////////////////////////////////////
        });
      })
    ).subscribe();
    console.log('uploadTask::::::', uploadTask.percentageChanges());
    ////////////////////////////////
  }
  orderAcceptedDeclined(orderId, status) {
    var orderStatus = '';
    var orderStatus1 = '';
    //var message_type_status = '';
    var count = 4;
    var count1 = 5;
    if (status == 1) {
      orderStatus = 'ACCEPTED';
      orderStatus1 = 'ACCEPTED';
      var message_type_status = count;
    } else {
      orderStatus = 'DECLINE';
      orderStatus1 = 'CANCEL';
      var message_type_status = count1;
    }
    let params = new HttpParams();
    params = params.append('orderId', orderId);
    params = params.append('status', orderStatus1);
    this.CS.updateForm(params, apiUrl._updateOfferDeal)
      .subscribe(res => {
        ////////////////////////////////////
        var msg = orderStatus;
        var chatBuyerId = this.buyer_id;
        var chatSellerId = this.seller_id;
        var chatProductId = this.product_id;
        var chatDiologId = this.chatDiologId;
        var unreadMessageCount = this.unReadMessageCount;
        var otherUserUnreadCounttext = this.otherUserUnreadCount;
        var lastMessageTimeOthertext = this.lastMessageTimeOther;
        var lastMessageOthertext = this.lastMessageOther;

        ////////////////////////////////////////////////////////////
        var userId = String(this.userId);
        var otherUserId = (userId == chatBuyerId) ? chatSellerId : chatBuyerId
        var readStatusArr = {};
        var last_message_time = new Date().getTime()
        var receiver_id = (userId == chatBuyerId) ? chatSellerId : chatBuyerId;
        readStatusArr[receiver_id] = receiver_id;
        var messagePush = this.db.list(`Messages/${chatDiologId}`).push({
          "attachment_url": '',
          "chat_dialog_id": chatDiologId,
          "firebase_message_time": last_message_time,//new Date().getTime(),
          "message": msg,
          "message_time": last_message_time,
          "message_type": message_type_status,
          "product_id": chatProductId,
          "receiver_id": receiver_id,
          "sender_id": userId,
          "message_read_status": readStatusArr,
          "shipping": ''
        });
        var aa = this.db.object(`Messages/${chatDiologId}/${messagePush.key}/`).update({
          "message_id": messagePush.key
        });
        this.db.list(`Notifications`).push({
          "attachment_url": '',
          "chat_dialog_id": chatDiologId,
          "firebase_message_time": last_message_time,//new Date().getTime(),
          "message": msg,
          "message_id": messagePush.key,//"-MDTRUjeevCCQYUhvZv9",
          "message_time": last_message_time,
          "message_type": message_type_status,
          "product_id": chatProductId,
          "receiver_id": receiver_id,
          "sender_id": userId,
          "message_read_status": readStatusArr,
          "shipping": ''
        });
        var messageTimeArr = {};

        messageTimeArr[otherUserId] = last_message_time;

        messageTimeArr[userId] = last_message_time;

        var messageTypeArr = {};
        messageTypeArr[otherUserId] = 1;
        messageTypeArr[userId] = 1;

        var lastMessageArr = {};

        lastMessageArr[otherUserId] = msg;

        lastMessageArr[userId] = msg;



        var deleteTime = {};
        deleteTime[otherUserId] = 0;
        deleteTime[userId] = 0;

        var unreadMessageArr = {};
        var message_count = '1';
        unreadMessageArr[otherUserId] = parseInt(unreadMessageCount) + parseInt(message_count);
        unreadMessageArr[userId] = 0;

        this.db.object(`Chats/${chatDiologId}`).update({
          "last_message_time": messageTimeArr,
          "last_message_type": messageTypeArr,
          "last_message_sender_id": userId,
          "last_message": lastMessageArr,
          "last_message_id": messagePush.key,
          "unread_count": unreadMessageArr,
          "dialog_status": 2,
          "payment_request_time": last_message_time
        });
        /////////////////////////////////////
      },
        err => {
        });
  }

  changeState(state) {
    this.tab = state;
    if (state == 'Buyer') {
      this.router.navigateByUrl('chat/1');
      this.tab2 = null;
    } else {
      this.router.navigateByUrl('chat');
      this.tab2 = null;

    }

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getChatHistory(chat_dialog_id, page1) {
    this.admin.loader(false);
    let params = new HttpParams();
    var limit = '100';
    var skip = 1;
    if (page1 == 1) {
      skip = 1
    } else {
      skip = ((parseInt(page1) - 1) * 10);
    }
    params = params.append('skip', skip.toString());
    params = params.append('limit', limit);
    params = params.append('chatDialogid', chat_dialog_id);
    //this.imgUrl = 'https://edee-dev.s3.amazonaws.com/product/';
    this.CS.getList(apiUrl._getChatHistory, params)
      .subscribe(res => {
        this.messageData1 = res.data;
        this.messageData1?.sort(function (a, b) {
          return b.id - a.id;
        });
        console.log('this.messageData1? ::::', this.messageData1);
      },
        err => {
        });
  }

  close() {
    this.modalRef.hide();
  }
  gotoChatWindow(buyer_id, seller_id, product_id, tab) {
    if (this.slideCheck == 1) {
      this.slideCheck = 0;
    }
    //this.router.navigateByUrl('chat/'+buyer_id+"/"+seller_id+"/"+product_id+"/"+tab);
    var chatDiologId = '';
    if (buyer_id < seller_id) {
      chatDiologId = buyer_id + ',' + seller_id + '-' + product_id;
    } else {
      chatDiologId = seller_id + ',' + buyer_id + '-' + product_id;
    }
    let currentUrl = 'chat/' + buyer_id + "/" + seller_id + "/" + product_id + "/" + tab;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    //console.log("chatDiologId", chatDiologId);
    //this.getChatHistory(chatDiologId,1);
    //this.getChatsDialog(chatDiologId);
    //this.getMessages(chatDiologId);

  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      // this.scrollToBottom();
    }
  }

  // scrollToBottom(): void {
  //   try {
  //     this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  //   } catch (err) { }
  // }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }


  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  scrollOnChat(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
    if (this.scrollContainer.scrollTop <= 2) {
      var count = '1';
      this.page = parseInt(this.page) + parseInt(count);
      if (this.route.snapshot.paramMap.get('product_id') != null || this.route.snapshot.paramMap.get('product_id') != '') {
        this.getChatHistory(this.chatDiologId, this.page);
      }

    }
  }


  ngAfterViewInit() {
    this.scrollToBottom();
  }


  openSidebar(value) {
    if (value == 1) {
      this.slideCheck = 1;
    } else {
      this.slideCheck = 0;
    }
  }

  // 21-09-2021===
  showNormel() {
    this.showNormalChat = true;
    this.showPremiumChat = false;
  }
  showPremium() {
    this.showNormalChat = false;
    this.showPremiumChat = true;
    this.currentPlandata(this.categoryID)
  }
  currentPlandata(category) {
    let params = new HttpParams();
    params = params.append('categoryId', category);
    this.CS.getList(apiUrl._getCurrentplan, params)
      .subscribe(res => {
        if (res.data.isPremimum == true) {
          this.showPremiumbuy = false;
          this.premiumDeal(category);
        } else {
          this.showPremiumbuy = true;
        }

        // 
      },
        err => {

        });
  }
  premiumDeal(category) {
    let limit: any = 10;
    let page: any = 1;
    let userId = this.userId.toString().trim();
    let params = new HttpParams();
    params = params.append('categoryId', category);
    params = params.append('userId', userId);
    params = params.append('page', page);
    params = params.append('limit', limit);
    this.CS.getList(apiUrl._getPremiumDeal, params)
      .subscribe(res => {
        this.premiumLead = res.data;
        for (let i = 0; i < this.premiumLead.length; i++) {
          for (let a = 0; a < this.premiumLead[i]?.categoryData.length; a++) {
            const pCategory = this.premiumLead[i].categoryData[a]
            if (this.premiumLead[i].categoryId == pCategory._id) {
              for (let b = 0; b < pCategory?.subCategory.length; b++) {
                if (this.premiumLead[i].subCategoryId == pCategory?.subCategory[b]._id) {
                  this.premiumDealName = pCategory?.subCategory[b].name
                }
              }
            }
          }
        }

        console.log(this.premiumLead, "Adsdasd")
      },
        err => {

        });

  }

  buyplanNow(getPlan) {

    this.modalRef = this.modalService.show(getPlan, { class: "my-class-modals lg", backdrop: 'static', });
    this.getPlanlist()
  }

  /*get plan list*/
  getPlanlist() {
    let params = new HttpParams();
    params = params.append('categoryId', this.categoryID);
    this.CS.getList(apiUrl._getPlanlisting, params)
      .subscribe(res => {
        this.plansList = res.data[0];
        console.log(this.plansList, "this.plansList")
      },
        err => {

        });
  }
  /*plan select */
  onlyOneValue(event, plan) {
    console.log(event.target.value);
    this.showPayment = 1;
    this.planPaymet = plan.planPrice;
    this.planId = plan._id;
    this.planName = plan.planType;
    this.planVisibility = plan.visibility;
    this.planTrade = plan.quantity;
    this.planSubscription = plan.subscription;
    this.userId = localStorage.getItem('id');
    this.phoneNumber = localStorage.getItem('phone').substring(localStorage.getItem('phone').length - 10, localStorage.getItem('phone').length)

  }
  /*plan buy api */
  /*amount pay and razerpay */
  payment() {
    const data = {
      'planId': this.planId,
      'planType': 'PLAN',
    };
    this.CS.submitForm(data, apiUrl._postPayment)
      .subscribe(async (res) => {
        console.log('payment data', res.data)
        this.modalRef.hide();
        this.buyRazorPay(res.data);
      })
  }

  buyRazorPay(razorPaData) {
    const data = {
      "key": "rzp_test_i3r1BLKbRv61mu",
      "id": razorPaData.id,
      "entity": razorPaData.entity,
      "amount": razorPaData.amount,
      "amount_paid": razorPaData.amount_paid,
      "amount_due": razorPaData.amount_due,
      "currency": razorPaData.currency,
      "receipt": razorPaData.receipt,
      "status": razorPaData.status,
      "attempts": razorPaData.attempts,
      "notes": [],
      "created_at": razorPaData.reated_at,
      "prefill": {
        "email": "egiiyok@gmail.com",
        "contact": this.phoneNumber,
      },
      "theme": {
        "color": "#ae0019"
      },
      "handler": (response) => {
        console.log('razorPay response', response);
        const data = {
          "planData": [{
            "planId": this.planId,
            "planName": this.planName,
            "visibility": this.planVisibility.toString(),
            "trade": this.planTrade.toString(),
            "price": razorPaData.amount.toString(),
            "subscriptionType": this.planSubscription,

          }],
          "planId": this.planId,
          "userId": this.userId,
          "razorpayPaymentId": response.razorpay_payment_id,
          "razorpayOrderId": response.razorpay_order_id,
          "razorpaySignature": response.razorpay_signature,
          "amount": razorPaData.amount,
          "paymentType": "SUBSCRIPTION",
        }
        this.CS.submitForm(data, apiUrl._purchasePlan)
          .subscribe(async (res) => {
            this.admin.showAlert('Payment Successfully Done', '');
            this.pointRefer();
            setTimeout(() => {
              this.router.url.indexOf("/seller");
            }, 2000);
            this.currentPlandata(this.categoryID)
          })

      },
    }
    var rzp1 = new Razorpay(data);
    rzp1.on('payment.failed', function (data) {
      alert(data.error.metadata.order_id);
      alert(data.error.metadata.payment_id);
    });
    var rezp1 = new Razorpay(data)
    rezp1.open()
    console.log('opened');
  }

  /*amount pay and razerpay end*/
  pointRefer() {
    let params = new HttpParams();
    this.CS.getList(apiUrl._getPoints, params)
      .subscribe(async (res) => {
        console.log('points redeen', res.data)

      })
  }

  onSomeAction(event) {
    if (event.keyCode === 13) {
      this.sendMessage(this.chatForm);
      //console.log('hello');
      //document.getElementById('chat_form').submit();
      //console.log('This id here');
      //submit form
    }
  }
  chatTime(time) {
    let time1 = moment(time).format('YYYY-MM-DD HH:mm');
    console.log(time1, "chatTime");
    return time1

  }
  messageTime(time) {
    let time1 = moment(time).format('YYYY-MM-DD HH:mm');
    console.log(time1, "messageTime");
    return time1

  }

  redirectDealDetails(id){
    this.router.navigateByUrl('/details/' + id);
  }

}
