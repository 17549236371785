import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { Router } from '@angular/router';
import { InternalService } from '../internal.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { BusinessModalComponent } from './../../Common/business-modal/business-modal.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var Razorpay: any;

@Component({
  selector: 'app-buyer-product-detail3',
  templateUrl: './buyer-product-detail3.component.html',
  styleUrls: ['./buyer-product-detail3.component.css']
})
export class BuyerProductDetail3Component implements OnInit {
  urlFaq :any;
  modalRef: BsModalRef;
  userId = '';
  search = '';
  Data: any = []; 
  allCategory:any = []; 
  planDatas:any; 
  planId:any;
  hideField=true; 
  plansList: any;
  showButton:boolean =false;
  categoryName:any;
  planSubscription:any;
  planVisibility:any;
  planName:any;
  planTrade:any;
  planPaymet='';
  showPayment = 0;
  phoneNumber: any;
  countRating:any;
  BussinessVerified: any = 'false';
  closeSearchicon =true;
  closeSearch = false;
  s3url:any;
  getbusineeslist: any;
  getuserlist: any;
  Countrylist: any;
  stateslist: any;

  specificLength = 4;

  mySlideOptions = {
    // items: 5, 
    margin: 10,
    loop: false,
    dots: false,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 4
      },
      1400: {
        items: 4
      }
    },
  };
  allData: any;
  myCarouselOptions = {
    items: 2,
    margin: 10,
    loop: false, dots: false, nav: true
  };

  // myCarouselImages =[1,2,3,4,5,6].map((i)=>`./assets/images/sld1.png`);

  mySlideImages = [ 
   
  ];

  constructor(private IS: InternalService, private router: Router, private admin: JsonPlaceHolderService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.s3url= localStorage.getItem('s3BaseUrl');
    this.urlFaq= localStorage.getItem('s3BaseUrl');
    var search1 = localStorage.getItem('search');
    if (search1 != null && search1 != '') {
      this.search = search1;
    }
    this.BussinessVerified = localStorage.getItem('BussinessVerified');
    this.userId = localStorage.getItem('id');
    this.getCategories();
    // this.productlist();
  }

  changeState(num) {
    for (let i = 0; i < this.mySlideImages.length; i++) {
      this.mySlideImages[i].plan = '';
    }
    this.mySlideImages[num].plan = 'UPGRADE PLAN';
    this.productlist(this.mySlideImages[num].id);
  }
  getCategories() {
    let params = new HttpParams();
    params = params.append('userId', this.userId);
    this.IS.getList(params, apiUrl._getCategories)
      .subscribe(res => {    
        this.allData = res.data; 
        if (this.allData.categoryData.length) {
          // let myArr = [];
          let plan = '';
          for (let i = 0; i < this.allData.categoryData.length; i++) {
            if (i == 0) {
              plan = 'UPGRADE PLAN'
            } else {
              plan = '';
            }
            this.mySlideImages.push({ 'image':   this.s3url +'categories/' + this.allData.categoryData[i].image, 'item': this.allData.categoryData[i].name, 'plan': plan, 'id': this.allData.categoryData[i]._id })
          }
          // this.mySlideImages = myArr;
          this.productlist(this.allData.categoryData[0]._id);
        }
      },
        err => {
          console.log("error", err)
          // let root = this.router.url.indexOf("/editCustomer/" + this.userId);
          // this.router.navigate([this.router.url.slice(0, root)]);
        });
  }
  rouths(id) {

    this.router.navigate(['seller/' + id]);
  }
  deals() {
    this.router.navigate(['approvedOrders/2']);
  }

  enableOrDeleteProduct(event, product, deleted) {
    let data = {
      'productId': product,
      'userId': localStorage.getItem('id'),
      'isActive': event.target.checked,
      'isDeleted': deleted,
    }
    this.IS.updateForm(data, apiUrl._putenableOrDeleteProduct)
      .subscribe(async (res) => {
        let status1 = '';
        if (event.target.checked == true) {
          status1 = 'Active';
        } else {
          status1 = 'Deactive';
        }
       // this.admin.showAlert('Product ' + status1 + ' Successfully', '');

      })

  }
  productlist(categoryId) {
    this.planData(categoryId);

    const data = {
      'userId': this.userId,
      // 'country':'+91'
      'categoryId': categoryId,
      'search':((this.search.trim()) ? (this.search.trim()) : undefined),
      // 'search':this.search
    }
    this.IS.submitForm(data, apiUrl._postproductList)
      .subscribe(res => {
        let catName= res.data[0].name;
        catName = catName.split(' ').filter(s => s).join(' ').toLowerCase();        
        if(catName=="doctor consultancy"){
          this.hideField=false;
        }else{
          this.hideField=true;
        }
        this.Data = [];

        for (let i = 0; i < res.data.length; i++) {
          let loopData = res.data[i].productList;      
          for (let j = 0; j < loopData.length;j++) { 
              loopData[j].rating = 0;
              for(let k=0;k<loopData[j].feedbackData.length;k++){
                loopData[j].rating = loopData[j].rating+ loopData[j].feedbackData[k].productRating
              } 
              if(loopData[j].feedbackMessageData.length == 0){
                  loopData[j].rating = 0;
                }else{
                  loopData[j].rating = loopData[j].rating / loopData[j].feedbackData.length;
                }

           
              
            this.Data.push(loopData[j]);
          }
        }
      },
        err => {
          console.log("error", err)
          // let root = this.router.url.indexOf("/editCustomer/" + this.userId);
          // this.router.navigate([this.router.url.slice(0, root)]);
        });
  }



 /* category  select*/
 categorySelect($event, category){
  this.showButton = $event.target.value;

  this.getPlanlist(category._id);
  this.categoryName = category.name
}
  /*plan details */
planData(categoryId) {
    const data = {
      'categoryId': categoryId,
    }
    this.IS.getList(data, apiUrl._getCurrentplan)
      .subscribe(res => {
        this.planDatas = res.data
      },
        err => {
          console.log("error", err)

        });
  }
/* upgrad plan */

  /*choice category */
  categoryPopup(template) {
    // this.modalRef.hide();
    this.modalRef = this.modalService.show(template, { 
      class: "my-class-modals lg",
      backdrop: 'static',

     });
    this.getCategorie();
  }
  /* category  list*/
  getCategorie() {
    let params = new HttpParams();
    // params = params.append('id', this.userId);
    this.IS.getList(params, apiUrl._getCategories)
      .subscribe(res => {
        this.allCategory = res.data.categoryData;
      },
        err => {
          console.log("error", err)
        });
  }
 /* category  select*/

/*plan api hit */
// planChoise(template){

//   this.getPlanlist(categoryId)
//   this.modalRef = this.modalService.show(template , {class: "my-class-modals"});

// }
/*get plan list*/
getPlanlist(category){
  let params = new HttpParams();
  params = params.append('categoryId', category);
  this.IS.getList(params, apiUrl._getPlanlisting)
    .subscribe(res => {      
      this.plansList = res.data[0];     
    },
      err => {

      });
}
/*get plan list end*/
 /*sub category*/
 subCategory(subCategory){
  this.planPaymet= '';
  this.modalRef.hide();
  this.modalRef = this.modalService.show(subCategory, { class: "my-class-modals lg" });
}
BackPopup(template){
  this.planPaymet= '';
  this.modalRef.hide();
  this.modalRef = this.modalService.show(template, { class: "my-class-modals" });
}


close() {
  this.planPaymet='';
  this.modalRef.hide();
}
/*plan select */
onlyOneValue(event, plan){
  this.showPayment = 1;
  this.planPaymet = plan.planPrice;
  this.planId = plan._id;
  this.planName = plan.planType;
  this.planVisibility = plan.visibility;
  this.planTrade = plan.quantity;
   
  this.planSubscription = plan.subscription;

  this.userId = localStorage.getItem('id');
  this.phoneNumber = localStorage.getItem('phone').substring(localStorage.getItem('phone').length - 10, localStorage.getItem('phone').length)

}
/*plan buy api */
/*amount pay and razerpay */
payments() {

  const data = {
    'planId': this.planId,
    'planType':'PLAN',

  };
  this.IS.submitForm(data, apiUrl._postPayment)
    .subscribe(async (res) => {
      this.modalRef.hide();
      this.buyRazorPay(res.data);
    })
}

buyRazorPay(razorPaData) {

  const data = {
    "key": "rzp_test_i3r1BLKbRv61mu",
    "id": razorPaData.id,
    "entity": razorPaData.entity,
    "amount": razorPaData.amount,
    "amount_paid": razorPaData.amount_paid,
    "amount_due": razorPaData.amount_due,
    "currency": razorPaData.currency,
    "receipt": razorPaData.receipt,
    "status": razorPaData.status,
    "attempts": razorPaData.attempts,
    "notes": [],
    "created_at": razorPaData.reated_at,
    "prefill": {
      "email": "egiiyok@gmail.com",
      "contact": this.phoneNumber,
    },
    "theme": {
      "color": "#ae0019"
    },
    "handler": (response) => {
      const data = {
        "planData":[{
          "planId": this.planId,
          "planName":  this.planName,
          "visibility": this.planVisibility.toString(),
          "trade":this.planTrade.toString(),
          "price": razorPaData.amount.toString(),
          "subscriptionType": this.planSubscription,

        }],
        "planId": this.planId,
        "userId": this.userId,
        "razorpayPaymentId": response.razorpay_payment_id,
        "razorpayOrderId": response.razorpay_order_id,
        "razorpaySignature": response.razorpay_signature,
        "amount": this.planPaymet,
        "paymentType": "SUBSCRIPTION",
      }
  
      this.IS.submitForm(data, apiUrl._purchasePlan)
        .subscribe(async (res) => {
          this.pointRefer();
          this.planPaymet= '';
          this.admin.showAlert('Payment Successfully Done', '');
          this.planData(this.allData.categoryData[0]._id);
          this.planPaymet ='';
          this.showPayment =0;
        })


    },
  }
  var rzp1 = new Razorpay(data);
  rzp1.on('payment.failed', function (data) {

    alert(data.error.metadata.order_id);
    alert(data.error.metadata.payment_id);
  });


  var rezp1 = new Razorpay(data)
  rezp1.open()

}
/*amount pay and razerpay end*/
  /*Refer a Friend */
  pointRefer(){
    let params = new HttpParams();
    this.IS.getList(params, apiUrl._getPoints)
    .subscribe(async (res) => {

    })
  }

  // avgRating(resdata){


  //   let count =  resdata.length;
  //   for(let j = 0; j < resdata.length; j++) {   

  //     this.countRating =+ resdata[j].averageRating; 
  //   }
   
  // }
  searchFun() {
    // this.currentPage = 1;
    // this.pagination.skip = 0;
    this.productlist(this.allData.categoryData[0]._id);
    this.closeSearch = true;
    this.closeSearchicon =false;
  
  }

  refreshPage() {
    // this.pagination.skip = 0;
    // this.currentPage = 1;
    this.closeSearch = false;
    this.closeSearchicon =true;
    this.search = '';
    this.productlist(this.allData.categoryData[0]._id);
  }
  eventHandler(event){
    if(event.target.value.length == this.specificLength && (event.code == "Backspace" || event.code == "Delete")){
      this.refreshPage();
    }
 
    return true;
   }

   unverifiedPopup(business: BusinessModalComponent){
    localStorage.setItem('unverified', 'true');
    business.openBusinessModel();
   }
  //  openBusinessModel() {
  //   this.getappInitials();
  //   this.modalRef = this.modalService.show(this.businessModal);
  //   // this.editbusiness()
  // }

 

  

}
