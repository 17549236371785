import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {FcmserviceService} from './services/fcmservice.service';
import { PrvurlService } from './services/prvurl.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Egiyok';
  previousUrl:any;
  currentUrl:any;
  navigationData: any;
  show:any;
  constructor(private router: Router,  private messagingService:FcmserviceService,  private urlService: PrvurlService) {}

  ngOnInit() {   
    
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const name = urlParams.get('name');
    const id = urlParams.get('id');

    if (token) {
      localStorage.setItem("adminToken", token);
    }

    if (name) {
      localStorage.setItem("name", name);
    }

    if (id) {
      localStorage.setItem("id", id);
    }

    this.messagingService.requestPermission()
    this.show = this.messagingService.currentMessage.next;
    /// Scroll to top on Route Change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      if(this.previousUrl){
        localStorage.setItem('previousUrl',this.previousUrl);
      }
      
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });
  }
  public setNavtionData(data) {
    this.navigationData = null;
    this.navigationData = data;
    localStorage.setItem('navigationData',JSON.stringify(data));

  }
  public getNavtionData() {
    if(this.navigationData){
      return this.navigationData;

    }
    else{
      return JSON.parse(localStorage.getItem('navigationData')) || null;
    }
    
  }
  
  
}
