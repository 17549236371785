<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view rating_pro trend_dtl_page">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-md-12 trend_ss">
                    <a (click)="GoBackHomePage()" class="go_back"><img src="./assets/images/back.svg"> Back </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="add_watchlist">
                        <div class="add_inner_cont">
                            <img src="./assets/images/watch.svg">
                            <h4>You have not added any watchlist yet!</h4>
                            <p>Add trends you want to track & follow<br> them in just a click.</p>
                            <div class="pro_slctn">
                                <label>Select Product</label>
                                <span class="slt_wrap">
                  <select>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                  <option>Egg</option>
                </select>
              </span>
                            </div>
                            <a (click)="trendslisting2()" class="m_btn_red btn_red">Submit</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>