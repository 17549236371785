<app-header></app-header>
<main class="main_cont profile_page_view privacy">

    <div class="container">
        <h1 class="">Disclaimer</h1>
     
        <p>Our Poultry price data are provided without warranty or claim of reliability. It is accepted by the site visitor on the condition that errors or omissions shall not be made the basis for any claim, demand or cause for action. The material on this website has no regard to the specific investment objectives, financial situation, or particular needs of any visitor. References made to third parties are based on information obtained from sources believed to be reliable but are not guaranteed as being accurate. Visitors should not regard it as a substitute for the exercise of their own judgment.</p>

    </div>


</main>
<app-footer></app-footer>