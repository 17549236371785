export const environment = {
    production: false,
    firebase : {
        apiKey: "AIzaSyDmc2FVWUrL7y96zkxkdJJN0v63GdAg13o",
        authDomain: "egiyok.firebaseapp.com",
        databaseURL: "https://egiyok-default-rtdb.firebaseio.com",
        projectId: "egiyok",
        storageBucket: "egiyok.appspot.com",
        messagingSenderId: "410465506928",
        appId: "1:410465506928:web:1455322cfe1cc5f21846b5",
        measurementId: "G-QMPK0ZHSYY"
      
    }
};