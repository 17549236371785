import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'dealInfo',
  template: `<router-outlet></router-outlet>`
})
export class DealInfoComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}