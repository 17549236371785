import { TemplateRef, Component, OnInit, Injectable, ViewChild, NgZone, ElementRef, ViewChildren, ContentChild, QueryList, HostListener } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { JsonPlaceHolderService } from '../../services/json-place-holder.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { NavigationService } from './header.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
// , TooltipLabel
import * as CONSTANT from '../../services/constants';
import { NoWhitespaceValidator } from '../../services/NoWhiteSpace.validator';
import { FcmserviceService } from '../../services/fcmservice.service'
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";
import { LoginModalComponent } from './../../Common/login-modal/login-modal.component';
import { BusinessModalComponent } from './../../Common/business-modal/business-modal.component';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { DataSharingService } from '../../services/data.share.service'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  isSticky: boolean = false;
  googlepinCode:any;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  form: FormGroup;
  updateProfileForm: FormGroup;
  loginsForm: FormGroup;
  busineesForm: FormGroup;
  languageForm: FormGroup;
  varification = '';
  s3url: any;
  password = '';
  password1 = '';
  BussinessVerified: any;
  showErrormatch1: boolean = false;
  showErrormatch2: boolean = false;
  resenderror: boolean = false;
  token = '';
  currentState:any;
  password2 = '';
  uploadImage = '';
  urlfile: any = '';
  uploadImage1 = '';
  urlfile1: any = '';
  image: any = [];
  headershow: boolean = false;
  uploadImage2 = '';
  urlfile2: any = '';
  admintoken1 = '';
  name = '';
  profilePic = '';
  commonSearch = '';
  showError: boolean = false;
  showError1: boolean = false;
  showErrormatch21: boolean = false;
  showErrormatch11: boolean = false;
  otperror: boolean = false;
  modalRef: BsModalRef;
  showhite: boolean = true;
  closeSearch: boolean = true;
  loginshow: boolean = false;
  getbusineeslist: any;
  urlnumber1: any;
  getuserlist: any;
  Countrylist: any;
  stateslist: any;
  latitude: number;
  longitude: number;
  gMaps: any;
  notificationData: any = [];
  mapPlot: any
  mapSector: any;
  mapLocality: any;
  mapCity = "Mohali";
  mapState = "Punjab";
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapStateshort: any;
  latitude1: any;
  longitude1: any;
  userId: any;
  private geoCoder;
  addressCurrent: any;
  addressCurrent2: any;
  zoom: number;
  newAddress = '';
  switchLang;
  browserLang;
  selectedValue: any;
  // options= {

  //   componentRestrictions: {country: 'CO'}
  // }  

  language = [
    { item: 'English', stname: 'en' },
    { item: 'Hindi', stname: 'hi' },
  ];
  secretCode = "0123456789123456";
  encrypted: any;
  decrypted: any;

  @ViewChild('model') private loginModal: LoginModalComponent;
  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild('mark') public searchElementRef1: ElementRef;
  @ViewChild('currentAddress', { static: false }) currentAddress: ModalDirective;
  // @ViewChild('currentAddress') private currentAddressmodal: ElementRef;

  mySlideOptions = {
    items: 1,
    loop: false,
    dots: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    // navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"]
  };
  myCarouselOptions = {
    items: 1, loop: true, dots: false, nav: true,
    // navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"]
  };
  // mySlideImages:any=[];
  mySlideImages1: any = [{ image: './assets/images/sld1.png' }];

  mySlideImages: any;

  // modalService: BsModalService;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  constructor(private ngZone: NgZone, private NS: NavigationService, private dataSharingService: DataSharingService, private mapsAPILoader: MapsAPILoader, public admin: JsonPlaceHolderService, private formBuilder: FormBuilder, private modalService: BsModalService, private router: Router, private route: ActivatedRoute, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private storage: AngularFireStorage, private modalServices: LoginModalComponent, private fcmService: FcmserviceService, public translate: TranslateService, private scroller: ViewportScroller,) {
    this.form = this.formBuilder.group({
      phoneNumber: ['', Validators.compose([Validators.required,
      Validators.minLength(10), Validators.maxLength(16),
      Validators.pattern(CONSTANT.MOBILE_REGEX_NEW_SPACE), NoWhitespaceValidator()])],

    });
    this.loginsForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      phoneNumber: ['', Validators.compose([Validators.required,
      Validators.minLength(10), Validators.maxLength(16),
      Validators.pattern(CONSTANT.MOBILE_REGEX_NEW_SPACE), NoWhitespaceValidator()])],

    });
    this.updateProfileForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      name: ['', Validators.compose([Validators.required,
      Validators.minLength(2), Validators.maxLength(45),
      Validators.pattern(CONSTANT.FIRSTNAME_REGEX), NoWhitespaceValidator()])],
      referredBy: ['', Validators.compose([
        Validators.minLength(2), Validators.maxLength(45)])],
      phoneNumber: ['', Validators.compose([Validators.required,
      Validators.minLength(10), Validators.maxLength(16),
      Validators.pattern(CONSTANT.MOBILE_REGEX_NEW_SPACE), NoWhitespaceValidator()])],

    });
    this.busineesForm = this.formBuilder.group({
      userType: ['', Validators.compose([Validators.required])],
      BusinessType: ['', Validators.compose([Validators.required])],
      BusinessName: ['', Validators.compose([Validators.required,
      Validators.minLength(2), Validators.maxLength(45)])],
      googleAddress: [],
      AddressLine1: ['', Validators.compose([Validators.required])],
      AddressLine2: ['', Validators.compose([])],
      Country: ['', Validators.compose([])],
      state: ['', Validators.compose([])],
      city: ['', Validators.compose([])],
      pincode: ['', Validators.compose([])],
    });

    // this.changeOfRoutes();'
    this.dataSharingService.isUser.subscribe(value => {
      this.name = localStorage.getItem('name');
      this.profilePic = localStorage.getItem('profilePic');
      this.latitude = Number(localStorage.getItem('latitude'));
      this.longitude = Number(localStorage.getItem('longitude'));
      this.mapCity = localStorage.getItem('city');
      this.mapState = localStorage.getItem('state');
      this.addressCurrent = "";
      if (this.mapCity) {
        this.addressCurrent = this.mapCity + ', ';
      }
      if (this.mapState) {
        if (this.addressCurrent) {
          this.addressCurrent = this.addressCurrent + this.mapState;
        } else {
          this.addressCurrent = this.mapState + ', India';
        }
      }
      if (!this.mapCity && !this.mapState) {
        this.addressCurrent = "Mohali, Punjab"
      }

    });
    this.dataSharingService.busniess.subscribe(value => {
      this.BussinessVerified = localStorage.getItem('BussinessVerified');
    });

    this.dataSharingService.selectedLang.subscribe(res => {
      this.switchLang = res;
      this.browserLang = res;
    });
    this.translate.addLangs(['EN', 'HI', 'BN', 'MR', 'TA', 'TE']);
    this.translate.setDefaultLang('EN');
    this.translate.use(this.browserLang);
  }

  openLoginModal() {
    this.loginModal.openModal();
  }

  routherPath(nav, business: BusinessModalComponent) {
    this.BussinessVerified = localStorage.getItem('BussinessVerified');
    if (this.BussinessVerified == true || this.BussinessVerified == 'true') {
      this.router.navigate([nav]);
    } else {
      business.openBusinessModel();
    }
  }


  ngOnInit(): void {
    this.latitude = Number(localStorage.getItem('latitude'));
    this.longitude = Number(localStorage.getItem('longitude'));

    if (localStorage.getItem('language')) {
      this.selectedValue = localStorage.getItem('language');
    } else {
      this.selectedValue = 'EN';
    }
    let addressCheck = localStorage.getItem('addressStatus');
    if (addressCheck == '1') {
      this.latitude = parseFloat(localStorage.getItem('latitude'));
      this.longitude = parseFloat(localStorage.getItem('longitude'));
      this.geoCoder = new google.maps.Geocoder;
      this.getCurrent(this.latitude, this.longitude)
    } else {
      this.latitude = localStorage.getItem('latitude') ? (parseFloat(localStorage.getItem('latitude'))) : 30.7046486;
      this.longitude = localStorage.getItem('longitude') ? (parseFloat(localStorage.getItem('longitude'))) : 76.71787259999999;
      localStorage.setItem('latitude', this.latitude.toString());
      localStorage.setItem('longitude', this.longitude.toString());
      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          componentRestrictions: { country: "IN" }
        });

        this.geoCoder = new google.maps.Geocoder;
        this.getCurrent(this.latitude, this.longitude)
      });
    }
 
    // localStorage.clear();

    this.commonSearch = localStorage.getItem('search') ? (localStorage.getItem('search')) : '';
    let data = localStorage.getItem('adminToken');
    this.name = localStorage.getItem('name');
    this.BussinessVerified = localStorage.getItem('BussinessVerified');


    if (data && this.name) {
      let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 1];    
      if (urlnumber == 'index') {
        this.router.navigate(['Buyer']);
      }
    } else {


    }
    let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 1];

    this.urlnumber1 = urlnumber;
    if (urlnumber == 'Buyer') {
      this.headershow = true;
    } else {
      this.headershow = false;
    }
    if (urlnumber == 'trendslisting' || urlnumber == 'sellerprofilenewregistration5' || urlnumber == 'trendslisting2' || urlnumber == 'trendslisting3' || urlnumber == 'seller' || urlnumber == 'BuyerProductDetail' || urlnumber == 'category' || urlnumber == 'buyingquotesdetailspage1') {
      this.showhite = false;
    } else {
      this.showhite = true;
    }
    if (urlnumber == 'index' || urlnumber == '') {
      this.loginshow = false;  

    } else if (urlnumber == 'plan' && !data && !this.name) {
      this.loginshow = false;


    } else {
      let authGard = localStorage.getItem("adminToken");
      if (authGard) {
        this.loginshow = true;
    
        //this.getappInitials();
      } else {

      }
    }
    let category = this.route.snapshot.paramMap.get('category');
    let productId = this.route.snapshot.paramMap.get('id');

    if (category || productId) {
      let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 2];
      this.urlnumber1 = urlnumber;
    }


    this.toggleVideo();
    // notification

    let token = localStorage.getItem("adminToken") ? (localStorage.getItem("adminToken")):'';
    if (token) { 
      if (localStorage.getItem('id') != null) {   
        this.userId = localStorage.getItem('id');
        this.getNotificationList();
        this.fcmService.firbasNotification.subscribe((value) => {
          this.admin.showLoader = false;
          this.getNotificationList();
        });
      }
    }
    let getUrl = this.router.url.toString();
    if (getUrl == '/index' || getUrl == '/Buyer') {
      this.getindex();
    }

    this.s3url = localStorage.getItem('s3BaseUrl');
    this.urlfile = this.s3url + 'users/';
  }

  // page scroll
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 110;
  }

  scrollToElement($element): void {

    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  // end

  search(event) {

    this.commonSearch = event.target.value;

  }
  removeSeach() {
    this.closeSearch = true;
    this.commonSearch = '';
    localStorage.setItem('searchText1', '');
    this.dataSharingService.isSearch.next(true);
    this.router.navigateByUrl('buyerlistingSearch');
  }
  commonsearch() {
    this.closeSearch = false;
    localStorage.setItem('searchText1', this.commonSearch);
    this.dataSharingService.isSearch.next(true);
    this.router.navigateByUrl('buyerlistingSearch');
    //
  }
  toggleVideo() {
    // this.videoplayer.nativeElement.play();
  }
  getindex() {
    let params = new HttpParams();
    this.currentState= localStorage.getItem('state') ? (localStorage.getItem('state')):'';
     params = params.append('state',this.currentState) 
    this.NS.getList(params, apiUrl._getIndexPage)
      .subscribe(async (res) => {
        this.s3url = res.data.s3BaseUrl;

        this.mySlideImages1 = [];
        for (let i = 0; i < res.data.banners.length; i++) {
          this.mySlideImages1.push({ image: this.s3url + 'banner/' + res.data.banners[i].fileName, 'title': res.data.banners[i].title, 'description': res.data.banners[i].description, 'format': res.data.banners[i].fileName.split('.')[res.data.banners[i].fileName.split('.').length - 1], 'searchText': res.data.banners[i].searchText, 'categoryId': res.data.banners[i].categoryId, 'link': res.data.banners[i].link },)
        }
        this.mySlideImages = this.mySlideImages1;
      })
  }

  bannerRedirect(link, searchText, categoryId) { 
    localStorage.setItem('bannerLink', link);
    localStorage.setItem('bannerSearchtext', searchText);
    localStorage.setItem('bannerCategoryid', categoryId);
    if (!this.loginshow) {
      this.openLoginModal();
      return
    }
    if (searchText != '') {  
      let search = link.split('/')
      let host = search[0] + '//' + search[2] + '/';
      let path = link.replace(host, '');
      let search2 = path.split('?')[0];
      if (search2 == 'buyerlistingSearch') {
        var decrypted = CryptoJS.AES.decrypt(searchText, this.secretCode);
        this.decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        localStorage.setItem('searchText1', this.decrypted)
        localStorage.setItem('searchText', '')
        this.router.navigateByUrl('buyerlistingSearch');
      } else {
        let id = search2.split('/')[1];
        localStorage.setItem('searchText1', '')
        localStorage.setItem('searchText', searchText)
        this.router.navigateByUrl('Buyerlisting/' + id);
      }
    } else if (categoryId != '') {
      this.router.navigateByUrl('Buyerlisting/ProductDetail/' + categoryId);
    } else {
      let url = link ?(link):'';
      if(url){
        window.open(link, "_blank");
      }     
    
      // let search = link.split('/')
      // let host = search[0] + '//' + search[2] + '/';
      // let path = link.replace(host, '');
      // this.router.navigate([path]);
    }

  }

  loginpagelogin(template, form) {
    if (form.valid) {
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken(),
        // 'otpBy' : 1,
        // 'otpRequestType':1
        // 'password': '12345678'
      };
      this.NS.submitForm(data, apiUrl._postvalidationphno)
        .subscribe(async (res) => {
          // this.varification='';
          this.loginsForm.patchValue({
            phoneNumber: form.value.phoneNumber,
          });
          this.modalRef.hide();
          this.modalRef = this.modalService.show(template);
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  loginForm(form) {
    if (form.valid && form.value.password.length != 5) {
      let params = new HttpParams();
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'password': form.value.password,
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken(),
      };
      this.NS.updateForm(data, apiUrl._postLogin)
        .subscribe(async (res) => {
          localStorage.setItem('name', <any>(res.data.name ? res.data.name : 'User Name'));
          localStorage.setItem('BussinessVerified', res.data.businessVerified);
          localStorage.setItem('adminToken', res.data.accessToken);
          localStorage.setItem('id', res.data._id);
          //////////////////////////////
          var userId = res.data._id;
          var deviceToken = this.getDeviceToken();
          var plateformStatus = 3;
          var aa = this.db.object(`Users/${userId}/`).update({
            "device_token": deviceToken.toString().trim(),
            "platform_status": plateformStatus,
          });
          /////////////////////////////
          this.modalRef.hide();
          this.router.navigate(['Buyer']);

        })
    } else {

      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  businessTypeSelect(event) {
    this.busineesForm.value.BusinessType = event.target.value;
  }
  userTypeSelect(event) {
    this.busineesForm.value.userType = event.target.value;
  }

  myprofile() {
    this.router.navigate(['myprofile']);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    // this.loading = false;
  }
  upload1(event, num) {
    if (event.target.files && event.target.files.length && num == 1) {
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          this.uploadImage2 = res.data;
          const reader = new FileReader();
          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            this.urlfile2 = reader.result;
          }
        })
    } else if (event.target.files && event.target.files.length && num == 2) {
      const file = event.target.files[0];

      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          this.uploadImage1 = res.data;
          const reader = new FileReader();
          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            this.urlfile1 = reader.result;
          }
        })
    }
  }

  routherPath1(path) {
    this.router.navigate([path]);
  }
  loginpage1(template, form) {

    if (form.valid) {
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken(),

      };
      this.NS.submitForm(data, apiUrl._postvalidationphno)
        .subscribe(async (res) => {
          this.varification = '';
          this.modalRef.hide();
          if (res.data.customMessage) {

            this.admin.errorAlert(res.data.customMessage, true);
          } else {
            this.modalRef = this.modalService.show(template);
          }
        })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  loginpage2(template, form) {
    if (this.varification != '' && this.varification.length > 3) {
      const data = {
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'otp': this.varification,
        'otpRequestType': 1
        // 'deviceToken': 'WEB',
        // 'password': '12345678'
      };
      this.NS.updateForm(data, apiUrl._PUTVarifyOTP)
        .subscribe(async (res) => {

          if (this.varification != '') {
            this.updateProfileForm.patchValue({
              phoneNumber: form.value.phoneNumber,
            });
            if (res.message == 'Success') {
              this.token = res.data.token;

              // localStorage.setItem('adminToken', this.token);
            }
            this.modalRef.hide();
            this.modalRef = this.modalService.show(template, { class: "my-class-modal" });
          }
        })
    } else if (this.varification.length < 3 || this.varification.length == 3) {
      this.otperror = true;
      setTimeout(() => {
        this.otperror = false;
      }, 5000);
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  resend() {
    if (this.form.value.phoneNumber) {
      const data = {
        'phoneNumber': '+91-' + this.form.value.phoneNumber,
        'otpBy': 1,
        'otpRequestType': 1
      };
      this.NS.updateForm(data, apiUrl._putGenrateOTP)
        .subscribe(async (res) => {
          this.admin.showAlert('OTP Resent Successfully', '');
        })
    } else {
      this.resenderror = true;
      setTimeout(() => {
        this.resenderror = false;
      }, 5000);
    }

  }
  resend1(template) {
    if (this.form.value.phoneNumber) {
      const data = {
        'phoneNumber': '+91-' + this.form.value.phoneNumber,
        // 'platform' : 'WEB',
        // 'deviceToken': 'WEB',
        'otpBy': 1,
        'otpRequestType': 1
        // 'password': '12345678'
      };
      this.NS.updateForm(data, apiUrl._putGenrateOTP)
        .subscribe(async (res) => {
          this.modalRef.hide();
          this.admin.showAlert('OTP Resent Successfully', '');
          setTimeout(() => {
            this.modalRef = this.modalService.show(template);
          }, 1000);
        })
    } else {
      this.resenderror = true;
      setTimeout(() => {
        this.resenderror = false;
      }, 5000);
    }

  }
  resendotp() {
    // loginModal2
  }
  resendpwd() {
    // loginModal2
  }
  upload(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append('directory', 'users');
      fd.append('image', event.target.files[0]);
      this.NS.submitForm(fd, apiUrl._postupload)
        .subscribe(async (res) => {
          this.uploadImage = res.data;
          const reader = new FileReader();
          const files = event.target.files;
          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            this.urlfile = reader.result;
          }
        })
    }
  }
  change(event) {
    this.varification = event.target.value;
  }
  passwordnum(event, num) {
    if (num == 1) {
      this.password1 = event.target.value;
    } else {
      this.password2 = event.target.value;
    }

  }
  loginpage(template) {
    this.form.patchValue({
      phoneNumber: '',
    })
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template);
  }
  updateProfile(form) {

    if (form.valid || (!form.valid && form.value.name != '' && form.value.email == '')) {

      // let accestoken = localStorage.getItem('adminToken');
      // var authorization  = accestoken;
      const data = {
        // 'authorization':'Bearer ' + authorization,
        'name': form.value.name,
        'email': form.value.email,
        'phoneNumber': '+91-' + form.value.phoneNumber,
        'image': this.uploadImage,
        'referredBy': form.value.referredBy,
        'platform': 'WEB',
        'deviceToken': this.getDeviceToken(),
      };
      this.NS.updateForm(data, apiUrl._putUpdateprofile)
        .subscribe(async (res) => {
          localStorage.setItem('name', <any>(res.data.name ? res.data.name : 'User Name'));

          localStorage.setItem('BussinessVerified', res.data.businessVerified);
          localStorage.setItem('id', res.data._id);
          ////////////////////////////////////////
          var userId = res.data._id.toString().trim();
          this.db.list(`Users`,
            query => query
              .orderByChild("user_id")
              .equalTo(userId)
              .limitToLast(10)
          ).valueChanges().subscribe(list => {
            /*this.messageList = list ? list : [];*/
      
            /////////////////////////////////////
            if (list.length == 0) {
              var userId = res.data._id.toString().trim();
              var accessToken = res.data.accessToken;
              var deviceToken = this.getDeviceToken();
              var plateformStatus = 3;
              var userName = res.data.name;
              var profilePic = res.data.profilePic;
   
              var chatDialog = this.db.object(`Users/${userId}`).set({
                "chat_dialog_id": '',
                "access_token": accessToken.toString().trim(),
                "device_token": deviceToken.toString().trim(),
                "platform_status": plateformStatus,
                "user_id": userId.toString().trim(),
                "user_name": userName.toString().trim(),
                "user_pic": profilePic.toString().trim(),
              });
              /////////////////////////////////
              this.router.navigate(['Buyer']);
              this.modalRef.hide();
            } else {
              this.router.navigate(['Buyer']);
              this.modalRef.hide();
            }
          });
          /////////////////////////////////////////

        })

    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }
  cancel() {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.form.patchValue({
      phoneNumber: '',
    })
    this.loginsForm.patchValue({
      phoneNumber: '',
      password: '',
    })

    this.updateProfileForm.patchValue({
      name: '',
      email: '',
      referredBy: '',
    })
    this.uploadImage = '';
    this.urlfile = '';
    this.password1 = '';
    this.password2 = '';
    this.varification = '';
  }
  Backopen(template) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template);
  }
  openModal15(template) {
    if (this.password2 == this.password1 && this.password1 != '' && this.password2 != '' && (this.password1.length == 4 || this.password1.length == 6)) {
      this.password = this.password1

      if (this.password != '') {
        const data = {
          'phoneNumber': '+91-' + this.form.value.phoneNumber,
          'token': this.token,
          'password': this.password,
          'platform': 'WEB',
          'deviceToken': this.getDeviceToken(),
        };
        this.NS.submitForm(data, apiUrl._postResetPassword)
          .subscribe(async (res) => {
            this.modalRef.hide();
            this.admin.showAlert('Pin set Successfully', '');
            // this.admintoken1 =res.data.accessToken;
            // localStorage.setItem('name', res.data.name);
            localStorage.setItem('id', res.data._id);
            localStorage.setItem('adminToken', res.data.accessToken);
            setTimeout(() => {
              this.modalRef = this.modalService.show(template);
            }, 1000);
          })
      }
    } else if (this.password1 == '') {
      this.showErrormatch1 = true;
      setTimeout(() => {
        this.showErrormatch1 = false;
      }, 5000);

    } else if (this.password2 == '') {
      this.showErrormatch2 = true;
      setTimeout(() => {
        this.showErrormatch2 = false;
      }, 5000);

    } else if (this.password1.length == 5 || this.password1.length == 3 || this.password1.length == 2 || this.password1.length == 1) {
      this.showErrormatch11 = true;
      setTimeout(() => {
        this.showErrormatch11 = false;
      }, 5000);

    } else if (this.password2.length == 5 || this.password2.length == 3 || this.password2.length == 2 || this.password2.length == 1) {
      this.showErrormatch21 = true;
      setTimeout(() => {
        this.showErrormatch21 = false;
      }, 5000);

    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }

  }
  Goback(template) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(template);
  }
  loginhome(template) {
    // this.modalRef = this.modalService.show(template , {class: 'log_pop otp_scrn submit_acc add_pop map_loc show'});
    this.modalRef = this.modalService.show(template);
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;

    this.geoCoder = new google.maps.Geocoder;
    this.zoom = 16;
    this.getAddress(this.latitude, this.longitude)
  }

  getCurrent(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.addressCurrent = "";
          let city = localStorage.getItem('city');
          let state = localStorage.getItem('state');
          if (city) {
            this.addressCurrent = city + ', ';
          }
          if (state) {
            if (this.addressCurrent) {
              this.addressCurrent = this.addressCurrent + state;
            } else {
              this.addressCurrent = state + ', India';
            }
          }
          if (!city && !state) {
            this.addressCurrent = "Mohali, Punjab"
          }
          this.busineesForm.patchValue({ 'googleAddress': this.addressCurrent });
          if (this.loginshow) {
            let city = localStorage.getItem('city');
            let state = localStorage.getItem('state');
            if (city) {
              this.addressCurrent = city + ', ';
            }
            if (state) {
              if (this.addressCurrent) {
                this.addressCurrent = this.addressCurrent + state;
              } else {
                this.addressCurrent = state + ', India';
              }
            }
            if (!city && !state) {
              this.addressCurrent = "Mohali, Punjab"
            }
          }

          this.ngZone.run(() => {
            this.addressCurrent = "";
            let city = localStorage.getItem('city');
            let state = localStorage.getItem('state');
            if (city) {
              this.addressCurrent = city + ', ';
            }
            if (state) {
              if (this.addressCurrent) {
                this.addressCurrent = this.addressCurrent + state;
              } else {
                this.addressCurrent = state + ', India';
              }

            }
            if (!city && !state) {
              this.addressCurrent = "Mohali, Punjab"
            }
            if (this.loginshow) {
              this.addressCurrent = "";
              let city = localStorage.getItem('city');
              let state = localStorage.getItem('state');
              if (city) {
                this.addressCurrent = city + ', ';
              }
              if (state) {
                if (this.addressCurrent) {
                  this.addressCurrent = this.addressCurrent + state;
                } else {
                  this.addressCurrent = state + ', India';
                }
              }
              if (!city && !state) {
                this.addressCurrent = "Mohali, Punjab"
              }
            }
          });
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
  addressModal() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: "IN" }
      });
      autocomplete.addListener("place_changed", () => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.gMaps = place;
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        // this.latitude = Number(localStorage.getItem('latitude'));
        // this.longitude = Number(localStorage.getItem('longitude'));
        this.zoom = 12;
        this.getAddress(this.latitude, this.longitude)
        this.ngZone.run(() => {
          //get the place result

        });
      });
      autocomplete.setComponentRestrictions
    });
    this.currentAddress.show();

  }

  /*popup address */
  getAddress(latitude, longitude) {
    let googleCity = "";
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status === 'OK') {
        for (let i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types
          //plot no
          for (var j = 0; j < types.length; j++) {
            if (types.indexOf('subpremise') != -1) {
              this.mapPlot = results[0].address_components[i].long_name
  
            }
            //Sector 
            if (types.indexOf('neighborhood') != -1) {
              this.mapSector = results[0].address_components[i].long_name
            }

            if (types.indexOf('sublocality_level_1') != -1) {
              this.mapLandmark = results[0].address_components[i].long_name
            }
            if (types.indexOf('locality') != -1) {
              this.mapLocality = results[0].address_components[i].long_name
            }
            //state
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.mapState = results[0].address_components[i].long_name,
              this.mapStateshort = results[0].address_components[i].short_name
  
            }
            //city
            if (types.indexOf('administrative_area_level_2') != -1) {
              googleCity = results[0].address_components[i].long_name ? (results[0].address_components[i].long_name) : '';
      
            }

            if (types.indexOf('country') != -1) {
              this.mapcountry = results[0].address_components[i].long_name
        
            }
            if (types.indexOf('postal_code') != -1) {
              this.mapPostal = results[0].address_components[i].long_name             
            }
            if (types[0] == 'postal_code') {
              this.mapPostal = results[0].address_components[i].long_name              
            }
          }
        }
        this.mapCity = googleCity;
        this.busineesForm.patchValue({
          'AddressLine1': results[0].formatted_address,
          'city': this.mapCity,
          'state': this.mapState,
          'Country': this.mapcountry,
          'pincode': this.mapPostal,
        })

        if (results[0]) {
          this.zoom = 12;
          if (this.mapCity) {
            this.newAddress = this.mapCity + ', ' + this.mapState;
            this.busineesForm.patchValue({ 'googleAddress': this.newAddress });
          } else {
            this.newAddress = this.mapState + ', ' + this.mapcountry;
            this.busineesForm.patchValue({ 'googleAddress': this.newAddress });
          }
 
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  hideAddress() {
    this.currentAddress.hide();
    this.ngZone.run(() => {
      if (this.newAddress != '') {
        this.addressCurrent = this.newAddress;
      } else {
        this.addressCurrent;
      }
    });

    localStorage.setItem('latitude', this.latitude.toString());
    localStorage.setItem('longitude', this.longitude.toString());
    localStorage.setItem('city', this.mapCity ? (this.mapCity.toString()) : "");
    localStorage.setItem('state', this.mapState.toString());
    localStorage.setItem('addressStatus', '1');

    this.dataSharingService.addressUpdate.next(true);
  }


  /// notification listing
  getNotificationList() {
    this.admin.showLoader = false;
    let data = { 'id': this.userId }
    this.NS.getList(data, apiUrl._getNotificationList)
      .subscribe(async (res) => {
        this.notificationData = res.data;
      })
  }
  checkNotification() {
    for (let i = 0; i <= this.notificationData.length; i++) {
      if (this.notificationData[i]?.isRead == 0) {
        let className = "redDot"
        return className
      }
    }
  }

  /*language*/
  selectLanguage(event) {
    let lang = event.target.value;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.browserLang = lang;
    localStorage.setItem('language', lang);
    this.languageChange();
    this.dataSharingService.selectedLang.next(this.browserLang);
  }
  languageChange() {
    this.translate.use(this.browserLang.match(/HI|EN|TE|MR|TA|BN/) ? this.browserLang : 'EN');
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.fcmService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  gotoRate() {
    let getUrl = this.router.url.toString()
    if (getUrl == '/index') {
      document.getElementById("rates").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    } else if (getUrl == '/Buyer') {
      document.getElementById("rates").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        document.getElementById("rates").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 1800);
    }

  }
  gotoAbout() {
    let getUrl = this.router.url.toString();
    if (getUrl == '/index') {
      document.getElementById("about").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        document.getElementById("about").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 1800);
    }
  }
  stopClick(event: any): void {
    event.stopPropagation();
  }


  // googlePinCode(value) {
  //   console.log(this.googlepinCode);
  //   this.admin.showLoader = false;
  //   let params = new HttpParams();
  //   params = params.append('postalcode', this.googlepinCode);
  //   params = params.append('countrycode', "IN");

  //   this.admin.googlePin(params)
  //     .subscribe(async (res) => {
  //      console.log(res)
  //     })
  // }




  


}