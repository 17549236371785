<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view profile_view2 sllr_profl sel_prfle">
        <div class="container-fluid">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="left_profile_detail">
                            <div class="sell_near">
                                <div class="detail_pro" style="margin-bottom: 10px;">
                                    <img class="widthset mr-2" src="./assets/images/icn.svg">
                                    <h5>{{allData?.userDetail?.businessName ? (allData?.userDetail?.businessName):"NA"}}
                                        <!-- <img *ngIf="allData?.userDetail?.businessName"
                                        class="veri_img" src="./assets/images/checked.svg"> -->
                                    </h5>
                                    <div class="approved_s" *ngIf="allData?.userDetail?.businessName">
                                        <!-- <span class="code_red">#PB11ABC</span> -->

                                        <span (click)="unverifiedPopup(business)" class="verify warning" *ngIf="allData.userDetail.businessVerified == false">
                                            {{'homePage.buyer.unverified' | translate}}
                                        </span>

                                        <span class="verify" *ngIf="allData.userDetail.businessVerified == true">
                                            <i class="fa fa-check"></i>{{'homePage.buyer.verified' | translate}}
                                        </span>

                                        <span *ngIf="allData?.userDetail?.userRating==5"><i class=""><img
                                                    src="./assets/images/star.svg"></i>
                                            {{allData?.userDetail?.userRating}}</span>
                                        <span *ngIf="allData?.userDetail?.userRating!=5"><i class=""><img
                                                    src="./assets/images/star.svg"></i>
                                            {{allData?.userDetail?.userRating | number:'1.1-1'}}</span>

                                    </div>
                                </div>
                                <!-- <p class="para_blck">Rating is below to 30, <a class="para_red" data-toggle="modal" data-target="#loginModal31">Improve Now</a></p> -->
                                <div class="loc_mark" *ngIf="allData?.userDetail?.businessName"><img src="./assets/images/loc-gray.svg"> {{allData?.userDetail?.businessAddress?.addressLine1}}
                                </div>

                                <ul class="order_earn">
                                    <li class="bg_org">
                                        <span><strong>{{allData?.userDetail?.productData?.liveProduct}}</strong>
                                            {{'homePage.buyer.liveProducts' | translate}}</span><span class="icn_earn"><img src="./assets/images/order.svg"></span>
                                    </li>
                                    <li class="bg_grn" (click)="deals()"><span><strong>
                                                {{allData?.userDetail?.productData?.totalDeal}}</strong>
                                            {{'homePage.buyer.totalDeals' | translate}}</span><span class="icn_earn"><img src="./assets/images/earning.svg"></span></li>
                                </ul>

                            </div>
                            <div class="water_m">
                                <img src="./assets/images/water2.png">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        <div class="right_profile_detail seller_pro_n_reg">

                            <div class="col-xl-12 col-lg-11 col-md-11">
                                <div class="row sort_seller_list mr_50">
                                    <div class="col-md-6">
                                        <form (ngSubmit)="searchFun()" name="searchForm">
                                            <div class="srch_order"><input type="text" name="" value="" placeholder="{{'homePage.buyer.search' | translate}}" [(ngModel)]="search" autocomplete="off" name="search" (keydown)="eventHandler($event)">
                                                <span (click)="searchFun()" class="search input-icon input-icon-right fa fa-search" type="submit" *ngIf="closeSearchicon"><i
                                                        class="ti-search"></i></span>
                                                <a class="closeBtn_search " (click)="refreshPage()" *ngIf="closeSearch">&times;</a>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            <div class="row slide_category">
                                <div class="col-md-12 ">

                                    <ul class="owl-carousel displaycss owl-theme category_pro">
                                        <owl-carousel [options]="mySlideOptions" [items]="mySlideImages" [carouselClasses]="['owl-theme', 'sliding']">
                                            <li class="item" *ngFor="let slide of mySlideImages;let i = index" (click)="changeState(i)">
                                                <a class="{{slide.plan != '' ? 'active1' : ''}}"><span>

                                                        <img class="image_80"
                                                            src="{{slide.image}}"></span><br>{{slide.item}}<i
                                                        *ngIf="slide.plan !=''" class="fa fa-check"></i></a>
                                            </li>

                                        </owl-carousel>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="row">
                            <div class="col-md-12 upgrd_text" *ngIf="Data.length != 0">
                                <div class="plan_complete_dtl">
                                    <p class="para_blck">Remaining Selling Quantity - <span class="para_red">{{planDatas?.usedQuantity}}
                                            /{{planDatas?.purchaseQuantity}}</span> </p>
                                    <p class="para_blck"> {{'homePage.buyer.planType' | translate}} - <span class="para_red">{{planDatas?.userPlan?.planType}} </span></p>
                                    <p class="para_blck">{{'homePage.buyer.expiredDate' | translate}} - <span class="para_red">{{planDatas?.expiredPlan?.planExpiredDate | date : "MMMM
                                            d,
                                            y"}}</span></p>
                                </div>
                                <a class="btn_red upgrade_btn" (click)="categoryPopup(choiceCategory)">{{'homePage.buyer.upgradePlan' |
                                    translate}}</a>
                            </div>
                        </div> -->
                            <div>
                                <div class="row selling_qnt">
                                    <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let show of Data;let i = index">
                                        <div class="near_sell_outer_box">
                                            <a class="sell_near profile_items" (click)="rouths(show._id)">
                                                <h5>{{show?.subCategoryName}}</h5>
                                                <label class="switch">
                                                    <input type="checkbox"
                                                        checked="{{show.isActive == true ? true : null}}"
                                                        (change)="enableOrDeleteProduct($event,show._id,show.isDeleted)">
                                                    <div class="slider round">
                                                        <span class="on">On</span>
                                                        <span class="off">Off</span>

                                                    </div>
                                                </label>
                                                <ul class="egg_rw1">
                                                    <li *ngFor="let data of show.attributesData;let index1 = index"><i *ngIf="index1 != 0" class="fa fa-circle"></i> {{data?.traitName}}
                                                    </li>
                                                    <!-- <li>6cm <i class="fa fa-circle"></i></li>
                                        <li>Family Pack</li> -->
                                                </ul>
                                                <ul class="order_prem">
                                                    <!-- <li class="bg_yellow">Orders 5</li>
                                        <li class="bg_red">Premium Leads</li> -->
                                                </ul>
                                                <div>
                                                    <h6>{{allData?.userDetail?.businessName}}
                                                        <!-- <img class="star_img"
                                                    src="./assets/images/checked.svg"> -->
                                                    </h6>
                                                </div>
                                                <div class="commnt_s">
                                                    <span><i class=""><img src="./assets/images/star.svg"></i>

                                                        {{show?.rating | number:'1.1-1'}}


                                                    </span>
                                                    <span><i class=""><img src="./assets/images/chat-bx.svg"></i>
                                                        {{show?.feedbackMessageData?.length}}</span>
                                                </div>
                                                <div class="unit_qtt" *ngIf="hideField">
                                                    <span *ngIf="show.price>0">Price<strong>
                                                            <i class="fa fa-rupee"></i>
                                                            {{show.price}}<label
                                                                style="display: initial;">/{{show.priceUnit
                                                                ? (show.priceUnit):''}}</label>

                                                        </strong></span>
                                                    <span *ngIf="show.quantityUnit">Selling Quantity<strong>
                                                            {{show.quantity}} <label> {{show.quantityUnit?
                                                                (show.quantityUnit):''}}</label>
                                                        </strong></span>
                                                </div>

                                            </a>
                                        </div>
                                    </div>
                                    <div class="No_found" *ngIf="Data.length == 0">
                                        <span class="productNoimg"></span>No new products added yet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

<button type="button" class="m_btn_red btn_red calc_button add_pp" [routerLink]="['/seller/category']">
    Add Product
</button>
<app-footer></app-footer>

<ng-template #choiceCategory>
    <div class="modal-dialog modal-dialog-centered slt_plan_popup_design" role="document">
        <div class="modal-content modulecss">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body sliders">
                <div class="form-title text-center">
                    <h2>Select Plan</h2>
                </div>
                <div class="d-flex flex-column text-center">
                    <form>
                        <div class="form-group row ">
                            <div class="items" *ngFor="let category of allCategory;let i = index">
                                <li class="item current">

                                    <label class="check_buy">
                                        <span>
                                            <img class="image_80"
                                                src="{{urlFaq+ 'categories/'+ category.image}}"></span><br>{{category?.item}}
                                        <p>{{category?.name}}</p>
                                        <input type="radio" name="checkbox" id={{category?.i}}
                                            (change)="categorySelect($event,  category)">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </div>
                        </div>
                        <button type="button" *ngIf="showButton" class="btn btn-info btn-block btn-round btn_red col-12" data-toggle="modal" data-target="" (click)="subCategory(planselect)">Select Plan</button>

                    </form>
                </div>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #planselect>
    <div class="log_pop sign_pop pop_dbl_btn lgout pro_added_suc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h4>Please update your plan {{categoryName}}</h4>

                    </div>
                    <div class="planList">
                        <ul>
                            <li *ngFor="let plan of plansList?.planData">
                                <label class="check_buy choose_slt_plan_opt" *ngIf="plan.planPrice > 0">
                                    <div class="plan_qnt_txt"> {{plan?.quantity}} <small>Quantity</small></div>
                                    <div class="prc_mnth">
                                        <strong><i class="fa fa-rupee"></i> {{plan?.planPrice}}<small>
                                                {{plan?.subscription}}</small></strong>

                                    </div>
                                    <p> {{plan?.planType}}</p>
                                    <input type="radio" name="checkbox" id={{plan?._id}}
                                        (change)="onlyOneValue($event,  plan)">
                                    <span class="checkmark"></span>
                                </label>

                            </li>


                        </ul>
                        <button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 mr-2" data-toggle="modal" (click)="BackPopup(choiceCategory)">Previous</button>
                        <button type="button" *ngIf="planPaymet != ''" class="btn mr-3 btn-info btn-block btn-round btn_red " (click)="payments()">Pay
                            <i class="fa fa-rupee"></i> {{planPaymet}}</button>

                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>


<app-business-modal #business>
</app-business-modal>