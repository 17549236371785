import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_END_POINT_Dev, API_END_POINT_Live } from './global';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiUrl } from '../global/global';
import swal from 'sweetalert2';
import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class JsonPlaceHolderService {
  modalRef: BsModalRef;
  apiEndpoint:string='';
  showLoader:boolean=true;
  server: string ='dev';
  public _albums = [];

  constructor(private http:HttpClient, private router:Router)
  {
    if(localStorage.getItem('server')) {
      this.server = localStorage.getItem('server');
    }
    if(this.server == 'dev') {
      this.apiEndpoint = API_END_POINT_Dev;
    } else if(this.server == 'live') {
      this.apiEndpoint = API_END_POINT_Live;
    }

  }

  getData(url,param)
  {
    return this.http.get<any>(this.apiEndpoint+url,{params:param});
  }

  getPDF(url, param) {   
    //const options = { responseType: 'blob' }; there is no use of this
  
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        return this.http.get(this.apiEndpoint+url, { params: param, responseType: 'blob' });
    }

  postData(url,value)
  {
    return this.http.post<any>(this.apiEndpoint+url,value);
  }
  deleteData(url,value)
  {
    return this.http.delete<any>(this.apiEndpoint+url,value);
  }
  putData(url,value)
  {
    return this.http.put<any>(this.apiEndpoint+url,value);
  }
  postNewData(url,key, value)
  {
    return this.http.post<any>(this.apiEndpoint+url,key, value);
  }

  getPrimaryOffice () {
    return localStorage.getItem('primaryOffice');
  }
  searchPinCode(param) {        
    return this.http.get<any>('https://maps.googleapis.com/maps/api/geocode/json', { params: param,});
  }

  // googlePin(value) {
  //   return this.http.post<any>('https://api.worldpostallocations.com/pincode', value);
  // }

    


  // Show and hide Loader
  // private loaderSubject = new Subject<Boolean>();
  // loaderState = this.loaderSubject.asObservable();

  // loader(value){
  //  // console.log("value ------", value);
  //   this.loaderSubject.next( value ); }

    showAlert(title:string,text:string){
      swal({
        title:title,
        text:text,
        type:'success',
        showConfirmButton: false,
        timer:2000
      })
    }
  
    errorAlert(text,status){
      if(text != "Bad token"){
      console.log("text")
      swal({
        type: 'error',
        title: 'Oops...',
        text: text,
        timer: 2500
      }).then((result)=>{
        if(status){}
      })
    }else{

      localStorage.removeItem('name');
      localStorage.removeItem('id');
      localStorage.removeItem('phone');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('fireBaseToken');
      localStorage.clear();
      this.router.navigate(['/index']);
    
      // swal({
      //   type: 'error',
      //   text: "Your Session is Expired",
      // }).then((result)=>
      // {
      //   if(status)
      //   {
      //     // this.modalRef.hide();
      //     localStorage.removeItem('name');
      //     localStorage.removeItem('id');
      //     localStorage.removeItem('phone');
      //     localStorage.removeItem('adminToken');
      //     localStorage.removeItem('fireBaseToken');
      //     this.router.navigate(['/index']);

      //   }
      // })
    }

    }

 // Show and hide Loader
 private loaderSubject = new Subject<Boolean>();
 loaderState = this.loaderSubject.asObservable();

 loader(value) { this.loaderSubject.next(value); }

 setdata(data) {
   for (let x of tabArray) {
     let array1 = {}
     for (let y of x.value) {
       array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
     }
     permission[x.name] = array1;
   }
   permission['status'] = "updated";
   return true;
 }




}
