<!-- <app-headerwithoutslick></app-headerwithoutslick> -->
<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view profile_view2 rating_pro pdb_0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="left_profile_detail">
                        <a class="go_back" (click)="back()"><img src="./assets/images/back.svg"> Back </a>
                        <div class="sell_near" *ngFor="let data of allData">
                            <div class="left_rate_img">

                                <span><img  src="{{data?.productdata[0]?.images[0]?.image ? s3url+'products/' + data?.productdata[0]?.images[0]?.image :'./assets/images/egg.png'}}" alt=""></span>
                            </div>
                            <div class="left_rate_txt">
                                <div class="detail_pro">
                                    <h5>{{data?.productdata[0]?.attributesData[0]?.attrName}}</h5>
                                    <ul>
                                        <li *ngFor="let opt of data?.productdata[0]?.attributesData;let i = index"><i *ngIf="i != 0" class="fa fa-circle"></i>{{opt.traitName}}</li>

                                    </ul>
                                    <div class="approved_s">
                                        <span><i class=""><img src="./assets/images/star.svg"></i> {{data?.buyerRating== null ? '0' : data?.buyerRating | number:'1.1-1'}}</span>
                                        <!-- <span><i class=""><img src="./assets/images/chat-bx.svg"></i> {{data?.feedbackDetail?.length}}</span> -->
                                    </div>
                                    <!-- <h6>{{data?.productdata[0]?.attributesData[0]?.attrName}} <img class="veri_img" src="./assets/images/checked.svg"></h6> -->
                                    <!-- <div class="approved_s">
                                        <span class="code_red">#PB11ABC</span><span class="verify"><i
                                                class="fa fa-check"></i> Verified</span>
                                        <span><i class=""><img src="./assets/images/star.svg"></i> 4.8</span>
                                    </div> -->
                                </div>
                                <!-- <div class="detail_cont"><a class="m_btn_red btn_red" (click)="chat()">Chat</a>
                                     <a><img src="./assets/images/phone-res.svg"></a> 
                                </div> -->
                            </div>
                        </div>
                        <div class="water_m">
                            <img src="./assets/images/water.png">
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="right_profile_detail rate_prfl_right">
                        <div class="row search_filter_row">

                            <!-- <div class="col-lg-8 col-md-12">
                                <div class="search_prdct">
                                    <input type="Search" name="" value=""
                                        placeholder="Search products from this seller">
                                    <a><img src="./assets/images/search-pro.svg"></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="form_cont">
                                    <span class="slt_wrap">
                                        <select>
                                            <option>Egg</option>
                                            <option>Egg</option>
                                            <option>Egg</option>
                                            <option>Egg</option>
                                            <option>Egg</option>
                                        </select>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                        <div class="row review_cst">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="main_rvw_div" *ngFor="let data of allData">
                                                    <div class="rv_profl" *ngFor="let profileData of data?.userdata ">
                                                        <span>
                                                            <img  src="{{profileData?.profilePic ?  s3url+'users/' + profileData?.profilePic:'https://image.ibb.co/jw55Ex/def_face.jpg'}}" alt="">
                                                            
                                                        </span>
                                                        <h6>{{profileData?.name}}</h6>
                                                    </div>
                                                    <div class="rate_commnt_descrip">
                                                        <div class="rating_str">
                                                            <div class="full-stars" [ngStyle]="riderRation(data.buyerRating | number:'1.0-0')"></div>
                                                        </div>
                                                        <h5>Good</h5>

                                                        <p>{{data?.description ? (data?.description) : '--' }}</p>
                                                    </div>
                                                </div>
                                                <!-- Pagination -->
                                                <nav class="user_review_pagination" aria-label="...">
                                                    <ul class="pagination ">
                                                        <li class="page-item disabled">
                                                            <span class="page-link">Previous</span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#_">1</a></li>
                                                        <li class="page-item active">
                                                            <span class="page-link">
                                          2
                                          <span class="sr-only">(current)</span>
                                                            </span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#_">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#_">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>



                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<app-footer></app-footer>