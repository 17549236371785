<app-header *ngIf="hideHeaderFooter"></app-header>
<main class="main_cont" [ngClass]="!hideHeaderFooter ? 'removeTopSpace':''">
    <section class="trend_pricing pdb_0 newPrice">
        <div class="container">
            <div class="g_bk_trends">
                <div class="row">
                    <a (click)="back()" class="go_back"><img src="../../../assets/images/back.svg"><span class="hideMobile">Back</span> <span class="showMobile">{{pageHeading}}</span> </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="product_slt">
                        <h5>Select Product & Service</h5>
                        <form [formGroup]="productForm" (ngSubmit)="searchProduct(productForm)">
                            <div class="fromWrap">
                                <div class="form_cont">
                                    <label>Products</label>
                                    <span class="slt_wrap">
                                        <select formControlName="product" (change)="selectProduct($event)">
                                            <option *ngFor="let name of productName" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="showError&&productForm.controls.product.errors?.required">
                                        *Please select product
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>Sub Category</label>
                                    <span class="slt_wrap">
                                        <select formControlName="category" (change)="selectSubProduct($event)">
                                            <option value="select" disabled>Select sub category</option>   
                                            <option *ngFor="let name of productData.categories" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="categoryError">
                                        *Please select Sub Category
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>State</label>
                                    <span class="slt_wrap">
                                        <select formControlName="state" (change)="selectState($event)" [attr.disabled]="stateEnabled">
                                            <option value="select" disabled>Select State</option> 
                                            <option *ngFor="let name of productData.state" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>

                                </div>
                                <div class="form_cont">
                                    <label>Station/Mandi</label>
                                    <span class="slt_wrap">
                                        <select formControlName="mandi" (change)="selectMandi($event)" [attr.disabled]="mandiEnabled">
                                            <option value="select" disabled>Station/Mandi</option> 
                                            <option *ngFor="let name of productData.mandi" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>

                                </div>
                                <button class="m_btn_red btn_red btn_gry handcursul" type="submit">Show
                                    Results</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="pricing_tbl">
                        <div class="newRatesWrap">
                            <h3 class="product-title hideMobile">{{pageHeading}}</h3>
                            <div class="title_text">
                                <h2>{{allData[0]?.rateData ? (allData[0]?.rateData.subproduct):''}}</h2>
                                <a href="javascript:;" *ngIf="isLoggedIn" style="margin-right: 5px;
                                animation: blinkingText 1s infinite;
                                padding: 0px 5px 0px 5px;
                                border-radius: 3px;">
                                    <input type="checkbox" 
                                      (change)="viewMyRates($event)"
                                      [checked]="filterMyRates"
                                      style="font-size: 16px;height: 15px;display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                      View My Rates
                                  </a>
                            </div>
                            <div class="table">
                                <table style="width:100%;">
                                    <thead class="thead-red">
                                        <tr>
                                            <th>Date</th>
                                            <th *ngIf="hideHeader">Company</th>
                                            <th>Station/Mandi </th>
                                            <th>Price Rs./{{productForm.value.product == 'Egg' ? '100 Eggs': productForm.value.product == 'Chicks & Hatching Egg'? 'Chick': productForm.value.product == 'Grains' ? 'Quintal' :'Kg'  }}<span *ngIf="hideHeader"> Small</span></th>
                                            <th *ngIf="hideHeader">Price Rs./{{productForm.value.product == 'Egg' ? '100 Eggs': productForm.value.product == 'Chicks & Hatching Egg'? 'Chick': productForm.value.product == 'Grains' ? 'Quintal' :'Kg'  }} Medium</th>
                                            <th *ngIf="hideHeader">Price Rs./{{productForm.value.product == 'Egg' ? '100 Eggs': productForm.value.product == 'Chicks & Hatching Egg'? 'Chick': productForm.value.product == 'Grains' ? 'Quintal' :'Kg'  }}  Big</th>
                                            <th *ngIf="hidehistory">History</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of allData">
                                            <td>{{data.rateData.rateDateAt ? (data.rateData.rateDateAt |date: 'dd/MM/yyyy'):''}}</td>
                                            <td *ngIf="hideHeader">{{data.rateData.companies ? (data.rateData.companies):''}}</td>
                                            <td>
                                                {{data.rateData.mandi ? (data.rateData.mandi):''}}
                                                <br/>
                                                <span *ngIf="isLoggedIn" style="font-size: 10px;bottom: -5px;white-space: nowrap;">
                                                    <input type="checkbox" 
                                                        (change)="addToMyRates($event, data)"
                                                        [checked]="data.isInMyRatesList"
                                                        style="font-size: 10px;height: 15px; display: inline;width: 15px;top: 2px;position: relative;cursor: pointer;" />
                                                    Add to my rates
                                                </span>
                                            </td>
                                            <td>
                                                <span [class]="data.rateData.color ? (data.rateData.color):''">{{data.rateData.modifiedRate ? (data.rateData.modifiedRate.replace('₹', '')):'Coming soon'}}</span>
                                            </td>

                                            <td *ngIf="hideHeader">
                                                <span [class]="data.rateData.mediumColor ? (data.rateData.mediumColor):''">{{data.rateData.modifiedMediumRate ? (data.rateData.modifiedMediumRate.replace('₹', '')):'Coming soon' }}</span>
                                            </td>
                                            <td *ngIf="hideHeader">
                                                <span [class]="data.rateData.bigColor ? (data.rateData.bigColor):''">{{data.rateData.modifiedBigRate ? (data.rateData.modifiedBigRate.replace('₹', '')):'Coming soon'  }}</span>

                                            </td>

                                            <td *ngIf="hidehistory"><a (click)="viewHistory(data)">Past 30 Days View</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="No_found" *ngIf="allData?.length == 0">
                                <span class="norecordIcon"></span> No Rate Found !
                            </div>

                            <div class="pagination-wrapper" *ngIf="allData?.length != 0 && !filterMyRates">
                                <pagination (pageChanged)="pageChanged($event)" *ngIf="pageCount != 0" [(ngModel)]="currentPage" [boundaryLinks]="true" [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prv" nextText="Next" firstText="First" lastText="Last"
                                    [rotate]="true" [totalItems]="pageCount">
                                </pagination>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer *ngIf="hideHeaderFooter"></app-footer>