import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-buyingquotesdetailspage1',
  templateUrl: './buyingquotesdetailspage1.component.html',
  styleUrls: ['./buyingquotesdetailspage1.component.css']
})
export class Buyingquotesdetailspage1Component implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  chat(){
  
    this.router.navigate(['Chat']);
  }
}
