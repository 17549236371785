import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { DatePipe } from '@angular/common';
import * as CONSTANT from '../../services/constants';
import { Router, ActivatedRoute } from '@angular/router';

import { JsonPlaceHolderService } from '../../services/json-place-holder.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  
  userId: any;
  oldDate: any;
  currentPage: number = 1;
  pageCount: any;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 0, totalItems: null };
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  urlfile:any;
  allData: any = [];
  showAllread=false;
  constructor(private service: JsonPlaceHolderService, private datePipe: DatePipe,  private router: Router, private route: ActivatedRoute) { }
  ngOnInit(): void {
    let s3url = localStorage.getItem('s3BaseUrl');
    this.urlfile= s3url+"users/";
    
    console.log(this.urlfile, "urlfile=====")
    this.userId = localStorage.getItem('id');
    this.getNotificationList();
  }

  /// notification listing
  getNotificationList() {
  
    let params = new HttpParams();
    params = params.append('id', this.userId);
    params = params.append('page', this.pagination.skip.toString());
    params = params.append('limit', this.pagination.limit.toString());

    this.service.getData(apiUrl._getNotificationList, params)
      .subscribe(async (res) => {
        this.allData = res.data;
        

        this.pageCount = this.allData.length;
        this.oldDate = this.allData[0].updatedAt;
        console.log(this.allData, "notification1")
        for (let i = 0; i < this.allData.length; i++) {
          if( this.allData[i].isRead==0){
            this.showAllread=true;
            return false
          }else{
            this.showAllread=false;
          }
        }
     
      })
  }
  checkDate(d, i) {  
    let date = this.datePipe.transform(d, "yyyy-MM-dd")
    let prvDate = this.datePipe.transform(this.oldDate, "yyyy-MM-dd")
    if (i == 0) {
      return true;
    }
    if (date != prvDate) {
      prvDate = date;
      return true;
    }  
  }

  checkNotification(data){
    this.gotoDetailspage(data)
    let ID= data._id
    if(data.isRead == 1){     
      return; 
    }else{
      this.readNotification(ID);
     
    }
  }
  readNotification(id){
    let data = { 'id': id }
    this.service.putData(apiUrl._readNotification, data)
      .subscribe(async (res) => {      
        this.getNotificationList();
        console.log(res, "read notification1")
     
      })

  }

  gotoDetailspage(data){
    if(data.notificationType==25){
      this.router.navigate(['/premium-lead/'+data.requirementId]);
    }

  }
  limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 1;
    this.pagination.totalItems = null;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.getNotificationList();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) + 1;
     this.getNotificationList();
  }

  // calculateDiff(sentOn){

  //           let todayDate = new Date();
  //           let sentOnDate = new Date(sentOn);
  //           sentOnDate.setDate(sentOnDate.getDate());
  //           let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
  //           // To calculate the no. of days between two dates
  //           let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); 
  //           return differenceInDays;
  //     }

  markAll(){
    let userId = localStorage.getItem('id');
    let data = { 'userId': userId }
    this.service.putData(apiUrl._readNotification, data)
      .subscribe(async (res) => {      
        this.getNotificationList();
        console.log(res, "read notification1")
     
      })

  }
}
