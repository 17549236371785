<app-header></app-header>
<main class="main_cont">
    <!-- Profile -->
    <section class="profile_page_view rating_pro trend_dtl_page notify_list_pg">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-md-12">
                    <h2>Notifications</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="noti_lst_opn">
                        <!-- <a href="#" class="markBtn">Mark
                            All Read</a> -->
                        <div class="notificationWrap">
                            <div class="readAll" *ngIf="showAllread">
                                <a href="javascript:" (click)="markAll()">Mark all Read</a>
                            </div>
                            <ul *ngFor="let data of allData; let i = index">
                                <li (click)="checkNotification(data)" class="{{data.isRead==0?('unread'):'read'}}">
                                    <div class="date_note">
                                        <!-- {{calculateDiff(data.createdAt)}} Day Ago -->
                                        {{data.createdAt | date : "MMMM d,y"}}
                                    </div>
                                    <h5> {{data.title ? (data.title):''}}</h5>
                                    <span><img
                                            src="{{data.userData.profilePic ?  (urlfile+data.userData.profilePic):'./assets/images/default.jpg'}}"></span>
                                    <strong class="msg">{{data.message? (data.message):''}}</strong>

                                </li>
                            </ul>
                        </div>
                        <div class="No_found" *ngIf="allData.length == 0">
                            <span class="norecordIcon"></span> No Data found
                        </div>
                        <div class="new-pagination-wrapper" *ngIf="allData.length > 9">
                            <div class="pagination-wrapper">

                                <pagination (pageChanged)="pageChanged($event)" *ngIf="pageCount != 0" [(ngModel)]="currentPage" [boundaryLinks]="true" [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prv" nextText="Next" firstText="First" lastText="Last"
                                    [rotate]="true" [totalItems]="pageCount"></pagination>

                            </div>
                        </div>
                        <!-- <ul>
                            <li>
                                <span><img src="images/grl.png"></span>
                                Nicky J. texted you - <strong style="color: #AE0019;">"Approved" </strong>
                                <small>10:10 AM</small>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>