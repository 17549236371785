<app-header *ngIf="hideHeaderFooter"></app-header>
<main class="main_cont" [ngClass]="!hideHeaderFooter ? 'removeTopSpace':''">
    <section class="trend_pricing pdb_0 newPrice">
        <div class="container">
            <div class="g_bk_trends">
                <div class="row">
                    <a (click)="back()" class="go_back"><img src="../../../assets/images/back.svg"> Back </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="product_slt">
                        <h5>Select Product & Service</h5>
                        <form [formGroup]="productForm" (ngSubmit)="searchProduct(productForm)">
                            <div class="fromWrap">
                                <div class="form_cont">
                                    <label>Products</label>
                                    <span class="slt_wrap">
                                        <select formControlName="product" (change)="selectProduct($event)">
                                            <option *ngFor="let name of productName" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="showError&&productForm.controls.product.errors?.required">
                                        *Please select product
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>Sub Category</label>
                                    <span class="slt_wrap">
                                        <select formControlName="category" (change)="selectSubProduct($event)">
                                            <option value="select" disabled>Select sub category</option>
                                            <option *ngFor="let name of productData.categories" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="categoryError">
                                        *Please select Sub Category
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>State</label>
                                    <span class="slt_wrap">
                                        <select formControlName="state" (change)="selectState($event)"
                                            [attr.disabled]="stateEnabled">
                                            <option value="select" disabled>Select State</option>
                                            <option *ngFor="let name of productData.state" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="stateError">
                                        *Please select state
                                    </div>
                                </div>
                                <div class="form_cont">
                                    <label>Station/Mandi</label>
                                    <span class="slt_wrap">
                                        <select formControlName="mandi" (change)="selectMandi($event)"
                                            [attr.disabled]="mandiEnabled">
                                            <option value="select" disabled>Station/Mandi</option>
                                            <option *ngFor="let name of productData.mandi" [value]="name">
                                                {{name}} </option>
                                        </select>
                                    </span>
                                    <div class="text-danger" *ngIf="mandiError">
                                        *Please select mandi
                                    </div>
                                </div>
                                <button class="m_btn_red btn_red btn_gry handcursul" type="submit">Show Results</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="pricing_tbl">
                        <div class="newRatesWrap">
                            <h3 class="product-title">Rates</h3>
                            <div class="title_text">
                                <h2>{{allData[0]?.mandi ? (allData[0]?.mandi):this.mandiName}}, {{allData[0]?.state ?(allData[0]?.state):this.stateName}}
                                </h2>

                                <div class="filterDate">
                                    <a class="" href="javascript:;" class="slt_wrap" (click)="openFilter()">
                                        {{filterTxt}}</a>
                                    <div *ngIf="filterWrap" class="dateWrap customDropdown">
                                        <div class="dropdownMenu">
                                            <form>
                                                <button type="button" class="close" (click)="closeFilter()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <ul class="form_cont input-daterange">
                                                    <li class="ps">
                                                        <input type="radio" name="filter" (change)="changeEventFilter(1)" [checked]="filterType==1?true:false">
                                                        <span>Daily Rates</span>

                                                    </li>
                                                    <li class="ps">
                                                        <input type="radio" name="filter" (change)="changeEventFilter(2)" [checked]="filterType==2?true:false">
                                                        <span>Monthly Rates</span>
                                                    </li>
                                                    <li class="ps">

                                                        <input type="radio" name="filter" (change)="changeEventFilter(3)" [checked]="filterType==3?true:false">
                                                        <span>Custom Date </span>
                                                        <div *ngIf="filterType==3">
                                                            <div class="form_cont">
                                                                <input type="text" placeholder="From date" class="datepicker form-control" [bsValue]="fromDateValue" bsDatepicker [maxDate]="todayDate" (bsValueChange)="fromDateChange($event)" [bsConfig]="{dateInputFormat: 'DD/MM/YYYY','customTodayClass': 'today', showWeekNumbers: false }"
                                                                    readonly>
                                                            </div>

                                                            <div class="form_cont">
                                                                <input type="text" placeholder="To date" class="datepicker form-control" bsDatepicker [maxDate]="todayDate" [bsValue]="toDateValue" (bsValueChange)="toDateChange($event)" [bsConfig]="{dateInputFormat: 'DD/MM/YYYY','customTodayClass': 'today', showWeekNumbers: false }"
                                                                    readonly>
                                                            </div>

                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="text-danger filterErr" *ngIf="filterError">*Please select Date
                                                </div>
                                                <div class="text-danger filterErr" *ngIf="selectfromError">*To Date is greater than or equal to the from Date.</div>
                                                <div class="buttonWrap">
                                                    <a href="javascript:;" (click)="applyFilter()" class="m_btn_red btn_red  ">Apply</a>
                                                    <a href="javascript:;" *ngIf="filterType" (click)="clearFilter()" class="m_btn_red btn_red btn_brdr">Clear All</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="table">
                                <table style="width:100%;">
                                    <thead class="thead-red">
                                        <tr>
                                            <th>Date</th>
                                            <th *ngIf="showTh">Company</th>
                                            <th>Station/Mandi</th>
                                            <th>Price Rs./{{productForm.value.product == 'Egg' ? '100 Eggs': productForm.value.product == 'Chicks & Hatching Egg'? 'Chick': productForm.value.product == 'Grains' ? 'Quintal' :'Kg'  }} <span *ngIf="showTh">Small</span></th>
                                            <th *ngIf="showTh">Price Rs./{{productForm.value.product == 'Egg' ? '100 Eggs': productForm.value.product == 'Chicks & Hatching Egg'? 'Chick': productForm.value.product == 'Grains' ? 'Quintal' :'Kg'  }}  Medium</th>
                                            <th *ngIf="showTh">Price Rs./{{productForm.value.product == 'Egg' ? '100 Eggs': productForm.value.product == 'Chicks & Hatching Egg'? 'Chick': productForm.value.product == 'Grains' ? 'Quintal' :'Kg'  }} Big</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of allData">
                                            <td>{{data.rateDateAt ? (data.rateDateAt |date: 'dd/MM/yyyy'):''}}</td>
                                            <td *ngIf="showTh">{{data.companies ? (data.companies):''}}</td>
                                            <td>{{data.mandi ? (data.mandi):''}}</td>
                                            <td>
                                                <span *ngIf="data.modifiedRate" [class]="data.color ? (data.color):''">{{data.modifiedRate ? (data.modifiedRate):''}}</span>
                                                <span *ngIf="!data.modifiedRate" class="purple">Coming soon</span>
                                            </td>

                                            <td *ngIf="showTh">
                                                <span *ngIf="!data.modifiedMediumRate" class="purple">Coming soon</span>
                                                <span *ngIf="data.modifiedMediumRate" [class]="data.mediumColor ? (data.mediumColor):''">{{data.modifiedMediumRate ? (data.modifiedMediumRate):'' }}</span>
                                            </td>
                                            <td *ngIf="showTh">
                                                <span *ngIf="!data.modifiedBigRate" class="purple">Coming soon</span>
                                                <span *ngIf="data.modifiedBigRate" [class]="data.bigColor ? (data.bigColor):''">{{data.modifiedBigRate ? (data.modifiedBigRate):'-'  }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="No_found" *ngIf="allData?.length == 0">
                                <span class="norecordIcon"></span> No Rate Found !
                            </div>

                            <div class="pagination-wrapper" *ngIf="allData?.length != 0">
                                <pagination (pageChanged)="pageChanged($event)" *ngIf="pageCount != 0" [(ngModel)]="currentPage" [boundaryLinks]="true" [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prv" nextText="Next" firstText="First" lastText="Last"
                                    [rotate]="true" [totalItems]="pageCount">
                                </pagination>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer *ngIf="hideHeaderFooter"></app-footer>