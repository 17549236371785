import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiUrl } from '../../global/global';
import { HttpParams } from '@angular/common/http';
import { IndexService } from '../index-page/index.service';
import { FormGroup, FormControl, FormBuilder, Validators, } from '@angular/forms';
import { AppComponent } from '../../app.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {
  productName: any = [];
  productData: any = [];
  allData: any = [];
  updateProductName = false;
  proName: any;
  subProduct: any;
  ratesType: any = 1;
  showError = false;
  stateName: any;
  pageHeading = "Today's Rate"
  mandiName: any;

  isLoggedIn = false;
  myStationsRaw: object[];
  filterMyRates = false;

  tempNewlyAddedRates_ids: any = {};

  mandiEnabled: boolean = true;
  stateEnabled: boolean = true;

  hideHeaderFooter = true;
  productForm: FormGroup;
  priceConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    'infinite': true,
    "autoplay": true,
    "autoplaySpeed": 1000,
    "vertical": false,
    'pauseOnHover': true,
    'centerMode': false,
    'cssEase': 'linear',
    // 'variableWidth': true,
    "responsive": [
      {
        "breakpoint": 767,
        "settings": {
          "slidesToShow": 3,
          "slidesToScroll": 1
        }
      }
    ]
  };

  constructor(private route: ActivatedRoute, private IS: IndexService, private _location: Location, private router: Router, private formBuilder: FormBuilder, private parent: AppComponent) {
    this.productForm = formBuilder.group({
      product: ['', Validators.required],
      category: ['', Validators.required],
      state: [''],
      mandi: ['']
    });
  }

  ngOnInit(): void {
    let appUrl = localStorage.getItem('mobileApp') ? (localStorage.getItem('mobileApp')) : '';
    if (appUrl) {
      // this.updateProductName = true;
      this.hideHeaderFooter = false;
    }

    this.getMyStations();
    this.getProductName();
    const parentData = this.parent.getNavtionData() ? (this.parent.getNavtionData()) : '';
    
    if (parentData) {
      this.proName = parentData[0].productName ? (parentData[0].productName) : "";
      this.ratesType = parentData[0].rateType ? (parentData[0].rateType) : 1;
      if (this.ratesType == 2) {
        this.pageHeading = "Tomorrow's Rate";
      }
      if (this.ratesType == 3) {
        this.pageHeading = "Current Selling Price";
      }
      if (this.ratesType == 4) {
        this.pageHeading = "Market Forecast";
      }
      this.getCategories(this.proName);
      this.productDetailRates();
    } else {
      this.updateProductName = true;
    }

    let token = localStorage.getItem("adminToken");
    if (token) {
      this.isLoggedIn = true;
    }
  }

  viewMyRates(event) {
    event.stopPropagation();

    if (!this.isLoggedIn) {
      return;
    }

    this.filterMyRates = event.target.checked;

    this.productDetailRates();
  }

  back() {
    let type;
    type = Number(this.ratesType);
    localStorage.setItem('rateType', type);
    let appUrl = localStorage.getItem('mobileApp') ? (localStorage.getItem('mobileApp')) : '';
    this.pageHeading = "Today's Rate"
    if (appUrl) {
      this.router.navigate(['/trendPrice/current-rate']);
    } else {
      this.router.navigate(['/']);
    }
  }

  getProductName() {
    let data = {}
    this.IS.getList(data, apiUrl._getProductName)
      .subscribe(async (res) => {
        this.productName = res.data;
        if (this.updateProductName) {
          this.proName = this.productName[0];

          this.getCategories(this.proName);
          this.productDetailRates();
        }
        this.productForm.patchValue({ 'product': this.proName })

      }, err => {

      })
  }

  getCategories(productName) {
    let params = new HttpParams();    
    params = params.append('rateType', this.ratesType);
    params = params.append('product', productName);
    params = params.append('view', '0');
    if (this.subProduct) {
      params = params.append('category', this.subProduct);
    }
    if (this.stateName) {
      params = params.append('state', this.stateName);
    }
    this.IS.getList(params, apiUrl._getCategoriesRate)
      .subscribe(async (res) => {
        this.productData = res.data;  

        if (this.productData.mandi.length > 0) {
          let boolean: boolean;
          this.mandiEnabled = boolean;
        }
        if (this.productData.state.length > 0) {
          let boolean: boolean;
          this.stateEnabled = boolean;
        }
      })
  }

  getMyStations() {
    this.IS.getList({}, apiUrl._getMyRates).toPromise().then((res) => {
      this.myStationsRaw = res.data;
    });
  }

  addToMyRates(event, mandiRate) {
    if (event.target.checked) {
      let url = apiUrl._getMyRates 
      + '?mandi='+ encodeURIComponent(mandiRate._id.mandi)
      + '&product=' + encodeURIComponent(this.proName)
      + '&state=' + encodeURIComponent(mandiRate._id.state) 
      + '&subproduct=' + encodeURIComponent(mandiRate._id.subproduct);

      this.IS.submitForm({}, url)
        .subscribe((res) => {
          if (res.statusCode == 200) {
            this.tempNewlyAddedRates_ids[`${res.data.success.mandi}_${res.data.success.subproduct}_${res.data.success.state}_${res.data.success.product}`] = res.data.success._id;
          }
          this.getMyStations();
      })
    } else {
      let wishListId = mandiRate.wishListId;
      if (!wishListId) {
        wishListId = this.tempNewlyAddedRates_ids[`${mandiRate.rateData.mandi}_${mandiRate.rateData.subproduct}_${mandiRate.rateData.state}_${this.proName}`];
      }

      if (wishListId) {
        this.IS.deleteList({}, apiUrl._getMyRates + '?wishlistId='+ wishListId)
        .subscribe((res) => {
            this.getMyStations();
        })
      }
    }
  }

  productDetailRates() {
  
    let params = new HttpParams();
    let type;
    type = Number(this.ratesType);
    params = params.append('product', this.proName);
    params = params.append('rateType', type);
    params = params.append('view', '0');

    if (this.subProduct) {
    
      params = params.append('category', this.subProduct);
    }
    if (this.stateName) {
      params = params.append('state', this.stateName);
    }
    if (this.mandiName) {
      params = params.append('mandi', this.mandiName);
    }

    this.IS.getList(params, apiUrl._getproductRateDetail)
      .subscribe(async (res) => {
        let temp = res.data || [];

        if (this.proName == 'Egg') {
          temp.sort((a, b) => a._id.toLowerCase() < b._id.toLowerCase() ? 1 : -1);
        } else {
          temp.sort((a, b) => a._id.toLowerCase() > b._id.toLowerCase() ? 1 : -1);
        }
        this.allData = temp;


        if (this.filterMyRates) {
          let tempData = []
          
          // get subcat 
          this.allData.map(cat => {
            let tempArr = [];

            this.myStationsRaw.map((mst) => {
              for (let index = 0; index < cat.subcategories.length; index++) {
                const st = cat.subcategories[index];
      
                if(st['_id'].mandi.toLowerCase() == mst['mandi'].toLowerCase() && st['_id'].subproduct.toLowerCase() == mst['subproduct'].toLowerCase()) {
                  st.isInMyRatesList = true;
                  st.wishListId = mst['_id'];
                  tempArr.push(st);
                  break;
                }
              }
            })

            tempData.push({
              '_id': cat._id,
              'subcategories': tempArr
            })
          });

          this.allData = tempData;
        } else {
          this.allData.map(cat => {
            this.myStationsRaw.map((mst) => {
              for (let index = 0; index < cat.subcategories.length; index++) {
                const st = cat.subcategories[index];
      
                if(st['_id'].mandi.toLowerCase() == mst['mandi'].toLowerCase() && st['_id'].subproduct.toLowerCase() == mst['subproduct'].toLowerCase()) {
                  st.isInMyRatesList = true;
                  st.wishListId = mst['_id'];
                  break;
                }
              }
            })
          });
        }
      }, err => {
      })
  }
  gotoPricePage(e) {
    this.subProduct = "";
    this.stateName = "";
    this.mandiName = "";
    this.proName = e;
    this.productForm.patchValue({ 'product': this.proName })
    this.getCategories(this.proName);
    this.productForm.patchValue({ 'category': "select" })
    this.productForm.patchValue({ 'state': "" })
    this.productForm.patchValue({ 'mandi': "" })
    this.productDetailRates();
  }

  selectProduct(event) {
    this.subProduct = "";
    this.stateName = "";
    this.mandiName = "";
    this.proName = event.target.value;
    this.productForm.patchValue({ 'category': "select" })
    this.getCategories(this.proName);
  }

  selectSubProduct(event) {
    this.subProduct = event.target.value;
    this.getCategories(this.proName);
    this.productForm.patchValue({ 'state': "select" })
  }
  selectState(event) {
    this.stateName = event.target.value;
    this.getCategories(this.proName);
    this.productForm.patchValue({ 'mandi': "select" })
  }
  selectMandi(event) {
    this.mandiName = event.target.value;
  }
  searchProduct(form) {
    if (form.valid) {
      this.productDetailRates();
    } else {
      this.showError = true
    }
  }
  viewRate(subProductName) {
    const data = [];
    data.push({ 'productName': this.proName, 'subProductName': subProductName, 'rateType': Number(this.ratesType) })
    this.parent.setNavtionData(data);
    this.router.navigateByUrl(`/price/product-price`);
  }
  checkValue(value) {
    let rate = parseInt(value);
    if (rate == value) {
      let type = '2'
      return type
    } else {
      let type = '1'
      return type
    }
  }
  checkText(text) {
    let type;
    if (text) {
      let checkTxt = text.split(' ').filter(s => s).join(' ').toLowerCase();
      if (checkTxt == 'coming soon') {
        type = '1'
        return type
      } else if (checkTxt == 'same normal') {
        type = '2'
        return type
      } else if (checkTxt == 'slow down') {
        type = '3'
        return type
      }
      else {
        type = '4'
        return type
      }
    } else {
      type = '5'
      return type
    }
  }
}
