<!-- <app-headerwithoutslick></app-headerwithoutslick> -->
<app-header></app-header>
<main class="main_cont">

    <!-- Profile -->
    <section class="profile_page_view profile_view2">
        <div class="container-fluid">
            <div class="row g_bk_trends">
                <div class="col-md-12">
                    <a [routerLink]="['/seller/category']" class="go_back"><img src="./assets/images/back.svg"> Back </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="right_profile_detail subscription_pay">
                        <div class="plan_payment">
                            <h4>Complete your Subscription</h4>
                            <div class="cutomize_tbl mr_50">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Title</th>
                                            <th scope="col">Total Categories</th>
                                            <th scope="col">Eligible Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Customize Plan</th>
                                            <td>Eggs</td>
                                            <td>1000</td>
                                        </tr>
                                        <tr>
                                            <th scope="row"></th>
                                            <td>Chicks</td>
                                            <td>500</td>
                                        </tr>
                                        <tr>
                                            <th scope="row"></th>
                                            <td>Chicken</td>
                                            <td>300</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <th scope="row"></th>
                                        <td></td>
                                        <td><span>Total</span><br><strong><i class="fa fa-rupee"></i> 1999</strong></td>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="row mr_50">
                                <div class="col-md-12">
                                    <h5>Payment Methods</h5>
                                </div>
                                <div class="col-lg-4 col-md-6 cc_pd">
                                    <a class="credit_cc"><img class="cc_img" src="./assets/images/cre_c.svg"> Credit Card</a>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <a class="credit_cc"><img src="./assets/images/gpay.svg"></a>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <a class="credit_cc"><img src="./assets/images/ptm.svg"></a>
                                </div>
                            </div>
                            <div class="row mr_40 paymnt_card_add">
                                <div class="col-md-12">
                                    <h5>Payment Methods</h5>
                                </div>
                                <div class="col-md-12">
                                    <div class="">
                                        <div class="">
                                            <form action="seller-product-detail5.html">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group form_cont">
                                                            <label for="" class="control-label">Name On Card</label>
                                                            <!-- <input id="cc-name" name="cc-name" type="text" class="form-control cc-name valid" data-val="true" data-val-required="Please enter the name on card" autocomplete="cc-name" aria-required="true" aria-invalid="false" aria-describedby="cc-name-error"> -->
                                                            <!-- <span class="help-block field-validation-valid" data-valmsg-for="cc-name" data-valmsg-replace="true"></span> -->
                                                            <input name="" type="text" class="form-control" value="" placeholder="John Williams">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group form_cont">
                                                            <label for="" class="control-label">Card Number</label>
                                                            <!-- <input id="cc-number" name="cc-number" type="tel" class="form-control cc-number identified visa" value="" data-val="true" data-val-required="Please enter the card number" data-val-cc-number="Please enter a valid card number" autocomplete="cc-number"> -->
                                                            <!-- <span class="help-block" data-valmsg-for="cc-number" data-valmsg-replace="true"></span> -->
                                                            <input id="" name="" type="text" class="form-control" value="" placeholder="4829 5544 7898 7455">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-6">
                                                        <div class="form-group form_cont">
                                                            <label for="" class="control-label">Valid Thru</label>
                                                            <!-- <input id="cc-exp" name="cc-exp" type="tel" class="form-control cc-exp" value="" data-val="true" data-val-required="Please enter the card expiration" data-val-cc-exp="Please enter a valid month and year" placeholder="MM / YY" autocomplete="cc-exp"> -->
                                                            <!-- <span class="help-block" data-valmsg-for="cc-exp" data-valmsg-replace="true"></span> -->
                                                            <input name="" type="text" class="form-control " value="" placeholder="MM/YY">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-6">

                                                        <div class="input-group form_cont">
                                                            <label for="" class="control-label">CVV</label>
                                                            <!-- <input id="x_card_code" name="x_card_code" type="tel" class="form-control cc-cvc" value="" data-val="true" data-val-required="Please enter the security code" data-val-cc-cvc="Please enter a valid security code" autocomplete="off"> -->
                                                            <input name="" type="" class="form-control " value="" placeholder="xxx">
                                                            <!-- <div class="input-group-addon">
                                          <span class="fa fa-question-circle fa-lg" data-toggle="popover" data-container="body" data-html="true" data-title="Security Code" 
                                          data-content="<div class='text-center one-card'>The 3 digit code on back of the card..<div class='visa-mc-cvc-preview'></div></div>"
                                          data-trigger="hover"></span>
                                      </div> -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12" [routerLink]="['/seller/' + id]">
                                                        <button class="btn btn-lg btn-success btn-block m_btn_red btn_red" [routerLink]="['/seller/' + id]">
                                  <!-- <i class="fa fa-lock fa-lg"></i>&nbsp; -->
                                  <span>Pay <i class="fa fa-rupee"></i> 1999</span>
                                  <!-- <span id="" style="display:none;">Sending…</span> -->
                              </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>