import { Component, OnInit, Renderer2, ViewChild, NgZone, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { apiUrl } from '../../global/global';
import { HttpParams } from '@angular/common/http';
import { InternalService } from '../internal.service';
import { DataSharingService } from '../../services/data.share.service';
import * as CONSTANT from '../../services/constants';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FcmserviceService } from './../../services/fcmservice.service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-buyerlisting',
  templateUrl: './buyerlisting.component.html',
  styleUrls: ['./buyerlisting.component.css']
})
export class BuyerlistingComponent implements OnInit, OnDestroy {
  category = '';
  nearby: any = 20000000;
  attributesList1: any = [];
  attributesData1: any = [];
  attributesData: any = [];
  moqUnitData: any = [];
  subcategoryID: any;
  search = "";
  maqUnit: any;
  priceType;
  maqUnitError: any;
  mainCategoryName: any = [];
  selectCategoryName: any;
  userType = "";
  categoryList: any = [];
  NearData: any = [];
  verifiedStatus = '';
  userType1: any = [];
  latitude: any;
  longitude: any;
  closeSearchicon = true;
  closeSearch = false;
  specificLength = 4;
  subCategoryList: any = [];
  nearbylist = [{ 'id': '1000', 'name': 'Any', 'checkbox': false }, { 'id': '20', 'name': '20Km', 'checkbox': false }, { 'id': '10', 'name': '10Km', 'checkbox': false }, { 'id': '50', 'name': '50Km', 'checkbox': false }]
  verifylist = [{ 'id': '1', 'name': 'Verified', 'checkbox': false }, { 'id': '2', 'name': 'Non verified', 'checkbox': false }]
  pageCount: any = [];
  currentPage: number = 1;
  slideCheck = 0;
  pagination = { limit: 30, maxSize: CONSTANT.MAX_SIZE, skip: 1, totalItems: null }
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  attributeFilter = false;
  zoom: number;
  private geoCoder;
  address: string;
  gMap: any;
  mapPlot: any
  mapSector: any;
  mapLocality: any;
  mapCity: any;
  mapState: any;
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapStateshort: any;
  isReadOnly: boolean = false;
  maq: any;
  rating: any;
  subCategoryId = '';
  subCategoryName = '';
  hideMoq = true;
  hideRating = true;
  hideSeaction = 0;

  secretCode = "0123456789123456";
  encrypted: any;
  encrypted1: any;
  decrypted: any;
  public unSubscribe: any = {}

  @ViewChild('mapSearch')
  public searchElementRef: ElementRef;
  constructor(private renderer: Renderer2, private router: Router, private route: ActivatedRoute, private IS: InternalService, private dataSharingService: DataSharingService, private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader, private messagingService: FcmserviceService, private location: Location) {
    this.renderer.addClass(document.body, 'order_flow_page');
    this.renderer.addClass(document.body, 'buyer_listing_page');
    this.category = this.route.snapshot.paramMap.get('category');
    console.log('id alert', this.category)
  }
  ngOnDestroy() {
    localStorage.removeItem('searchText');
    this.search = '';
    this.renderer.removeClass(document.body, 'order_flow_page');
    this.renderer.removeClass(document.body, 'buyer_listing_page');

    const url = this.router.createUrlTree([], { relativeTo: this.route }).toString()
    // this.dataSharingService.isSearch.unsubscribe()
    this.unSubscribe.unsubscribe()
    // this.dataSharingService.addressUpdate.unsubscribe()
    return this.location.go(url);

  }

  ngOnInit(): void {
    console.log('url', this.router.url.split('?')[0]);
    this.latitude = localStorage.getItem('latitude');
    this.longitude = localStorage.getItem('longitude');
    var search1 = localStorage.getItem('search');
    var headerSearch = localStorage.getItem('searchText');
    var headerSearch1 = localStorage.getItem('searchText1');
    console.log('h1', headerSearch1);
    if (search1 != null && search1 != '') {
      this.search = search1;
    }
    if (headerSearch != null && headerSearch != '') {
      var decrypted = CryptoJS.AES.decrypt(headerSearch, this.secretCode);
      this.decrypted = decrypted.toString(CryptoJS.enc.Utf8);
      this.search = this.decrypted;
    }
    this.unSubscribe = this.dataSharingService.isSearch.subscribe(value => {

      if (this.router.url.split('?')[0] == '/buyerlistingSearch') {
        var headerSearch1 = localStorage.getItem('searchText1');
        if (headerSearch1 != null && headerSearch1 != '') {
          this.search = headerSearch1;
          this.hideSeaction = 1;
          this.getindex(this.category);
          // this.dataSharingService.isSearch.unsubscribe()
        } else {
          this.search = '';
          this.getindex(this.category);
          // this.dataSharingService.isSearch.unsubscribe()
        }
      } else {
        this.getindex(this.category);
      }
    });

    this.dataSharingService.addressUpdate.subscribe(value => {
      let newlatitude = localStorage.getItem('latitude');
      let newlongitude = localStorage.getItem('longitude');
      if (newlatitude != this.latitude && newlongitude != this.longitude) {
        this.latitude = newlatitude;
        this.longitude = newlongitude;
        this.getindex(this.category);
      }
    });
    console.log(this.category, "asddasd")
    this.getCategories();
    if (this.category != null) {
      this.getSubCategories();
    }

    this.getuser();

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: "IN" }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.gMap = place;
          console.log('place', place);
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)

        });
      });
      autocomplete.setComponentRestrictions
    });
  }

  decript() {
    var decrypted = CryptoJS.AES.decrypt(this.encrypted, this.secretCode);
    this.decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    console.log('decripted', this.decrypted);
  }


  getuser() {
    let params = new HttpParams();
    this.IS.getList(params, apiUrl._getusertype)
      .subscribe(async (res) => {
        console.log(res.data)

        for (let i = 0; i < res.data.length; i++) {
          this.userType1.push({ 'id': res.data[i].id, 'name': res.data[i].name, 'checkbox': false })
        }
        console.log(this.userType1)
      })
  }
  getCategories() {
    let params = new HttpParams();
    this.IS.getList(params, apiUrl._getCategories)
      .subscribe(res => {
        this.categoryList = res.data.categoryData;
        this.mainCategoryName = this.categoryList.filter((item) => {
          if (item._id == this.category) {
            return item;
          }
        });
        this.selectCategoryName = this.mainCategoryName[0]?.name.split(' ').filter(s => s).join(' ');
        console.log(this.selectCategoryName, " this.selectCategoryName")
        setTimeout(() => {
          this.showFilterByCategoriesName();
        }, 100);

      })
  }
  categoryChange(event) {
    this.subcategoryID = "";
    this.attributesData1 = [];
    this.category = event.target.value;
    this.mainCategoryName = this.categoryList.filter((item) => {
      if (item._id == this.category) {
        return item;
      }
    });

    this.selectCategoryName = this.mainCategoryName[0]?.name.split(' ').filter(s => s).join(' ');
    console.log(this.selectCategoryName, " this.selectCategoryName")
    setTimeout(() => {
      this.showFilterByCategoriesName();
    }, 100);


    this.getindex(this.category);
    this.getSubCategories()
  }
  showFilterByCategoriesName() {
    switch (this.selectCategoryName) {
      case 'Chicken Meat': {
        this.hideMoq = false;
        //this.hideRating = false;
        break;
      }
      case 'Skilled Manpower': {
        this.hideMoq = false;
        break;
      }
      case 'Littre & Manure': {
        this.hideMoq = false;
        break;
      }
      case 'Cold Store': {
        this.hideMoq = false;
        break;
      }
      case 'Chicken Processing Cutting Services': {
        this.hideMoq = false;
        break;
      }
      case 'Doctor Consultancy': {
        this.hideMoq = false;
        break;
      }
      case 'Lease/Sale/Rent Services': {
        this.hideMoq = false;
        break;
      }
      default: {
        this.hideMoq = true;
      }

    }
  }

  onChange(e) {
    let checkID = e.target.checked
    if (checkID) {
      for (let i = 0; i < this.userType1.length; i++) {
        this.userType1[i].checkbox = false;
        console.log(this.userType1[i].checkbox)
        if (this.userType1[i].id == e.target.id) {
          this.userType1[i].checkbox = true
        }
        console.log(this.userType1[i].checkbox)
      }
      this.userType = e.target.id;
      console.log(this.userType)
      this.getindex(this.category);
    } else {
      this.userType = "";
      console.log(this.userType)
      this.getindex(this.category);
    }
  }
  onChange1(e) {
    let checkID = e.target.checked
    if (checkID) {
      for (let i = 0; i < this.verifylist.length; i++) {
        this.verifylist[i].checkbox = false
        if (this.verifylist[i].id == e.target.id) {
          this.verifylist[i].checkbox = true
        }
      }
      this.verifiedStatus = e.target.id;
      this.getindex(this.category);
    }
    else {
      this.verifiedStatus = "";
      this.getindex(this.category);
    }
  }
  onChange2(e) {
    let checkID = e.target.checked
    if (checkID) {
      for (let i = 0; i < this.nearbylist.length; i++) {
        this.nearbylist[i].checkbox = false
        if (this.nearbylist[i].id == e.target.id) {
          this.nearbylist[i].checkbox = true
        }

      }
      this.nearby = e.target.id;
      this.nearby = this.nearby * 1000;
      this.getindex(this.category);
    }
    else {
      this.nearby = 20000000;
      this.getindex(this.category);
    }
  }

  getindex(id) {
    if (id == null) {
      id = '';
    }
    console.log(this.attributesData1, "api")
    let params = new HttpParams();
    params = params.append('latitude', this.latitude);
    params = params.append('longitude', this.longitude);
    if (this.maq) {
      params = params.append('moq', this.maq);
    }
    if (this.maqUnit) {
      params = params.append('moqUnit', this.maqUnit);
    }
    if (this.rating) {
      params = params.append('rating', this.rating);
    }
    if (this.subcategoryID) {
      params = params.append('subCategoryId', this.subcategoryID);
    }
    if (this.userType != '') {
      params = params.append('userType', this.userType);
    }
    if (this.verifiedStatus != '') {
      params = params.append('verifiedStatus', this.verifiedStatus);
    }
    if (this.attributeFilter) {
      params = params.append('attributeData', JSON.stringify(this.attributesData1));
    }
    // if (this.priceType) { 
    //   let abc=this.priceType;
    //   params = params.append('priceType', );
    // }


    params = params.append('deviceToken', this.getDeviceToken());
    params = params.append('categoryId', id);
    params = params.append('platform', 'WEB');
    params = params.append('minDistance', '0');
    params = params.append('priceType', this.priceType ? (this.priceType) : '1');
    params = params.append('limit', this.pagination.limit.toString());
    params = params.append('page', this.pagination.skip.toString());
    params = params.append('nearBy', this.nearby);
    if (this.search.trim())
      params = params.append('search', (this.search.trim()) ? (this.search.trim()) : undefined);

    this.IS.submitForm(params, apiUrl._posthomePage)
      .subscribe(async (res) => {

        this.pageCount = res.total;
        console.log('listings', res)
        this.NearData = res.data;
        this.attributeFilter = false;
        console.log('url', this.router.url.split('?')[0]);
        if (this.router.url.split('?')[0] == '/Buyerlisting/' + id) {
          if (this.search.trim() && this.encrypted)
            this.location.go("/Buyerlisting/" + id + "?searchText=" + this.encrypted);
        }

        if (this.router.url.split('?')[0] == '/buyerlistingSearch') {
          if (this.search.trim())
            this.encrypted1 = CryptoJS.AES.encrypt(this.search, this.secretCode);
          this.location.replaceState("/buyerlistingSearch" + "?searchText=" + this.encrypted1);
        }
      })


  }



  myprofile() {
    console.log("buyer")
    this.router.navigate(['myprofile']);
  }
  BuyerProductDetail(id) {
    localStorage.setItem('buyerCategory', this.category);
    this.router.navigate(['Buyerlisting/ProductDetail/' + id]);
  }
  searchFun() {
    this.currentPage = 1;
    this.pagination.skip = 1;
    this.encrypted = CryptoJS.AES.encrypt(this.search, this.secretCode);
    console.log('encrypted', this.encrypted.toString());
    //   console.log('data encript', this.search);
    //   let encrptedString =   this.encryptUsingAES256(this.search);
    //   console.log('data encript', String(encrptedString))
    // localStorage.setItem('data encript', encrptedString.toString());

    //   let decryptedString =  this.decryptUsingAES256(this.search)
    //   console.log('data dencript', decryptedString);
    this.getindex(this.category);
    this.closeSearch = true;
    this.closeSearchicon = false;

  }


  refreshPage() {
    this.pagination.skip = 1;
    this.currentPage = 1;
    this.closeSearch = false;
    this.closeSearchicon = true;
    this.search = '';
    this.getindex(this.category);

  }
  eventHandler(event) {
    if (event.target.value.length == this.specificLength && (event.code == "Backspace" || event.code == "Delete")) {
      this.refreshPage();
    }

    return true;
  }

  /*pagination */
  limitRecordChange(event) {
    let limit = 0;
    this.limitRecordList.map((item) => { if (item.key == event.target.value) { limit = item.value; } });
    this.currentPage = 1;
    this.pagination.limit = limit;
    this.pagination.skip = 1;
    this.pagination.totalItems = null;
    this.pagination.maxSize = CONSTANT.MAX_SIZE;
    this.getindex(this.category);
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.pagination.skip = (event.page - 1) + 1;
    this.getindex(this.category);
  }
  openSidebar(value) {
    if (value == 1) {
      this.slideCheck = 1;
    } else {
      this.slideCheck = 0;
    }
  }

  //get sub category id
  getSubCategories() {
    let params = new HttpParams();
    params = params.append('categoryId', this.category);
    this.IS.getList(params, apiUrl._getSubCategories)
      .subscribe(res => {
        this.subCategoryList = res.data
        console.log(res.data, "res.data ")
        this.attributesList1 = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i]._id == this.category) {
            //this.attributesList1 = res.data[i].subCategory.attributesData;
            const attribute1 = res.data[i].subCategory
            console.log(attribute1, "attribute1")
            for (let a = 0; a < attribute1.attributesData.length; a++) {
              if (attribute1.attributesData[a].filterType == 1) {
                this.attributesList1.push(attribute1.attributesData[a]);
                for (let i = 0; i < this.attributesList1.length; i++) {
                  this.attributesData1.push({ 'attrId': this.attributesList1[i]._id, 'attrName': this.attributesList1[i].name, 'traitId': this.attributesList1[i].fieldType == 0 ? this.attributesList1[i].traitsData[0]._id : '', 'traitName': this.attributesList1[i].fieldType == 0 ? this.attributesList1[i].traitsData[0].traitName : '' });
                }

              }
            }
            return false
          }
        }
        console.log(this.attributesData1, " this.attributesData1")
        console.log(this.attributesList1, "this.attributesList1, ")


      },
        err => {
          console.log("error", err)

        });
  }
  /*get location*/
  getAddress(latitude, longitude) {
    console.log('map', this.gMap);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        console.log('ch', results[0].formatted_address);
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          console.log('adasd', this.address);
          this.getindex(this.category);

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  filterByRating(e) {
    this.rating = e.target.value;
    this.getindex(this.category);
  }
  filterByMaq(e) {
    this.getindex(this.category);

  }
  selectmoqUnit(e) {
    this.maqUnit = e.target.value;
    this.getindex(this.category);
  }

  // subcategoryChange(event) {
  //   this.subcategoryID = event.target.value
  //   this.getindex(this.category);
  // }
  selectPriceType(e) {
    this.priceType = e;
    this.getindex(this.category);
  }



  valueset(int, event) {
    this.attributeFilter = true;
    this.attributesData1[int].traitName = event.target.value;
    if (this.attributeFilter) {
      this.getindex(this.category);
    }
  }

  traitChange(event, int) {
    for (let i = 0; i < this.attributesList1[int].traitsData.length; i++) {
      console.log(i, "i======")
      if (event.target.value == this.attributesList1[int].traitsData[i]._id) {
        this.attributesData1[int].traitId = this.attributesList1[int].traitsData[i]._id;
        this.attributesData1[int].traitName = this.attributesList1[int].traitsData[i].traitName;
        console.log(this.attributesData1[int].traitName)
        console.log(this.attributesData1, "this.attributesData1")
        this.attributeFilter = true;
        this.getindex(this.category);
      }
    }
  }
  subCategoryChange(event) {
    console.log(event.target.value, "select sub catiogr==")
    this.subcategoryID = event.target.value;
    this.maq = "";
    this.maqUnit = "";
    this.rating = "";

    this.getindex(this.category);
    let subCategory = this.subCategoryList.find(function (o) {
      if (o.subCategory && o.subCategory._id == '' + event.target.value) {
        return o;
      }
    });
    // this.subCategoryName = subCategory.subCategory.name;
    this.attributesData = [];
    this.attributesList1 = [];
    console.log(this.subCategoryList, " this.subCategoryList====")
    for (let i = 0; i < this.subCategoryList.length; i++) {
      if (event.target.value == this.subCategoryList[i].subCategory._id) {
        this.moqUnitData = this.subCategoryList[i].subCategory.moqUnitData;
        this.maq = "";
        console.log(this.subCategoryList[i])
        var attribute1 = this.subCategoryList[i].subCategory
        for (let i = 0; i < attribute1.attributesData.length; i++) {
          if (attribute1.attributesData[i].filterType == 1) {
            this.attributesList1.push(attribute1.attributesData[i]);
            for (let i = 0; i < this.attributesList1.length; i++) {
              console.log(i, "i======")
              this.attributesData1.push({
                'attrId': this.attributesList1[i]._id,
                'attrName': this.attributesList1[i].name,
                'traitId': this.attributesList1[i].fieldType == 0 ? this.attributesList1[i].traitsData[0]._id : '',
                'traitName': this.attributesList1[i].fieldType == 0 ? this.attributesList1[i].traitsData[0].traitName : ''
              });
            }
          }
        }
      }
    }

    // for (let i = 0; i < this.subCategoryList.length; i++) {
    //   if (this.subCategoryList[i].subCategory._id == this.subcategoryID) {

    //   }
    // }
    console.log(this.moqUnitData, "this.moqUnitData=====")

  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fireBaseToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    }
    else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem('fireBaseToken')
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  getDistanceFromLatLonInKm(e) {
    const lat1 = Number(this.latitude);
    const lon1 = Number(this.longitude);
    const lat2 = e[1]
    const lon2 = e[0]
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
}
