<body class="order_flow_page">
    <!-- <app-headerwithoutslick></app-headerwithoutslick> -->
    <app-header></app-header>
    <!-- Main -->
    <main class="main_cont">
        <div class="btn-group innerDropmanu" dropdown>
            <div class="layerBg"></div>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                {{myProfile}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="ChangeState('Basic info')">Basic Info </a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="ChangeState('Manage Profile')">{{'homePage.buyer.manageProfile' | translate}}</a></li>

                <li role="menuitem"><a class="dropdown-item" (click)="ChangeState('Change Password')">{{'homePage.buyer.changePin' | translate}}</a>
                </li>
                <li role="menuitem">
                    <a (click)="ChangeState('Push Notification')">Push
                        Notifications</a>
                </li>
                <li role="menuitem"> <a class="dropdown-item" (click)="ChangeState('Refer a friend')">
                        {{'homePage.buyer.referaFriend' | translate}}</a></li>

                <li role="menuitem"> <a class="dropdown-item" (click)="ChangeState('Conatct Support')">{{'homePage.buyer.contactSupport' | translate}}</a>
                </li>
                <li role="menuitem"> <a class="dropdown-item" (click)="ChangeState('Faq')">{{'homePage.buyer.Faq' |
                        translate}}</a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"> <a class="dropdown-item" (click)="openModule(loginModal17)" data-target="#loginModal17"> {{'homePage.buyer.Logout' | translate}}</a></li>
            </ul>
        </div>
        <div class="clear"></div>
        <!-- Pending Orders -->
        <section class="pending_orders confirm_ordr chat_box_page my_profl">
            <div class="sidebar_order" id="sidenav" [ngClass]="{
                'activeSlidebar':slideCheck == 1}">
                <div class="togg_btn_sidebr">
                    <button class="side_tog" type="button" data-toggle="" data-target="" aria-controls="" aria-expanded="false" aria-label="Toggle navigation" (click)=openSidebar(1)>
                        <span class="fa fa-list"></span>
                    </button>
                </div>
                <ul class="side_nav">
                    <li>
                        <a (click)="ChangeState('Basic info')" class="{{myProfile == 'Basic info' ? 'active-cls':''}}"><img class="cat_im" src="./assets/images/pro1.svg"> Basic Info</a>
                    </li>
                    <!-- <li>
                        <a (click)="ChangeState('submit-req')" class="{{myProfile == 'submit-req' ? 'active-cls':''}}"><img class="cat_im" src="./assets/images/pro2.svg">Submit Requirement</a>
                    </li> -->

                    <!-- <li>
                        <a (click)="ChangeState('Subscriptions')" class="{{myProfile == 'Subscriptions' ? 'active-cls':''}}"><img class="cat_im" src="./assets/images/pro2.svg"> Subscriptions & Plans </a>
                    </li> -->

                    <li class="main_m">
                        <a class="" data-toggle="collapse" href="#collapseExample" class="{{myProfile == 'Manage Profile' || myProfile == 'Change Password' || myProfile == 'Push Notification' || myProfile == 'Refer a friend' || myProfile == 'Pricing' ? 'active-cls active open_drp collapsed':'active open_drp collapsed'}}"
                            role="button" aria-expanded="false" aria-controls="collapseExample"><img class="cat_im" src="./assets/images/pro3.svg"> {{'homePage.buyer.general' | translate}}</a>
                        <div id="collapseExample" class="main_drp collapse">
                            <ul class="nav nav-tabs drop_menu" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="{{myProfile == 'Manage Profile' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Manage Profile')"> {{'homePage.buyer.manageProfile' |
                                        translate}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="{{myProfile == 'Change Password' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Change Password')">{{'homePage.buyer.changePin' |
                                        translate}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="{{myProfile == 'Push Notification' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Push Notification')" role="tab">Push
                                        Notifications</a>
                                </li>
                                <li class="nav-item">
                                    <a class="{{myProfile == 'Refer a friend' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Refer a friend')"> {{'homePage.buyer.referaFriend' |
                                        translate}}</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="{{myProfile == 'Pricing' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Pricing')">{{'homePage.buyer.rateandTrends' | translate}}</a>
                                </li> -->
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a data-toggle="collapse" href="#collapseExample2" class="{{myProfile == 'Conatct Support' || myProfile == 'Faq' ? 'active-cls active open_drp collapsed':'active open_drp collapsed'}}" role="button" aria-expanded="false" aria-controls="collapseExample"><img class="cat_im" src="./assets/images/pro4.svg"> {{'homePage.buyer.Support' | translate}} {{'homePage.buyer.Buying' | translate}}</a>
                        <div id="collapseExample2" class="main_drp collapse">
                            <ul class="nav nav-tabs drop_menu" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="{{myProfile == 'Conatct Support' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Conatct Support')">{{'homePage.buyer.contactSupport' |
                                        translate}}t</a>
                                </li>
                                <li class="nav-item">
                                    <a class="{{myProfile == 'Faq' ? 'nav-link active':'nav-link'}}" (click)="ChangeState('Faq')">{{'homePage.buyer.Faq' | translate}}</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a data-toggle="modal" (click)="openModule(loginModal17)" data-target="#loginModal17"><img class="cat_im" src="./assets/images/pro5.svg"> {{'homePage.buyer.Logout' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="content_sidebr">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                    <div *ngIf="myProfile =='Basic info'" class="chat_left_usr">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>My Profile</h4>

                                            </div>
                                        </div>
                                        <div class="row mr_50">
                                            <div class="col-12">
                                                <div class="prof_dtl_usr sell_near myprofile_cont">
                                                    <div class="dtl_left_sd">
                                                        <span class="pointer profileimg" *ngFor="let image of album; let i=index">
                                                            <img *ngIf="album.length != 0" class="us_pro"
                                                                src="{{image.src? image.src : './assets/images/default.jpg'}}"
                                                                (click)="openImage(i)">
                                                        </span>
                                                        <span class="profileimg" *ngIf="album.length == 0">
                                                            <img class="us_pro"
                                                                src="./assets/images/default.jpg"></span>
                                                        <div class="detail_pro">
                                                            <h5>{{allData?.name}}</h5>
                                                            <h6>{{allData?.businessType}}
                                                                <img *ngIf="allData?.businessType" class="veri_img" src="./assets/images/checked.svg">
                                                            </h6>
                                                            <div class="approved_s" *ngIf="allData?.businessVerified==true">
                                                                <span class="code_red">
                                                                    {{allData.userUniqueId}}</span><span class="verify"><i
                                                                        class="fa fa-check"></i>{{allData?.businessVerified==true
                                                                    ? 'Verified':'Unverified'}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="dtl_right_sd spc">
                                                        <ul>
                                                            <li><img src="./assets/images/ph.svg"> {{allData?.phoneNumber}}
                                                            </li>
                                                            <li *ngIf="allData?.businessAddress?.addressLine1"><img src="./assets/images/mp.svg" class="mapIcons"> {{allData?.businessAddress?.addressLine1}}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- <a class="edit_prof" (click)="edit(Editprofile)"><img
                                                            src="./assets/images/edit.svg"></a> -->
                                                </div>
                                            </div>
                                            <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-5">
                                                <div class="prof_dtl_usr sell_near point_ern_coup">
                                                    <div class="points_tot">
                                                        <h5>Total earned points <strong> 
                                                                {{allData?.referralPoint}}</strong></h5>
                                                        <a (click)="ChangeState('Refer a friend')" class="m_btn_red btn_red color_white">Redeem Now</a>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-6 refer_fld_mrgn">
                                                <h4>{{'homePage.buyer.referaFriend' | translate}}</h4>
                                                <p class="m-0">If you love our services, do invite your friends to join us, here is your customized referral link</p>
                                                <p class="m-0">Share this code</p>
                                                <div class="code_text refer_fld_mrgn">
                                                    <span><input type="text" name="" value="{{allData?.referralCode}}"
                                                            placeholder="XXXXXX2573" readonly><img
                                                            (click)="copy(allData.referralCode)" class="code_copy"
                                                            src="./assets/images/copy.svg">
                                                        <button class="m_btn_red btn_red"
                                                            (click)="shareOpen(share)">Share</button>
                                                        <!-- <share-button button="sms" text="Share" class="btn_red"  [showIcon]="false" [showText]="true"></share-button> -->
                                                    </span>

                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6">
                                                <h4>Preferred Language</h4>
                                                <div class="lang_pref mr_50">
                                                    <span class="slt_wrap">
                                                        <select (change)="selectLanguage($event)"
                                                            [(ngModel)]="selectedValue">
                                                            <option value="EN">English</option>
                                                            <option value="HI">Hindi</option>
                                                            <option value="BN">Bengali</option>
                                                            <option value="MR">Marathi</option>
                                                            <option value="TA">Tamil</option>
                                                            <option value="TE">Telugu</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </div>




                                        </div>



                                    </div>
                                    <div *ngIf="myProfile =='Subscriptions'" class="chat_left_usr">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>My Subscription</h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-11 col-lg-12 col-md-12">
                                                <div class="my_subscrip">
                                                    <div class="current_pln">
                                                        <div class="row plan_dt mr_50" *ngIf="plandetails?.expiredPlan != null">
                                                            <div class="col-lg-6">
                                                                <h5>{{plandetails?.userPlan?.planType}}</h5>
                                                                <p>{{'homePage.buyer.nextbilling' | translate}}: {{plandetails?.expiredPlan?.planExpiredDate | date : "MMMM d, y"}}
                                                                </p>
                                                                <p>Limit: {{plandetails?.expiredPlan?.planQauantity}}
                                                                </p>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <!-- <a class="btn_grad">Cutomized Plan</a> -->
                                                            </div>
                                                        </div>
                                                        <div class="row plan_dt">
                                                            <div class="col-lg-6">
                                                                <!-- <h4>Valid for 1 month</h4> -->
                                                                <a class="m_btn_red btn_red" (click)="categoryPopup(choiceCategory)">{{plandetails?.expiredPlan
                                                                    == null? 'Add' : ('homePage.buyer.upgradePlan' |
                                                                    translate) }} </a>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <!-- <strong><i class="fa fa-rupee"></i> 1999</strong> -->
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="bill_hist">
                                                        <h4>{{'homePage.buyer.planHistory' | translate}}</h4>
                                                        <table class="table" *ngIf="paymentListing?.length != 0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">{{'homePage.buyer.Date' | translate}}
                                                                    </th>
                                                                    <th scope="col">Plan Name</th>
                                                                    <th scope="col">Category</th>
                                                                    <th scope="col">Valid Till</th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngFor="let paymentlists  of paymentListing">

                                                                    <tr *ngFor="let paymentlist  of paymentlists.planData">

                                                                        <td> {{paymentlist?.createdAt ? (paymentlist?.createdAt | date : "MMMM d, y") : '--'}}
                                                                        </td>
                                                                        <td>{{paymentlist?.planName ? (paymentlist?.planName) : '--'}}</td>
                                                                        <td>{{paymentlist?.categoryName ? (paymentlist?.categoryName) : '--'}}</td>
                                                                        <td>{{paymentlist?.planExpiredDate ? (paymentlist?.planExpiredDate | date : "MMMM d, y") : '--'}}</td>
                                                                        <td class="text-success" [ngClass]="{'text-danger': paymentlist?.status == 1}">
                                                                            {{paymentlist?.status == 1? 'Active' :'Expired' ? (paymentlist?.status == 1? 'Active' :'Expired' ) : '--'}}</td>
                                                                        <td><strong><i class="fa fa-rupee"></i>
                                                                                {{paymentlist?.amount ?
                                                                                (paymentlist?.amount) : '--'}}</strong>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                        <div class="No_found" *ngIf="paymentListing?.length == 0">
                                                            <span class="norecordIcon"></span> No Data Found !
                                                        </div>
                                                        <div class="new-pagination-wrapper" *ngIf="pageCount1 != 0">
                                                            <div class="pagination-wrapper">
                                                                <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage" [boundaryLinks]="true" [maxSize]="pagination.maxSize" [itemsPerPage]="pagination.limit" previousText=" Prv" nextText="Next" firstText="First" lastText="Last" [rotate]="true" [totalItems]="pageCount1"></pagination>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="myProfile =='Manage Profile'" class="chat_left_usr manage_profl" style="width: 100%;">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>{{'homePage.buyer.manageProfile' | translate}}</h4>
                                            </div>
                                        </div>
                                        <div class="row mr_50">
                                            <div class="col-lg-6 col-md-6 col-sm-6 ">
                                                <div class="prof_dtl_usr sell_near">
                                                    <h6>{{'homePage.buyer.personalProfile' | translate}}</h6>
                                                    <div class="dtl_left_sd">
                                                        <span class="pointer" *ngFor="let image of album; let i=index">
                                                            <img *ngIf="album.length != 0" class="us_pro"
                                                                src="{{image.src? image.src : './assets/images/default.jpg'}}"
                                                                (click)="openImage(i)">
                                                        </span>
                                                        <span *ngIf="album.length == 0" class="pointer">
                                                            <img class="us_pro"
                                                                src="./assets/images/default.jpg"></span>
                                                        <div class="detail_pro">
                                                            <h5><span class="charIimit">{{allData?.name?
                                                                    allData?.name:'New User'}}</span>
                                                                <a class="edit_prof" (click)="edit(Editprofile)"><img src="./assets/images/edit.svg"></a>
                                                            </h5>

                                                            <div class="approved_s">
                                                                <!-- <span class="code_red">{{allData?.userUniqueId}}</span> -->
                                                                <span class="verify warning" *ngIf="allData?.businessVerified!=true">
                                                                    {{'homePage.buyer.unverified' | translate}}</span>
                                                                <span class="verify" *ngIf="allData?.businessVerified==true"><i
                                                                        class="fa fa-check"></i>
                                                                    {{'homePage.buyer.verified' | translate}}</span>

                                                            </div>
                                                            <div class="dtl_right_sd">
                                                                <ul>
                                                                    <li><img src="./assets/images/ph.svg"> {{allData?.phoneNumber}}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="prof_dtl_usr sell_near">
                                                    <h6>{{'homePage.buyer.businessProfile' | translate}}</h6>
                                                    <div class="dtl_left_sd dtl_left_sd">
                                                        <span class="pointer">
                                                            <img class="us_pro" src="./assets/images/icn.svg">
                                                        </span>
                                                        <!-- src="{{urlFaq + 'users/' +allData?.businessDocuments && allData.businessDocuments[0] ? urlFaq + 'users/' +allData?.businessDocuments[0]: './assets/images/default.jpg'}}"> -->
                                                        <div class="detail_pro">
                                                            <h5><span class="charIimit">{{allData?.businessName ?
                                                                    allData.businessName:'-'}}</span>
                                                                <a *ngIf="allData?.businessName" class="edit_prof" (click)="editbusiness(loginModal12,'edit')"><img src="./assets/images/edit.svg"></a>
                                                                <a *ngIf="!allData?.businessName" class="edit_prof" (click)="editbusiness(loginModal12,'add')"><img src="./assets/images/edit.svg"></a>
                                                            </h5>

                                                            <div class="approved_s">
                                                                <span class="code_red">
                                                                    {{allData?.userUniqueId ?
                                                                    (allData?.userUniqueId):''}}
                                                                </span>
                                                                <span class="verify" *ngIf="allData?.businessVerified==true">
                                                                    <i class="fa fa-check"></i>Verified </span>
                                                                <span *ngIf="allData?.businessName" style="cursor: pointer;" (click)="editbusiness(loginModal12,'edit')">
                                                                    <span class="verify warning"
                                                                        *ngIf="allData?.businessVerified==false">Unverified</span>
                                                                </span>
                                                                <span *ngIf="!allData?.businessName" style="cursor: pointer;" (click)="editbusiness(loginModal12,'add')">
                                                                    <span class="verify warning"
                                                                        *ngIf="allData?.businessVerified==false">
                                                                        UnVerified
                                                                    </span>
                                                                </span>
                                                                <span><i class="fa fa-star"></i> {{allData?.userRating
                                                                    == null? '0' : allData?.userRating|
                                                                    number:'1.1-1'}}</span>
                                                            </div>
                                                            <div class="dtl_right_sd">
                                                                <ul>
                                                                    <li><img src="./assets/images/ph.svg"> {{allData?.phoneNumber}}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div *ngIf="myProfile =='Change Password'" class="chat_left_usr manage_profl chng_pin_cd" style="width: 100%;">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>{{'homePage.buyer.changePin' | translate}}</h4>
                                            </div>
                                        </div>
                                        <div class="row mr_50">
                                            <div class="col-xl-6 col-lg-8 col-md-8 col-sm-7">
                                                <div class="change_p">
                                                    <div class="form_cont">
                                                        <label for="exampleInputPassword1">{{'homePage.buyer.enterOldpin' |
                                                            translate}}</label>
                                                        <input type="password" class="form-control" value="{{oldpwd}}" mask="000000" (keyup)="change($event,1)" id="exampleInputPassword1" placeholder="******">
                                                        <div class="text-danger" *ngIf="showError1">
                                                            *Please enter pin
                                                        </div>
                                                        <div class="text-danger" *ngIf="showErrormatch11">
                                                            *Pin should be 4 or 6 digits long.
                                                        </div>
                                                    </div>
                                                    <div class="form_cont">
                                                        <label for="exampleInputPassword1">{{'homePage.buyer.enterNewpin' |
                                                            translate}}</label>
                                                        <input type="password" class="form-control" value="{{newpwd1}}" mask="000000" (keyup)="change($event,2)" id="exampleInputPassword1" placeholder="******">
                                                        <div class="text-danger" *ngIf="showError2">
                                                            *Please enter pin
                                                        </div>
                                                        <div class="text-danger" *ngIf="showErrormatch21">
                                                            *Pin should be 4 or 6 digits long.
                                                        </div>

                                                    </div>
                                                    <div class="form_cont">
                                                        <label for="exampleInputPassword1">Enter confirm pin</label>
                                                        <input type="password" class="form-control" value="{{newpwd2}}" mask="000000" (keyup)="change($event,3)" id="exampleInputPassword1" placeholder="******">
                                                        <div class="text-danger" *ngIf="showError3">
                                                            *Please enter pin
                                                        </div>
                                                        <div class="text-danger" *ngIf="showError">
                                                            *New pin and confirm pin does not match.
                                                        </div>

                                                    </div>
                                                    <!-- <p class="frgt_p">Forgot Password? <a> Reset</a></p> -->
                                                    <a class="m_btn_red btn_red mt-4" (click)="changepassword()">Confirm</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="myProfile =='Push Notification'" class="chat_left_usr manage_profl">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>Push Notifications</h4>
                                            </div>
                                        </div>
                                        <div class="row mr_50">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="change_p push_noti">
                                                    <div class="form_cont">
                                                        <p>Push Notifications</p>
                                                        <div class="btn_on_off">
                                                            <label class="switch">
                                                                <input type="checkbox"
                                                                    [checked]="onNotification==0? true:false "
                                                                    (change)="applyNofification($event)">
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="form_cont">
                                                        <p>New Messages</p>
                                                        <div class="btn_on_off">
                                                            <label class="switch">
                                                                <input type="checkbox">
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div> -->
                                                    <div class="form_cont">
                                                        <p>Plans validity expiring reminders</p>
                                                        <div class="btn_on_off">
                                                            <label class="switch">
                                                                <input type="checkbox"
                                                                    [checked]="planExpirenotification == 0 ? (true):false"
                                                                    (change)="applyplanNofification($event)">
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="form_cont">
                                                        <p>Transactions success & Failures</p>
                                                        <div class="btn_on_off">
                                                            <label class="switch">
                                                                <input type="checkbox">
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div> -->
                                                    <a href="javascript:;" (click)="updateNotification()" class="m_btn_red btn_red">Save</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="myProfile=='Refer a friend'" class="chat_left_usr">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- <h4>Earned Points</h4> -->
                                            </div>
                                        </div>
                                        <div class="row refer_frnd">
                                            <!-- <div class="col-xl-4 col-lg-5 col-md-7 col-sm-5">
                                                <div class="prof_dtl_usr sell_near">
                                                    <div class="points_tot">
                                                        <h5>Total earned points <strong>
                                                                {{allData?.referralPoint}}</strong></h5>
                                                        <a class="m_btn_red btn_red color_white" (click)="openModule(RefferFriend)" data-toggle="modal" data-target="#loginModal18">Redeem Now</a>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-xl-8 col-lg-7 col-md-12 col-sm-7">
                                                <div class="code_text pt-0 refer_fld_mrgn earned_point_refer">
                                                    <h5 class="m-0">{{'homePage.buyer.referaFriend' | translate}}</h5>
                                                    <p class="m-0">If you love our services, do invite your friends to join us, here is your customized referral link</p>
                                                    <p class="m-0">Share this code</p>
                                                    <span><input type="text" name="" value="{{allData?.referralCode}}"
                                                            placeholder="XXXXXX2573" readonly><img
                                                            (click)="copy(allData.referralCode)" class="code_copy"
                                                            src="./assets/images/copy.svg">
                                                        <button class="m_btn_red btn_red"
                                                            (click)="shareOpen(share)">Share</button>
                                                        <!-- <share-button button="sms" text="Share" class="btn_red" [showIcon]="false" [showText]="true"></share-button> -->

                                                    </span>
                                                </div>
                                            </div>
                                            <img class="hen_ref" src="./assets/images/hn.png">
                                        </div>
                                        <div class="row coupon_list">
                                            <div class="col-xl-7 col-lg-9">
                                                <!-- <ul *ngFor="let opt of allData.onetimeFee; let i = index;">
                                                    <li>#PB22ABC</li>
                                                    <li>Parveen</li>
                                                    <li>{{opt.amount}} Points</li>
                                                </ul> -->
                                                <ul *ngFor="let opt of redeemdata; let i = index;">
                                                    <li>{{opt?.type == 1? 'Redeem Request' : 'Referral'}}

                                                    </li>
                                                    <li *ngFor="let data of opt.ortherData">{{data?.name}}</li>
                                                    <li>
                                                        <span class="green" [ngClass]="{'red': opt?.type == 1 }">{{opt?.type == 1? '-' :
                                                            '+'}} {{opt.point}} Points</span>
                                                        <i class="next">{{(opt?.type == 1 && opt?.status == false)?
                                                            'Pending' : opt?.type == 0 ? '' : 'Approved' }}</i>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="myProfile=='submit-req'" class="chat_left_usr">
                                      
                                        <h4> </h4>
                                        <app-submit-requirement></app-submit-requirement>
                                    </div> -->
                                    <div *ngIf="myProfile=='Conatct Support'" class="chat_left_usr">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h4>{{'homePage.buyer.contactSupport1' | translate}}</h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-11 col-lg-12 col-md-12">
                                                <div class="tech_support">
                                                    <h5>{{'homePage.buyer.assistYou' | translate}} </h5>
                                                    <div class="form_cont">
                                                        <label> {{'homePage.buyer.requestType' | translate}}</label>
                                                        <!-- <input type="text" name="" value="{{supportType}}"
                                                            (keyup)="suportkey($event,1)" name="" placeholder="Account"> -->
                                                        <span class="slt_wrap">
                                                            <select [(ngModel)]="supportType">
                                                                <option value="" selected disabled hidden>
                                                                    Select Options
                                                                </option>
                                                                <option *ngFor="let sportName of sportType" [selected]>
                                                                    {{sportName?.name}}</option>
                                                            </select>
                                                        </span>
                                                        <div class="text-danger" *ngIf="showErrorsupport">
                                                            *Please Enter Request Type.
                                                        </div>
                                                    </div>

                                                    <div class="form_cont">
                                                        <label> {{'homePage.buyer.message' | translate}}</label>
                                                        <textarea rows="4" cols="50" value="{{description}}" (keyup)="suportkey($event,2)" name="comment" form="usrform" placeholder="Message here.."></textarea>
                                                        <div class="text-danger" *ngIf="showErrorsupport1">
                                                            *Please Enter Message.
                                                        </div>
                                                    </div>
                                                    <div class="form_cont">
                                                        <ul>
                                                            <li>
                                                                <a class="inputcss">
                                                                    <span *ngIf="imageUpload3.length==1" class="float-right hand delete" (click)="delete1(1)"><img
                                                                            src="./assets/images/cls.svg"></span>
                                                                    <input *ngIf="imageUpload3.length==0" type="file" name="file" id="file-upload" class="" (change)="uploadSupport($event,1)" accept=".png, .jpg, .jpeg" />
                                                                    <img src="{{imageUpload3.length==1 ?imageUpload3[0].image:'./assets/images/ad.svg'}}">
                                                                </a><br>
                                                                <span *ngIf="imageUpload3.length==1"></span>
                                                                <span>{{'homePage.buyer.uploadImage' |
                                                                    translate}}</span>
                                                            </li>
                                                            <li>
                                                                <a class="inputcss1">
                                                                    <span *ngIf="imageUpload4.length==1" class="float-right hand delete" (click)="delete1(2)"><img
                                                                            src="./assets/images/cls.svg"></span>
                                                                    <input *ngIf="imageUpload4.length==0" type="file" name="file" id="file-upload" class="" (change)="uploadSupport($event,2)" accept=".png, .jpg, .jpeg" />
                                                                    <img src="{{imageUpload4.length==1 ?imageUpload4[0].image:'./assets/images/ad.svg'}}">

                                                                    <!-- <span *ngIf="imageUpload4.length==1">{{imageUpload4[0].string}}</span> -->
                                                                </a>
                                                                <br>
                                                                <span *ngIf="imageUpload4.length==1"></span>
                                                                <span>{{'homePage.buyer.tapAddmore' | translate}}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="form_cont">
                                                        <a class="m_btn_red btn_red" (click)="support()">{{'homePage.buyer.Submit' |
                                                            translate}}</a>
                                                        <!-- <a class="m_btn_red btn_red btn_brdr" (click)="supportcancel('Basic info')">Cancel</a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="myProfile=='Faq'" class="faq_page">
                                        <div class="accordion" id="faq">
                                            <div class="card" *ngFor="let data of faqlist; let i = index;">
                                                <div class="card-header" id="{{'faq' + i}}" (click)="faqcss(i)">
                                                    <a class="{{i == indexvalue ? 'btn btn-header-link collapsed' : 'btn btn-header-link'}}" data-toggle="collapse" data-target="{{'#faq' + i}}" aria-expanded="true" aria-controls="faq1">{{data.title}}</a>
                                                </div>
                                                <div id="{{'faq' + i}}" class="{{i == indexvalue?'collapse show':'collapse'}}" aria-labelledby="faqhead1" data-parent="#faq">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-xl-4 col-lg-5">
                                                                <a *ngIf="data?.fileType == 0"><img src="{{urlFaq +'banner/'+ data.fileName}}"></a>
                                                                <a *ngIf="data?.fileType == 1">
                                                                    <video class="imageheight w-100" controls #videoPlayer>
                                                                        <source
                                                                            src="{{urlFaq +'banner/'+ data.fileName}}"
                                                                            type="video/mp4" />
                                                                    </video>
                                                                </a>
                                                            </div>
                                                            <div class="col-xl-8 col-lg-7">
                                                                <p>{{data.description}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</body>
<ng-template #loginModal17>
    <div class="log_pop sign_pop pop_dbl_btn lgout">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h4>{{'homePage.buyer.logoutExit' | translate}}</h4>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="row">
                                <div class="col-6"><button type="button" class="btn btn-info btn-block btn-round btn_red mr-2" (click)="logout()">{{'homePage.buyer.yes' | translate}}</button></div>
                                <div class="col-6"><button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr" style="width: 100%;" (click)="close()">{{'homePage.buyer.no' | translate}}</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #Editprofile>
    <div class="log_pop otp_scrn update_prf">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Update Profile</h2>
                        <p>Verified Profiles would get more deals as compared to uncertified profiles</p>

                    </div>
                    <div class="d-flex flex-column text-center row update_prf">
                        <div class="create_prof">
                            <div class="upld_img upld_fl">

                                <div class="file btn btn-lg btn-primary attach_f">
                                    <img *ngIf="uploadImage == null || uploadImage == undefined || uploadImage == ''" src="./assets/images/upload.png">
                                    <img *ngIf="uploadImage != null && uploadImage != undefined && uploadImage != ''" src="{{urlfile22}}">
                                    <!-- <img *ngIf="uploadImage != null && uploadImage != undefined && uploadImage != ''" src="{{croppedImage}}"> -->
                                    <input type="file" name="file" (change)="upload($event, preview)" accept=".png, .jpg, .jpeg" />


                                </div>
                                <div class="text-danger" *ngIf="showError1">
                                    *Please upload Image.
                                </div>

                            </div>
                            <form [formGroup]="updateProfileForm">
                                <div class="form-group position_rel row">
                                    <label for="fullname" class="col-md-4 col-form-label text-md-right">Full
                                        Name</label>
                                    <div class="col-12 change_no">
                                        <input style="text-transform:  capitalize;" type="text" name="fullname" value="" placeholder="John Williams" formControlName="name" id="fullname" class="form-control">
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('required') && showError">
                                            *Please enter name.
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('minlength') && showError">
                                            *Minimum length of 2 characters
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['name'].hasError('maxlength') && showError">
                                            *Maximum length of 45 characters
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group position_rel row">
                                    <label for="email_address" class="col-md-4 col-form-label text-md-right">E-Mail</label>
                                    <div class="col-12">
                                        <input type="text" value="" placeholder="johnwilliams@gmail.com" formControlName="email" id="email_address" class="form-control" name="email-address" required autofocus>
                                        <!-- <div class="text-danger" *ngIf="updateProfileForm.controls['email'].hasError('required') && showError">
                                        *Please enter email.
                                    </div> -->
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['email'].hasError('pattern') && showError">
                                            *Please enter a valid email.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group position_rel row">
                                    <label for="username" class="col-md-12 col-form-label text-md-right">Phone
                                        Number</label><br>
                                    <div class="col-12">
                                        <!-- <input type="text" formControlName="referredBy" value="" placeholder="John" name="username" id="username" class="form-control" readonly> -->
                                        <input disabled readonly type="text" formControlName="phoneNumber" value="{{allData.phoneNumber}}" placeholder="John" name="username" id="username" class="form-control" readonly>

                                        <div class="text-danger" *ngIf="updateProfileForm.controls['phoneNumber'].hasError('minlength') && showError">
                                            *Minimum length of 2 characters
                                        </div>
                                        <div class="text-danger" *ngIf="updateProfileForm.controls['phoneNumber'].hasError('maxlength') && showError">
                                            *Maximum length of 45 characters
                                        </div>
                                    </div>
                                </div>
                                <!-- <p>Enter valid referral code and you can earn 50 coins on successful first transaction</p> -->
                                <button type="button" class="btn btn-info btn-block btn-round btn_red create_prof" (click)="updateProfile(updateProfileForm)">Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</ng-template>

<ng-template #RefferFriend>
    <div class="log_pop sign_pop pop_dbl_btn">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Redeem Points<br><span>Total {{allData.referralPoint}} </span></h2>

                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group position_rel row">
                                <div class="col-12 change_no">
                                    <input class="form-control" type="" value="{{PointRedeem}}" (keyup)="changeredeem($event)" id="" placeholder="How many points you want to redeem?">
                                    <div class="text-danger" *ngIf="showError4">
                                        *Please Enter Redeem Points
                                    </div>
                                    <div class="text-danger" *ngIf="showError1">
                                        *Redeem Points should be upto 1000
                                    </div>
                                    <small id="" class="form-text text-muted text-left req_msg">Minimum 1000 coins
                                        required for redemption</small>
                                </div>
                            </div>
                            <button type="button" (click)="redeempoint()" class="btn btn-info btn-block btn-round btn_red mr-2" data-toggle="modal" data-target="">Confirm</button>
                            <button type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr " (click)="close()" data-toggle="modal" data-target="">Cancel</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #loginModal12>

    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Enter Basic Information</h2>
                    </div>
                    <div class="d-flex flex-column text-center">
                        <form [formGroup]="busineesForm">
                            <div class="form-group position_rel row">
                                <label for="category">User Type</label>
                                <div class="col-12">
                                    <input *ngIf="editbusinessprofile" type="text" formControlName="userType" readonly disabled>
                                    <span class="slt_wrap" *ngIf="!editbusinessprofile">

                                        <select formControlName="userType" (click)="userTypeSelect($event)">

                                            <option value="">--Select--</option>
                                            <option *ngFor="let opt of getuserlist" [value]="opt.name">{{opt.name}}
                                            </option>
                                        </select>

                                        <div class="text-danger"
                                            *ngIf="busineesForm.controls['userType'].hasError('required') && showError">
                                            *Please select user Type.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="category">Business Type</label>
                                <div class="col-12">
                                    <input *ngIf="editbusinessprofile" type="text" formControlName="userType" readonly disabled>
                                    <span class="slt_wrap" *ngIf="!editbusinessprofile">
                                        <select formControlName="BusinessType" (change)="businessTypeSelect($event)">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let opt of busineesTyps" [value]="opt.name">{{opt.name}}
                                            </option>
                                        </select>
                                        <div class="text-danger"
                                            *ngIf="busineesForm.controls['BusinessType'].hasError('required') && showError">
                                            *Please select Business Type.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="address">{{'homePage.buyer.businessName' | translate}}</label>
                                <div class="col-12">
                                    <input formControlName="BusinessName" type="text" value="" name="" placeholder="John Williams">
                                    <div class="text-danger" *ngIf="busineesForm.controls['BusinessName'].hasError('required') && showError">
                                        *Please enter Business Name.
                                    </div>
                                    <div class="text-danger" *ngIf="busineesForm.controls['BusinessName'].hasError('minlength') && showError">
                                        *Minimum length of 3 characters
                                    </div>
                                    <div class="text-danger" *ngIf="busineesForm.controls['BusinessName'].hasError('maxlength') && showError">
                                        *Maximum length of 45 characters
                                    </div>
                                </div>
                            </div>
                            <div class="profiletxt">Verified Profiles would get more deals as compared to uncertified profiles
                            </div>
                            <button *ngIf="editbusinessprofile" type="button" class="btn btn-info btn-block btn-round btn_red col-12" data-toggle="modal " (click)="open(loginModal20, 2); close()" data-target="#loginModal20">Next</button>

                            <button *ngIf="!editbusinessprofile" type="button" class="btn btn-info btn-block btn-round btn_red col-12" data-toggle="modal" (click)="open(addressType, 1)" data-target="#loginModal9">Next</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #addressType>
    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close handcursul" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                    </div>
                    <div class="d-flex flex-column text-center">
                        <button type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(loginModal91, 4)">Search Address</button>
                        <button type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(loginModal91, 5)">Pick From Map</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<div class="modal fade" bsModal #loginModal91="bs-modal" Manage Profile tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="log_pop otp_scrn submit_acc add_pop map_loc myprfl_page_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="loginModal91.hide()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="busineesForm">
                        <div class="form-group row mar_0" *ngIf="googleMapForm">
                            <div class="col-md-12">
                                <label>Search Business Location</label>
                                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search formControlName="googleAddress">
                            </div>
                        </div>
                        <div class="form-group" *ngIf="googleMapForm">
                            <div class="srch_opt_in">
                                <label for="address">Pincode</label>
                                <input type="number" value="" formControlName="pincode" name="" placeholder="160022">
                                <div class="text-danger" *ngIf="busineesForm.controls['pincode'].hasError('required') && showError ">
                                    *Enter Pincode.
                                </div>
                                <div class="text-danger" *ngIf="busineesForm.controls['pincode'].hasError('required') && showError ">
                                    *Enter Pincode.
                                </div>
                                <div class="text-danger" *ngIf="pinError">
                                    *Please enter valid Pincode.
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!googleMapForm">
                            <div class="row">
                                <div class="col-8"> <input type="text" (keypress)="keyPressNumbers($event)" value="" formControlName="pincode" name="" placeholder="160022"></div>
                                <div class="col-4">
                                    <a href="javascript:;" class="searchpincode btn_red" (click)="googlePinCode()">Search</a>
                                </div>

                            </div>

                        </div>

                        <div class="form-group mar_0" *ngIf="!googleMapForm">
                            <agm-map [latitude]="latitude" [longitude]="longitude">
                                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                            </agm-map>
                        </div>
                        <div class="d-flex flex-column text-center">
                            <div class="form-group position_rel row mar_0" *ngIf="!googleMapForm">
                                <div class="col-xl-6 col-md-12">
                                    <div class="srch_opt_in">
                                        <label for="address">Address Line 1</label>
                                        <input type="text" value="" formControlName="AddressLine1" name="" placeholder="SCO 10, Sector 22 A">
                                        <div class="text-danger" *ngIf="busineesForm.controls['AddressLine1'].hasError('required') && showError">
                                            *Please enter address.
                                        </div>
                                        <div class="text-danger" *ngIf="addressError">
                                            *Please enter valid address.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-12">
                                    <div class="srch_opt_in">
                                        <label for="address">Address Line 2</label>
                                        <input type="text" formControlName="AddressLine2" value="" name="" placeholder="SCO 10, Sector 22 A">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row" *ngIf="!googleMapForm">
                                <div class="col-xl-4 col-md-6 col-sm-6">
                                    <div class="srch_opt_in">
                                        <label for="address">Country</label>
                                        <input type="text" formControlName="Country" value="" name="" placeholder="India">
                                    </div>
                                    <div class="text-danger" *ngIf="countryError">
                                        *Please enter valid Country.
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-6 col-sm-6">
                                    <div class="srch_opt_in">
                                        <label for="address">State</label>
                                        <input type="text" formControlName="state" value="" name="" placeholder="Punjab">
                                    </div>
                                    <div class="text-danger" *ngIf="stateError">
                                        *Please enter valid State.
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-6 col-sm-6 ">
                                    <div class="srch_opt_in">
                                        <label for="address">City</label>
                                        <input type="text" formControlName="city" value="" name="" placeholder="Chandigarh">
                                        <div class="text-danger" *ngIf="busineesForm.controls['city'].hasError('required') && showError ">
                                            *Enter city.
                                        </div>
                                        <div class="text-danger" *ngIf="cityError">
                                            *Please enter valid city.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn_map_bottom row">
                            <div class="col-6"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 mr-2" data-toggle="modal" (click)="Backopen1(addressType); loginModal91.hide()">Previous</button></div>
                            <div class="col-6"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3" data-toggle="modal" (click)="open(loginModal20,2, loginModal91)" data-target="#loginModal20">Next</button></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loginModal20>
    <div class="log_pop otp_scrn submit_acc add_pop doc_upload_pop myprof_doc_up">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">

                        <h2>Enter Basic Information</h2>
                        <p class="m_bottom">Please upload documents (optional)</p>

                    </div>
                    <div class="d-flex flex-column text-center">

                        <form>
                            <div class="form-group position_rel row noMargin">
                                <div class="col-xl-6 col-md-6 col-sm-6">
                                    <div class="bx-upl_fl custom-file-upload inputcss">
                                        <input type="file" name="file" id="file-upload" class="" (change)="upload1($event,1)" *ngIf="uploadImage2 ==''" />

                                        <img src="./assets/images/up-doc.svg" for="file-upload" *ngIf="uploadImage2 ==''">


                                        <span class="float-right hand delete iconRight" (click)="removeImage(2)" *ngIf="uploadImage2 !=''">
                                            <img src="./assets/images/cls.svg">
                                        </span>



                                        <span *ngIf="uploadImage2 !='' && showPdf != 1" for="file-upload">
                                            <a href="{{urlFaq + 'users/' + uploadImage2}}" target="_blank">
                                                <img src="{{urlFaq + 'users/' + uploadImage2}}" for="file-upload">
                                            </a>
                                        </span>
                                        <span *ngIf="showPdf == 1" for="file-upload">
                                            <a href="{{urlFaq + 'users/' + uploadImage2}}" target="_blank">
                                                <img src="./assets/images/icon_File_104851.svg">
                                                <p>Click Here</p>
                                            </a>

                                        </span>

                                        <span *ngIf="uploadImage2 ==''" for="file-upload">Upload Document 1</span>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-sm-6 ">
                                    <div class="bx-upl_fl custom-file-upload inputcss">
                                        <input type="file" name="file" id="file-upload" class="" (change)="upload1($event,2)" *ngIf="uploadImage1 ==''" />
                                        <img src="./assets/images/up-doc.svg" for="file-upload" *ngIf="uploadImage1 ==''">
                                        <span class="float-right hand delete iconRight" (click)="removeImage(1)" *ngIf="uploadImage1 !=''">
                                            <img src="./assets/images/cls.svg">
                                        </span>

                                        <span *ngIf="uploadImage1 !=''  && showPdf1 != 1" for="file-upload">
                                            <a href="{{urlFaq + 'users/' + uploadImage1}}" target="_blank">
                                                <img src="{{urlFaq + 'users/' +uploadImage1}}" for="file-upload">
                                            </a>
                                        </span>
                                        <span *ngIf="showPdf1 == 1" for="file-upload">
                                            <a href="{{urlFaq + 'users/' + uploadImage1}}" target="_blank">
                                                <img src="./assets/images/icon_File_104851.svg">
                                                <p>Click Here</p>
                                            </a>

                                        </span>
                                        <span *ngIf="uploadImage1 ==''" for="file-upload">Upload Document 2</span>
                                    </div>
                                </div>


                                <div class="col-md-12">
                                    <p class="marginTB"><span class="textred ">*Optional</span><br>Upload Your Business/Personal Proof Id to get the <br> <span class="float-left">Verified
                                            Badge.</span></p>
                                </div>
                                <!-- <div class="col-xl-12 col-md-12">
                                    <ul class="doc_list_req">

                                    </ul>
                                </div> -->
                            </div>
                            <div class="use_add"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 mr-2" data-toggle="modal" (click)="Backopen(loginModal91)" data-target="#loginModal20">Previous</button></div>

                            <div class="use_add" *ngIf="busineesadd =='edit'"><button type=" button" class="btn btn-info btn-block btn-round btn_red sign2_step4 " data-toggle="modal " (click)="open(loginModal23,3) " data-target="#loginModal23 ">Update profile</button>
                            </div>
                            <div class="use_add " *ngIf="busineesadd=='add'"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step4" data-toggle="modal" (click)="open(loginModal23,3)" data-target="#loginModal23">Register</button></div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loginModal23></ng-template>
<ng-template #share>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modulecss">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-title text-center">
                    <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                    <h2>{{'homePage.buyer.referaFriend' | translate}}</h2>

                </div>
                <div class="d-flex flex-column text-center">
                    <form>
                        <div class="form-group position_rel row">
                            <div class="col-12 change_no">
                                <input class="form-control" type="" value="{{Phonenum}}" (keyup)="changeredeem1($event)" id="" placeholder="Enter Mobile Number">

                                <div class="text-danger" *ngIf="showError1">
                                    *Please enter valid mobile no.
                                </div>
                            </div>
                        </div>
                        <button type="button" (click)="sendRefer()" class="btn btn-info btn-block btn-round btn_red mr-2" data-toggle="modal" data-target="">Share</button>
                        <button style="width: 100%;" type="button" class="btn btn-info btn-block btn-round btn_red btn_brdr " (click)="close()" data-toggle="modal" data-target="">Cancel</button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</ng-template>


<ng-template #choiceCategory>
    <div class="modal-dialog modal-dialog-centered slt_plan_popup_design" role="document">
        <div class="modal-content modulecss">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body sliders">
                <div class="form-title text-center">
                    <h2>Select Plan</h2>
                </div>
                <div class="d-flex flex-column text-center">
                    <form>
                        <div class="form-group row ">
                            <div class="items" *ngFor="let category of allCategory;let i = index">
                                <li class="item current">

                                    <label class="check_buy">
                                        <span>
                                            <img class="image_80"
                                                src="{{urlFaq+ 'categories/'+ category.image}}"></span><br>{{category?.item}}
                                        <p>{{category?.name}}</p>
                                        <input type="radio" name="checkbox" id={{category?.i}}
                                            (change)="categorySelect($event,  category)">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </div>
                        </div>
                        <button type="button" *ngIf="showButton" class="btn btn-info btn-block btn-round btn_red col-12" data-toggle="modal" data-target="" (click)="subCategory(planselect)">Select Plan</button>

                    </form>
                </div>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #planselect>
    <div class="log_pop sign_pop pop_dbl_btn lgout pro_added_suc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h4>Please update your plan {{categoryName}}</h4>

                    </div>
                    <div class="planList">
                        <ul>
                            <li *ngFor="let plan of plansList?.planData">
                                <label class="check_buy choose_slt_plan_opt" *ngIf="plan.planPrice > 0">
                                    <div class="plan_qnt_txt"> {{plan?.quantity}} <small>Quantity</small></div>
                                    <div class="prc_mnth">
                                        <strong><i class="fa fa-rupee"></i> {{plan?.planPrice}}<small>
                                                {{plan?.subscription}}</small></strong>

                                    </div>
                                    <p> {{plan?.planType}}</p>
                                    <input type="radio" name="checkbox" id={{plan?._id}}
                                        (change)="onlyOneValue($event,  plan)">
                                    <span class="checkmark"></span>
                                </label>

                            </li>


                        </ul>
                        <button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 mr-2" data-toggle="modal" (click)="BackPopup(choiceCategory)">Previous</button>
                        <button type="button" *ngIf="planPaymet != ''" class="btn mr-3 btn-info btn-block btn-round btn_red pay_pln" (click)="payments()">Pay
                            <i class="fa fa-rupee"></i> {{planPaymet}}</button>

                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #paymentPopup>

    <div class="" role="document">
        <div class="modal-content modulecss">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex flex-column text-center">
                    <div class="cutomize_tbl mr_50">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col"></th>
                                    <th scope="col">{{'homePage.buyer.Account' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Yearly Registration Fee</th>
                                    <td></td>
                                    <td>{{allData?.onetimeFee[0]?.amount}}</td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <th scope="row"></th>
                                <td></td>
                                <td><span>Total</span><br><strong><i
                                            class="fa fa-rupee"></i>{{allData?.onetimeFee[0]?.amount}}</strong></td>
                            </tfoot>
                        </table>
                    </div>

                </div>
                <div class="use_add d-flex">
                    <button type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" (click)="onetimePayment()">Next
                    </button>
                </div>

            </div>
        </div>
    </div>


</ng-template>

<!-- modal -->

<ng-template #preview>
    <div class="" role="document">
        <div class="modal-content cropperPopup">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Crop Image</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="croperHide(Editprofile)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" format="png" (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
                <!-- <img [src]="croppedImage" /> -->
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal" (click)="cropImages(Editprofile)">OK</button>
            </div>
        </div>
    </div>
</ng-template>