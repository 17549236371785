import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { JsonPlaceHolderService } from './../../services/json-place-holder.service';
import { Router, ActivatedRoute, NavigationEnd  } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomeService } from './../home-page/home-page.service';

@Component({
  selector: 'app-pending-orders-seller',
  templateUrl: './pending-orders-seller.component.html',
  styleUrls: ['./pending-orders-seller.component.css']
})
export class PendingOrdersSellerComponent implements OnInit {
  value = 'Seller';
  value1 = 'PENDING';
  value2 = 'PENDING';
  pageLoaded: boolean = false;
  userId:any;
  allDatas:any;
  buyerIds:any;
  sellerIds:any;
  currentUserId: any;
  chatDialog: any;
  participantId: any;
  userName: any;
  chatDiologId: any;
  messageList: any;
  dataArr: any;
  userImage: any;
  showChat = 0;
  ratingBuyer =0;
  ratingSeller =0;
  serviceRate =0;
  qualityRate =0;
  commitmentRate =0;
  paymentRate =0;
  packagingRate =0;
  description:any;
  showErrorRating: boolean = false;
  modalRef: BsModalRef;
  isReadonly = false;
  max = 5;
  rate = 0;
  slideCheck =0;
  ratingDatas: any
  config = {
    ignoreBackdropClick: true
  
    };
  constructor(private api:JsonPlaceHolderService, private hs: HomeService,  private router: Router,  private db: AngularFireDatabase, private modalService: BsModalService) {
   
   }

  ngOnInit(): void {
    this.value1 = localStorage.getItem("setDeal")  ? localStorage.getItem("setDeal"): "PENDING";

    // this.value2 = localStorage.getItem("setDeal")  ? localStorage.getItem("setDeal"): "PENDING";

    this.value = localStorage.getItem("setUser")  ? localStorage.getItem("setUser"): "Buyer";

     this.userId = localStorage.getItem('id');
     this.currentUserId = localStorage.getItem('id');
    let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 1];
    console.log(urlnumber)
    if (urlnumber == '2') {
      // this.value = 'Buyer';
    }
    this.dealBuyer(this.userId);




    // if(localStorage.getItem('setUser')  == null || localStorage.getItem('setUser')  == 'null'
    // ){

    //   localStorage.setItem('setUser', 'Buyer');
    //   localStorage.setItem('setDeal', 'PENDING');
    // }else{

    //   this.changeState(localStorage.getItem('setUser'));
    //   this.changeState1(localStorage.getItem('setDeal') ? localStorage.getItem('setDeal'):"PENDING" );
    //   // this.changeState1(localStorage.getItem('setDeal'));
    // }
 
  }

  changeState(state) {
    this.value = state;
    // localStorage.removeItem('setDeal');
    localStorage.setItem('setUser',this.value);
    localStorage.setItem("setDeal","PENDING");
    this.value1 = 'PENDING';
    if(state == 'Seller'){
      this.sellerIds = this.userId;
      this.dealBuyer(this.sellerIds);
      // localStorage.setItem('setDeal','PENDING');
    }else{
      this.buyerIds = this.userId;
      this.dealBuyer(this.buyerIds);
    }
  }
  changeState1(state) {
    if(this.slideCheck == 1 ){
      this.slideCheck = 0;
    }
    this.value1 = state;
    localStorage.setItem('setDeal',this.value1);
    this.dealBuyer(this.userId)
  }


  dealBuyer(buyer) {
    this.pageLoaded = false;
    let params = new HttpParams();

    // params = params.append('buyerId', this.userId);
    params = params.append('status', this.value1);
    console.log('value',this.value);
    if(this.value == 'Buyer') {
      params = params.append('buyerId', buyer);
      params = params.append('sellerId', '');
    }else{
      params = params.append('sellerId', buyer);
      params = params.append('buyerId', '');
    }

    // params = params.append('limit', this.pagination.limit.toString());
    // params = params.append('search', this.search || '');

    this.api.getData(apiUrl._getdealBuyer, params).subscribe((res: any) => {
      this.allDatas = res.data;
      // this.pageCount = res.total;
      console.log('buyer',res);

    },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
      () => {

      })
  }

  ngOnDestroy() {
    localStorage.removeItem('setUser');
    localStorage.removeItem('setDeal');
 }

/*chat redriections */
  createChatDialog(id) {
    const data = {
      id: id,
    }
    let url = apiUrl._getProductDetails;
    this.api.getData(url, data).subscribe(async (res) => {
      var dataArr = res.data[0];
      if (dataArr) {
        if ((res.data.userId) > (this.currentUserId)) {
          var chatDiologId = this.currentUserId + ',' + dataArr.userId + '-' + dataArr._id;
          var participantId = this.currentUserId + ',' + dataArr.userId;
        } else {
          var chatDiologId = dataArr.userId + ',' + this.currentUserId + '-' + dataArr._id;
          var participantId = dataArr.userId + ',' + this.currentUserId;
        }
        var profileArr = {};
        profileArr[dataArr.userId] = dataArr.sellerDetail.profilePic;
        profileArr[this.currentUserId] = this.userImage;

        var userNameArr = {};
        userNameArr[dataArr.userId] = dataArr.sellerDetail.name;
        userNameArr[this.currentUserId] = this.userName;

        var messageTimeArr = {};
        messageTimeArr[dataArr.userId] = new Date().getTime();
        messageTimeArr[this.currentUserId] = new Date().getTime();

        var messageTypeArr = {};
        messageTypeArr[dataArr.userId] = 1;
        messageTypeArr[this.currentUserId] = 1;

        var lastMessageArr = {};
        lastMessageArr[dataArr.userId] = "";
        lastMessageArr[this.currentUserId] = "";
        var deleteTime = {};
        deleteTime[dataArr.userId] = 0;
        deleteTime[this.currentUserId] = 0;

        var blockStatus = {};
        blockStatus[dataArr.userId] = 0;
        blockStatus[this.currentUserId] = 0;

        var unreadMessageArr = {};
        unreadMessageArr[dataArr.userId] = 0;
        unreadMessageArr[this.currentUserId] = 0;

        this.db.list(`Chats`,
          query => query
            .orderByChild("chat_dialog_id")
            .equalTo(chatDiologId)
            .limitToLast(100)
        ).valueChanges().subscribe(list => {
          this.messageList = list ? list : [];
          /////////////////////////////////////
          var tab = 1;
          if (list.length == 0) {
            var chatDialog = this.db.object(`Chats/${chatDiologId}`).set({
              "chat_dialog_id": chatDiologId.toString().trim(),
              "buyer_id": this.currentUserId.toString().trim(),
              "dialog_status": 2,
              "seller_id": dataArr.userId.toString().trim(),
              "product_id": dataArr._id.toString().trim(),
              "product_price": dataArr.price,
              "product_image": dataArr.images ? dataArr.images[0].image : "",
              "product_dec": dataArr.description.toString().trim(),
              "product_title": dataArr.description.toString().trim(),
              "participant_ids": participantId,
              "profile_pic": profileArr,
              "name": userNameArr,
              "last_message_time": messageTimeArr,
              "last_message_type": messageTypeArr,
              "last_message_sender_id": "",
              "last_message": lastMessageArr,
              "last_message_id": "",
              "delete_dialog_time": deleteTime,
              "block_status": blockStatus,
              "sub_category_id": dataArr.subCategoryId,
              "category_id": dataArr.categoryId,
              "premium_user": 0,
              "payment_request_time": 0,
              "unread_count": unreadMessageArr,
            });
            /////////////////////////////////
            this.router.navigateByUrl('chat/' + this.currentUserId + "/" + dataArr.userId + "/" + dataArr._id + "/" + tab);
          } else {
            this.router.navigateByUrl('chat/' + this.currentUserId + "/" + dataArr.userId + "/" + dataArr._id + "/" + tab);
          }
        });
      }
    }, err => {
      console.log(err);
    });

  }

  /*rating*/
  confirmSelection(event: KeyboardEvent) {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.isReadonly = true;
    }
  }
 
  resetStars() {
    this.rate = 0;
    this.isReadonly = false;
  }
  openRating(template, data, type){
    this.modalRef = this.modalService.show(template);
    this.ratingDatas = data;
    console.log(this.ratingDatas);
    this.ratingBuyer = type;
  }

  submitRating() {
 
    let params = new HttpParams();
    params = params.append('userId', localStorage.getItem('id'));
    params = params.append('productId', this.ratingDatas[0].productId);
    params = params.append('service', 'service');
    params = params.append('serviceRating', this.serviceRate+'');
    params = params.append('commitment', 'commitment');
    params = params.append('commitmentRating', this.commitmentRate+'');
    params = params.append('description', this.description);

    params = params.append('orderId',  this.ratingDatas[0].orderId);  
    params = params.append('buyerId',  this.ratingDatas[0].buyerId);
    params = params.append('sellerId',  this.ratingDatas[0].sellerId);  

    
    if(this.ratingBuyer == 1){
      if(this.serviceRate == 0 || this.commitmentRate == 0 || this.qualityRate == 0 || this.packagingRate == 0){
        this.showErrorRating = true;
        setTimeout(() => {
          this.showErrorRating = false;
        }, 3000);
        return;
      }
      params = params.append('quality', 'quality');
      params = params.append('qualityRating', this.qualityRate+'');
      params = params.append('package', 'package');
      params = params.append('packageRating', this.packagingRate+'');
      params = params.append('otherId', this.ratingDatas[0].sellerId);
      params = params.append('userType', 'buyer');
      params = params.append('paymentOnTime', 'paymentOnTime');
      params = params.append('paymentOnTimeRating', '0');

    }else{
      if(this.serviceRate == 0 || this.commitmentRate == 0 || this.paymentRate == 0){
        this.showErrorRating = true;
        setTimeout(() => {
          this.showErrorRating = false;
        }, 3000);
        return;
      }
      params = params.append('quality', 'quality');
      params = params.append('qualityRating', '0');
      params = params.append('package', 'package');
      params = params.append('packageRating', '0');
      params = params.append('userType', 'seller');
      params = params.append('paymentOnTime', 'paymentOnTime');
      params = params.append('paymentOnTimeRating', this.paymentRate+'');
      params = params.append('otherId', this.ratingDatas[0].buyerId);
    }
    console.log(params);
    this.api.postData(apiUrl._postFeedback, params)
      .subscribe(async (res) => {
        this.modalRef.hide();
  
        this.serviceRate = 0;
        this.qualityRate = 0;
        this.commitmentRate = 0;
        this.paymentRate = 0;
        this.packagingRate = 0;
        this.description = '';
        
      })

    // this.ratingBusinesslist1 = [];
    // console.log(this.ratingBusinesslist)
    // for (let i = 0; i < this.ratingBusinesslist.length; i++) {
   

    //   this.ratingBusinesslist1.push(this.ratingBusinesslist[i])
   
    // }
    // console.log(this.ratingBusinesslist1)
    // this.ratingBusinesslist.splice(0, 1);
    // this.modalRef.hide();
    // if (this.ratingBusinesslist1.length != 0) {

    //   this.ratingBusinessname = this.ratingBusinesslist1[0].businessName;
    //   this.modalRef = this.modalService.show(this.Rating);
    // }

  }
  close() {

    this.modalRef.hide();
  }
  openSidebar(value){
    if(value == 1){
      this.slideCheck =1;
    }else{
      this.slideCheck = 0;
    }
  }

}
