<ng-template #business>
    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div *ngIf="showUnverifie" class="unverifieDiv">
                    <div class="icon"></div>
                    <div class="bottom">Upload your id proof and get Verified Badge in your profile</div>
                </div>
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close handcursul" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                        <h2>Enter Basic Information</h2>

                    </div>
                    <div class="d-flex flex-column text-center">

                        <form [formGroup]="busineesForm">
                            <div class="form-group position_rel row">
                                <label for="category">User Type</label>
                                <div class="col-12">
                                    <input *ngIf="editBussionProfile" formControlName="userType" type="text" disabled>
                                    <!--  -->
                                    <span *ngIf="!editBussionProfile" class="slt_wrap">
                                        <select formControlName="userType" (change)="userTypeSelect($event)">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let opt of getuserlist" [value]="opt.name">{{opt.name}}
                                            </option>
                                        </select>
                                        <div class="text-danger"
                                            *ngIf="busineesForm.controls['userType'].hasError('required') && showError">
                                            *Please select user Type.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="category">Business Type</label>
                                <div class="col-12">
                                    <input *ngIf="editBussionProfile" formControlName="BusinessType" type="text" disabled>
                                    <span *ngIf="!editBussionProfile" class="slt_wrap">
                                        <select formControlName="BusinessType" (change)="businessTypeSelect($event)">
                                            <option value="">--Select--</option>
                                            <option *ngFor="let opt of busineesTyps" [value]="opt.name">{{opt.name}}
                                            </option>
                                        </select>
                                        <div class="text-danger"
                                            *ngIf="busineesForm.controls['BusinessType'].hasError('required') && showError">
                                            *Please select Business Type.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group position_rel row">
                                <label for="address">{{'homePage.buyer.businessName' | translate}}</label>
                                <div class="col-12">
                                    <input formControlName="BusinessName" type="text" value="" name="" placeholder="John Williams">
                                    <div class="text-danger" *ngIf="busineesForm.controls['BusinessName'].hasError('required') && showError">
                                        *Please enter Business Name.
                                    </div>
                                    <div class="text-danger" *ngIf="busineesForm.controls['BusinessName'].hasError('minlength') && showError">
                                        *Minimum length of 3 characters
                                    </div>
                                    <div class="text-danger" *ngIf="busineesForm.controls['BusinessName'].hasError('maxlength') && showError">
                                        *Maximum length of 45 characters
                                    </div>
                                </div>
                            </div>
                            <div class="profiletxt">Verified Profiles would get more deals as compared to uncertified profiles
                            </div>

                            <button *ngIf="editBussionProfile" type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(loginModal20, 2)" data-target="#loginModal9">Next</button>

                            <button *ngIf="!editBussionProfile" type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(addressType, 1)">Next</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #addressType>
    <div class="log_pop otp_scrn submit_acc add_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close handcursul" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <a class="login_logo"><img src="./assets/images/logo.png" alt=""></a>
                    </div>
                    <div class="d-flex flex-column text-center">
                        <!-- <button *ngIf="!editBussionProfile" type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(loginModal91, 4)">Search Address</button> -->
                        <button *ngIf="!editBussionProfile" type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" data-toggle="modal" (click)="open(loginModal91, 5)">Pick From Map</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<div class="modal fade" bsModal #loginModal91="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="log_pop otp_scrn submit_acc add_pop map_loc location_map_site">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close handcursul" (click)="loginModal91.hide()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="busineesForm">
                        <div class="form-group hide_grp_mob" *ngIf="googleMapForm">
                            <label>Search address</label>
                            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Location" autocorrect="off" autocapitalize="off" formControlName="googleAddress" spellcheck="off" type="text" #search>

                        </div>
                        <div class="form-group" *ngIf="googleMapForm">
                            <div class="srch_opt_in">
                                <label for="address">Pincode</label>
                                <input type="number" value="" formControlName="pincode" name="" placeholder="160022">
                                <div class="text-danger" *ngIf="busineesForm.controls['pincode'].hasError('required') && showError ">
                                    *Enter Pincode.
                                </div>
                                <div class="text-danger" *ngIf="busineesForm.controls['pincode'].hasError('required') && showError ">
                                    *Enter Pincode.
                                </div>
                                <div class="text-danger" *ngIf="pinError">
                                    *Please enter valid Pincode.
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!googleMapForm">
                            <div class="row">
                                <div class="col-8"> <input type="text" (keypress)="keyPressNumbers($event)" value="" formControlName="pincode" name="" placeholder="160022"></div>
                                <div class="col-4">
                                    <a href="javascript:;" class="searchpincode btn_red" (click)="googlePinCode()">Search</a>
                                </div>

                            </div>

                        </div>
                        <div class="form-group" *ngIf="!googleMapForm">
                            <agm-map [latitude]="latitude" [longitude]="longitude">
                                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                            </agm-map>
                        </div>
                        <div class="d-flex flex-column text-center">
                            <div class="form-group hide_grp_mob position_rel" *ngIf="!googleMapForm">
                                <div class="row">
                                    <div class="col-xl-6 col-md-12">
                                        <div class="srch_opt_in">
                                            <label for="address">Address Line 1</label>
                                            <input type="text" value="" formControlName="AddressLine1" name="" placeholder="SCO 10, Sector 22 A">
                                            <div class="text-danger" *ngIf="busineesForm.controls['AddressLine1'].hasError('required') && showError">
                                                *Please enter address.
                                            </div>
                                            <div class="text-danger" *ngIf="addressError">
                                                *Please enter valid address.
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-12 ">
                                        <div class="srch_opt_in">
                                            <label for="address">Address Line 2</label>
                                            <input type="text" formControlName="AddressLine2" value="" name="" placeholder="SCO 10, Sector 22 A">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position_rel row mar_0" *ngIf="!googleMapForm">
                                <div class="col-xl-4 col-xs-6">
                                    <div class="srch_opt_in">
                                        <label for="address">Country</label>
                                        <input type="text" formControlName="Country" value="" name="" placeholder="India">
                                    </div>
                                    <div class="text-danger" *ngIf="countryError">
                                        *Please enter valid Country.
                                    </div>
                                </div>
                                <div class="col-xl-4 col-xs-6">
                                    <div class="srch_opt_in">
                                        <label for="address">State</label>
                                        <input type="text" formControlName="state" value="" name="" placeholder="Punjab">
                                    </div>
                                    <div class="text-danger" *ngIf="stateError">
                                        *Please enter valid State.
                                    </div>
                                </div>
                                <div class="col-xl-4 col-xs-6">
                                    <div class="srch_opt_in">
                                        <label for="address">City</label>
                                        <input type="text" formControlName="city" value="" name="" placeholder="Chandigarh">
                                        <div class="text-danger" *ngIf="busineesForm.controls['city'].hasError('required') && showError ">
                                            *Enter city.
                                        </div>
                                        <div class="text-danger" *ngIf="cityError">
                                            *Please enter valid city.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="use_add d-flex"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 mr-2" (click)="Backopen1(addressType); loginModal91.hide()">Previous</button>
                                <button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 handcursul" data-toggle="modal" (click)="open(loginModal20,2,loginModal91)" data-target="#loginModal20">Next</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loginModal20>
    <div class="log_pop otp_scrn submit_acc add_pop doc_upload_pop">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modulecss">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close handcursul" (click)="cancel()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <h2>Enter Basic Information</h2>
                        <p class="m_bottom">Please upload documents (optional)</p>
                    </div>
                    <div class="d-flex flex-column text-center">
                        <form>
                            <div class="form-group  position_rel row noMargin">
                                <div class="col-xl-6 col-md-6 col-sm-6">
                                    <div class="bx-upl_fl custom-file-upload inputcss">
                                        <input type="file" name="file" id="file-upload" class="" (change)="upload1($event,1)" *ngIf="uploadImage2 ==''" />

                                        <img src="./assets/images/up-doc.svg" for="file-upload" *ngIf="uploadImage2 ==''">

                                        <span class="float-right hand delete iconRight" (click)="removeImage(2)" *ngIf="uploadImage2 !=''">
                                            <img src="./assets/images/cls.svg">
                                        </span>
                                        <span *ngIf="uploadImage2 !=''&& showPdf != 1" for="file-upload">
                                            <a href="{{url + 'users/' + uploadImage2}}" target="_blank">
                                                <img src="{{url + 'users/' + uploadImage2}}" for="file-upload">
                                            </a>
                                        </span>
                                        <span *ngIf="showPdf == 1 " for="file-upload">
                                            <a href="{{url + 'users/' + uploadImage2}}" target="_blank">
                                                <img src="./assets/images/icon_File_104851.svg">
                                                <p>Click Here</p>
                                            </a>

                                        </span>

                                        <span *ngIf="uploadImage2 ==''" for="file-upload">Upload Document 1</span>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-sm-6">
                                    <div class="bx-upl_fl custom-file-upload inputcss">
                                        <input type="file" name="file" id="file-upload" class="" (change)="upload1($event,2)" *ngIf="uploadImage1 ==''" />

                                        <img src="./assets/images/up-doc.svg" for="file-upload" *ngIf="uploadImage1 ==''">
                                        <span class="float-right hand delete iconRight" (click)="removeImage(1)" *ngIf="uploadImage1 !=''">
                                            <img src="./assets/images/cls.svg">
                                        </span>
                                        <span *ngIf="uploadImage1 !='' && showPdf1 != 1" for="file-upload">
                                            <a href="{{url + 'users/' + uploadImage1}}" target="_blank">
                                                <img src="{{url + 'users/' + uploadImage1}}" for="file-upload">
                                            </a>
                                        </span>
                                        <span *ngIf="showPdf1 == 1" for="file-upload">
                                            <a href="{{url + 'users/' + uploadImage1}}" target="_blank">
                                                <img src="./assets/images/icon_File_104851.svg">
                                                <p>Click Here</p>
                                            </a>
                                        </span>
                                        <span *ngIf="uploadImage1 ==''" for="file-upload">Upload Document 2</span>
                                    </div>
                                </div>
                                <div class="col-md-12">

                                    <p class="marginTB"><span class="textred ">*Optional</span><br>Upload Your Business/Personal Proof Id to get the <br> <span class="float-left">Verified
                                            Badge.</span></p>
                                </div>
                            </div>
                            <div class="use_add d-flex"><button type="button" class="btn btn-info btn-block btn-round btn_red sign2_step3 mr-2" data-toggle="modal" (click)="Backopen(loginModal91)" data-target="#loginModal20">Previous</button>
                                <button type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step4" data-toggle="modal" (click)="open(null, 3)" data-target="#loginModal23">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loginModal23></ng-template>

<ng-template #paymentPopup>

    <div class="" role="document">
        <div class="modal-content modulecss extra_padd">
            <div class="modal-header border-bottom-0">
                <button type="button" class="close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex flex-column text-center">
                    <div class="cutomize_tbl mr_50">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col"></th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Yearly Registration Fee</th>
                                    <td></td>
                                    <td>{{allData?.onetimeFee[0]?.amount}}</td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <th scope="row"></th>
                                <td></td>
                                <td><span>Total</span><br><strong><i class="fa fa-rupee"></i>
                                        {{allData?.onetimeFee[0]?.amount}}</strong></td>
                            </tfoot>
                        </table>
                    </div>

                </div>
                <div class="use_add d-flex">
                    <button type="button" class="handcursul btn btn-info btn-block btn-round btn_red sign2_step2" (click)="payment()">Next
                    </button>
                </div>

            </div>
        </div>
    </div>


</ng-template>