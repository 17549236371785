import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-trendslisting2',
  templateUrl: './trendslisting2.component.html',
  styleUrls: ['./trendslisting2.component.css']
})
export class Trendslisting2Component implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  trendslisting3(){
    
    console.log("home")
    // this.reloadCurrentRoute();
    this.router.navigate(['trendslisting3']);
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    // this.loading = false;
  }

}
