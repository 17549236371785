import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { JsonPlaceHolderService } from '../../../services/json-place-holder.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InternalService } from '../../internal.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../../global/global';
import { FcmserviceService } from './../../../services/fcmservice.service';
import {Location} from '@angular/common';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from "@angular/fire/storage";

@Component({
  selector: 'app-dealdetails',
  templateUrl: './dealdetails.component.html',
  styleUrls: ['./dealdetails.component.css']
})
export class DealdetailsComponent implements OnInit {
  id: any;
  editId: any;
  categoryData:any; 
  indexvalue: any = 0;
  allData: any = [];
  addressLabel=false;
  showImageArray: any = [];
  Data: any;
  checkVideo: any;
  step = 0;
  s3url:any;
  hideField=true;
  hideIsDelivery=true;
  hidePriceNegotiate=true;
  hidePrice=true;
  dealID:any;
  selectedVideoImage: any;

// chat 
userImage: any;
currentUserId: any;
chatDialog: any;
participantId: any;
userName: any;
chatDiologId: any;
messageList: any;
userData: any;
userDataS: any;
dataArr: any;


  @ViewChild('video')
  public video: ElementRef;
  constructor(  private route: ActivatedRoute,private _location: Location, private db: AngularFireDatabase,private afAuth: AngularFireAuth, private storage: AngularFireStorage,
    private IS: InternalService, private router: Router, private admin: JsonPlaceHolderService,  private messagingService: FcmserviceService,) {  }

  

  ngOnInit(): void {
    this.dealID = this.route.snapshot.paramMap.get('id');
    this.s3url= localStorage.getItem('s3BaseUrl');
    this.currentUserId = localStorage.getItem('id');
    let urlnumber = window.location.href.split('/')[window.location.href.split('/').length - 1];
    this.id = urlnumber;
     this.getDeal();
  }
  routh() {
    this.router.navigateByUrl('seller/category/edit-product/' + this.id);
  }
  goBack(){
    this._location.back();
  }


  fileTypeChecker(fileType) {
    let valid = [
      "pdf", "tif", "tiff", "jpg", "jpeg", "gif", "png"
    ];

    if (valid.includes(fileType.toLowerCase())) {
      return true
    }
    return false;
  }

  selectImageVideo(i, fileTypes) {
    this.selectedVideoImage = fileTypes;
    this.video.nativeElement.load();
  }

// ====
getDeal() {
  let params = new HttpParams();
  params = params.append('id', this.dealID);
  this.IS.getList(params, apiUrl._getdeal)
    .subscribe(async (res) => { 
      this.allData = res.data[0];
      let imgData = this.allData.productdata ? (this.allData.productdata.images):[];      
      for (var i = 0; i < imgData.length; i++) {
        let fileType = imgData[i].image.split(".")[1];
        let isVideo = true;
        if (this.fileTypeChecker(fileType)) {
          isVideo = false
        }
        let file = {
          fileType: isVideo,
          file: imgData[i].image
        }
        this.showImageArray=[];
        this.showImageArray.push(file);
        
      }      
      console.log(this.allData,"this.allData")
      this.selectedVideoImage = this.showImageArray[0];
      this.categoryData = this.allData.categoryData[0];
      console.log(this.categoryData,"this.allData====")
      const catName=  this.categoryData.name.split(' ').filter(s => s).join(' ').toLowerCase();
      console.log(catName, "catName=====")
      if(catName=="doctor consultancy"){
        this.hideField=false;
        this.hidePrice=false;
        this.hidePriceNegotiate=false;
        this.hideIsDelivery=false;
        this.addressLabel=true;
      }
      if(catName=="lease/sale/rent services"){ 
        this.hidePrice=false;
        this.hideIsDelivery=false;       
      }
      if(catName=="lease/sale/rent services"){ 
        this.hidePriceNegotiate=false;
      }
      if(catName=="cold store"){ 
        const catSubName=  this.allData.productdata?.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
        if(catSubName=="chicken"){ 
          this.hidePriceNegotiate=false;
        }else{
          this.hidePriceNegotiate=true;
        }         
      }
      if(catName=="manpower"){ 
        const catSubName=  this.allData.productdata?.subCategoryName.split(' ').filter(s => s).join(' ').toLowerCase();
        if(catSubName=="poultry manpower"){ 
          this.hidePriceNegotiate=false;
          this.hideIsDelivery=false;
        }else if(catSubName=="poulitrey manpower"){
          this.hidePriceNegotiate=false;
          this.hideIsDelivery=false;
        }else{
          this.hidePriceNegotiate=true;
          this.hideIsDelivery=true;
        }         
      }

    })
}


 

  riderRation(rating) {
    let riderRating = rating ? (rating) : '0';
    //console.log(this.allData?.feedback, "let riderRating")
    let styles = {
      'width': ((riderRating * 2) * 10) + '%'
    };
    return styles;
  }


  createChatDialog(id) {
    const data = {
      id: id,
    }
    let url = apiUrl._getProductDetails;
    this.admin.getData(url, data).subscribe(async (res) => {
      console.log(1)
      var dataArr = res.data[0];
      if (dataArr) {
        if ((res.data.userId) > (this.currentUserId)) {
          var chatDiologId = this.currentUserId + ',' + dataArr.userId + '-' + dataArr._id;
          var participantId = this.currentUserId + ',' + dataArr.userId;
        } else {
          var chatDiologId = dataArr.userId + ',' + this.currentUserId + '-' + dataArr._id;
          var participantId = dataArr.userId + ',' + this.currentUserId;
        }
        var profileArr = {};
        profileArr[dataArr.userId] = dataArr.sellerDetail.profilePic;
        profileArr[this.currentUserId] = this.userImage;

        var userNameArr = {};
        userNameArr[dataArr.userId] = dataArr.sellerDetail.name;
        userNameArr[this.currentUserId] = this.userName;

        var messageTimeArr = {};
        messageTimeArr[dataArr.userId] = new Date().getTime();
        messageTimeArr[this.currentUserId] = new Date().getTime();

        var messageTypeArr = {};
        messageTypeArr[dataArr.userId] = 1;
        messageTypeArr[this.currentUserId] = 1;

        var lastMessageArr = {};
        lastMessageArr[dataArr.userId] = "";
        lastMessageArr[this.currentUserId] = "";
        var deleteTime = {};
        deleteTime[dataArr.userId] = 0;
        deleteTime[this.currentUserId] = 0;

        var blockStatus = {};
        blockStatus[dataArr.userId] = 0;
        blockStatus[this.currentUserId] = 0;

        var unreadMessageArr = {};
        unreadMessageArr[dataArr.userId] = 0;
        unreadMessageArr[this.currentUserId] = 0;

        this.db.list(`Chats`,
          query => query
            .orderByChild("chat_dialog_id")
            .equalTo(chatDiologId)
            .limitToLast(100)
        ).valueChanges().subscribe(list => {
          this.messageList = list ? list : [];
          /////////////////////////////////////
          var tab = 1;
          if (list.length == 0) {
            var chatDialog = this.db.object(`Chats/${chatDiologId}`).set({
              "chat_dialog_id": chatDiologId.toString().trim(),
              "buyer_id": this.currentUserId.toString().trim(),
              "dialog_status": 2,
              "seller_id": dataArr.userId.toString().trim(),
              "product_id": dataArr._id.toString().trim(),
              "product_price": dataArr.price,
              "product_image": dataArr.images ? dataArr.images[0].image : "",
              "product_dec": dataArr.description.toString().trim(),
              "product_title": dataArr.description.toString().trim(),
              "participant_ids": participantId,
              "profile_pic": profileArr,
              "name": userNameArr,
              "last_message_time": messageTimeArr,
              "last_message_type": messageTypeArr,
              "last_message_sender_id": "",
              "last_message": lastMessageArr,
              "last_message_id": "",
              "delete_dialog_time": deleteTime,
              "block_status": blockStatus,
              "sub_category_id": dataArr.subCategoryId,
              "category_id": dataArr.categoryId,
              "premium_user": 0,
              "payment_request_time": 0,
              "unread_count": unreadMessageArr,
            });
            /////////////////////////////////
            this.router.navigateByUrl('chat/' + this.currentUserId + "/" + dataArr.userId + "/" + dataArr._id + "/" + tab);
          } else {
            this.router.navigateByUrl('chat/' + this.currentUserId + "/" + dataArr.userId + "/" + dataArr._id + "/" + tab);
          }
        });
      }
    }, err => {
      console.log(err);
    });

  }

}
